import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Spinner,
  InputGroup,
  Modal,
} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import user from "../../../services/user";
import events from "../../../services/events";
import LinkEvent from "./LinkEvent";
import Payment from "./Payment";
import Pagination from "react-js-pagination";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import 'bootstrap/dist/css/bootstrap.css';
// import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { FaCalendarAlt, FaFileExcel, FaFileDownload } from "react-icons/fa";
import TableLoader from "../Loaders/TableLoader";

LoadingOverlay.propTypes = undefined;

const Payments = (props) => {
  const [userName, baseUrl, userId, apiUrl] = useSelector((state) => {
    return [state.userName, state.baseUrl, state.userId, state.apiUrl];
  });

  //Coustom hook for to firce update child
  const useForceUpdate = () => {
    const [count, setCount] = useState(0);

    const increment = () => setCount((prevCount) => prevCount + 1);
    return [increment, count];
  };

  const [paymentId, setPaymentId] = useState(null);
  const [linkEventModal, setLinkEventModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [forceUpdate, forceUpdateValue] = useForceUpdate();
  const [pageNo, setPageNo] = useState(1);
  const [pageDetails, setPageDetails] = useState(null);
  const [recordsPerPage, setRecordPerPage] = useState(50);
  const [fields, setFields] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [downloadFile, setDownloadFile] = useState(false);

  useEffect(() => {
    getPayments();
  }, []);

  const getPayments = (page = 1) => {
    setLoading(true);
    user
      .payments({ page: page, pageSize: recordsPerPage, fields: JSON.stringify(fields) })
      .then((res) => {
        if (res.data.success) {
          setPayments(res.data.payments);
          setPageDetails(res.data.pages);
          setPageNo(page);
          setSubmitted(false);
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const toggleLinkEventModal = (paymentId = "") => {
    setLinkEventModal(!linkEventModal);
    if (paymentId !== "") {
      setPaymentId(paymentId);
    }
  };
  const unLinkPayment = (payment_id) => {
    if (window.confirm("Are you sure to unlink this payment?")) {
      events
        .unLinkPayment({ payment_id: payment_id })
        .then((res) => {
          if (res.data.success) {
            getPayments();
          } else if (res.data.error) {
            toast.error(res.data.error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const unLinkMultiplePayment = () => {
    if (window.confirm("Are you sure to unlink this payment?")) {
      events
        .unLinkPayment({ payment_id: selectedPayments })
        .then((res) => {
          if (res.data.success) {
            getPayments();
            setSelectedPayments([]);
          } else if (res.data.error) {
            toast.error(res.data.error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const resetSelection = () => {
    setSelectedPayments([]);
  };
  const handleCheckBox = (e) => {
    let paymentIdArr = selectedPayments;
    if (e.target.checked) {
      paymentIdArr.push(e.target.value);
    } else {
      let item_to_be_removed = paymentIdArr.indexOf(e.target.value);
      paymentIdArr.splice(item_to_be_removed, 1);
    }
    setSelectedPayments(paymentIdArr);
    forceUpdate();
  };

  const handlePageChange = (pageNo) => {
    getPayments(pageNo);
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    getPayments();
  };
  const handleCustomChange = (e) => {
    let date_range =
      moment().format("MM-DD-YYYY") + " to " + moment().format("MM-DD-YYYY");
    setFields({ ...fields, ["date_range"]: "" });
  };
  const handleCallback = (startDate, endDate) => {
    let date_range =
      startDate.format("MM-DD-YYYY") + " to " + endDate.format("MM-DD-YYYY");
    setFields({ ...fields, ["date_range"]: date_range });
  };

  const exportExcel = () => {
    user
      .exportPayments({ export: true })
      .then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data], {
            type: res.data.type,
            encoding: "UTF-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = "payments-" + moment().format("MM-DD-YYYY") + ".xlsx";
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const deletePayment = (id) => {
    if (window.confirm("Are you sure to delete?" + id)) {
      let params = { id: id };
      user
        .deletePayment(params)
        .then((response) => {
          if (response.data.success) {
            let newpayments = payments.filter(
              (payment) => parseInt(payment.id) !== parseInt(id)
            );
            setPayments(newpayments);
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit} className="search-container">
        <Row>
          <Col md={2}>
            <FormGroup row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Transaction ID"
                  onChange={(e) => {
                    handleChange(e, "payment_identifier");
                  }}
                  value={
                    fields["payment_identifier"]
                      ? fields["payment_identifier"]
                      : ""
                  }
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={3}>
            <Form.Control
              type="text"
              placeholder="Name"
              onChange={(e) => {
                handleChange(e, "name");
              }}
              value={fields["name"] ? fields["name"] : ""}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              type="text"
              placeholder="Amount"
              onChange={(e) => {
                handleChange(e, "amount");
              }}
              value={fields["amount"] ? fields["amount"] : ""}
            />
          </Col>
          <Col md={3}>
            {/* <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FaCalendarAlt />
              </InputGroup.Text>
              <DateRangePicker
                initialSettings={{
                  locale: { format: "MM-DD-YYYY", cancelLabel: "Clear" },
                  ranges: fields.ranges,
                  autoUpdateInput: false,
                  startDate: moment().format("MM-DD-YYYY"),
                  endDate: moment().format("MM-DD-YYYY"),
                }}
                onCallback={handleCallback}
                onCancel={(e) => {
                  handleCustomChange(e);
                }}
              >
                <input
                  className="form-control"
                  name="date_range"
                  value={
                    fields["date_range"] &&
                    fields["date_range"]
                  }
                />
              </DateRangePicker>
            </InputGroup> */}
          </Col>
          <Col md={2} className="text-end">
            <Button
              type="submit"
              className="btn-block"
              variant="success"
              disabled={submitted}
            >
              {submitted && <Spinner size="sm" />} Search
            </Button>
            <Button type="button" className="ms-2" variant="warning" onClick={() => exportExcel()}>
              <FaFileExcel size="1em" className="mb-1 mr-1" />
              Export
            </Button>
          </Col>
        </Row>
      </Form>
      <LoadingOverlay active={loading} text={<TableLoader />}>
    
        <Table
          className="table-responsive customise_table mt-3"
          style={{ minHeight: 300 }}
        >
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Payment Id</th>
              <th scope="col">Amount</th>
              <th scope="col">Service Name</th>
              <th scope="col">Received At</th>
              <th scope="col">Linked To Event</th>
            </tr>
          </thead>
          <tbody>
            {payments.length > 0 ? (
              payments.map((payment, index) => (
                <Payment
                  payment={payment}
                  key={index}
                  index={index}
                  handleCheckBox={handleCheckBox}
                  toggleLinkEventModal={toggleLinkEventModal}
                  unLinkPayment={unLinkPayment}
                  selectedPayments={selectedPayments}
                  reloadPayments={getPayments}
                  deletePayment={deletePayment}
                  pageNo={pageNo}
                />
              ))
            ) : (
              <tr key={99999}>
                <td className="text-center" colSpan="9">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Row>
          <Col>
            <Button
              className="btn btn-danger me-3"
              onClick={toggleLinkEventModal}
              disabled={selectedPayments.length === 0}
            >
              Link
            </Button>
            <Button
              className="btn btn-info"
              onClick={unLinkMultiplePayment}
              disabled={selectedPayments.length === 0}
            >
              UnLink
            </Button>
          </Col>
          <Col className="text-right">
            {pageDetails !== null &&
              parseInt(pageDetails.totalCount) > recordsPerPage && (
                <Pagination
                  activePage={pageNo}
                  itemsCountPerPage={recordsPerPage}
                  totalItemsCount={pageDetails.totalCount}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              )}
          </Col>
        </Row>
      </LoadingOverlay>
      {linkEventModal && (
        <LinkEvent
          showModal={linkEventModal}
          closeModal={setLinkEventModal}
          paymentId={paymentId}
          selectedPayments={selectedPayments}
          resetSelection={resetSelection}
          getPayments={getPayments}
        />
      )}
      {downloadFile && (
        <Modal isOpen={downloadFile} size="sm">
          <Modal.Body className="text-center">
            <a
              href={`${apiUrl}/web/exports/payments-${moment(new Date()).format(
                "MM-DD-YYYY"
              )}.xls`}
              className="btn btn-lg btn-success"
            >
              <FaFileDownload /> Download
            </a>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => setDownloadFile(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Payments;
