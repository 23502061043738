/* eslint-disable react/prop-types */
import { Button, Modal, Spinner, FloatingLabel, Form } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { connect } from "react-redux";


class AddEditPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            records: [],
            showModal: false,
            fields: { event_id: this.props.eventId },
            errors: {},
            submitted: false,
        };
    }
    handleChange = (e, field) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    };
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Please enter plan name.";
        }
        if (!fields["duration"]) {
            formIsValid = false;
            errors["duration"] = "Please choose duration.";
        }
        if (!fields["price"]) {
            formIsValid = false;
            errors["price"] = "Please enter price.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    handlePlanSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            if(!this.props.eventId){
                this.props.updateRecords(this.state.fields);
            }
            else{
                this.setState({ submitted: true });
                let params = { fields: this.state.fields };
                events.savePlan(params).then((res) => {
                    if (res.data.success) {
                        this.setState({ submitted: false }, () => {
                            this.props.listRecords();
                            this.props.closeModal();
                            toast.success(res.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        });
                    } else if (res.data.error) {
                        this.setState({ errors: res.data.message, submitted: false });
                    }
                })
                .catch(() => {
                    toast.error("Unexpected Error !", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
            }
        }
    };
    componentDidMount = () => {
        if (this.props.planId !== null) {
            this.getPlanDetails();
        }
    }

    getPlanDetails = () => {
        this.setState({ loader: true });
        events
            .getPlanDetails({
                id: this.props.planId
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ fields: res.data.plan, loader: false });
                } else if (res.data.error) {
                    console.log(res.data.message);
                }
                this.setState({ loader: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    render() {
        return (
            <Modal 
                show={this.props.showModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                size="md"
            >
                <Modal.Header closeButton>Add Plan</Modal.Header>
                <Modal.Body>
                    <LoadingOverlay
                        active={this.state.loader}
                        spinner
                        text="Loading..."
                    >
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            className="mb-3"
                            >
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="name"
                                onChange={(event) => this.handleChange(event, "name")}
                                isInvalid={this.state.errors.name}
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.name}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Duration(In Days)"
                            className="mb-3"
                            >
                            <Form.Control
                                type="number"
                                name="duration"
                                placeholder="duration"
                                onChange={(event) => this.handleChange(event, "duration")}
                                isInvalid={this.state.errors.duration}
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.duration}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Price"
                            className="mb-3"
                            >
                            <Form.Control
                                type="number"
                                name="price"
                                placeholder="price"
                                onChange={(event) => this.handleChange(event, "price")}
                                isInvalid={this.state.errors.price}
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.price}</Form.Control.Feedback>
                        </FloatingLabel>
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        type="button"
                        disabled={this.state.submitted}
                        onClick={this.handlePlanSubmit}
                    >
                        {this.state.submitted && (
                            <Spinner
                                size="sm"
                                variant="light"
                                className="mr-1"
                            />
                        )}
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(AddEditPlan);
