/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, ModalBody, ModalHeader
  } from "react-bootstrap";
  import { connect } from "react-redux";
import MutualAidForm from "./MutualAidForm";

class MutualAidFormButton extends Component {
  constructor(props) {
    super(props);
        this.state = {
            showFormModal:false
        };
    }
  render() {
    return (
        <>
        <Button
          color="warning"
          className="btn-lg btn-block"
          type="button"
          onClick={() => this.setState({showFormModal:true})}
        >
          <strong>Mutual Aid Form</strong>
        </Button>
        <p className="text-center"><strong>Note:</strong> Click here to submit mutual aid form.</p>
        
        {this.state.showFormModal && 
            <Modal isOpen={this.state.showFormModal} size="lg">
                <ModalHeader toggle={() => this.setState({showFormModal:false})}>Mutual Aid Form</ModalHeader>
                <ModalBody>fadsfasd
                    <MutualAidForm eventDetails={this.props.eventData} redirect={false} closeModal={()=>this.setState({showFormModal:false})}/>
                </ModalBody>
            </Modal>
        }
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(MutualAidFormButton);


