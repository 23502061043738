/* eslint-disable react/prop-types */
import {
  Button,
  Table,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Spinner,
  Badge,
} from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import user from "../../../services/user";
import moment from "moment";
import { connect } from "react-redux";
import { FaListAlt, FaCheckCircle, FaFileExcel } from "react-icons/fa";
import Pagination from "react-js-pagination";
import common from "../../../services/common";
import TableLoader from "../Loaders/TableLoader";

class ListBeneficiaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      loader: false,
      beneficiaries: [],
      pageDetails: {},
      disabled: false,
      showDonorDetailModal: false,
      fields: {},
      errors: {},
      submitted: false,
      events: [],
      selectedRecords: [],
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  handleSubmit = (e = null) => {
    if (e) e.preventDefault();
    this.setState({ submitted: true }, () => {
      this.listDonors();
    });
  };

  listBeneficiaries = () => {
    this.setState({ loader: true });
    user
      .getBeneficiaries({
        event_id: this.props.selectedEventId,
        page: this.state.pageNo,
        fields: this.state.fields,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            beneficiaries: res.data.beneficiaries,
            loader: false,
            submitted: false,
            pageDetails: res.data.pages,
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.listBeneficiaries();
  };

  deleteBeneficiary = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id };
      events
        .deleteBeneficiary(params)
        .then((response) => {
          if (response.data.success) {
            let beneficiaries = this.state.beneficiaries.filter(
              (beneficiary) => parseInt(beneficiary.id) !== parseInt(id)
            );
            this.setState({ beneficiaries }, () => {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  deleteMultipleDonors = () => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: this.state.selectedDonors };
      events
        .deleteBeneficiary(params)
        .then((response) => {
          if (response.data.success) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ selectedDonors: [] }, () => this.listDonors());
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.listDonors();
    });
  };
  selectRecord = (e) => {
    let selectedRecords = this.state.selectedRecords;
    if (e.target.checked) {
      selectedRecords.push(e.target.value);
    } else {
      let index_to_be_removed = selectedRecords.indexOf(e.target.value);
      selectedRecords.splice(index_to_be_removed, 1);
    }
    this.setState({ selectedRecords });
  };
  selectAll = (e) => {
    let checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((c) => {
      c.checked = e.target.checked;
    });
    let selectedRecords = [];
    if (e.target.checked) {
      this.state.beneficiaries.forEach((d, index) => {
        selectedRecords[index] = d.id;
      });
    }
    this.setState({ selectedRecords });
  };
  /*exportExcel = () =>{
    this.setState({loader:true});
    user.exportDonors({export:true}).then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data],{ type: res.data.type, encoding: 'UTF-8' })
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = 'donors-'+moment().format("MM-DD-YYYY")+'.xlsx';
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({loader:false});
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  } */
  render() {
    return (
      <>
        {/* <Form onSubmit={this.handleSubmit}>
            <Row>
                <Col>
                    <FormGroup row>
                        <Col>
                            <Input type="text" placeholder="Donor Name" onChange={(e) => {
                                this.handleChange(e, "name");
                            }}
                                value={
                                    this.state.fields["name"]
                                        ? this.state.fields["name"]
                                        : ""
                                }
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row>
                        <Col>
                            <Input type="text" placeholder="Donor Email" onChange={(e) => {
                                this.handleChange(e, "email");
                            }}
                                value={
                                    this.state.fields["email"]
                                        ? this.state.fields["email"]
                                        : ""
                                }
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row>
                        <Col>
                            <Input type="number" placeholder="Amount" onChange={(e) => {
                                this.handleChange(e, "amount");
                            }}
                                value={
                                    this.state.fields["amount"]
                                        ? this.state.fields["amount"]
                                        : ""
                                }
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col>
                    <Button type="submit" className="btn-block" variant="success" disabled={this.state.submitted}>{this.state.submitted && <Spinner size="sm"/>} Search</Button>
                </Col>
            </Row>
        </Form> */}
        {/* <LoadingOverlay active={this.state.loader} text={<TableLoader />}> */}
        {this.state.beneficiaries.length > 0 && <Row>
            <Col md={12} className="text-end">
              <Button
                type="button"
                variant="warning"
                onClick={() => this.exportExcel()}                
              >
                <FaFileExcel size="1em" className="mb-1 mr-1" />
                Export
              </Button>
            </Col>
          </Row>}
          
          <Table className="table table-responsive customise_table mt-2">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" onChange={this.selectAll} value={0} />
                </th>
                <th>#</th>
                <th>Name</th>
                <th>Father's Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.beneficiaries.length > 0 ? (
                this.state.beneficiaries.map((ele, index) => (
                  <tr key={index}>
                    <th>
                      <input
                        type="checkbox"
                        onChange={this.selectRecord}
                        value={ele.id}
                      />
                    </th>
                    <td>
                      {this.state.pageNo === 1
                        ? index + 1
                        : (this.state.pageNo - 1) * 50 + index + 1}
                      .{" "}
                    </td>
                    <td>{ele.beneficiary_name}</td>
                    <td>{ele.fathers_name}</td>
                    <td> {ele.beneficiary_email}</td>
                    <td> {ele.beneficiary_phone}</td>
                    <td> {ele.beneficiary_city}</td>
                    <td>
                      <Button
                        type="button"
                        className="btn-sm mr-1"
                        variant="danger"
                        onClick={() => this.deleteBeneficiary(ele.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="8"
                    className="text-center"
                    style={{ height: 300 }}
                  >
                    Record not found.
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="2">
                  <Button
                    type="button"
                    variant="danger"
                    disabled={this.state.selectedRecords.length === 0}
                    onClick={this.deleteMultipleDonors}
                  >
                    <Badge
                    bg="secondary"                      
                      style={{ width: "auto", overflow: "auto" }}
                    >
                      {this.state.selectedRecords.length}
                    </Badge>{" "}
                    Delete
                  </Button>
                </td>
                <td colSpan="6">
                  <div className="d-flex justify-content-end">
                  <Pagination
                    activePage={this.state.pageNo}
                    itemsCountPerPage={30}
                    totalItemsCount={
                      this.state.pageDetails.totalCount
                        ? parseInt(this.state.pageDetails.totalCount)
                        : 0
                    }
                    pageRangeDisplayed={5}
                    onChange={(e) => this.handlePageChange(e)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        {/* </LoadingOverlay> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ListBeneficiaries);
