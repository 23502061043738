/* eslint-disable react/prop-types */
import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const PassedEventModal = (props) => {
  return (
    <Modal
      size="md"
      isOpen={props.showModal}
    >
      <ModalHeader
        toggle={() => {
          props.closeModal(false);
        }}
        className="bg-danger"
      >
        Event has ended!
      </ModalHeader>
      <ModalBody className="text-center">
        <p className="text-center">
            <Button
                color="danger"
                /* className="btn-sm mt-3" */
                onClick={() => props.closeModal(false)}
            >Close</Button>
        </p>
      </ModalBody>
    </Modal>
  );
};

export default PassedEventModal;
