/* eslint-disable react/prop-types */
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Spinner, ModalFooter
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import events from "../../../services/events";
import { Link } from "react-router-dom"

function AddDcCc(props) {
  const [loader, setLoader] = useState(false);
  const [checkingSetting, setCheckingSetting] = useState(true);
  const [isPaypalLinked, setIsPayPalLinked] = useState(false);

  useEffect(() => {
    if (props.selectedAppId === "73") {
      events
        .isPaypalLinked().then((res) => {
          if (res.data.success) {
            setIsPayPalLinked(true)
          } else if (res.data.error) {
            setIsPayPalLinked(false)
          }
          setCheckingSetting(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      events.isEdgePayLinked().then((res) => {
        if (res.data.success) {
          setIsPayPalLinked(true)
        } else if (res.data.error) {
          setIsPayPalLinked(false)
        }
        setCheckingSetting(false);
      })
        .catch((err) => {
          console.log(err);
        });
    }

  }, []);

  return <Modal show={props.isOpen} onHide={props.resetPaymentService} backdrop="static" keyboard={false} centered>
    <ModalHeader closeButton className="bg-danger text-light">Have you configured your {props.selectedAppId === '73' ? 'PayPal' : 'EdgePay'} Merchant account?</ModalHeader>
    {checkingSetting && <ModalBody className="text-center p-3"><Spinner style={{ width: 50, height: 50 }} /></ModalBody>}
    {!isPaypalLinked && !checkingSetting && <ModalBody className="p-3">
      {props.selectedAppId === '73' ? <><strong>Error:</strong> It seems you have not configured your PayPal Merchant account to receive payments. Please <Link to="/settings#paypal"><strong>Click here</strong></Link> to configure.</> : <><strong>Error:</strong> It seems you have not configured your EdgePay Merchant account to receive payments. Please <a href="https://tiphub.iso-dev.gettrx.com/cfd2d654-84c6-43d9-872d-c2b6f26ff050/signup"><strong>Click here</strong></a> to register yourself as EdgePay merchant. If you have already registered then <Link to="/settings/#edgepay"><strong>click here</strong></Link> to enter your details to start receiving funds.</>}
    </ModalBody>}

    {isPaypalLinked && <ModalFooter>
      <Button
        variant="success"
        type="button"
        onClick={() => props.handleSubmit()}
      >
        {loader && (
          <Spinner
            color="light"
            component="span"
            size="sm"
          />
        )}
        Yes
      </Button>
      {props.selectedAppId === '73' ? <Link className="btn btn-danger ml-2" to="/settings#paypal">No</Link> : <a className="btn btn-danger ml-2" href="https://tiphub.iso-dev.gettrx.com/1ccd7e68-d1db-4015-9a28-238ec35883fa/signup">No</a>}
    </ModalFooter>}
  </Modal>
}
export default AddDcCc;