import React from "react";
import { Col, Row, Container } from "react-bootstrap";

const UserNotFound = (props) => {
    return <div className="defalut-overview tiphub-banner pb-5">
        <div className="tiphub-banner-bg h-100"></div>
        <div className="d-flex justify-content-center  pb-5">
            <div className="float-left"><img src="/assets/under-review.png"/></div>
            <div className="float-left align-self-center">
                <Container className="text-center">
                    <h3>404, User not found.</h3>
                    <h5>Please make sure username is correct.</h5>
                </Container>
            </div>
        </div>
    </div>;
}

export default UserNotFound;