/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Form, 
  Button,
  Col,
  Modal,
  Spinner,
  Row
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import events from "../../../services/events";

const LinkEvent = (props) => {
  const [userName, baseUrl, userId] = useSelector((state) => {
    return [state.userName, state.baseUrl, state.userId];
  });
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [allEvents, setEvents] = useState([]);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  useEffect(() => {
    events
      .listByUser()
      .then((res) => {
        if (res.data.success) {
          setEvents(res.data.events);
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Unknown error!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let payment_id = null;
      setSubmitted(true);
      if (props.selectedPayments.length > 0 && typeof props.paymentId === "object") {
        payment_id = props.selectedPayments;
      } else {
        payment_id = [props.paymentId];
      }
      events
        .linkPayment({ event_id: fields.event_id, payment_id })
        .then((response) => {
          if (response.data.success) {
            props.resetSelection();
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.closeModal();
            props.getPayments();
          } else if (response.data.error) {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setErrors({ errors });
          setSubmitted(false);
        });
    }
  };
  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.event_id) {
      errors["event_id"] = "Pleaes choose event from the list!";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };
  return (
    <Modal
    show={props.showModal}
    onHide={props.closeModal}
    backdrop="static"
    keyboard={false}
    centered 
    >
      <Modal.Header
        closeButton
      >
        Link Event
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} method="post">
          <Row>
            <Form.Group as={Col} md={8}>
              <Form.Select
                name="event_id"
                id="event_id"
                onChange={(e) => handleChange(e, "event_id")}
                invalid={errors["event_id"] ? true : false}
              >
                <option value="">-Select Event-</option>
                {allEvents.map((event, index) => (
                  <option value={event.id} key={index}>
                    {event.title}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback invalid>{errors["event_id"]}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Button variant="success" disabled={submitted} type="submit">
                {submitted && <Spinner size="sm" />} Save
              </Button>
          </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LinkEvent;
