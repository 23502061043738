/* eslint-disable react/prop-types */
import { Button, Modal, InputGroup, Form, Spinner } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { connect } from "react-redux";

class AddEditGroupMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      records: [],
      showModal: false,
      fields: { event_id: this.props.eventId },
      errors: {},
      submitted: false,
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  componentDidMount = () => {
    if (this.props.teamId) {
      this.getGroupDetails();
    }
  };
  getGroupDetails = () => {
    this.setState({ loader: true });
    events
      .getGroupDetails({
        id: this.props.teamId,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ fields: res.data.groups, loader: false });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = "Please enter name of the team member.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
        this.setState({ submitted: true });
        let params = { fields: this.state.fields };
        if (this.props.eventId) {
          events.saveGroup(params).then((res) => {
            if (res.data.success) {
                this.setState({ submitted: false }, () => {
                    this.props.listRecords();
                    this.props.closeModal();
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
            } else if (res.data.error) {
                this.setState({ errors: res.data.message, submitted: false });
            }
        })
        .catch(() => {
            toast.error("Unexpected Error !", {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
        }else{
          this.props.updateRecords(this.state.fields);
        }
    }
  }

  render() {
    return (
      <Modal show={this.props.showModal}
        onHide={this.props.closeModal}
        backdrop="static"
        keyboard={false}
        centered
        size="sm"
      >
        <Modal.Header toggle={() => this.props.closeModal()}>
          Add New Group
        </Modal.Header>
        <Modal.Body>
          <LoadingOverlay active={this.state.loader} spinner text="Loading...">
            <InputGroup className="mb-3" size="lg" hasValidation>
                <Form.Control
                    name="name" 
                    type="text" 
                    onChange={(event) => this.handleChange(event, 'name')} 
                    isInvalid={!!this.state.errors.name}
                />
                <Button type="submit" variant="success" disabled={this.state.submitted}>{this.state.submitted && <Spinner animation="border" variant="light" className="me-1" style={{width:20, height:20}}/>}Submit</Button>
                <Form.Control.Feedback type="invalid">{this.state.errors.name}</Form.Control.Feedback>
            </InputGroup>
          </LoadingOverlay>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddEditGroupMember;
