import axios from "axios";
import store from "../redux/store";
import { toast } from "react-toastify";

let apiUrl;
if(document.URL.search("localhost")){
  // apiUrl = "https://tiphub.mitiztechnologies.in/api";
  apiUrl = "https://tiphub.co/api";
}
else{
  apiUrl = "https://tiphub.co/api";
}


const http = axios.create({
  baseURL: apiUrl,
  //timeout: 1000,
  headers: { 'Content-Type': 'application/json' }
});

// if (store.getState().token) {
//   http.defaults.headers.common['X-Api-Key'] = `Bearer ${store.getState().token}`;
// }

// New Code ------
http.interceptors.request.use(
  config => {
    const token = store.getState()?.token;
    if (token) {
      config.headers['X-Api-Key'] = `Bearer ${token}`;
    }
    return config;
  }
);
// End New Code ------

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error);
    if (error.response.status === 401) {
      window.location.href = "/logout";
    } else if (error.response.status === 404) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (error.response.status === 500) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (error.response.status === 403) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (error.response.status === 415) {
      toast.error(error.message + " - Unsupported media type. Please upload image file only.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }else {
      console.log(error);
    }
    return Promise.reject(error);
  }
);

export default http;