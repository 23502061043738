import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

class TermsAndConditions extends Component {
  render() {
    return (
      <div className="dashboard-banner">
        <div className="tiphub-banner-bg"></div>
        <Container>
          <Row>
            <Col sm="12" className="mt-5">
              <h1>Terms and conditions</h1>
              <hr className="mt-0" />
              <p>These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the{" "}<a target="_blank" rel="nofollow" href="http://tiphub.co"> Tiphub Payments Inc</a>{" "} website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or
                “your”) and this Website operator (“Operator”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a
                business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and the Operator,
                even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.
              </p>
              <h4 className="mb-1">Accounts, payments and membership</h4>
              <p>
                If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully
                responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but
                have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false
                contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.
              </p>
              <p>
                Tiphub operates  as a payment splitting system based on the total amount received for services rendered or transactions conducted. The disbursement of funds will be distributed to the respective parties involved according to the agreed-upon terms and conditions. The splitting of payments is contingent upon the total amount received by Tiphub and will be executed in a fair and transparent manner. Tiphub reserves the right to adjust the disbursement process if necessary, ensuring equitable distribution among all relevant parties. Any disputes or discrepancies related to payment splitting should be reported to Tiphub promptly for resolution. By engaging in transactions with Tiphub, users agree to abide by these payment splitting terms and conditions.
              </p>
              <h4 className="mb-1">User content</h4>
              <p>
                We do not own any data, information or material (collectively, “Content”) that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability,
                appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no obligation to, monitor and review the Content on the Website submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. You also grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.
              </p>
              <h4 className="mb-1">Backups</h4>
              <p>
                We perform regular backups of the Website and its Content, however, these backups are for our own administrative purposes
                only and are in no way guaranteed. You are responsible for maintaining your own backups of your data. We do not provide
                any sort of compensation for lost or incomplete data in the event that backups do not function properly. We will do our
                best to ensure complete and accurate backups, but assume no responsibility for this duty.
              </p>
              <h4 className="mb-1">Links to other resources</h4>
              <p>
                Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not,
                directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked
                resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant
                the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or
                liability for the actions, products, services, and content of any other third parties. You should carefully review the legal
                statements and other conditions of use of any resource which you access through a link on the Website. Your linking to any
                other off-site resources is at your own risk.
              </p>
              <h4 className="mb-1">Changes and amendments</h4>
              <p>
                We reserve the right to modify this Agreement or its terms related to the Website and Services at any time at our
                discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in
                other ways at our discretion, such as through the contact information you have provided.
              </p>
              <p>
                An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless
                otherwise specified. Your continued use of the Website and Services after the effective date of the revised Agreement (or
                such other act specified at that time) will constitute your consent to those changes.
              </p>
              <h4 className="mb-1">For Dwolla Users</h4>
              <p>In order to use the payment functionality of our application, you must open a "Dwolla Account" provided by Dwolla, Inc. and you must accept the <a href="https://www.dwolla.com/legal/tos" target="_blank">Dwolla Terms of Service</a> and <a href="https://www.dwolla.com/legal/privacy" target="_blank">Privacy Policy</a>. Any funds held in or transferred through your Dwolla Account are held or transferred by Dwolla's <a href="https://www.dwolla.com/legal/about-our-financial-institution-partners/" target="_blank">financial institution</a> partners. You must be at least 18 years old to create a Dwolla Account. You authorize us to collect and share with Dwolla your personal information including full name, date of birth, social security number, physical address, email address and financial information, and you are responsible for the accuracy and completeness of that data. You understand that you will access and manage your Dwolla Account through our application, and Dwolla account notifications will be sent by us, not Dwolla. <br />
                [We will provide customer support for your Dwolla Account activity, and can be reached at  <a href="https://tiphub.co/" target="_blank">tiphub.co</a>, <a href="mailto:contact@tiphub.co">contact@tiphub.co</a>.]</p>

              <p>If you register to create an account to receive payments via our application, you expressly authorize our service provider, Dwolla, Inc. to originate credit transfers to your financial institution account. You must be at least 13 years old and obtain parental permission if under 18 to receive funds. You authorize us to collect and share with Dwolla your personal information including full name, email address and financial information, and you are responsible for the accuracy and completeness of that data. <a href="https://www.dwolla.com/legal/privacy/" target="_blank">Dwolla’s Privacy Policy</a> is availablehere.</p>

              <h4 className="mb-1">Acceptance of these terms</h4>
              <p>
                You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the
                Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement,
                you are not authorized to access or use the Website and Services.
              </p>
              <h4 className="mb-1">Contacting us</h4>
              <p>
                If you have any questions, concerns, or complaints regarding
                this Agreement, we encourage you to contact us using the
                details below:
              </p>
              <p>https://twitter.com/Tiphub_co</p>
              <p>This document was last updated on February 12, 2024</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default TermsAndConditions;
