import React, { Component } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {Col,Row,Spinner,Card} from "react-bootstrap";
import events from "../../../services/events";

import { connect } from "react-redux";
import MutualAidForm from "./MutualAidForm";

class MutualAidRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      eventDetails:{},
    };
  }
  fetchEventDetails = () => {
    this.setState({ loader: true })
    let params = { id: this.props.match.params.id };
    events
      .getBasicDetails(params)
      .then((res) => {
        if (res.data.success) {
          this.setState({ eventDetails: res.data.event, loader: false })
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.history.push(`${this.props.baseUrl}`);
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  componentDidMount = () => {
    this.fetchEventDetails();
  }
  
  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Mutual Aid Reqeust : Tiphub.co</title>
        </Helmet>
        <section className="login-enter-section mb-md-5">
          <div id="lp-register">
            <div className="container wrapper">
              <Row className="mt-4">
                <Col sm={7} md={7} lg={7} className="mx-auto">
                    <Card>
                        <h2 className="mt-3 text-center">Mutual aid request form</h2>
                        <p className="text-center reset mb-3">To request funds, please fill out this form.</p>
                        {this.state.loader ? <div className="d-block text-center p-5"><Spinner/></div>:<Row>
                            <Col md={12} className="text-center" style={{minHeight:100}}><img src={`${this.props.apiUrl}/web/events/${this.state.eventDetails.image}`} className="img-fluid px-2 mb-4" alt="Responsive image"/></Col>
                            <Col md={12}>
                                <MutualAidForm eventDetails={this.state.eventDetails} redirect={true}/>
                            </Col>
                        </Row>}
                    </Card>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
    return {
      baseUrl: state.baseUrl,
      apiUrl: state.apiUrl,
      userId: state.userId,
    };
  };
  export default connect(mapStateToProps)(MutualAidRequest);