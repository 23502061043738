import React, { useEffect, useState } from 'react'
import { Card,Button,Row,Col,Modal, Spinner, Form, FloatingLabel } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'
import {useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FaCopy, FaSave } from 'react-icons/fa'
import user from "../../../services/user";
import { useNavigate } from 'react-router-dom';

import { FaTrash } from "react-icons/fa";
import domtoimage from 'dom-to-image';

const BasicProfile = (props) => {
  const [userName, baseUrl, apiUrl, userImage] = useSelector((state) => {
    return [state.userName, state.baseUrl, state.apiUrl, state.userImage]
  })
  
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({})

  const [showDeleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateForm = () => {
    let formIsValid = true
    const errors = {}
    if (!fields.username) {
      errors['username'] = 'Username can not be empty'
      formIsValid = false
    }
    if (!fields.name) {
        errors['name'] = 'Name can not be empty'
        formIsValid = false
      }
    setErrors(errors)
    return formIsValid
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
        setLoader(true)
        let params = { fields: fields }
        user.updateProfile(params).then((res) => {
            if (res.data.success) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
              let payload = {
                userName: res.data.username,
                uName: res.data.name,
                userImage: res.data.image,
              };
              localStorage.setItem("userName", res.data.username);
              localStorage.setItem("uName", res.data.name);
              localStorage.setItem("userImage", res.data.image);
              dispatch({type:"update-profile", ...payload});
            } else if (res.data.error) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
              setErrors(res.data.message)
            }
            setLoader(false)
          }).catch((err) => {
            toast.error('Unexpected error !', {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
      }
  }

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value })
  }

  const getLoggedInUser = () => {
    user.getLoggedInUser().then((res) => {
        if (res.data.success) {
            setFields(res.data.user);
        } 
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getLoggedInUser();
  }, [])

  const genImage = () =>{
    var node = window.document.getElementById('test-div');

    domtoimage.toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }
  const deleteAccount = () =>{
    setLoader(true);
    user.deleteAccount({delete:true}).then((res) => {
        if (res.data.success) {
            setLoader(false);
            navigate("/logout");
        }
        }).catch(() => {
            setLoader(false);
            toast.error("Unexpected Error !", {
                position: toast.POSITION.TOP_RIGHT,
        });
    });
  }

  const uploadFile = (e) => {
      setImgLoader(true);
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      user.uploadProfileImage(formData).then((res) => {
          if (res.data.success) {
            setFields({ ...fields, image: `temp/${res.data.file.file_name}`,file:res.data.file })
          } else if (res.data.error) {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            })
          }
          setImgLoader(false);
      }).catch((err) => {
          toast.error('Unexpected Error!', {
            position: toast.POSITION.TOP_RIGHT,
          })
      })
  }
  return (
      <>
        <Card className='modified-card'>
              <Card.Header>
                  <Row>
                      <Col><h4 className="mb-0 p-2">Basic Profile</h4></Col>
                      <Col className="text-end mt-2">
                        <Button className='btn-sm' variant="danger" type="button" onClick={() => setDeleteModal(true)}>
                        <FaTrash /> Delete Account</Button>
                      </Col>
                  </Row>
              </Card.Header>
              <Card.Body>
                  <Form onSubmit={handleSubmit} method="post" className='p-5'>
                    <FloatingLabel
                      label="Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        name="name"
                        placeholder="name"
                        onChange={(event) => handleChange(event, "name")}
                        isInvalid={errors.name}
                        value={fields.name !== undefined ? fields.name : ''}
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      label="Username"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder="username"
                        onChange={(event) => handleChange(event, "username")}
                        isInvalid={errors.username}
                        value={fields.username !== undefined ? fields.username : ''}
                      />
                      <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                    </FloatingLabel>
                    <Row>
                      <Form.Group as={Col} md={6}>
                            <Form.Label id="profile_image">User Profile Image</Form.Label>
                              <Form.Control
                                  type="file"
                                  id="file"
                                  name="file"
                                  onChange={(event) => uploadFile(event)}
                                  /* style={{ border: '1px solid #e4e7ea' }} */
                              />
                      </Form.Group>
                      <Form.Group as={Col}  md={6}>
                        <LoadingOverlay active={imgLoader} spinner text="Loading. Please wait...">
                          <img
                              src={
                                  fields['image'] ? `${apiUrl}/web/profile_images/${fields['image']}` : `/assets/no-profile-image.png`
                              }
                              //src="http://media.istockphoto.com/photos/green-leaf-with-dew-on-dark-nature-background-picture-id1050634172?k=20&m=1050634172&s=612x612&w=0&h=w0nk0tsmBysY4S119AhMJuUlof3Wf5FkJ3t2MD50oUE="
                              style={{ borderRadius: '50%', height: 60 }}
                              className="img-fluid"
                              />
                        </LoadingOverlay>
                      </Form.Group>
                    </Row>  
                    <Form.Group as={Col} md={12} className="mt-3">
                      <Button variant="success" type="submit" disabled={loader}> 
                      {loader && (
                        <Spinner
                            variant="light"
                            component="span"
                            size="sm"
                            aria-hidden="true"
                        />
                        )} Update
                      </Button>
                    </Form.Group>  
                    
                  </Form>
              </Card.Body>
          </Card>
          {showDeleteModal && (
              <Modal size="md" isOpen={showDeleteModal}>
                  <Modal.Header toggle={() => { setDeleteModal(false); }}> Delete Account</Modal.Header>
                  <Modal.Body>All events and it's related records along with your account will be deleted. Are you sure to proceed?</Modal.Body>
                  <Modal.Footer>
                      <Button color="danger" onClick={() => setDeleteModal(false)} disabled={loader}>No</Button>
                      <Button onClick={() => deleteAccount()} color="success" disabled={loader}>{loader && <Spinner size="sm" className="mr-1" />}Yes</Button>
                  </Modal.Footer>
              </Modal>
          )}
      </>
  )
}

export default BasicProfile
