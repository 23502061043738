/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from "react";
import { Modal, Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import events from "../../../services/events";
import { useSelector } from "react-redux";

export default function PrintTickets(props) {
    const componentRef = useRef();
    const [ticketsToBePrinted, setTicketsToBePrinted] = useState([]);
    const [loader, setLoader] = useState(true);
    const apiUrl = useSelector((state) => state.apiUrl);
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      copyStyles: true
    });
    useEffect(()=>{
        let params;
        if(props.allotments){
            params = {allotments: props.allotments}
        }
        else if(props.bookingId){
            params = {booking_id: props.bookingId}
        }
        events.getTicketsToPrint(params).then((res) => {
            if (res.data.success) {
                setTicketsToBePrinted(res.data.tickets)
                setLoader(false);
            } 
            else{
                setLoader(false);
            }
        });
    },[])
    return (
        <Modal show={props.showModal}
          onHide={()=>props.closeModal(false)}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
      >
        
            <Modal.Header closeButton><h4>Tickets</h4></Modal.Header>
            <Modal.Body>
                {loader && <p className="text-center"><Spinner/></p>}
                <div style={{display:'none', width:600}}><ContentToPrint ticketsToBePrinted={ticketsToBePrinted} ref={componentRef}  apiUrl={apiUrl}/></div>
                <ContentToShow ticketsToBePrinted={ticketsToBePrinted} apiUrl={apiUrl}/>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Button variant="success" size="lg" onClick={handlePrint}>Print</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
}; 

export class ContentToPrint extends React.PureComponent {
    render(){
        return <div>
        {this.props.ticketsToBePrinted.map((ticket, index) => <Card key={`ticket-card-${index}`} style={{border:'2px solid black'}}>
            <Row>
                <Col md={3} style={{backgroundImage:`url(${this.props.apiUrl}/${
                            ticket.event.image !== null
                              ? `web/events/${ticket.event.image}`
                              : `web/images/no-image.jpeg`
                          })`, backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                    
                </Col>
                <Col md={3} className="p-4">
                    <img
                        src={ticket.qrcode}
                        alt={ticket.event.title}
                        className="img-fluid rounded-start"
                    />
                </Col>
                <Col md={6} className="pl-0 pt-4">
                    <Card.Body className="p-1">
                        <h5 style={{fontSize:50}}><strong>{ticket.event.title}</strong></h5>
                        <Card.Text className="mb-1" style={{fontSize:35}}>Venue: {ticket.event.venue_name}</Card.Text>
                        <Card.Text className="mb-1" style={{fontSize:35}}>Address: {ticket.event.location}</Card.Text>
                        <Row>
                            <Col style={{fontSize:35}}><strong>{ticket.ticket.name}</strong></Col>
                            <Col style={{fontSize:35}}><strong>{ticket.custom_ticket_id}</strong></Col>
                            <Col style={{fontSize:35}}><strong>${ticket.ticket.price}</strong></Col>
                        </Row>
                    </Card.Body>
                </Col>
            </Row>
        </Card>)}
    </div>
    } 
} 

export class ContentToShow extends React.PureComponent {
    render(){
        return <div>
        {this.props.ticketsToBePrinted.map((ticket, index) => <Card key={`ticket-card-${index}`}>
            <Row>
                <Col md={3} style={{backgroundImage:`url(${this.props.apiUrl}/${
                            ticket.event.image !== null
                              ? `web/events/${ticket.event.image}`
                              : `web/images/no-image.jpeg`
                          })`, backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                    
                </Col>
                <Col md={3}>
                    <img
                        src={ticket.qrcode}
                        alt={ticket.event.title}
                        className="img-fluid rounded-start"
                    />
                </Col>
                <Col md={6} className="pl-0">
                    <Card.Body className="p-1">
                        <h5><strong>{ticket.event.title}</strong></h5>
                        <Card.Text className="mb-1"><strong>Venue:</strong> {ticket.event.venue_name}</Card.Text>
                        <Card.Text className="mb-1"><strong>Address:</strong> {ticket.event.location}</Card.Text>
                        <Row>
                            <Col><strong>{ticket.ticket.name}</strong></Col>
                            <Col><strong>{ticket.custom_ticket_id}</strong></Col>
                            <Col><strong>${ticket.ticket.price}</strong></Col>
                        </Row>
                    </Card.Body>
                </Col>
            </Row>
        </Card>)}
    </div>
    } 
} 