/* eslint-disable react/prop-types */
import { Button, Table, Row,Col,Form,Spinner,Badge, Modal } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import moment from "moment";
import { connect } from "react-redux";
import { FaFileExcel } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import TableLoader from "../Loaders/TableLoader"


class ListMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      pageSize: 20,
      loader: false,
      records: [],
      pageDetails: {},
      disabled: false,
      showDonorDetailModal: false,
      fields:{},
      errors:{},
      submitted:false,
      events:[],
      selectedRecords:[],
      showMoreDetailsModal:false,
      questionData:[],
      answerData:[],
      selectedMember:this.props.transaction ? this.props.transaction.member_id : null
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  handleSubmit = (e = null) => {
    if (e) e.preventDefault();
    this.setState({ submitted: true },()=>{
        this.listRecords();
    });
  };
  componentDidMount = () => {
    this.listRecords();
  }

  listRecords = () => {
    this.setState({ loader: true });
    events
      .listMembers({
        page: this.state.pageNo,
        fields:this.state.fields,
        event_id:this.props.eventId ? this.props.eventId : null
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ records: res.data.members, loader: false, submitted:false, pageDetails:res.data.pages });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  deleteRecord = (id) => {
    if (window.confirm('Are you sure to delete?')) {
      let params = { id: id }
      events.deleteMember(params).then((response) => {
        if (response.data.success) {
          let records = this.state.records.filter((r) => parseInt(r.id) !== parseInt(id))
          this.setState({records},()=>{
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        console.log(err)
            toast.error('Unexpected error !', {
            position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }

  deleteMultipleRecords = () => {
    if (window.confirm('Are you sure to delete?')) {
      let params = { id: this.state.selectedRecords }
      events.deleteMember(params).then((response) => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
          this.setState({selectedRecords:[]},()=>this.listRecords());
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        console.log(err)
        toast.error('Unexpected error !', {
        position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }

  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.listRecords();
    });
  };
  selectRecord = (e) => {
    let selectedRecords = this.state.selectedRecords;
    if (e.target.checked) {
      selectedRecords.push(e.target.value);
    } else {
      let index_to_be_removed = selectedRecords.indexOf(e.target.value);
      selectedRecords.splice(index_to_be_removed, 1);
    }
    this.setState({ selectedRecords });
  };
  selectAll = (e) => {
    let checkboxes = document.querySelectorAll('input[type=checkbox]');
    checkboxes.forEach(c=>{
      c.checked = e.target.checked;
    });
    let selectedRecords = [];
    if(e.target.checked){
      this.state.records.forEach((d,index)=>{
        selectedRecords[index] = d.id;
      });
    }
    this.setState({selectedRecords});
  }
  exportExcel = () =>{
    this.setState({loader:true});
    events.exportMembers({fields:this.state.fields, export:true}).then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data],{ type: res.data.type, encoding: 'UTF-8' })
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = 'members-'+moment().format("MM-DD-YYYY")+'.xlsx';
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({loader:false});
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  linkMember = () => {
    events.linkMember({
        transaction_id:this.props.transaction.id,
        member_id:this.state.selectedMember
      }).then((response) => {
      if (response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if(this.props.reloadPayments){
          this.props.reloadPayments();
        }
        if(this.props.closeModal){
          this.props.closeModal();
        }
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }).catch((err) => {
      console.log(err)
          toast.error('Unexpected error !', {
          position: toast.POSITION.TOP_RIGHT,
      })
    })
  }
  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
            <Row>
                <Col>
                    <Form.Group row>
                        <Col>
                            <Form.Control type="text" placeholder="Name" onChange={(e) => {
                                this.handleChange(e, "name");
                            }}
                                value={
                                    this.state.fields["name"]
                                        ? this.state.fields["name"]
                                        : ""
                                }
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group row>
                        <Col>
                            <Form.Control type="text" placeholder="Email" onChange={(e) => {
                                this.handleChange(e, "email");
                            }}
                                value={
                                    this.state.fields["email"]
                                        ? this.state.fields["email"]
                                        : ""
                                }
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group row>
                        <Col>
                            <Form.Control type="text" placeholder="Phone" onChange={(e) => {
                                this.handleChange(e, "phone");
                            }}
                                value={
                                    this.state.fields["phone"]
                                        ? this.state.fields["phone"]
                                        : ""
                                }
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col>
                    <Button type="submit" className="btn-block" color="success" disabled={this.state.submitted}>{this.state.submitted && <Spinner size="sm"/>} Search</Button>
                </Col>
                <Col>
                  <Button type="button" color="warning" className="btn-block" onClick={()=>this.exportExcel()}><FaFileExcel size="1em" className="mb-1 mr-1"/>Export</Button>
                </Col>
            </Row>
        </Form>
        <LoadingOverlay
            active={this.state.loader}
            text={<TableLoader height={this.props.height ? this.props.height : 400}/>}
        >
          {/* <Row>
            <Col md={12} className="text-right">
                <Button type="button" color="warning" onClick={()=>this.exportExcel()}><FaFileExcel size="1em" className="mb-1 mr-1"/>Export</Button>
            </Col>
          </Row> */}
          <Row>
            <Col md={12} style={{minHeight:300}}>
              {this.state.records.length > 0 ? <Table className="table table-responsive">
                <thead>
                    <tr>
                      <th>{!this.props.linkMember && <input type="checkbox" onChange={this.selectAll} value={0}/>}</th>
                      <th>#</th>
                      {/* {this.state.records[0].form.form_data.map((th, index) => {
                        if(index < 3){
                          return <th>{th.label}</th>
                        }
                      })} */}
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Membership Level</th>
                      <th>Added On</th>
                      <th colSpan="2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.records.length > 0 ? (
                    this.state.records.map((ele, index) => (
                        <tr key={index}>
                        <th>{this.props.linkMember ? <input type="radio" name="selectedMember" value={ele.id} onClick={()=>this.setState({selectedMember: ele.id})} checked={this.state.selectedMember === ele.id}/> : <input type="checkbox" onChange={this.selectRecord} value={ele.id}/>}</th>
                        <td>{this.state.pageNo === 1
                            ? index + 1
                            : (this.state.pageNo - 1) * this.state.pageSize + index + 1}
                            . </td>
                        
                        <td>{ele.first_name}</td>
                        <td>{ele.last_name}</td>
                        <td>{ele.email}</td>
                        <td>{ele.phone}</td>
                        <td> {ele.plan !== null ? ele.plan.name : '--'}</td>
                        <td> {moment(ele.added_on).format("MMM Do, YYYY")}</td>
                        <td><Button type="button" className="btn-sm" color="primary" onClick={() => this.setState({showMoreDetailsModal:true, questionData:ele.form.form_data !== null ? ele.form.form_data : [], answerData:ele.answer !== null ? ele.answer : {}})}>More Details</Button></td>
                        <td>
                            <Button type="button" className="btn-sm" color="danger" onClick={() => this.deleteRecord(ele.id)}>Delete</Button>
                        </td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan="10" className="text-center" style={{height:300}}>
                            Record not found.
                        </td>
                    </tr>
                    )}
                    <tr>
                        <td colSpan="3">
                          {this.props.linkMember ?  <Button type="button" color="danger" disabled={this.state.selectedMember === null} onClick={this.linkMember}>Link Member</Button> : <Button type="button" color="danger" disabled={this.state.selectedRecords.length === 0} onClick={this.deleteMultipleRecords}><Badge color="primary" style={{width:'auto',overflow:'auto'}}>{this.state.selectedRecords.length}</Badge> Delete</Button>}
                        </td>
                        <td colSpan="10">
                          <Pagination
                            activePage={this.state.pageNo}
                            itemsCountPerPage={30}
                            totalItemsCount={
                              this.state.pageDetails.totalCount ? parseInt(this.state.pageDetails.totalCount) : 0
                            }
                            pageRangeDisplayed={5}
                            onChange={(e) => this.handlePageChange(e)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </td>
                      </tr>
                </tbody>
              </Table>:<p className="text-center">Records not found</p>}
            </Col>
          </Row>
            
            {this.state.showMoreDetailsModal && <Modal size="md" isOpen={this.state.showMoreDetailsModal}>
              <Modal.Header toggle={() => { this.setState({showMoreDetailsModal:false}) }}>More Details</Modal.Header>
              <Modal.Body>
                <ReactFormGenerator
                    data={this.state.questionData}
                    hide_actions={true}
                    answer_data={this.state.answerData}
                    read_only={true}
                />
              </Modal.Body>
            </Modal>}
        </LoadingOverlay>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ListMembers);
