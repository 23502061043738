/* eslint-disable react/prop-types */
import { Modal, } from "react-bootstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import ListMembers from "../Payments/ListMembers";

class LinkMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  render() {
    return (
        <Modal size="lg" isOpen={this.props.showModal}>
            <Modal.Header toggle={() => { this.props.closeModal(false); }}>Link Member</Modal.Header>
            <Modal.Body>
                <ListMembers 
                    eventId={this.props.eventId} 
                    height={200} linkMember={true} 
                    transaction={this.props.transaction}
                    closeModal={()=>this.props.closeModal(false)}
                    reloadPayments={this.props.reloadPayments}
                />
            </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(LinkMember);
