/* eslint-disable react/prop-types */
import { Modal, Spinner, Button, Form, Col } from "react-bootstrap";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import common from "../../../services/common";


class TransferFund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                eventId:this.props.eventId ? this.props.eventId : null,
                userId:this.props.userId ? this.props.userId : null,
                amount: 50, 
                funding_source_url:this.props.dwollaFundingSource 
            },
            errors: {},
            submitted: false,
        };
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["amount"]) {
            formIsValid = false;
            errors["amount"] = "Amount can not be blank.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            let params = { fields: this.state.fields };
            common.transferFund(params).then((res) => {
                if (res.data.success) {
                    this.setState({ submitted: false }, () => {
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.props.closeModal();
                    })
                } else if (res.data.error) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }).catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    render() {
        return (
            <Modal show={this.props.showModal} size="md" onHide={this.props.closeModal} backdrop="static">
                <Modal.Header closeModal>Transfer Fund</Modal.Header>
                <Modal.Body>
                    {this.state.loader ? <p className="p-5 text-center"><Spinner size='lg' /></p> : <Form method="post" onSubmit={this.handleSubmit}>
                        
                            <Col md={7} sm={12} className="pr-0">
                                <Form.Control
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    value={
                                        this.state.fields["amount"]
                                            ? this.state.fields["amount"]
                                            : ""
                                    }
                                    onChange={event =>
                                        this.handleChange("amount", event)
                                    }
                                    invalid={
                                        this.state.errors["amount"] ? true : false
                                    }
                                />
                                {this.state.errors["amount"] && (
                                    <Form.Feedback>
                                        {this.state.errors["amount"]}
                                    </Form.Feedback>
                                )}
                            </Col>
                            <Col md={5} sm={12}>
                                <Button
                                    color="success"
                                    type="submit"
                                    disabled={this.state.submitted}
                                    className="btn-block"
                                >
                                    {this.state.submitted && (
                                        <Spinner
                                            size="sm"
                                            color="#887d7d"
                                            className="mr-1"
                                        />
                                    )}
                                    Send
                                </Button>
                            </Col>
                    </Form>}
                </Modal.Body>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(TransferFund);
