import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PublicProfile from "./PublicProfile"
import Dashboard from "./Dashboard"
import { toast } from 'react-toastify'
import { useLocation, useParams } from "react-router-dom"
import user from '../../../services/user'
import {Spinner} from "react-bootstrap"
import ZelleModal from "../Common/ZelleModal";
import PaypalPaymentForm from "../Common/PaypalPaymentForm";
import VerifyBank from "../Event/VerifyBank";
import TransferFund from "../Event/TransferFund";
import DwollaPaymentForm from "../Event/DwollaPaymentForm";
import UserNotFound from "../Common/UserNotFound"
import EdgePayPaymentForm from "../Common/EdgePayPaymentForm";

const Profile = () => {
  const queryString = useParams();
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [services, setServices] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [qrCodes, setQrCodes] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [zelleEmailModal, setZelleEmailModal] = useState(false);
  const [zelleEmail, setZelleEmail] = useState(null);
  const [dwollaPaymentFormModal, setDwollaPaymentFormModal] = useState(false);
  const [appId, setAppId] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [paypalPaymentFormModal, setPaypalPaymentFormModal] = useState(false);
  const [edgePayPaymentFormModal, setEdgePayPaymentFormModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [verifyBankModal, setVerifyBankModal] = useState(false);
  const [dwollaFundingSource, setDwollaFundingSource] = useState(null);
  const [dwollaCustomerUrl, setDwollaCustomerUrl] = useState(null);
  const [transferDwollaFundModal, setTransferDwollaFundModal] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  
  const userName = useSelector((state) => state.userName);
  const userId = useSelector((state) => state.userId);
  const location = useLocation();

  useEffect(() => {
    if(location.search){
      let queryString = new URLSearchParams(location.search);
      setInvoiceId(queryString.get('invoice'));
    }
    user
      .getOne({ id: location.pathname.substring(1) }, userId)
      .then((res) => {
        if (res.data.success) {
          setServices(res.data.user.services);
          setQrCodes(res.data.user.qrcodes);
          setSocialLinks(res.data.user.sociallinks);
          setUserDetails(res.data.user);
          setDescription(res.data.user.description);
        } else if (res.data.error) {
          setUserNotFound(true);
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  const updateSocialLinks = (newList) => {
    setSocialLinks(newList);
  };

  const updateDescription = (newDescription) => {
    setDescription(newDescription);
  };

  const updatePaymentList = (newList) => {
    setServices(newList);
  };

  const updateQrCodeList = (newQrCodeList) => {
    setQrCodes(newQrCodeList);
  };

  const toggleZelleModel = (email) => {
    setZelleEmailModal(!zelleEmailModal);
    if (zelleEmailModal === false) {
      setZelleEmail(email);
    }
  };

  const copyToClipBoard = (e, content) => {
    e.preventDefault();
    navigator.clipboard.writeText(content).then(
      () => {
        toast.success("Address copied to clipboard.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const shareProfile = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: "Tiphub Profile",
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    }
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };
  // Button Dropdown //
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Button Dropdown //

  const openDonnerModal = (appDetails) => {
    if (
      parseInt(appDetails.service_id) === 7 ||
      parseInt(appDetails.service_id) === 17 ||
      parseInt(appDetails.service_id) === 15 ||
      parseInt(appDetails.service_id) === 22 ||
      parseInt(appDetails.service_id) === 23 ||
      parseInt(appDetails.service_id) === 24
    ) {
      let zelleEmail = null;
      if (appDetails.url !== "" && appDetails.url.search("cash.app")) {
        zelleEmail = appDetails.url.split("/")[3];
      }
      else if (appDetails.phone && appDetails.phone !== "") {
        zelleEmail = appDetails.phone;
      } else {
        zelleEmail = appDetails.email;
      }
      toggleZelleModel(zelleEmail);
    } 
    else if (parseInt(appDetails.service_id) === 73) {
      setPaypalPaymentFormModal(true);
    }
    else if (parseInt(appDetails.service_id) === 73) {
      setEdgePayPaymentFormModal(true);
    }
    else {
      trackUserApp(appDetails);
    }
  };

  const trackUserApp = (appDetails) => {
    if (parseInt(appDetails.service_id) === 64) {
      setDwollaPaymentFormModal(true);
    }
    else{
      let url = appDetails.url;
      let aTag = document.createElement("a");
      aTag.setAttribute("href", url);
      aTag.setAttribute("target", "_blank");
      document.body.appendChild(aTag);
      setTimeout(function () {
        aTag.click();
        document.body.removeChild(aTag);
      }, 300);
    }
    user
      .trackApp({
        user_id: appDetails.user_id,
        service_id: appDetails.service_id,
      })
      .then((res) => {
        if (res.data.success) {
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  if(loading){
    return <div className='dashboard-banner'><div className="tiphub-banner-bg"></div>
    <div className='d-flex align-items-center justify-content-center' style={{height:600}}><Spinner
      animation="grow"
      size="sm"
      className="me-1"
      variant="primary"
    /> Loading...</div>
  </div>
  }
  else{
    return (
      <div>
        {(userName && queryString.username === userName) ? <Dashboard 
          socialLinks={socialLinks} 
          updateSocialLinks={updateSocialLinks}
          description={description}
          updateDescription={updateDescription}
          qrCodes={qrCodes}
          updateQrCodeList={updateQrCodeList}
          services={services}
          updatePaymentList={updatePaymentList}
          userDetails={userDetails}
          copyToClipBoard={copyToClipBoard}
        /> : userNotFound ? <UserNotFound/> : <PublicProfile 
            socialLinks={socialLinks} 
            description={description}
            qrCodes={qrCodes}
            services={services}
            openDonnerModal={openDonnerModal}
            userDetails={userDetails}
        />}
        
        {zelleEmailModal && 
          <ZelleModal
            size="md"
            zelleEmailModal={zelleEmailModal}
            closeModal={setZelleEmailModal}
            zelleEmail={zelleEmail}
            appId={appId}
          />
        }
        {paypalPaymentFormModal && (
          <PaypalPaymentForm
            showModal={paypalPaymentFormModal}
            closeModal={()=>setPaypalPaymentFormModal(false)}
            userDetails={userDetails}
            invoiceId={invoiceId}
            editAmount={true}
          />
        )}
        {edgePayPaymentFormModal && (
          <EdgePayPaymentForm
            showModal={edgePayPaymentFormModal}
            closeModal={()=>setEdgePayPaymentFormModal(false)}
            userDetails={userDetails}
            invoiceId={invoiceId}
            editAmount={true}
          />
        )}
        {verifyBankModal && (
          <VerifyBank
            showModal={verifyBankModal}
            closeModal={()=>setVerifyBankModal(false)}
            initiateTranser={
              (dwollaFundingSource)=>{
                setVerifyBankModal(false);
                setTransferDwollaFundModal(true);
                setDwollaFundingSource(dwollaFundingSource)
              }
            }
            dwollaCustomerUrl={dwollaCustomerUrl}
          />
        )}
        {transferDwollaFundModal && (
          <TransferFund
            showModal={transferDwollaFundModal}
            closeModal={()=>setTransferDwollaFundModal(false)}
            userId={userDetails.id}
            dwollaFundingSource={dwollaFundingSource}
          />
        )}
        {dwollaPaymentFormModal && (
          <DwollaPaymentForm
            showModal={dwollaPaymentFormModal}
            closeModal={() => setDwollaPaymentFormModal(false)}
            initiateVefification={
              (dwollaCustomerUrl)=>{
                setVerifyBankModal(true);
                setTransferDwollaFundModal(false);
                setDwollaCustomerUrl(dwollaCustomerUrl);
              }
            }
          />
        )}
      </div>  
    );
  }
  
};

export default Profile;
