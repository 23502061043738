import React, { Component } from "react";
import {
  Card,
  Spinner,
  Button,
  Form,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import events from "../../../services/events";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";

class ManageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      submitted: false,
      overlayLoading: false,
      listLoading: true,
      gallery: [],
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.files;
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.formValidation()) {
      this.setState({ submitted: true });
      const formData = new FormData();
      for (var i = 0; i < this.state.fields.file_names.length; i++) {
        formData.append("file_names[]", this.state.fields.file_names[i]);
      }
      formData.append("event_id", this.props.eventDetails.id);
      events
        .uploadGallery(formData)
        .then((res) => {
          if (res.data.success) {
            document.getElementById("file_names").value = "";
            this.setState({ gallery: res.data.gallery });
            this.setState({ submitted: false });
            if (this.props.updateSlider) {
              this.props.updateSlider(res.data.gallery);
            }
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ submitted: false });
          }
        })
        .catch((err) => {
          toast.error("Unexpected Error!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ submitted: false });
        });
    }
  };
  formValidation = () => {
    const errors = {};
    let formIsValid = true;
    if (!this.state.fields.file_names) {
      errors["file_names"] = "Please choose qrcode images.";
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  };
  deleteGallery = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id };
      events
        .deleteGallery(params)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            let gallery = this.state.gallery.filter(
              (image) => parseInt(image.id) !== parseInt(id)
            );
            this.setState({ gallery });
            if (this.props.updateSlider) {
              this.props.updateSlider(gallery);
            }
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  componentDidMount() {
    events
      .getGallery({ event_id: this.props.eventDetails.id })
      .then((res) => {
        if (res.data.success) {
          this.setState({ gallery: res.data.gallery }, () => {
            this.setState({ listLoading: false });
          });
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Unexpected Error!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  render() {
    return (
      <Modal show={this.props.showModal}
          onHide={this.props.closeModal}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          className="modal-gallery"
      >
        <Modal.Header closeButton>
          Manage Gallery
        </Modal.Header>
        <Form onSubmit={this.handleSubmit} method="post">
          <Modal.Body>
            <Row className="mx-2">
              <Col sm="12">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control 
                    type="file"
                    id="file_names"
                    name="file_names"
                    onChange={(event) => this.handleChange(event, "file_names")}
                    multiple
                  />
                </Form.Group>
                {this.state.errors.file_names && (
                  <span style={{ color: "red" }}>
                    {this.state.errors.file_names}
                  </span>
                )}
              </Col>
            </Row>
            <Card>
              <Card.Header>
                <h3>Images</h3>
              </Card.Header>
              <LoadingOverlay
                active={this.state.listLoading}
                spinner
                text="Loading..."
              >
                <Card.Body style={{ minHeight: 150 }}>
                  {this.state.gallery.length > 0 ? (
                    <Row>
                      {this.state.gallery.map((image) => (
                        <Col md={3} className="text-center">
                          <img
                            src={`${this.props.apiUrl}/web/events/${image.file_name}`}
                            className="img-thumbnail"
                          />
                          <br />
                          <Button
                            color="danger"
                            className="btn-sm mt-1 mb-2"
                            onClick={() => this.deleteGallery(image.id)}
                          >
                            Delete
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <h5 className="text-center">No records found</h5>
                  )}
                </Card.Body>
              </LoadingOverlay>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => this.props.closeModal()}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              type="submit"
              className="btn-sm"
              disabled={this.state.submitted}
            >
              {this.state.submitted && <Spinner size="sm" className="mr-1" />}
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
  };
};
export default connect(mapStateToProps)(ManageGallery);
