/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import events from "../../../services/events";
import AddEditGroupMember from "./AddEditGroupMember";
import { toast } from "react-toastify";

class ManageGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      pageSize: 20,
      loader: false,      
      records: [],
      showModal: false,
      teamId: null
    };
  }
  componentDidMount = () => {
    this.listRecords();
  }

  listRecords = () => {
    this.setState({ loader: true });
    events.getGroups({
        page: this.state.pageNo,
        event_id: this.props.eventId
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ records: res.data.groups, pageDetails: res.data.pages });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  updateRecords = (records) => {
    let newRecords = [...this.state.records, records];
    this.setState({ records: newRecords, showModal: false }, ()=>{
      let teamsWithoutEventId = newRecords.filter(nr=>nr.event_id === undefined);
      this.props.updateTeams(teamsWithoutEventId);
    });
  }

  deleteRecord = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      if (this.state.records[index].event_id === undefined) {
        let records = this.state.records.filter((r, indx) => index !== indx);
        this.setState({ records });
      }
      else {
        let params = { id: this.state.records[index].id }
        events.deleteGroup(params).then((response) => {
          if (response.data.success) {
            let records = this.state.records.filter((r) => parseInt(r.id) !== parseInt(this.state.records[index].id))
            this.setState({ records }, () => {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            })
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        }).catch((err) => {
          console.log(err)
          toast.error('Unexpected error !', {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
      }
    }
  }

  render() {
    return (
      <>
        <Card style={{ border: "1px solid #c8ced3" }} className="mb-2">
          <Card.Header
            style={{
              backgroundColor: "#f0f3f5",
              borderBottom: "1px solid #c8ced3",
            }}
          >
            <Row>
              <Col>
                <h4>Manage Group</h4>
              </Col>
              <Col className="text-right">
                <Button
                  type="button"
                  className="btn-sm"
                  color="success"
                  style={{ backgroundColor: "green", width: "auto" }}
                  onClick={() => this.setState({ showModal: true })}
                >
                  Add New Group 
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-2">
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading..."
            >
              <Table className="table table-responsive customise_table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th colSpan={2} width="20%">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.records.length > 0 ? (
                    this.state.records.map((ele, index) => (
                      <tr key={`member--${index}`}>
                        <td>
                          {this.state.pageNo === 1
                            ? index + 1
                            : (this.state.pageNo - 1) * this.state.pageSize +
                              index +
                              1}
                          .{" "}
                        </td>
                        <td>{ele.name}</td>
                        {ele.event_id && (
                          <td>
                            <Button
                              type="button"
                              className="btn-sm"
                              color="primary"
                              style={{ backgroundColor: "blue", width: "auto" }}
                                onClick={() =>
                                  this.setState({
                                    teamId: ele.id,
                                    showModal: true,
                                  })
                                }
                            >
                              Edit
                            </Button>
                          </td>
                        )}
                        <td>
                          <Button
                            type="button"
                            className="btn-sm"
                            color="danger"
                            style={{ backgroundColor: "red", width: "auto" }}
                            onClick={() => this.deleteRecord(index)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="15"
                        className="text-center"
                        style={{ height: 100 }}
                      >
                        Record not found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </LoadingOverlay>
            {this.state.showModal && (
              <AddEditGroupMember
                showModal={this.state.showModal}
                eventId={this.props.eventId}
                closeModal={() =>
                  this.setState({ showModal: false, teamId: null })
                }
                listRecords={this.listRecords}
                teamId={this.state.teamId}
                updateRecords={this.updateRecords}
              />
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};

export default connect(mapStateToProps)(ManageGroup);
