/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {Form, Button, Col, Spinner, Row} from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import service from "../../../services/service";
import events from "../../../services/events";

class MutualAidForm extends Component {
  constructor(props) {
    super(props);
        this.state = {
            fields: {event_id:this.props.eventDetails.id},
            errors: {},
            loader: false,
            submitted:false,
            serviceNames:[],
            amountArr:[50, 100, 150, 200, 250],
            paymentMethodsToShow:[1,2,15,7],
            reasons:['Adult caregiving', 'Bill payment', 'Childcare', 'Food/groceries/personal care items', 'Housing', 'Legal fees', 'Medical care', 'Mental Health', 'Transportation', 'Other']
        };
    }
    componentDidMount = () => {
        service.getPaymentServiceNames().then((res) => {
            if (res.data.success) {
                this.setState({serviceNames:res.data.names});
            }
        }).catch((err) => {
            toast.error("Unexpected error !", {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    }
    handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["first_name"]) {
        formIsValid = false;
        errors["first_name"] = "Please enter your first name.";
    }
    if (!fields["last_name"]) {
        formIsValid = false;
        errors["last_name"] = "Please enter your last name.";
    }
    if (!fields["email"]) {
        formIsValid = false;
        errors["email"] = "Please enter your email.";
    }
    if (!fields["amount"]) {
        formIsValid = false;
        errors["amount"] = "Please choose amount.";
    }
    if (!fields["service_id"]) {
        formIsValid = false;
        errors["service_id"] = "Please choose payment method.";
    }
    if (!fields["service_identifier"]) {
        formIsValid = false;
        errors["service_identifier"] = "Please enter service identifier.";
    }
    if (!fields["reason"]) {
        formIsValid = false;
        errors["reason"] = "Please choose reason.";
    }
    if (fields["reason"] && fields["reason"] === 'Other' && !fields["other_reason"]) {
        formIsValid = false;
        errors["other_reason"] = "Please enter reason.";
    }
    this.setState({ errors: errors });
    return formIsValid;
    };

    handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
        this.setState({ submitted: true });
        let params = { fields:this.state.fields };
        events
        .saveMutualAidRequest(params)
        .then((res) => {
            if (res.data.success) {
            this.setState({ submitted: false },()=>{
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                if(this.props.redirect === true){
                    window.location = `${this.props.baseUrl}/${this.props.eventDetails.url}/${this.props.eventDetails.id}`;
                }
                else if(this.props.closeModal){
                    this.props.closeModal();
                }
            })
            } else if (res.data.error) {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        })
        .catch(() => {
            toast.error("Unexpected Error !", {
            position: toast.POSITION.TOP_RIGHT,
            });
        });
    }
    };
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
  render() {
    return (<>
            <Form
                method="post"
                className="px-2"
                onSubmit={this.handleSubmit}
            >
                <Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="first_name">First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="first_name"
                            id="first_name"
                            value={
                                this.state.fields["first_name"]
                                    ? this.state.fields["first_name"]
                                    : ""
                            }
                            onChange={event =>
                                this.handleChange("first_name", event)
                            }
                            invalid={
                                this.state.errors["first_name"] ? true : false
                            }
                        />
                        {this.state.errors["first_name"] && (
                            <Form.Control.Feedback type="invalid">{this.state.errors["first_name"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="last_name">Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="last_name"
                            id="last_name"
                            value={
                                this.state.fields["last_name"]
                                    ? this.state.fields["last_name"]
                                    : ""
                            }
                            onChange={event =>
                                this.handleChange("last_name", event)
                            }
                            invalid={
                                this.state.errors["last_name"] ? true : false
                            }
                        />
                        {this.state.errors["last_name"] && (
                            <Form.Control.Feedback type="invalid">{this.state.errors["last_name"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="email">Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            id="email"
                            value={
                                this.state.fields["email"]
                                    ? this.state.fields["email"]
                                    : ""
                            }
                            onChange={event =>
                                this.handleChange("email", event)
                            }
                            invalid={
                                this.state.errors["email"] ? true : false
                            }
                        />
                        {this.state.errors["email"] && (
                            <Form.Control.Feedback type="invalid">{this.state.errors["email"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="amount">Amount Requested</Form.Label>
                        {this.state.amountArr.map(amount=><Form.Check
                            required
                            name="amount"
                            label={`$${amount}`}
                            onClick={event => this.handleChange("amount", event)}
                            isInvalid={!!this.state.errors.amount}
                            feedback={this.state.errors.amount}
                            feedbackType="invalid"
                            value={amount}
                            key={`amount-${amount}`}
                            inline
                        />)}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="service_id">Payment Method</Form.Label>
                        <Form.Select onChange={(event) => this.handleChange("service_id", event)}>
                            <option value="">- Select Payment Type - </option>
                            {this.state.serviceNames.map((sname, indx) => {
                                if(this.state.paymentMethodsToShow.includes(parseInt(sname.id))){
                                    return <option value={sname.id} key={`sname${indx}`}>{sname.name}</option>
                                }
                            })}
                        </Form.Select>
                        {this.state.errors["service_id"] && (
                            <Form.Control.Feedback type="invalid">{this.state.errors["service_id"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="email">Payment platform username (Cash App, Venmo) or email address(Zelle, PayPal)</Form.Label>
                        <Form.Control
                            type="text"
                            name="service_identifier"
                            id="service_identifier"
                            value={
                                this.state.fields["service_identifier"]
                                    ? this.state.fields["service_identifier"]
                                    : ""
                            }
                            onChange={event =>
                                this.handleChange("service_identifier", event)
                            }
                        />
                        {this.state.errors["service_identifier"] && (
                            <Form.Control.Feedback type="invalid">{this.state.errors["service_identifier"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="reason">Reason for Request of Funds</Form.Label>
                        <Form.Select onChange={(event) => this.handleChange("reason", event)}>
                            <option value="">- Select Reason- </option>
                            {this.state.reasons.map((r, indx) => <option value={r} key={`reason${indx}`}>{r}</option>)}
                        </Form.Select>
                        {this.state.errors["reason"] && (
                            <Form.Control.Feedback type="invalid">{this.state.errors["reason"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {this.state.fields.reason && this.state.fields.reason === "Other" && <Form.Group as={Col} md={6}>
                        <Form.Label htmlFor="email">Payment platform username (Cash App, Venmo) or email address(Zelle, PayPal)</Form.Label>
                        <Form.Control
                            type="text"
                            name="other_reason"
                            id="other_reason"
                            value={
                                this.state.fields["other_reason"]
                                    ? this.state.fields["other_reason"]
                                    : ""
                            }
                            onChange={event =>
                                this.handleChange("other_reason", event)
                            }
                        />
                        {this.state.errors["other_reason"] && (
                            <Form.Control.Feedback type="invalid">{this.state.errors["other_reason"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>}
                    <Col md={12}>
                        <Button
                            variant="success"
                            type="submit"
                            disabled={this.state.submitted}
                        >
                            {this.state.submitted && (
                                <Spinner
                                    size="sm"
                                    variant="light"
                                    className="me-1"
                                />
                            )}
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(MutualAidForm);


