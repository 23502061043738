/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import common from "../../../services/common";
import {ProgressBar, Card} from "react-bootstrap";

class TargetedAmount extends Component {
  render() {
    return (
        <>
        <Card className="mb-3 p-3">
            <h4>Targeted Amount</h4>
            <p>
                {common.currencySymbolsArr[this.props.eventData.currency]}
                {this.props.eventData.transactions.length > 0
                ? common.numberFormat(
                    this.props.eventData.transactions[0]
                        .totalAmount
                    )
                : 0}{" "}
                raised of {common.currencySymbolsArr[this.props.eventData.currency]}
                {this.props.eventData && this.props.eventData.target_amount
                ? common.numberFormat(
                    this.props.eventData.target_amount
                    )
                : 0}{" "}
                goal
            </p>
            <div>
                <ProgressBar
                  variant="success"
                  now={this.props.targetAcheived}
                  label={`${this.props.targetAcheived}%`}
                />
            </div>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(TargetedAmount);


