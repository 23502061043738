/* eslint-disable react/prop-types */
import { Modal, ModalBody, ModalHeader, Spinner } from "react-bootstrap";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import common from "../../../services/common";


class VerifyBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            records: [],
            showModal: false,
            fields: { event_id: this.props.eventId },
            errors: {},
            submitted: false,
        };
    }
    componentDidMount = () => {
        this.getIavToken();
    }
    getIavToken = () => {
        let memberId = this.props.memberId ? this.props.memberId : null;
        let that = this;
        this.setState({ loader: true });
        let params = {
            dwollaCustomerUrl:this.props.dwollaCustomerUrl
        }
        common.getIavToken(params).then((res) => {
            if (res.data.success) {
                /* microDeposits: 'true',
                fallbackToMicroDeposits: 'true' */
                this.setState({loader:false}, () => {
                    var iavToken = res.data.iavToken;
                    const dwolla = window.dwolla;
                    //dwolla.configure('sandbox');
                    dwolla.iav.start(iavToken, {
                        container: 'iavContainer',
                        stylesheets: [
                            'https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext'
                        ],
                    }, function(err, res) {
                        console.log('Error: ' + JSON.stringify(err) + ' -- Response: ' + JSON.stringify(res));
                        if(memberId !== null){
                            common.saveFundingSourceUrl({memberId:memberId, fundingSourceUrl:res}).then((res) => {
                                if (res.data.success) {
                                    toast.success(res.data.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    that.props.initiateTranser(res);
                                } else if (res.data.error) {
                                    toast.error(res.data.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        }
                        else{
                            that.props.initiateTranser(res);
                        }
                    });
                });
            } else if (res.data.error) {
                console.log(res.data.message);
            }
            this.setState({ loader: false });
        })
        .catch((err) => {
            console.log(err);
        });
    };
    render() {
        return (
            <Modal isOpen={this.props.showModal} size="md">
                <ModalHeader toggle={() => this.props.closeModal()}>Verify Bank</ModalHeader>
                <ModalBody>
                    {this.state.loader ? <p className="p-5 text-center"><Spinner size='lg'/></p> : <div id="iavContainer">

                    </div>}
                </ModalBody>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(VerifyBank);
