/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Modal, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import common from "../../../services/common";

const PasscodeValidator = (props) => {
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({event_id:props.eventId});
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setSubmitted(true);
            let params = { fields: fields };
            common.validatePassCode(params).then((res) => {
                if (res.data.success) {
                    setSubmitted(false)
                    props.closeModal();
                } else if (res.data.error) {
                    let errors = {};
                    errors['passcode'] = res.data.message;
                    setErrors(errors)
                    setSubmitted(false)
                }
            }).catch((err) => {
                toast.error('Unexpected Error!', {
                    position: toast.POSITION.TOP_RIGHT,
                })
                setSubmitted(false)
            })
        }
    }
    const validateForm = () => {
        const errors = {};
        let formIsValid = true;
        if (!fields.passcode) {
            errors["passcode"] = "Please enter passcode!";
            formIsValid = false;
        }
        setErrors(errors);
        return formIsValid;
    };
    const handleChange = (e, field) => {
        setFields({ ...fields, [field]: e.target.value });
    };
    return (
        <div className="passwordValidator">
           <Modal 
                show={props.showModal}
                onHide={props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                size="md"
            >
                <Modal.Body className="text-center">
                    <Form noValidate onSubmit={handleSubmit}>
                        <InputGroup className="mb-3" size="lg" hasValidation>
                            <Form.Control
                                name="passcode" 
                                type="text" 
                                onChange={(event) => handleChange(event, 'passcode')} 
                                isInvalid={!!errors.passcode}
                            />
                            <Button type="submit" variant="success" disabled={submitted}>{submitted && <Spinner animation="border" variant="light" className="me-1" style={{width:20, height:20}}/>}Validate</Button>
                            <Form.Control.Feedback type="invalid">{errors.passcode}</Form.Control.Feedback>
                        </InputGroup>
                    </Form>
                </Modal.Body>
            </Modal>
            <div className="modal-backdrop fade show"></div>
        </div>
    );
};

export default PasscodeValidator;
