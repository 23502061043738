/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import {
  Button,
  Spinner,
  Form,
  FloatingLabel,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import user from '../../../services/user'
import { FaUpload } from 'react-icons/fa'
import Slider from "react-slick"

function QrCode(props) {
  const inputFile = useRef();
  const apiUrl = useSelector((state) => state.apiUrl)
  const [file, setFile] = useState('')
  const [error, setError] = useState({})
  const [fileSubmitted, setFileSubmitted] = useState(false)
  const [showTitle, setShowTitle] = useState(false)
  const [title, setTitle] = useState('')
  const [address, setAddress] = useState('')

  const chooseFile = (e) => {
    setFile(e.target.files[0])
    setShowTitle(true)
  }

  const uploadQrCode = (e) => {
    e.preventDefault()
    let errors = {}
    if (file === '') {
      document.getElementById('upload').click()
    } else if (!title) {
      errors['title'] = 'Title can not be blank'
      setError(errors)
    } else {
      setFileSubmitted(true)
      const formData = new FormData()
      formData.append('filepond', file)
      formData.append('title', title)
      formData.append('address', address)
      user
        .uploadQrCode(formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (res.data.success) {
            setFileSubmitted(false)
            setTitle('')
            setAddress('')
            setFile('')
            setShowTitle(false)
            props.updateQrCodeList(res.data.qrcodes)
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        })
        .catch((error) => {
          setFileSubmitted(false)
        })
    }
    setError(errors)
  }

  const deleteQrcode = (id) => {
    let params = { id: id }
    if (window.confirm('Are you sure to delete?')) {
      user.deleteQrCode(params).then((response) => {
        if (response.data.success) {
          let qrcodes = props.qrCodes
          qrcodes = props.qrCodes.filter((code) => code.id !== id)
          props.updateQrCodeList(qrcodes)
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        toast.error('Unexpected error !', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }

  const qrCode = {
    className: "center qrSlider",
    centerMode: true,
    infinite: true,
    slidesToShow: props.qrCodes.length === 1 ? 1 : 2,
    speed: 500,
    padding: 100,
    responsive: [
      {
        breakpoint: 768,    // For mobile view (max-width: 768px)
        settings: {
          padding: 0,
          slidesToShow: 1,  // Show only 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(props.qrCodes);
  return (
    <div>
      <p>
        You can update your QR code payment account below
      </p>
      {props.qrCodes &&
        props.qrCodes.length > 0 && <Slider {...qrCode}>
          {props.qrCodes.map((code, index) =>
            <div key={index} className="qrSlider-box">
              <div className='qrSlider-box-list'>

                <div><img src={`${apiUrl}/web/qrcodes/${code.image}`} title={code.title} className="img-thumbnail p-4" /></div>
                {code.title && <div><div><Button className="delete" variant='danger' size='sm' onClick={() => deleteQrcode(code.id)}>Delete</Button></div><h3 className='text-center mt-1'>{code.title}</h3></div>}
                {code.address !== '' && <Button className="copy" variant='success' size='sm' onClick={(event) => props.copyToClipBoard(event, code.address)}>Copy</Button>}
              </div>
            </div>
          )}</Slider>}



      {showTitle ? <div className='mt-5'>
        <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
          <Form.Control
            type="text"
            placeholder="title"
            onChange={(e) => setTitle(e.target.value)}
            isInvalid={error.title}
          />
          <Form.Control.Feedback type="invalid">{error.title}</Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Crypto Address" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Crypto Address"
            onChange={(e) => setAddress(e.target.value)}
          />
        </FloatingLabel>
        <p className='text-end'>
          <Button disabled={fileSubmitted} type="button" variant='danger' onClick={() => setShowTitle(false)} className="me-2">Cancel</Button>
          <Button disabled={fileSubmitted} onClick={(e) => uploadQrCode(e)} type="button" variant='success'>
            {fileSubmitted && (
              <Spinner
                variant="light"
                size="sm"
                className='mt-1 me-1'
              />
            )}Save
          </Button>
        </p>
      </div> : <div className='mt-5'>
        <input type="file" ref={inputFile} hidden accept="image/*" id="upload" onChange={(e) => chooseFile(e)} />
        <p className='text-center mt-2'><Button disabled={fileSubmitted} onClick={() => inputFile.current.click()} type="button" variant='primary' className='text-nowrap'>
          {fileSubmitted ? (
            <Spinner
              variant="light"
              size="sm"
              className='mt-1 me-1'
            />
          ) : <FaUpload className='me-1' />}
          Upload QR Code
        </Button></p>
      </div>}
    </div>
  )
}

export default QrCode
