import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  FormFeedback,
  Spinner,
  Form,
  Modal,
  FloatingLabel
} from "react-bootstrap";
class AddEditBeneficiaryFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiary: {},
      fields: {},
      errors: {},
      submitted: false,
      imgLoader: false,
    };
  }

  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleBeneficiarySubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ submitted: true });
      this.props.revertBeneficiaryData(this.state.fields);
      this.setState({ fields: {} });
      this.props.toggleBeneficiaryModal();
    }
    this.setState({ submitted: false });
  };

  validateForm = () => {
    const fields = this.state.fields;
    let formIsValid = true;
    const errors = {};
    if (!fields.beneficiary_name) {
      errors["beneficiary_name"] = "Name can not be empty.";
      formIsValid = false;
    }
    if (!fields.fathers_name) {
      errors["fathers_name"] = "Father's name can not be empty.";
      formIsValid = false;
    }
    if (!fields.grand_fathers_name) {
      errors["grand_fathers_name"] = "Grandfather's name can not be empty.";
      formIsValid = false;
    }
    if (!fields.beneficiary_city) {
      errors["beneficiary_city"] = "City can not be empty.";
      formIsValid = false;
    }
    if (!fields.beneficiary_kelebe) {
      errors["beneficiary_kelebe"] = "Beneficiary kebele can not be empty.";
      formIsValid = false;
    }
    if (!fields.beneficiary_amount) {
      errors["beneficiary_amount"] = "Amount can not be empty.";
      formIsValid = false;
    }
    if (!fields.beneficiary_wereda) {
      errors["beneficiary_wereda"] = "Beneficiary wereda can not be empty.";
      formIsValid = false;
    }
    if (!fields.beneficiary_phone) {
      errors["beneficiary_phone"] =
        "Beneficiary phone number can not be empty.";
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  };

  closeModal = () => {
    this.props.toggleBeneficiaryModal();
    this.setState({ fields: {} });
  };

  componentDidMount = () => {
    console.log(this.props.beneficiaryData);
    if (this.props.beneficiaryData) {
      this.setState({ fields: this.props.beneficiaryData });
    }
  };

  render() {
    return (
      <Modal 
          show={this.props.showModal}
          onHide={this.props.closeModal}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
      >
        <Modal.Header closeButton>Beneficiary (Sponsored Family)</Modal.Header>
        <LoadingOverlay
          active={this.state.imgLoader}
          spinner
          text="Uploading document..."
        >
          <Form onSubmit={this.handleBeneficiarySubmit}>
            <Modal.Body>
              <Form.Group>
                <Row>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="beneficiary_name"
                      label="Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="beneficiary_name"
                        placeholder="beneficiary_name"
                        onChange={(event) => this.handleChange(event, "beneficiary_name")}
                        isInvalid={this.state.errors.beneficiary_name}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.beneficiary_name}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="fathers_name"
                      label="Father's Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="fathers_name"
                        placeholder="fathers_name"
                        onChange={(event) => this.handleChange(event, "fathers_name")}
                        isInvalid={this.state.errors.fathers_name}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.fathers_name}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="grand_fathers_name"
                      label="Grandfather's Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="grand_fathers_name"
                        placeholder="grand_fathers_name"
                        onChange={(event) => this.handleChange(event, "grand_fathers_name")}
                        isInvalid={this.state.errors.grand_fathers_name}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.grand_fathers_name}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="beneficiary_city"
                      label="City"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="beneficiary_city"
                        placeholder="beneficiary_city"
                        onChange={(event) => this.handleChange(event, "beneficiary_city")}
                        isInvalid={this.state.errors.beneficiary_city}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.beneficiary_city}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="beneficiary_wereda"
                      label="Wereda"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="beneficiary_wereda"
                        placeholder="beneficiary_wereda"
                        onChange={(event) => this.handleChange(event, "beneficiary_wereda")}
                        isInvalid={this.state.errors.beneficiary_wereda}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.beneficiary_wereda}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="beneficiary_kelebe"
                      label="Kebele"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="beneficiary_kelebe"
                        placeholder="beneficiary_kelebe"
                        onChange={(event) => this.handleChange(event, "beneficiary_kelebe")}
                        isInvalid={this.state.errors.beneficiary_kelebe}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.beneficiary_kelebe}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="beneficiary_phone"
                      label="Phone No"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="beneficiary_phone"
                        placeholder="beneficiary_phone"
                        onChange={(event) => this.handleChange(event, "beneficiary_phone")}
                        isInvalid={this.state.errors.beneficiary_phone}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.beneficiary_phone}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="beneficiary_email"
                      label="Email"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="beneficiary_email"
                        placeholder="beneficiary_email"
                        onChange={(event) => this.handleChange(event, "beneficiary_email")}
                        isInvalid={this.state.errors.beneficiary_email}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.beneficiary_email}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col sm={6}>
                    <FloatingLabel
                      controlId="beneficiary_amount"
                      label="Amount"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        name="beneficiary_amount"
                        placeholder="beneficiary_amount"
                        onChange={(event) => this.handleChange(event, "beneficiary_amount")}
                        isInvalid={this.state.errors.beneficiary_amount}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.errors.beneficiary_amount}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="text-right">
              <Button
                color="danger"
                type="button"
                className="btn-sm"
                onClick={this.closeModal}
              >
                Cancel
              </Button>
              <Button
                color="success"
                type="submit"
                className="btn-sm"
                disabled={this.state.submitted}
              >
                {this.state.submitted && <Spinner size="sm" />}
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </LoadingOverlay>
      </Modal>
    );
  }
}

export default AddEditBeneficiaryFamily;
