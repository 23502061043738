import React, { useEffect, useState } from "react";
import { Card, Button, Spinner, Row, Col, InputGroup, Form } from "react-bootstrap";
import events from "../../../services/events";
import { BsArrowRight } from "react-icons/bs";
import { toast } from "react-toastify";

const EdgePay = (props) => {
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState({})
    const [fields, setFields] = useState(props.fields ? props.fields : {})

    useEffect(() => {
        events
            .getEdgepayConfigs()
            .then((res) => {
                if (res.data.success) {
                    setFields(res.data.record);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleChange = (e, field) => {
        setFields({ ...fields, [field]: e.target.value });
    }
    const validateForm = () => {
        let formIsValid = true
        const errors = {}
        if (!fields.sID) {
            errors['sID'] = 'sID can not be empty.'
            formIsValid = false
        }
        setErrors(errors)
        return formIsValid
    }

    const saveSettings = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoader(true);
            events.saveEdgepayConfigs({ fields }).then((res) => {
                if (res.data.success) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                    setLoader(false);
                }
            }).catch((err) => {
                console.log(err);
                setLoader(false);
            });
        }
    }
    return (
        <Card className="modified-card">
            <Card.Header><h4 className="mb-0 p-2">EdgePay Settings</h4></Card.Header>

            <Card.Body className="p-3 d-flex align-items-center justify-content-center" style={{ minHeight: 300 }}>
                <Form
                    noValidate
                    method="post"
                    onSubmit={saveSettings}
                >
                    <InputGroup className="mb-1" size="lg">
                        <Form.Control
                            placeholder="sID"
                            onChange={(event) => handleChange(event, "sID")}
                            isInvalid={errors.sID}
                            value={fields.sID ? fields.sID : ''}
                            name="sID"
                        />
                        <Button
                            variant="success"
                            type="submit"
                            disabled={loader}
                        >
                            {loader && (
                                <Spinner
                                    size="sm"
                                    className="me-1"
                                    variant="light"
                                />
                            )}
                            Save
                        </Button>
                    </InputGroup>
                    {errors.sID && <span className="text-danger">{errors.sID}</span>}
                </Form>
            </Card.Body>
            <Card.Footer><strong>Note:</strong> If you don't have the above details then please visit <a href="https://tiphub.iso-dev.gettrx.com/cfd2d654-84c6-43d9-872d-c2b6f26ff050/signup" target="_blank">register EdgePay merchant account</a> to get your sID. </Card.Footer>
        </Card>
    );
};

export default EdgePay;
