/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  DropdownButton, Dropdown, ButtonGroup
  } from "react-bootstrap";
  import { connect } from "react-redux";
  import { toast } from "react-toastify";

import ListMutualRequests from "./ListMutualRequests";

class MutualAidButton extends Component {
  constructor(props) {
    super(props);
        this.state = {
            showMenu:false,
            showModal:false,
        };
    }
    toggle = tab => {
        this.setState({showMenu:!this.state.showMenu})
    }
    shareForm = () => {
      if(window.innerWidth > 900){
        navigator.clipboard.writeText(`${this.props.baseUrl}/events/mutual-aid-request/${this.props.eventData.id}`).then(
          () => {
            toast.success("URL copied to clipboard.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          },
          function () {
            toast.success("Sorry! Your browser does not support this feature.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
      }
      else if (navigator.share) {
        navigator
          .share({
            title: 'Mutual Aid Request Form',
            text: this.props.eventData.title,
            url: `${this.props.baseUrl}/events/mutual-aid-request/${this.props.eventData.id}`,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error));
      }
    };
  render() {
    return (
      <>
        <DropdownButton as={ButtonGroup} title="Mutual Aid" className="me-2 mb-1" size="sm">
            <Dropdown.Item onClick={()=>this.shareForm()}>Share Form</Dropdown.Item>
            <Dropdown.Item onClick={()=>this.setState({showModal:true})}>Form Submissions</Dropdown.Item>
        </DropdownButton>
        {this.state.showModal && (
          <ListMutualRequests
            showModal={this.state.showModal}
            eventData={this.props.eventData}
            closeModal={() => this.setState({ showModal: false })}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(MutualAidButton);


