import {CircularProgressbar} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';

const DoughnutChart = ({percentage})=>{
    return(
        <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            circleRatio={0.7}
            styles={{   
                trail:{
                    transform: 'rotate(-126deg)',
                    strokeLinecap: 'butt',
                    transformOrigin: 'center center',
                },
                path:{
                    transform: 'rotate(-126deg)',
                    strokeLinecap: 'butt',
                    transformOrigin: 'center center',
                    stroke: 'green' 
                },
                text:{
                    fill: '#ddd'
                }
            }}
        />
    )
}
export default DoughnutChart;