/* eslint-disable react/prop-types */

import {
  Row,
  Col,
  Card,
  Table,
  TabContent, TabPane, Nav, NavItem, NavLink
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import events from "../../../services/events";
import LoadingOverlay from "react-loading-overlay";
import classnames from 'classnames';
import common from "../../../services/common";
import { BsDisplay, BsFiles } from "react-icons/bs";
import { BiCoinStack } from "react-icons/bi";



function Report(props) {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [topTippers, setTopTippers] = useState([]);
  const [traffic, setTraffic] = useState([]);
  const [repeatTippers, setRepeatTippers] = useState([]);
  const [fromDevice, setFromDevice] = useState([]);
  const [avgTippingAmount, setAvgTippingAmount] = useState(0);
  const [tippingApps, setTippingApps] = useState([]);
  

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const getTrackReport = () => {
      setLoading(true);
      events.trackReport({ event_id:props.eventId }).then((res) => {
        if (res.data.success) {
          setTotalVisitors(res.data.totalVisitors);
          setTopTippers(res.data.topTippers);
          setTraffic(res.data.traffic)
          setRepeatTippers(res.data.repeatTippers)
          setFromDevice(res.data.fromDevice)
          if(res.data.avgTippingAmount !== null){
            setAvgTippingAmount(parseFloat(res.data.avgTippingAmount).toFixed(2))
          }
          setTippingApps(res.data.tippingApps)
          
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };  

  useEffect(() => {
    getTrackReport();
  }, []);
  
  return (
    <LoadingOverlay active={loading} spinner text="Loading...">
        <div className="reports_card">
            <Row>
                <Col>
                    <Card body inverse color="primary" className="reports_card_child">
                        <Card.Title tag="h5" style={{}}>{totalVisitors}</Card.Title>
                        <Card.Text>Times Event Visited</Card.Text>
                        <BsFiles className="reports_card_child_icons" />
                    </Card>
                </Col>
                {fromDevice.length > 0 && fromDevice.map((fd,index)=><Col key={index}>
                    <Card body inverse color={index === 0 ? 'success':'danger'} className="reports_card_child">
                        <Card.Title tag="h5">{fd.totalVisitors}</Card.Title>
                        <Card.Text>Visitors from {fd.device}</Card.Text>
                        <BsDisplay className="reports_card_child_icons" />
                    </Card>
                </Col>)}
                <Col>
                    <Card body inverse color="info" className="pb-1 pt-2 reports_card_child">
                        <Card.Title tag="h5">${avgTippingAmount}</Card.Title>
                        <Card.Text>Average Tipping Amount</Card.Text>
                        <BiCoinStack className="reports_card_child_icons" />
                    </Card>
                </Col>
            </Row>
            <hr/>
            {tippingApps.length > 0 && <>
            <h3 className="mt-3">
                <strong style={{ fontWeight: 600 }}>Tipping App Clicked</strong>
            </h3>
            <hr/>
            <Row className="mt-3">
                
                {tippingApps.map((tp,index)=><Col key={index}>
                    <div className="info-box bg-light tipping_clicked_box">
                        <span className="info-box-icon bg-primary payment_image"><img src={`/assets/${tp.servicedetails.icon}`} className="responsive"/></span>
                        <div className="info-box-content ml-2">
                            <span className="info-box-text" style={{float: "inherit"}}>{tp.servicedetails.name}</span>
                            <span className="info-box-number" style={{fontSize:25, float: "inherit"}}>{tp.totalVisitors}</span>
                        </div>
                    </div>
                </Col>)}
            </Row></>}
            <hr/>
            <Row>
                <Col className="mt-3">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >Top Tippers</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >Repeat Tippers</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >Traffic</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Table className='table-responsive customise_table' style={{ minHeight: 300 }}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topTippers.length > 0 ?
                                        topTippers.map((t, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{t.name}</td>
                                                <td>${t.amount}</td>
                                                <td>{common.customeFormat(t.added_on,"MM dd, yyyy")}</td>
                                            </tr>
                                        )):<tr><td className="text-center" colSpan={4}>No record found.</td></tr>}
                                </tbody>
                            </Table>
                        </TabPane>
                        <TabPane tabId="2">
                            <Table className='table-responsive customise_table' style={{ minHeight: 300 }}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Payment Done</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {repeatTippers.length > 0 ?
                                        repeatTippers.map((rt, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{rt.name}</td>
                                                <td>{rt.totalDonations} times</td>
                                            </tr>
                                        )):<tr><td className="text-center" colSpan={4}>No record found.</td></tr>}
                                </tbody>
                            </Table>
                        </TabPane>
                        <TabPane tabId="3">
                            <Table className='table-responsive customise_table' style={{ minHeight: 300 }}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Device</th>
                                        <th scope="col">IP Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {traffic.length > 0 ?
                                        traffic.map((t, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{common.customeFormat(t.added_on,"MM dd, yyyy")}</td>
                                                <td>{t.device}</td>
                                                <td>{t.ip_address}</td>
                                            </tr>
                                        )):<tr><td className="text-center" colSpan={4}>No record found.</td></tr>}
                                </tbody>
                            </Table>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </div>
    </LoadingOverlay>
  );
}

export default Report;
