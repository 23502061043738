import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from 'react-redux'

const Logout = () =>{
  const dispatch = useDispatch();
  dispatch({type:'logout'});
  return <Navigate to="/" />;
}
export default Logout
