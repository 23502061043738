/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Modal, Collapse, Spinner, ProgressBar, Button, Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import events from "../../../services/events";
import common from "../../../services/common";
import domtoimage from 'dom-to-image';
import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton
  } from "react-share";

  import {
    FacebookIcon,
    LinkedinIcon,
    PinterestIcon,
    TwitterIcon,
    WhatsappIcon
  } from "react-share";
  import {FaList } from "react-icons/fa";

const EventDetailsModal = (props) => {
    const [userId, apiUrl, baseUrl] = useSelector((state) => {
        return [state.userId, state.apiUrl, state.baseUrl]
    })
  const [loader, setLoader] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [socialIconSize, setSocialIconSize] = useState(32);
  const [imageUrl, setImageUrl] = useState(null);
  const [downloadButton, setDownloadButton] = useState(false);
  const [shareButton, setShareButton] = useState(true);
  const [attempt, setAttempt] = useState(0);

  const genImage = (id) =>{
    //var node = window.document.getElementById(id);
    var node = window.document.getElementById('test-div');
    setLoader(true);
    domtoimage.toPng(node)
      .then(function (dataUrl) {
          if(dataUrl.length < 10){
            genImage(id);
          }
          else{
              let params = {event_id:props.eventDetails.id, transaction_id:props.supporter.id, image: dataUrl};
                events.saveBaseIncodedImage(params).then((res) => {
                    if (res.data.success) {
                        if(attempt === 2){
                            setPopoverOpen(true); 
                            setLoader(false); 
                            setImageUrl(res.data.image);
                            //setDownloadButton(true)
                            setShareButton(false)
                            let aTag = document.createElement("a");
                            aTag.setAttribute("href", `${apiUrl}/web/events/${res.data.image}`);
                            //aTag.setAttribute("target", "_blank");
                            document.body.appendChild(aTag);
                            setTimeout(function () {
                                aTag.click();
                                document.body.removeChild(aTag);
                            }, 300);
                        }
                        else{
                            let attemptCount = attempt + 1;
                            setAttempt(attemptCount);
                        }
                    } else if (res.data.error) {
                            toast.error(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((err) => {
                    setLoader(false);
                    toast.error("Unexpected error !", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
          }
      })
      .catch(function (error) {
        setLoader(false);
        console.error('oops, something went wrong!', error);
      });
  }
  const shareTransaction = (transaction)=>{
    if (navigator.share) {
      navigator
        .share({
          title: transaction.event.name,
          text: `${transaction.name} donated $${transaction.amount} via ${transaction.appname.name} on Tiphub`,
          url: `${apiUrl}/site/share-event?transid=${transaction.id}`,
          //files: filesArray
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    }
  };
  useEffect(() => {
      if(attempt > 0 && attempt < 3){
        genImage(props.supporter.id);
      }
  }, [attempt])
  return (
      
        <Modal show={props.showModal}
            onHide={props.closeModal}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
          <Modal.Body className="profile_layout" id="test-div" style={{backgroundColor:'#ffffff'}}>
            <Row>
                <Col md={2}>
                    <Image src={`${apiUrl}/web/profile_images/${props.eventDetails.user.image}`} fluid roundedCircle width={100}/>
                </Col>
                <Col  md={10} className="profile-details">
                    <h4>{props.eventDetails.title}</h4>
                    <p><strong>Created By: </strong> {props.eventDetails.user.username}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Image src={`${apiUrl}/web/events/${props.eventDetails.image}`} fluid/>
                </Col>
            </Row>
            <h4 className="mt-2">Targeted Amount</h4>
            <ProgressBar
                variant="success"
                now={parseInt(
                common.getTragetPercentageNew(props.eventDetails.target_amount, props.eventDetails.transactions))}
                className="mitiz-progress-bar"
                label={<span style={{color:'black',fontWeight:'bold'}}>{common.getTragetPercentageNew(props.eventDetails.target_amount, props.eventDetails.transactions)}%</span>}
            />
            <div className="preview-details mt-4">
                <div className="profile d-flex justify-content-center">
                    <div className="me-2">
                        {props.supporter.appname !== null && <img className="supporter_user mt-2 float-left" src={`/assets/${props.supporter.appname.icon}`} width={50}/>}
                    </div>
                    <div className="profile-details">
                        <h4 className="supporters_title_name">{props.supporter.name}</h4>
                        ${props.supporter.amount} &bull;{" "}
                        <span>
                            {props.supporter.added_on
                                ? common.fromNow(
                                    props.supporter.added_on
                                )
                                : "-- days ago "}
                        </span>
                    </div>
                </div>
            </div>
            <Collapse in={popoverOpen} className="mt-3">
                <ul className="social-share-icons">
                    <li className="border-bottom-0">
                        <FacebookShareButton
                        url={`${apiUrl}/site/share-event?transid=${props.supporter.id}`}
                        /* url={imageUrl} */
                        
                        >
                        <FacebookIcon size={socialIconSize} />
                        </FacebookShareButton>
                    </li>
                    <li className="border-bottom-0">
                        <TwitterShareButton
                        url={`${apiUrl}/site/share-event?transid=${props.supporter.id}`}
                        /* url={imageUrl} */
                        title={`${props.supporter.name} donated $${props.supporter.amount} via ${props.supporter.appname ? props.supporter.appname.name : ''} on Tiphub`}
                        >
                        <TwitterIcon size={socialIconSize} />
                        </TwitterShareButton>
                    </li>
                    <li className="border-bottom-0">
                        <LinkedinShareButton
                        summary={`${props.supporter.name} donated $${props.supporter.amount} via ${props.supporter.appname ? props.supporter.appname.name : ''} on Tiphub`}
                        url={`${apiUrl}/site/share-event?transid=${props.supporter.id}`}
                        /* url={imageUrl} */
                        >
                        <LinkedinIcon size={socialIconSize} />
                        </LinkedinShareButton>
                    </li>
                    <li className="border-bottom-0">
                        <PinterestShareButton
                        url={`${apiUrl}/site/share-event?transid=${props.supporter.id}`}
                        /* url={imageUrl} */
                        description={props.eventDetails.description}
                        media={props.eventDetails.image}
                        >
                        <PinterestIcon size={socialIconSize} />
                        </PinterestShareButton>
                    </li>
                    <li className="border-bottom-0">
                        <WhatsappShareButton
                        title={`${props.supporter.name} donated $${props.supporter.amount} via ${props.supporter.appname ? props.supporter.appname.name : ''} on Tiphub`}
                        url={`${apiUrl}/site/share-event?transid=${props.supporter.id}`}
                        /* url={imageUrl} */
                        >
                        <WhatsappIcon size={socialIconSize}/>
                        </WhatsappShareButton>
                    </li>
                    <li className="border-bottom-0">
                        <FaList style={{width:30,height:30}} onClick={()=>shareTransaction(props.supporter)}/>
                    </li> 
                </ul>
            </Collapse> 
          </Modal.Body>
          <Modal.Footer>
              {downloadButton && <a href={`${apiUrl}/web/events/${imageUrl}`} download={imageUrl} className="btn btn-primary">Download Image</a>}
              {shareButton && <Button type="button" variant="success" onClick={()=>genImage(`supporter-${props.supporter.id}`)} disabled={loader}>{loader && <Spinner size="sm" className="me-1" />}Share</Button>}
              <Button type="button" variant="danger" onClick={()=>props.closeModal()}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
};

export default EventDetailsModal;
