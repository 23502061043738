import moment from "moment";
import React from "react";
import { FaFacebook, FaGoogle, FaTwitter } from "react-icons/fa";
import http from './http'

const common = {
  currencyArr: ["", "cilDollar", "cilBritishPound", "cilEuro"],
  servicesArr: ["", "CashApp", "Venmo", "Patreon", "Paypal.me"],
  servicesLinkArr: [
    "",
    "https://cash.app/$",
    "https://venmo.com/code?user_id=",
    "https://www.patreon.com/",
    "https://www.paypal.com/paypalme/",
  ],
  customeFormat: (date, format, time = false) => {
    let d = new Date(date);
    let date_format = "";
    if (format === "dd-mm-yyyy") {
      date_format = moment(date).format("D-mm-YYYY");
    } else if (format === "dd MM, yyyy") {
      date_format = moment(date).format("DD MMMM, YYYY");
    } else if (format === "MM dd, yyyy") {
      date_format = moment(date).format("MMMM Do, YYYY");
    } else if (format === "yyyy-mm-dd") {
      date_format = moment(date).format("YYYY-mm-D");
    }
    if (time) {
      date_format =
        date_format +
        " at " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        moment(d).format(" a");
    }
    return date_format;
  },
  getTragetPercentage: (targetAmount, transactions) => {
    if (transactions.length > 0) {
      let totalCollectedAmountArr = [];
      transactions.forEach((transaction, index) => {
        totalCollectedAmountArr[index] = transaction.amount;
      });
      if (totalCollectedAmountArr.length > 0) {
        let totalCollectedAmount = 0;
        totalCollectedAmount = totalCollectedAmountArr.reduce(
          (accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue);
          }
        );
        let percentage = totalCollectedAmount / targetAmount;
        return (percentage * 100).toFixed(2);
      }
    } else {
      return 0;
    }
  },
  getTragetPercentageNew: (targetAmount, transactions) => {
    if (transactions.length > 0) {
      let percentage = parseFloat(transactions[0].totalAmount) / targetAmount;
      return (percentage * 100).toFixed(2);
    } else {
      return 0;
    }
  },
  fromNow: (dateTime) => {
    let now = moment(new Date());
    if (now.diff(dateTime, "months") > 1) {
      return `${now.diff(dateTime, "months")} months ago`;
    } else if (now.diff(dateTime, "weeks") > 1) {
      return `${now.diff(dateTime, "weeks")} weeks ago`;
    } else if (now.diff(dateTime, "days") > 1) {
      return `${now.diff(dateTime, "days")} days ago`;
    } else if (now.diff(dateTime, "days") == 1) {
      return `${now.diff(dateTime, "days")} day ago`;
    } else if (now.diff(dateTime, "hours") > 1) {
      return `${now.diff(dateTime, "hours")} hours ago`;
    } else if (now.diff(dateTime, "hours") == 1) {
      return `${now.diff(dateTime, "hours")} hour ago`;
    } else if (now.diff(dateTime, "minutes") > 1) {
      return `${now.diff(dateTime, "minutes")} minutes ago`;
    }
  },
  fundRaised: (transaction) => {
    if (transaction.length > 0) {
      return transaction.reduce((a, b) => ({
        amount: parseInt(a.amount) + parseInt(b.amount),
      }));
    } else return 0;
  },
  getProfileLink: (userDetails) => {
    if (userDetails.twitter_id !== null) {
      return (
        <React.Fragment>
          <a
            href={`https://twitter.com/${userDetails.twitter_username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="details_link"
          >
            <FaTwitter />
            {userDetails.twitter_username}
          </a>
        </React.Fragment>
      );
    } else if (userDetails.facebook_id !== null) {
      return (
        <React.Fragment>
          <a
            /* href={`https://facebook.com/${userDetails.username}`} */
            href="javascript:void(0)"
            /* target="_blank" */
            rel="noopener noreferrer"
            className="details_link"
          >
            <FaFacebook />
            {userDetails.facebook_username}
          </a>
        </React.Fragment>
      );
    } else if (userDetails.google_id !== null) {
      return (
        <React.Fragment>
          <span className="details_link">
            <FaGoogle />
            {userDetails.google_username}
          </span>
        </React.Fragment>
      );
    }
  },
  //eventCategories:['Accidents & Emergencies','Animals & Pets','Babies, Kids & Family','Business & Entrepreneurs','Celebrations & Events','Community & Neighbors','Creative Arts, Music & Film','Dreams, Hopes & Wishes','Education & Learning','Envoirnment','Funerals & Memorials','Medical & Illness & Healing','Missions, Faith & Church','Rend, Food & Monthly Bills','Sports, Teams & Club','Travel & Adeventure','Volunteer & Services','Weddings & Honeymoons','Family Giving','Other'],
  currencySymbols: ["USD", "CAD", "EUR", "GBP", "kr", "CHF", "AUD"],
  currencySymbolsArr: ["$", "C$", "€", "£", "SEK", "₣", "A$"],
  currencySymbolsCode: ["840", "124", "978", "826", "752", "756", "036"],
  numberFormat: (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  },
  getStates: (param) => http.get('/common/get-states', { params: param }),
  getCountries: (param) => http.get('/common/get-countries', { params: param }),
  careerLevel: [
    { id: 1, label: 'College student' },
    { id: 2, label: 'Recent graduate' },
    { id: 3, label: '3-5 years work experience' },
    { id: 3, label: '5-10 years work experience' },
  ],
  membershipLevel: [
    { id: 1, label: 'Bronze' },
    { id: 2, label: 'Silver' },
    { id: 3, label: 'Gold' },
  ],
  tigrinyaProficiency: [
    { id: 1, label: 'Beginner' },
    { id: 2, label: 'Intermediate' },
    { id: 3, label: 'Advanced' },
  ],
  durations: [
    { id: 1, label: 'Monthly' },
    { id: 2, label: 'Quaterly' },
    { id: 3, label: 'Half Yearly' },
    { id: 4, label: 'Yearly' },
  ],
  getIavToken: (data) => http.post('common/get-iav-token', data),
  saveFundingSourceUrl: (data) => http.post('common/save-funding-source-url', data),
  transferFund: (data) => http.post('common/transfer-fund', data),
  bankingEnquiry: (data) => http.post('common/banking-enquiry', data),
  addDwollaCustomer: (data) => http.post('common/add-dwolla-customer', data),
  createPayment: (data) => http.post('common/create-payment', data),
  getStripeApiKey: (param) => http.get('/common/get-stripe-api-key', { params: param }),
  checkSubscriptionSetting: (param) => http.get('/common/check-subscription-setting', { params: param }),
  isValidEmail: (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  },
  validatePassCode: (data) => http.post('common/validate-passcode', data),
  getPaypalConfig: (param) => http.get('/common/get-paypal-config', { params: param }),
  getEdgepayConfig: (param) => http.get('/common/get-edgepay-config', { params: param }),
  getGuid: () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  },
  eventRoleArr: [
    { label: 'Admin', value: 1 },
    { label: 'Editor', value: 2 },
    { label: 'Contributor', value: 3 },
    { label: 'Viewer', value: 4 },
    { label: 'Physical Ticket Vendor', value: 5 }
  ],
  getReferenceId: (data) => http.post('common/get-reference-id', data),
  processEdgepayPayment: (data) => http.post('common/process-edgepay-payment', data),
  appendEdgePayLibrary: (authKey) => {
    let scripts = document.getElementsByTagName("script");
    let scriptFound = false;
    //let edgePayLib = "https://js.edgepayportal.com/2.0.0/js/sandbox/client.min.js";
    let edgePayLib = "https://js.edgepayportal.com/2.0.0/js/prod/client.min.js";
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') == edgePayLib) {
        scriptFound = true;
      }
    }
    if (!scriptFound) {
      const script = document.createElement('script');
      script.src = edgePayLib;

      script.async = true;
      script.onload = () => {
        /* eslint-disable */
        EdgePay.init({
          authKey: authKey
        });
        /* eslint-enable */
      };
      document.body.appendChild(script);
    }
    else {
      /* eslint-disable */
      EdgePay.init({
        authKey: authKey
      });
      /* eslint-enable */
    }
  },
  prepareCcExpiry: (inputValue) => {
    return inputValue.replace(
      /[^0-9]/g, '' // To allow only numbers
    ).replace(
      /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
      /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
  },
  prepareCcNumber: (ccNumber) => {
    const v = ccNumber
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : ccNumber;
  },
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
      );
  }
}

export default common;


