import React, { useState, useRef } from 'react'
import { Button,Modal, Spinner, Form, InputGroup } from 'react-bootstrap'
import {useDispatch, useSelector } from 'react-redux'
import axios from "axios";
import {useNavigate} from "react-router-dom"

const UpdateUsername = (props) => {
  const apiUrl = useSelector((state) => state.apiUrl);

  const [errors, setErrors] = useState({...props.errors})
  const [fields, setFields] = useState({...props.dataToUpdate})
  const [loader, setLoader] = useState(false);
  const [submitted, setSumitted] = useState(false);
  const focusInput = useRef(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleUpdate = (e) => {
    e.preventDefault();
    if(validateForm()){
      setLoader(true);
      
      axios.post(apiUrl + "/user/update-username", {fields:fields}).then(function(response) {
        if (response.data.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userType", response.data.user_type);
          localStorage.setItem("userName", response.data.username);
          localStorage.setItem("uName", response.data.name);
          localStorage.setItem("userId", response.data.user_id);
          localStorage.setItem("userImage", response.data.image);
  
          let payload = {
            token: response.data.token,
            userName: response.data.username,
            uName: response.data.name,
            userId: response.data.user_id,
            userType: response.data.user_type,
            userImage: response.data.image
          };
          dispatch({type:"login", ...payload});
          setLoader(false);
          navigate("/home");
        }
        else if (response.data.error) {
          setErrors({...response.data.message});
          setLoader(false);
        }
      }).catch(function(error) {
        console.log(error);
      });
    }
  }
  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value })
  }
  const validateForm = () => {
    let formIsValid = true
    const errors = {}
    if (!fields.username) {
        errors['username'] = 'Username can not be empty.'
        formIsValid = false
    }
    setErrors(errors)
    return formIsValid
  }
    return (
      <Modal
          show={props.showModal}
          onHide={props.closeModal}
          backdrop="static"
          keyboard={false}
          centered
      >
          <Modal.Header><h4>Update Username</h4></Modal.Header>
          <Modal.Body>
              <Form noValidate onSubmit={handleUpdate}>
                  <InputGroup className="mb-3" size="lg" hasValidation>
                      <Form.Control
                          name="username" 
                          type="text" 
                          onChange={(event) => handleChange(event, 'username')} 
                          ref={focusInput}
                          isInvalid={!!errors.username}
                      />
                      <Button type="submit" variant="success" disabled={submitted}>{submitted && <Spinner animation="border" variant="light" className="me-1" style={{width:20, height:20}}/>}Update</Button>
                      <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                  </InputGroup>
              </Form>
          </Modal.Body>
      </Modal>
    );
  }


export default UpdateUsername;
