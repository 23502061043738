/* eslint-disable react/prop-types */

import {
  Modal,
  Row,
  Col,
  Button,
  Spinner,
  Form,
  Card,
  Table,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import events from "../../../services/events";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";

function ManageQrCode(props) {
  const [formLoading, setFormLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({});
  const [qrCodes, setQrCodes] = useState([]);

  const apiUrl = useSelector((state) => state.apiUrl);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.files });
  };

  const formValidation = () => {
    const errors = {};
    let formIsValid = true;
    if (!fields.file_names) {
      errors["file_names"] = "Please choose qrcode images.";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValidation()) {
        setFormLoading(true);
        const formData = new FormData()
        //formData.append('file_names', e.target.files[0])
        fields['file_names'].forEach(f=>{
          formData.append('file_names[]', f)
        })
        formData.append('event_id', props.eventId);
        events.uploadQrCode(formData).then((res) => {
            if (res.data.success) {
              document.getElementById('file_names').value = '';
              setFields({});
              setQrCodes(res.data.images);
              let newEventData = props.eventData;
              newEventData.qrcodes = res.data.images;
              props.setEventData(newEventData)
            } else if (res.data.error) {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
            setFormLoading(false)
        }).catch((err) => {
            toast.error('Unexpected Error!', {
                position: toast.POSITION.TOP_RIGHT,
            })
        })
    }
  };

  const getQrCodes = () => {
    setListLoading(true);
    events.getQrCodes({ event_id: props.eventId }).then((res) => {
        if (res.data.success) {
          setListLoading(false);
          setQrCodes(res.data.qrcodes);
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const deleteQrCode = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id };
      events.deleteQrcode(params).then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            let newQrCodes = qrCodes.filter(code=>parseInt(code.id) !== parseInt(id));
            setQrCodes(newQrCodes);
            let newEventData = props.eventData;
            newEventData.qrcodes = newQrCodes;
            props.setEventData(newEventData)
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    getQrCodes();
    //getPaymentServiceNames();
  }, []);

  return (
    <Modal 
        show={props.showModal}
        onHide={props.closeModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
    >
      <Modal.Header closeButton>Manage QrCode Image</Modal.Header>
      <Modal.Body>
        <LoadingOverlay active={formLoading} spinner text="Loading...">
          <Form onSubmit={handleSubmit} method="post">
            <Modal.Body>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload QR Code</Form.Label>
                <Form.Control type="file" id="file_names" name="file_names"onChange={(event) => handleChange(event, 'file_names')} multiple/>
                {errors.file_names && <span style={{color:'red'}}>{errors.file_names}</span>}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="btn-sm" onClick={()=>props.closeModal()}>Cancel</Button>
              <Button
                variant="success"
                type="submit"
                className="btn-sm"
                disabled={formLoading}
              >
                {formLoading && (
                  <Spinner
                    variant="light"
                    size="sm"
                  />
                )}
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </LoadingOverlay>
        <Card>
          <Card.Header><h3>Qr Codes</h3></Card.Header>
          <LoadingOverlay active={listLoading} spinner text="Loading...">
            <Card.Body style={{minHeight:150}}>
              {qrCodes.length > 0 ? (
                <Row>
                  {qrCodes.map(image=><Col md={3} className="text-center"><img src={`${apiUrl}/web/qrcodes/${image.file_name}`} className="img-thumbnail"/><br/><Button color="danger" className="btn-sm mt-1 mb-2" onClick={()=>deleteQrCode(image.id)}>Delete</Button></Col>)}
                </Row>
              ) : (
                <h5 className="text-center">No records found</h5>
              )}
            </Card.Body>
          </LoadingOverlay>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default ManageQrCode;
