/* eslint-disable react/prop-types */
import { Button, Table, Badge, Spinner, Form, FloatingLabel, Modal, TabPane, TabContent, Nav, NavItem, NavLink, Row, Col} from "react-bootstrap";
import React, { Component, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import events from "../../../services/events";
import { connect } from "react-redux";
import { FaFileExcel } from "react-icons/fa";
import { toast } from "react-toastify";
import moment from "moment";
import classnames from "classnames";
import ListMembers from "../Payments/ListMembers";
import { useSelector } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class MemberPaymentsStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      pageSize: 1000,
      loader: false,
      records: [],
      pageDetails: {},
      disabled: false,
      showDonorDetailModal: false,
      fields:{},
      errors:{},
      activeTab: "1",
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  handleSubmit = (e = null) => {
    if (e) e.preventDefault();
    this.setState({ submitted: true },()=>{
        this.listRecords();
    });
  };
  componentDidMount = () => {
    //this.listRecords();
  }
  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.listRecords();
    });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };
  render() {
    return (
      <>
        <LoadingOverlay
            active={this.state.loader}
            spinner
            text="Loading..."
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <strong>Payments</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <strong>Members</strong>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1" className="membership_due">
              {this.state.activeTab === "1" && <ListPaymentStatus eventDetails={this.props.eventDetails}/>}
            </TabPane>
            <TabPane tabId="2">
              {this.state.activeTab === "2" && <ListMembers eventId={this.props.eventDetails.id}/>}
            </TabPane>
          </TabContent>
        </LoadingOverlay>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(MemberPaymentsStatus);

export function ListPaymentStatus (props){
  const [members, setMembers] = useState([]);
  const [loader, setLoader] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [showSmsModal, setShowSmsModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [pageNo, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRecords, setSelectedRecords] = useState([])
  const userName = useSelector((state) => state.userName);
  const [months, setMonths] = useState(['Jan','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])
  const monthsScrollPositions = [
    {id: 0, name: 'Jan', scrollPosition: 1},
    {id: 1, name: 'Feb', scrollPosition: 81},
    {id: 2, name: 'Mar', scrollPosition: 151},
    {id: 3, name: 'Apr', scrollPosition: 225},
    {id: 4, name: 'May', scrollPosition: 290},
    {id: 5, name: 'Jun', scrollPosition: 360},
    {id: 6, name: 'Jul', scrollPosition: 435},
    {id: 7, name: 'Aug', scrollPosition: 505},
    {id: 8, name: 'Sep', scrollPosition: 575},
    {id: 9, name: 'Oct', scrollPosition: 650},
    {id: 10, name: 'Nov', scrollPosition: 719},
    {id: 11, name: 'Dec', scrollPosition: 755}
  ];

  const selectRecord = (e) => {
    let selRecords = [...selectedRecords];
    if (e.target.checked) {
      selRecords.push(e.target.value);
      selRecords = [...selectedRecords, e.target.value]
    } else {
      selRecords.splice(selRecords.indexOf(e.target.value), 1);
    }
    setSelectedRecords(selRecords);
  };
  
  const selectAll = (e) => {
    let checkboxes = document.querySelectorAll('input[name=members]');
    checkboxes.forEach(c=>{
      c.checked = e.target.checked;
    });
    let selRecords = [];
    if(e.target.checked){
      members.forEach((d,index)=>{
        selRecords[index] = d.id;
      });
    }
    setSelectedRecords(selRecords);
  }

  const listMembers = () => {
    setLoader(true);
    events
    .getEventMembers({
      page: pageNo,
      event_id:props.eventDetails.id,
      pageSize:pageSize
    })
    .then((res) => {
      if (res.data.success) {
        setMembers(res.data.members);
      } else if (res.data.error) {
        console.log(res.data.message);
      }
      setLoader(false);
      //alert(moment().format('MMM'));
      const currentScrollPosition = monthsScrollPositions.filter((msp, index) => {
        if(msp.name === moment().format('MMM')){
          return msp;
        }
      });
      //alert(currentScrollPosition[0].id);
      document.getElementById('payment-status').scrollLeft = monthsScrollPositions[currentScrollPosition[0].id - 3].scrollPosition;
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    listMembers();
  }, []);

  const exportExcel = () => {
    events
      .getEventMembersExport({
        page: pageNo,
        event_id:props.eventDetails.id,
        pageSize:pageSize,
        export: true,
      })
      .then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data], {
            type: res.data.type,
            encoding: "UTF-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = userName + "-" + props.eventDetails.url + "-" + moment().format("MM-DD-YYYY") + ".xlsx";
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleScroll = () => {
    console.log('window.scrollX', document.getElementById('payment-status').scrollLeft);
  }

  return <div className="outer">
  <div className="inner" id="payment-status">
    {loader ? <div className="text-center p-5"><Spinner /></div> : <Table  className="table table-responsive report-table-payment" >
      <thead>
          <tr>
            <th className='border-top-0 first-col'><input type="checkbox" onChange={selectAll} value={0}/> Name</th>
            {monthsScrollPositions.map((msp,index) => {
                let d = new Date();
                let startFrom = d.getMonth() - 2;
                /* if(index >= startFrom){
                  return <th key={index} className='border-top-0'>{msp.name}</th>;
                } */
                return <th key={index} className='border-top-0' style={{whiteSpace:'nowrap'}}>{msp.name} {d.getFullYear()}</th>;
              }
            )}
            {monthsScrollPositions.map((msp,index) => {
                let d = new Date();
                let startFrom = d.getMonth() - 2;
                /* if(index < startFrom){
                  return <th key={index} className='border-top-0'>{msp.name}</th>;
                } */
                return <th key={index} className='border-top-0' style={{whiteSpace:'nowrap'}}>{msp.name} {d.getFullYear() + 1}</th>;
              }
            )}
          </tr>
      </thead>
      <tbody>
          {members.length > 0 ? (
          members.map((ele, index) => (
              <tr key={index}>
                <td className="first-col bg-white"><input type="checkbox" onChange={selectRecord} value={ele.id} name="members"/> <span>{ele.name}</span></td>
                {monthsScrollPositions.map((m, indx)=>{
                  let d = new Date();
                  let startFrom = d.getMonth() - 2;
                  let mDetails = ele.month.filter(em=>{
                    let newNameCurrent = m.name +'-'+ d.getFullYear();
                    if(em.name === newNameCurrent){
                      return em;
                    }
                  });
                  /* if(indx >= startFrom){
                    if(mDetails.length > 0){
                      if(mDetails[0].value === 'Due')
                        return <td className="bg-danger">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                      else if(mDetails[0].value === 'Overdue')
                        return <td className="bg-warning">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                      else if(mDetails[0].value === 'Paid')
                        return <td className="bg-success">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                      else
                        return <td>${mDetails[0].value}</td>
                    }
                    else{
                      return <td></td>
                    }
                  } */
                  if(mDetails.length > 0){
                    if(mDetails[0].value === 'Due')
                      return <td className="bg-danger">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                    else if(mDetails[0].value === 'Overdue')
                      return <td className="bg-warning">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                    else if(mDetails[0].value === 'Paid')
                      return <td className="bg-success">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                    else
                      return <td>${mDetails[0].value}</td>
                  }
                  else{
                    return <td></td>
                  }
                })}
                {monthsScrollPositions.map((m, indx)=>{
                  let d = new Date();
                  let startFrom = d.getMonth() + 2;
                  let mDetails = ele.month.filter(em=>{
                    
                    let newNameNext = m.name +'-'+ (parseInt(d.getFullYear()) + 1);
                    if(em.name === newNameNext){
                      return em;
                    }
                  });
                  if(indx < startFrom){
                    if(mDetails.length > 0){
                      if(mDetails[0].value === 'Due')
                        return <td className="bg-danger">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                      else if(mDetails[0].value === 'Overdue')
                        return <td className="bg-warning">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                      else if(mDetails[0].value === 'Paid')
                        return <td className="bg-success">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                      else
                        return <td>${mDetails[0].value}</td>
                    }
                    else{
                      return <td></td>
                    }
                  }
                  /* if(mDetails.length > 0){
                    if(mDetails[0].value === 'Due')
                      return <td className="bg-danger">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                    else if(mDetails[0].value === 'Overdue')
                      return <td className="bg-warning">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                    else if(mDetails[0].value === 'Paid')
                      return <td className="bg-success">{mDetails[0].amount !== "" ? `$${mDetails[0].amount}` : mDetails[0].value}</td>;
                    else
                      return <td>${mDetails[0].value}</td>
                  }
                  else{
                    return <td></td>
                  } */
                })}
              </tr>
          ))
          ) : (
          <tr>
              <td colSpan="15" className="text-center" style={{height:250}}>
                  Record not found.
              </td>
          </tr>
          )}
      </tbody>
    </Table>}
  </div>
  <Row>
    <Col md={7}>
      <Button type="button" color="success" disabled={selectedRecords.length === 0} className="mr-2" onClick={()=>setShowEmailModal(true)}><Badge color="primary" style={{width:'auto',overflow:'auto'}}>{selectedRecords.length}</Badge> Send Email</Button>
      <Button type="button" className="mr-2" color="warning" disabled={selectedRecords.length === 0} onClick={()=>setShowSmsModal(true)}><Badge color="primary" style={{width:'auto',overflow:'auto'}}>{selectedRecords.length}</Badge> Send SMS</Button>
      <Button
          type="button"
          color="primary"
          name="export"
          disabled={submitted}
          onClick={exportExcel}
        >
          {submitted && <Spinner size="sm" />}
          <FaFileExcel size="1em" className="mb-1 mr-1" /> Export
      </Button>
    </Col>
    
  </Row>
  {showSmsModal && <SendSmsModal 
    showModal = {showSmsModal} 
    closeModal={()=>setShowSmsModal(false)}
    eventId={props.eventDetails.id}
    selectedRecords={selectedRecords}
  />}
  {showEmailModal && <SendEmailModal 
    showModal = {showEmailModal} 
    closeModal={()=>setShowEmailModal(false)}
    eventId={props.eventDetails.id}
    selectedRecords={selectedRecords}
  />}
</div>
}

export function SendSmsModal(props) {
  const [submitted, setSubmitted] = useState(false)
  const [fields, setFields] = useState({eventId: props.eventId, selectedRecords:props.selectedRecords})
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    const errors = {};
    e.preventDefault();
    if(!fields.sms_content){
      errors['sms_content'] = 'Sms content can not be blank.';
    }
    else{
      setSubmitted(true);
      events.sendPaymentReminder(fields).then((res) => {
          if (res.data.success) {
            setSubmitted(false);
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            props.closeModal();  
          } else if (res.data.error) {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setSubmitted(false);
          }
      }).catch((err) => {
          toast.error(err.message, {
              position: toast.POSITION.TOP_RIGHT,
          });
          setSubmitted(false);
      });
    }
    setErrors(errors);
  }
  return (
    <Modal show={props.showModal} onHide={()=>props.closeModal()} centered>
      <Modal.Header closeButton>Send Payment Reminder</Modal.Header>
        <Form onSubmit={handleSubmit} method="post">
          <Modal.Body>
            <Form.Control
              as="textarea"
              id="sms_content"
              name="sms_content"
              rows={3}
              onChange={(event) => handleChange(event, "sms_content")}
              isInvalid={!!errors.sms_content}
            />
            <Form.Control.Feedback type="invalid">{errors.sms_content}</Form.Control.Feedback>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={()=>props.closeModal()}>Cancel</Button>
            <Button
              variant="success"
              type="submit"
              disabled={submitted}
            >
              {submitted && (
                <Spinner
                  color="light"
                  size="sm"
                />
              )}
              Send
            </Button>
          </Modal.Footer>
        </Form>
    </Modal>
  );
}

export function SendEmailModal(props) {
  const [submitted, setSubmitted] = useState(false)
  const [fields, setFields] = useState({eventId: props.eventId, selectedRecords:props.selectedRecords})
  const [errors, setErrors] = useState({})
  const [email_content, setEmailContent] = useState('')

  const handleChange = (e) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    const errors = {};
    e.preventDefault();
    if(!fields.email_subject){
      errors['email_subject'] = 'Subject can not be blank.';
    }
    if(email_content === ''){
      errors['email_content'] = 'Email content can not be blank.';
    }
    else{
      setSubmitted(true);
      events.sendEmailPaymentReminder({...fields, email_content})
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.closeModal();
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    setErrors(errors);
  }
  return (
    <Modal show={props.showModal} onHide={()=>props.closeModal()} centered>
      <Modal.Header closeButton><h4>Send Email</h4></Modal.Header>
        <Form onSubmit={handleSubmit} method="post">
          <Modal.Body>
            <FloatingLabel
                label="Subject"
                className="mb-3"
              >
              <Form.Control
                type="text"
                name="email_subject"
                placeholder="email_subject"
                onChange={handleChange}
                isInvalid={!!errors.email_subject}
              />
              <Form.Control.Feedback type="invalid">{errors.email_subject}</Form.Control.Feedback>
            </FloatingLabel>
            <Form.Group>
              <Form.Label>Email Content</Form.Label>
                <CKEditor
                    editor={ ClassicEditor }
                    onChange={ ( event, editor ) => {
                      setEmailContent(editor.getData())
                    }}
                />
                {errors.email_content && (
                  <span style={{ color: "red" }}>
                    {errors.email_content}
                  </span>
                )}
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button color="danger" onClick={()=>props.closeModal()}>Cancel</Button>
            <Button
              variant="success"
              type="submit"
              disabled={submitted}
            >
              {submitted && (
                <Spinner
                  variant="light"
                  size="sm"
                />
              )}
              Send
            </Button>
          </Modal.Footer>
        </Form>
    </Modal>
  );
}
