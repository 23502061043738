import http from './http'

const events = {
  add: (data) => http.post('/event/add', data),
  list: (param) => http.get('/event/list', { params: param }),
  listByUser: (param) => http.get('/event/list-by-user', { params: param }),
  getOne: (param, userId = null) => http.get(userId !== null ? '/event/get-with-logged-in' : '/event/get', { params: param }),
  deleteOne: (data) => http.post('/event/delete', data),
  addEventApp: (param) => http.post('event/add-event-app', { fields: param }),
  listEventApps: (param) => http.get('event/list-event-apps', { params: param }),
  getOneEventApp: (param) => http.get('event/get-event-app', { params: param }),
  deleteEventApp: (data) => http.post('event/delete-event-app', data),
  addTransaction: (param) => http.post('event/add-transaction', { fields: param }),
  listTransaction: (param) => http.get('event/list-transactions', { params: param }),
  deleteTransaction: (data) => http.post('event/delete-transaction', data),
  uploadEventImage: data => http.post("/common/upload-event-image", data, {headers: {'Content-Type': 'multipart/form-data'}}),
  reportlist: (param) => http.get("event/reports", { params: param }),
  generateQrCode: (data) => http.post('event/generate-qr-code', data),
  trackEventApp: (param) => http.get('/event/track-event-app', { params: param }),
  trackReport: (param) => http.get('/event/track-report', { params: param }),
  uploadQrCode: data => http.post('/event/upload-qrcode', data, {headers: {'Content-Type': 'multipart/form-data'}}),
  getQrCodes: (param) => http.get('/event/get-qrcodes', { params: param }),
  deleteQrcode: (data) => http.post('event/delete-qrcode', data),
  getReports: (data) => http.post('event/reports', data),
  getSupporters: (param) => http.get('event/get-supporters', { params: param }),
  checkIn: (data) => http.post('event/check-in', data),
  exportSupporters: (data) => http.post('event/export-supporters', data, { responseType: 'blob' }),
  linkPayment: (data) => http.post('event/link-payment', data),
  unLinkPayment: (data) => http.post('event/unlink-payment', data),
  postUpdate: (data) => http.post('event/post-update', data),
  eventList: (param) => http.get('event/get-updates', { params: param }),
  getTransactionDetails: (param) => http.get('/event/get-transaction-details', { params: param }),
  deleteUpdate: (data) => http.post('event/delete-update', data),
  saveBaseIncodedImage: (data) => http.post('event/save-base-incoded-image', data),
  uploadGallery: (data) => http.post('event/upload-gallery', data, {headers: {'Content-Type': 'multipart/form-data'}}),
  deleteGallery: (data) => http.post('event/delete-gallery', data),
  getGallery: (param) => http.get("event/get-gallery", { params: param }),
  getTraffic: (param) => http.get('event/get-traffic', { params: param }),
  getToEdit: (data) => http.post('event/get-to-edit', data),
  searchEvent: (param) => http.get('event/search', { params: param }),
  saveDonorDetails: (data) => http.post('event/save-donor-details', data),
  listDonors: (param) => http.get('event/list-donors', { params: param }),
  checkDonor: (param) => http.get('event/check-donor', { params: param }),
  sendThanksEmail: (data) => http.post('event/send-thanks-email', data),
  sendReceiptEmail: (data) => http.post('event/send-receipt-email', data),
  updateSequence: (data) => http.post('event/update-app-sequence', data),
  getEventApps: (param) => http.get('event/get-apps', { params: param }),
  getOverAllReport: (param) => http.get('event/get-overall-reports', { params: param }),
  addPermission: (data) => http.post('event/assign-team', data),
  getTeams: (param) => http.get('event/get-team-list', { params: param }),
  permissionList: (param) => http.get('role/get-actions', { params: param }),
  getTeamDetails: (param) => http.get('event/get-team-details', { params: param }),
  getUsers: (param) => http.get('user/get-team', { params: param }),
  featuredEvent: (param) => http.get('event/get-featured', { params: param }),
  paymentDocUpload: (data) => http.post('common/upload-documents', data, {headers: {'Content-Type': 'multipart/form-data'}}),
  donorDetails: (param) => http.get('event/get-donor-details', { params: param }),
  vefifyDonor: (data) => http.post('event/verify-donor', data),
  sendPaymentConfirmationEmail: (data) => http.post('event/send-payment-confirmation-email', data),
  sendFollowUpEmail: (data) => http.post('event/send-follow-up-email', data),
  getEventCategory: (param) => http.get('event/get-categories', { params: param }),
  getSubCategory: (param) => http.get('event/get-sub-categories', { params: param }),
  deleteDonor: (data) => http.post('event/delete-donor', data),
  getBasicDetails: (param) => http.get('event/get-basic-details', { params: param }),
  saveMutualAidRequest: (data) => http.post('event/save-mutual-aid-request', data),
  listMutualRequests: (param) => http.get('event/list-mutual-aid-requests', { params: param }),
  deleteMutualAidRequest: (data) => http.post('event/delete-mutual-aid-request', data),
  toggleDonorForm: (data) => http.post('event/toggle-donor-form', data),
  updateDonorAmount: (data) => http.post('event/update-donor-amount', data),
  exportMutualRequests: (data) => http.post('event/export-mutual-requests', data, { responseType: 'blob' }),
  deleteBeneficiary: (data) => http.post('event/delete-beneficiary', data),
  saveMember: (data) => http.post('event/save-member', data),
  deleteMember: (data) => http.post('event/delete-member', data),
  listMembers: (param) => http.get('event/list-members', { params: param }),
  getEventMembers: (param) => http.get('event/get-event-members', { params: param }),
  getEventMembersExport: (param) => http.get('event/get-event-members', { params: param, responseType: 'blob' }),
  getPlans: (param) => http.get('event/get-plans', { params: param }),
  getTickets: (param) => http.get('event/get-tickets', { params: param }),
  getPrograms: (param) => http.get('event/get-programs', { params: param }),
  getPlanDetails: (param) => http.get('event/get-plan-details', { params: param }),
  getTicketDetails: (param) => http.get('event/get-ticket-details', { params: param }),
  getProgramDetails: (param) => http.get('event/get-program-details', { params: param }),
  savePlan: (data) => http.post('event/save-plan', data),
  saveTicket: (data) => http.post('event/save-ticket', data),
  saveProgram: (data) => http.post('event/save-program', data),
  deletePlan: (data) => http.post('event/delete-plan', data),
  deleteTicket: (data) => http.post('event/delete-ticket', data),
  deleteProgram: (data) => http.post('event/delete-program', data),
  sendMutualEmail: (data) => http.post('event/send-mutual-email', data),
  getMembers: (param) => http.get('event/get-members', { params: param }),
  addMember: (data) => http.post('event/add-member', data),
  removeMember: (data) => http.post('event/remove-member', data),
  deleteTeam: (data) => http.post('event/delete-team', data),
  saveMembershipDueForm: (data) => http.post('event/save-membership-due-form', data),
  getMembershipDueForm: (param) => http.get('event/get-membership-due-form', { params: param }),
  getLocations: (param) => http.get('event/get-locations', { params: param }),
  saveSoldTicket: (data) => http.post('event/save-sold-ticket', data),
  getBookings: (param) => http.get('event/get-bookings', { params: param }),
  getBookingsXls: (param) => http.get('event/get-bookings', { params: param, responseType: 'blob' }),
  confirmBooking: (data) => http.post('event/confirm-booking', data),
  confirmGroupDonor: (data) => http.post('event/confirm-group-donor', data),
  deleteBooking: (data) => http.post('event/delete-booking', data),
  checkInBooking: (data) => http.post('event/check-in-booking', data),
  checkOutBooking: (data) => http.post('event/check-out-booking', data),
  getTicketsWithDetails: (param) => http.get('event/get-tickets-with-details', { params: param }),
  viewTicketDetails: (param) => http.get('event/view-ticket-details', { params: param }),
  followUpBuyer: (data) => http.post('event/follow-up-buyer', data),
  followUpGroupDonor: (data) => http.post('event/follow-up-group-donor', data),
  getGroups: (param) => http.get('event/get-groups', { params: param }),
  getGroupDetails: (param) => http.get('event/get-group-details', { params: param }),
  saveGroup: (data) => http.post('event/save-group', data),
  getGroupList: (param) => http.get('event/get-groups', { params: param }),
  getGroupPaymentList: (param) => http.get('event/get-group-payments', { params: param }),
  getReligiousPayments: (param) => http.get('event/get-religious-payments', { params: param }),
  exportReligiousPayments: (param) => http.get('event/get-religious-payments', { params: param, responseType: 'blob' }),
  saveGroupPayment:(data) => http.post('event/save-group-payment', data),
  deleteGroupPayment:(data) => http.post('event/delete-group-payment', data),
  deleteGroup:(data) => http.post('event/delete-group', data),
  getGroupPaymentDetails: (param) => http.get('event/get-group-payment-details', { params: param }),
  getLeadershipBoard: (param) => http.get('event/get-leadership-board', { params: param }),
  deleteSupporter: (data) => http.post('event/delete-supporter', data),
  sendPaymentReminder: (data) => http.post('event/send-payment-reminder', data),
  exportMembers: (data) => http.post('event/export-members', data, { responseType: 'blob' }),
  getPaypalToken: (data) => http.post('event/get-paypal-token', data),
  validateOtp: (data) => http.post('event/verify-otp', data),
  checkPaymentApp: (data) => http.post('event/check-payment-app', data),
  isPaypalLinked: (data) => http.post('event/is-paypal-linked', data),
  isEdgePayLinked: (data) => http.post('event/is-edgepay-linked', data),
  linkMember: (data) => http.post('event/link-member', data),
  getAllEventMembers: (param) => http.get('event/get-all-event-members', { params: param }),
  sendEmailPaymentReminder: (data) => http.post('event/send-mail-payment-reminder', data),
  allotPhysicalTickets: (data) => http.post('event/allot-physical-tickets', data),
  deleteAlloment: (data) => http.post('event/delete-allotment', data),
  getAllotments: (param) => http.get('event/get-allotments', { params: param }),
  getVendors: (param) => http.get('event/get-vendors', { params: param }),
  checkEventVendor: (param) => http.get('event/check-event-vendor', { params: param }),
  getTicketAnalytics: (param) => http.get('event/get-ticket-analytics', { params: param }),
  getTicketsToPrint: (data) => http.post('event/get-tickets-to-print', data),
  exportTicketAnalytics: (param) => http.get('event/get-ticket-analytics', { params: param, responseType: 'blob' }),
  getEdgepayConfigs: (param) => http.get('event/get-edgepay-configs', { params: param }),
  saveEdgepayConfigs: (data) => http.post('event/save-edgepay-configs', data),
  getTicketAnalyticDetails: (param) => http.get('event/get-ticket-analytic-details', { params: param }),
}

export default events