import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Container, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { AiOutlineEllipsis } from "react-icons/ai"
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

class FeaturedEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventList: [],
      loader: true,
      featuredSlider: {
        infinite: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 768,    // For mobile view (max-width: 768px)
            settings: {
              padding: 0,
              slidesToShow: 1,  // Show only 1 slide on mobile
              slidesToScroll: 1,
            },
          },
        ],
      }
    };
  }

  componentDidMount = () => {
    events
      .featuredEvent({ pageSize: 5, user_id: this.props.userId ? this.props.userId : 0 })
      .then((res) => {
        if (res.data.success) {
          this.setState({ loader: false, eventList: res.data.events });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Unexpected Error!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  render() {
    if (this.state.loader) {
      return <Spinner />
    }
    else if (this.state.eventList.length > 0) {
      return (
        <div className="feature-events">
          <Container>
            <Row>
              <Col md={12}>
                <h1 className="mb-4">
                  <b>Supporting your raising money endeavours from begin to wrap up!</b>
                </h1>
                <h5>{this.props.title ? this.props.title : 'Recent Events'}</h5>
              </Col>
            </Row>
            {this.state.loader ? <Spinner /> : <Slider className="feature-slider" {...this.state.featuredSlider} data-aos="zoom-in">
              {this.state.eventList.map((ele, index) => <Link to={`/${ele.url}/${ele.id}`} target="_blank" key={`featured-event-${index}`}>
                <div className="blogs-box" key={`key-featured-${index}`}>
                  <div className="blogs-box-bg">
                    <img className="img-fluid" src={`${this.props.apiUrl}${ele.image !== null ? `/web/events/${ele.image}` : `/web/images/no-image.jpeg`}`} alt={ele.title} />
                    <span className="ads-tag">{ele.categoryDetails && `#${ele.categoryDetails.name}`}</span>
                    <div className="blog-box-content">
                      <a>
                        <AiOutlineEllipsis />
                      </a>
                    </div>
                  </div>
                  <div className="content">
                    <h3 className="mb-3"><b>{ele.title}</b></h3>
                    <h5 className="mb-4" dangerouslySetInnerHTML={{
                      __html: ele.description.substring(0, 1500),
                    }}></h5>
                    <Link to={`/${ele.url}/${ele.id}`} target="_blank">Donate Now</Link>
                  </div>
                </div></Link>
              )}
            </Slider>}
          </Container>
        </div>
      );
    }

  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
  };
};
export default connect(mapStateToProps)(FeaturedEvents);
