/* eslint-disable react/prop-types */
import { Button, Table, Row, Col, Card } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { connect } from "react-redux";
import common from "../../../services/common";
import AddEditPlan from "./AddEditPlan";
import DesignMembershipDueForm from "./DesignMembershipDueForm";

class ManageMembershipPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      pageSize: 20,
      loader: false,
      records: [],
      showModal: false,
      showDesignMembershipDueFormModal: false,
      planId: null
    };
  }
  componentDidMount = () => {
    this.listRecords();
  }
  listRecords = () => {
    this.setState({ loader: true });
    events
      .getPlans({
        page: this.state.pageNo,
        event_id: this.props.eventId
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ records: res.data.plans, pageDetails: res.data.pages });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  deleteRecord = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      if (this.state.records[index].event_id === undefined) {
        let records = this.state.records.filter((r, indx) => index !== indx);
        this.setState({ records });
      }
      else {
        let params = { id: this.state.records[index].id }
        events.deletePlan(params).then((response) => {
          if (response.data.success) {
            let records = this.state.records.filter((r) => parseInt(r.id) !== parseInt(this.state.records[index].id))
            this.setState({ records }, () => {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            })
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        }).catch((err) => {
          console.log(err)
          toast.error('Unexpected error !', {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
      }
    }
  }
  updateRecords = (records) => {
    let newRecords = [...this.state.records, records];
    this.setState({ records: newRecords, showModal: false }, ()=>{
      let plansWithoutEventId = newRecords.filter(nr=>nr.event_id === undefined);
      this.props.updatePlans(plansWithoutEventId);
    });
  }
  updateFormData = (formData) => {
    this.setState({showDesignMembershipDueFormModal:false}, ()=>{
      this.props.updateFormData(formData);
    })
  }
  render() {
    return (
      <>
        <Card style={{ border: '1px solid #c8ced3' }} className="mb-2">
          <Card.Header style={{ backgroundColor: '#f0f3f5', borderBottom: '1px solid #c8ced3' }}>
            <Row>
              <Col><h4>Manage Plans</h4></Col>
              <Col className="text-end">
                <Button type="button" className="btn-sm me-2" color="primary" style={{ backgroundColor: 'black', width: 'auto' }} onClick={() => this.setState({ showDesignMembershipDueFormModal: true })}>Design Form</Button>
                <Button type="button" className="btn-sm" color="success" style={{ backgroundColor: 'green', width: 'auto' }} onClick={() => this.setState({ showModal: true })}>Add New Plan</Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-2">
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading..."
            >

              <Table className="table table-responsive customise_table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Duration</th>
                    <th>Price</th>
                    <th colSpan={2}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.records.length > 0 ? (
                    this.state.records.map((ele, index) => (
                      <tr key={`member--${index}`}>
                        <td>{this.state.pageNo === 1
                          ? index + 1
                          : (this.state.pageNo - 1) * this.state.pageSize + index + 1}
                          . </td>
                        <td>{ele.name}</td>
                        <td>{ele.duration} Days</td>
                        <td>${ele.price}</td>
                        <td><Button type="button" className="btn-sm" color="primary" style={{ backgroundColor: 'blue', width: 'auto' }} onClick={() => this.setState({ planId: ele.id, showModal: true })}>Edit</Button></td>
                        <td><Button type="button" className="btn-sm" color="danger" style={{ backgroundColor: 'red', width: 'auto' }} onClick={() => this.deleteRecord(index)}>Delete</Button></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="15" className="text-center" style={{ height: 100 }}>Record not found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </LoadingOverlay>
            {this.state.showModal && (
              <AddEditPlan
                showModal={this.state.showModal}
                eventId={this.props.eventId}
                closeModal={() => this.setState({ showModal: false, planId: null })}
                listRecords={this.listRecords}
                planId={this.state.planId}
                updateRecords={this.updateRecords}
              />
            )}
            {this.state.showDesignMembershipDueFormModal && (
              <DesignMembershipDueForm
                showModal={this.state.showDesignMembershipDueFormModal}
                eventId={this.props.eventId}
                closeModal={() => this.setState({ showDesignMembershipDueFormModal: false })}
                updateFormData={this.updateFormData}
                formData={this.props.formData.length > 0 ? this.props.formData: [
                  {  
                    element: "TextInput",
                    field_name: "app_username",
                    id: "app_username",
                    label: "Your Payment Username",
                    required: true,
                    text: "Text Input"
                  },
                  {  
                    element: "TextInput",
                    field_name: "birth_date",
                    id: "birth_date",
                    label: "BirthDate(MM/DD/YYYY)",
                    required: true,
                    text: "Text Input"
                  },
                  {  
                    element: "Dropdown",
                    field_name: "state",
                    id: "state",
                    label: "State",
                    required: true,
                    text: "Dropdown",
                    options: [
                      { text: 'Alaska', value: 'Alaska' },
                      { text: 'Alabama', value: 'Alabama' },
                      { text: 'Arizona', value: 'Arizona' },
                      { text: 'Arkansas', value: 'Arkansas' },
                      { text: 'California', value: 'California'},
                      { text: 'Colorado', value: 'Colorado' },
                      { text: 'Connecticut', value: 'Connecticut'},
                      { text: 'Delaware', value: 'Delaware'},
                      { text: 'Florida', value: 'Florida'},
                      { text: 'Gregoria', value: 'Gregoria' },
                      { text: 'Hawaii', value: 'Hawaii'},
                      { text: 'Idaho', value: 'Idaho'},
                      { text: 'Illinois', value: 'Illinois'},
                      { text: 'Indiana', value: 'Indiana'},
                      { text: 'Iowa', value: 'Iowa' },
                      { text: 'Kansas', value: 'Kansas'},
                      { text: 'Kentucky', value: 'Kentucky'},
                      { text: 'Maine', value: 'Maine' },
                      { text: 'Maryland', value: 'Maryland' },
                      { text: 'Massachusetts', value: 'Massachusetts'},
                      { text: 'Michigan', value: 'Michigan'},
                      { text: 'Minnesota', value: 'Minnesota'},
                      { text: 'Utah', value: 'Utah'},
                      { text: 'Oregon', value: 'Oregon'},
                      { text: 'Ohio', value: 'Ohio' },
                      { text: 'New York', value: 'New York'},
                      { text: 'Nevada', value: 'Nevada' },
                      { text: 'Washington', value: 'Washington'},
                      { text: 'Virginia', value: 'Virginia' },
                      { text: 'Texas', value: 'Texas' },
                      { text: 'West Virginia', value: 'West Virginia'},
                      { text: 'Vermont', value: 'Vermont' },
                      { text: 'Wyoming', value: 'Wyoming' },
                      { text: 'South Dakota', value: 'South Dakota'},
                      { text: 'Oklahoma', value: 'Oklahoma' },
                      { text: 'New Jersey', value: 'New Jersey'},
                      { text: 'New Mexico', value: 'New Mexico'},
                      { text: 'New Hemisphere', value: 'New Hemisphere'},
                      { text: 'Mississippi', value: 'Mississippi'},
                      { text: 'Montana', value: 'Montana'}
                    ]
                  },
              ]}
              />
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ManageMembershipPlans);
