import http from "./http";

const user = {
  add: data => http.post("/user/add", data),
  updateDescription: data => http.post("/user/update-description", data),
  //getOne: param => http.get("/user/get", { params: param }),
  getOne: (param, userId = null) => http.get(userId !== null ? '/user/get-with-logged-in' : '/user/get', { params: param }),
  list: param => http.get("/user/list", { params: param }),
  delete: data => http.post("/user/delete", data),
  deleteDocument: data => http.post("/user/delete-document", data),
  uploadQrCode: data => http.post("/common/upload-qr-code", data, {headers: {'Content-Type': 'multipart/form-data'}}),
  deleteQrCode: data => http.post("/user/delete-qr-code", data),
  addSocialLink: data => http.post("/user/add-social-link", data),
  deleteLink: data => http.post("/user/delete-link", data),
  logout: data => http.post("/user/logout", data),
  deleteAccount: data => http.post("/user/delete-account", data),
  getLoggedInUser: param => http.get("/user/get-logged-in-user", { params: param }),
  uploadProfileImage: data => http.post("/user/upload-profile-image", data, {headers: {'Content-Type': 'multipart/form-data'}}),
  updateProfile: data => http.post("/user/update-profile", data),
  changePassword: data => http.post("/user/change-password", data),
  checkSocialUser: param => http.get("/user/check-social-user", { params: param }),
  resetPassword: data => http.post("/user/reset-password", data),
  payments: param => http.get("/user/payments", { params: param }),
  getEmails: param => http.get("/user/get-emails", { params: param }),
  linkEmail: data => http.post("/user/link-email", data),
  confirmCode: data => http.post("/user/confirm-code", data),
  trackApp: (param) => http.get('/user/track-app', { params: param }),
  getReports: (data) => http.post('user/reports', data),
  deleteEmail: data => http.post("/user/delete-email", data),
  checkUserExistence:(data) => http.post('user/check-user-existence', data),
  getTeams:(param) => http.get('user/get-team', { params: param }),
  addTeam:(data) => http.post('user/add-team', data),
  getTraffic:(param) => http.get('user/get-traffic',{ params: param }),
  featuredProfile:(param) => http.get('user/get-featured', { params: param }),
  exportPayments:(data) => http.post('user/export-payments', data,{responseType:'blob'}),
  deletePayment: (data) => http.post('user/delete-payment', data),
  exportDonors:(data) => http.post('user/export-donors', data,{responseType:'blob'}),
  getBeneficiaries:(param) => http.get('user/beneficiaries',{ params: param }),
  saveBeneficial:(data) => http.post('settings/save-beneficial', data),
  checkFundingSource:(data) => http.post('settings/check-funding-source', data),
  saveFundingSourceUrl:(data) => http.post('user/save-funding-source-url', data),
  getBeneficial:(param) => http.get('user/get-beneficial', { params: param }),
  getDwollaAccount:(param) => http.get('user/get-dwolla-account', { params: param }),
  connectStripe:(data) => http.post('user/connect-stripe', data),
  checkStripeConnectRequest:(param) => http.get('user/check-stripe-connect-request', { params: param }),
  checkStripeConnectStatus:(param) => http.get('user/check-stripe-connect-status', { params: param }),
  removeStripeAccount:(data) => http.post('user/remove-stripe-account', data),
  getMembership:(param) => http.get('user/get-membership', { params: param }),
  getUserByAccessToken:(param) => http.get('user/get-user-by-access-token', { params: param }),
};


export default user;