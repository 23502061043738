/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Container
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import moment from "moment";
import blogs from "../../../services/blogs";
import NotFound from "../Common/NotFound";
import EventDetailsLoader from "../Loaders/EventDetailsLoader";
import { useParams } from "react-router-dom";

import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,

    FacebookIcon, LinkedinIcon, PinterestIcon, RedditIcon, TelegramIcon, TumblrIcon, TwitterIcon, WhatsappIcon
  } from "react-share";

const BlogDetails = (props) => {
  const queryString = useParams();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [record, setRecord] = useState({});
  const userId = useSelector((state) => state.userId);
  const apiUrl = useSelector((state) => state.apiUrl);
  const shareIconSize  = 50;
  const shareUrl  = document.URL;
  //'https://tiphub.co/greater-sheraro-idp-humanitarian-fundraiser/510';

  const getRecord = () => {
    //alert(props.match.params.id);
    const blogId = queryString.id;
    let params = { id: blogId };
    blogs
      .getDetail(params, userId)
      .then((res) => {
        if (res.data.success) {
          setRecord(res.data.record);
          setLoading(false);
        } else if (res.data.error) {
          setNotFound(true);
          setLoading(false);
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getRecord();
  }, []);

  const shareBlog = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: record.title,
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    }
  };

  return (
    <div className="event-details">
      <Container>
        {loading ? (
          <EventDetailsLoader />
        ) : notFound ? (
          <NotFound />
        ) : (
          <>
            <Helmet>
              <title>{record.title}</title>
              <meta property="og:title" content={record.title} />
              {record.image !== null && (
                <meta
                  property="og:image"
                  itemprop="image"
                  content={`${apiUrl}/web/blogs/${record.id}_${record.image}`}
                />
              )}
              <meta
                property="og:description"
                name="description"
                content={record.description}
              />
            </Helmet>
            <Row>
              <Col md={8}>
                <h3><b>{record.title}</b></h3>
                <p><strong>Posted On:</strong> {moment(
                      record.added_on
                    ).format(
                      "MMM DD, YYYY"
                    )}</p>
                {record.image !== null && (
                  <p className="text-center">{record.image !== null && <img
                    className="img-fluid" src={`${apiUrl}/web/blogs/${record.id}_${record.image}`}/>}</p>
                )}
                <hr />
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: record.description,
                    }}
                  />
                </div>
                </Col>
                <Col md={4}>
                  <Card>
                    <Card.Header><h3 className="mb-0">Share</h3></Card.Header>
                    <Card.Body>
                        <FacebookShareButton url={shareUrl} className="me-2">
                            <FacebookIcon size={shareIconSize} round={true}/>
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} className="me-2">
                            <TwitterIcon size={shareIconSize} round={true}/>
                        </TwitterShareButton>
                        <LinkedinShareButton url={shareUrl} className="me-2">
                            <LinkedinIcon size={shareIconSize} round={true}/>
                        </LinkedinShareButton>
                        <WhatsappShareButton url={shareUrl} className="me-2">
                            <WhatsappIcon size={shareIconSize} round={true}/>
                        </WhatsappShareButton>
                        <PinterestShareButton url={shareUrl} className="me-2">
                            <PinterestIcon size={shareIconSize} round={true}/>
                        </PinterestShareButton>
                        <RedditShareButton url={shareUrl} className="me-2">
                            <RedditIcon size={shareIconSize} round={true}/>
                        </RedditShareButton>
                        <TelegramShareButton url={shareUrl} className="me-2">
                            <TelegramIcon size={shareIconSize} round={true}/>
                        </TelegramShareButton>
                        <TumblrShareButton url={shareUrl} className="me-2 mt-2">
                            <TumblrIcon size={shareIconSize} round={true}/>
                        </TumblrShareButton>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default BlogDetails;
