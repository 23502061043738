import React, { useState, useEffect } from "react";
import { Button, Row, Col, Spinner, Form, Modal, FloatingLabel } from "react-bootstrap";
import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import TwitterLogin from "react-twitter-auth";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowLeft } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";

const facebookId = "809703702992782";
const googleClientId = "373805934341-lvhcmiccakdb9r6giag6i2f7dd90udn0.apps.googleusercontent.com";

const Login = (props) => {
  const apiUrl = useSelector((state) => state.apiUrl);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({});
  const [loader, setLoader] = useState(false);


  const [formValidated, setFormValidated] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity() === true) {
      setLoader(true);
      let reqData = {
        username: fields["username"],
        password: fields["password"],
      };
      axios
        .post(apiUrl + "/user/login", reqData)
        .then(function (response) {
          if (response.data.success) {
            props.setUserData(response.data);
            setLoader(false);
            props.closeModal();
          } else if (response.data.error) {
            setFields({ ...fields, ['password']: '' })
            setErrors({ password: response.data.error });
            setLoader(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setFormValidated(true);
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const responseTwitter = (response) => {
    setLoader(true);
    if (response.ok) {
      response.json().then((result) => {
        let image = "";
        if (result.profile_image_url) {
          image = result.profile_image_url;
        }
        let params = {
          fields: {
            name: result.name,
            email: result.email,
            image: image,
            twitter_id: result.id,
            twitter_username: result.screen_name,
            username: result.screen_name,
            description: result.description,
          },
        };

        axios
          .post(apiUrl + "/user/social-login", params)
          .then(function (response) {
            if (response.data.success) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("userType", response.data.user_type);
              localStorage.setItem("userName", response.data.twitter_username);
              localStorage.setItem("userName", response.data.username);
              localStorage.setItem("uName", response.data.name);
              localStorage.setItem("userId", response.data.user_id);
              localStorage.setItem("userImage", response.data.image);
              localStorage.setItem("userEmail", response.data.email);
              localStorage.setItem(
                "userDescription",
                response.data.description
              );
              let payload = {
                token: response.data.token,
                authenticated: true,
                userName: response.data.username,
                uName: response.data.name,
                userId: response.data.user_id,
                userType: response.data.user_type,
                userImage: response.data.image,
                userDescription: response.data.description,
                userEmail: response.data.email,
              };
              dispatch({ type: "login", ...payload });
              setLoader(false);
            } else if (response.data.error) {
              if (response.data.message.username) {
                props.closeModal();
                props.showUpdateUsernameModal(true);
                setErrors(response.data.message);
                setFields(response.data.userDetails);
                setLoader(false);
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    } else {
      setLoader(false);
      toast.error(response, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const responseGoogle = (response) => {
    setLoader(true);
    if (!response.error) {
      let params = {
        fields: {
          name: response.profileObj.name,
          google_username: `G${response.profileObj.googleId}`,
          username: `G${response.profileObj.googleId}`,
          email: response.profileObj.email,
          image: response.profileObj.imageUrl,
          google_id: response.profileObj.googleId,
        },
      };
      axios
        .post(apiUrl + "/user/social-login", params)
        .then(function (response) {
          if (response.data.success) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userType", response.data.user_type);
            localStorage.setItem("userName", response.data.username);
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem("userImage", response.data.image);
            localStorage.setItem("userEmail", response.data.email);
            localStorage.setItem("uName", response.data.name);

            let payload = {
              token: response.data.token,
              userName: response.data.username,
              uName: response.data.name,
              userId: response.data.user_id,
              userType: response.data.user_type,
              userImage: response.data.image,
              userEmail: response.data.email,
            };
            dispatch({ type: "login", ...payload });
            setLoader(false);
            props.closeModal();
          } else if (response.data.error) {
            if (response.data.message.username) {
              props.closeModal();
              props.showUpdateUsernameModal(true);
              setErrors(response.data.message);
              setFields(response.data.userDetails);
              setLoader(false);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
        });
    } else {
      setLoader(false);
      toast.error("There is some problem with the authorization.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const responseFacebook = (response) => {
    if (
      !response.status &&
      !response.error &&
      (response.userId !== undefined || response.userId !== null)
    ) {
      setLoader(true);
      let image = "";
      if (response.picture.data.url) {
        image = response.picture.data.url;
      }
      let params = {
        fields: {
          name: response.name,
          facebook_username: `F${response.userID}`,
          username: `F${response.userID}`,
          email: response.email,
          image: image,
          facebook_id: response.userID,
        },
      };

      axios
        .post(apiUrl + "/user/social-login", params)
        .then(function (response) {
          if (response.data.success) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userType", response.data.user_type);
            localStorage.setItem("userName", response.data.username);
            localStorage.setItem("uName", response.data.name);
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem("userImage", response.data.image);
            localStorage.setItem("userEmail", response.data.email);

            let payload = {
              token: response.data.token,
              userName: response.data.username,
              uName: response.data.name,
              userId: response.data.user_id,
              userType: response.data.user_type,
              userImage: response.data.image,
              userEmail: response.data.email,
            };
            dispatch({ type: "login", ...payload });
            setLoader(false);
          } else if (response.data.error) {
            if (response.data.message.username) {
              props.closeModal();
              props.showUpdateUsernameModal(true);
              setErrors(response.data.message);
              setFields(response.data.userDetails);
              setLoader(false);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.closeModal}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="frontend_modal"
    >

      <div className="modal-background">
        <Row>
          <Col md={6}></Col>
          <Col md={6}>
            <div className="modal-align">
              <div className="modal-align-content">
                <h3 className="mb-3">HELLO</h3>
                <p className="mb-5">Enter your personal details and start journey with us</p>
                <Button
                  variant="link" onClick={() => { props.closeModal(); props.showSignupModal(true) }}>
                  <BsArrowLeft /> Sign up
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Form noValidate method="post" onSubmit={handleLogin} validated={formValidated} className="shadow login">
          <Modal.Header closeButton>
            <h4>SIGN IN</h4>
          </Modal.Header>
          <FloatingLabel
            controlId="floatingInput"
            label="Email / Username"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="username"
              value={fields["username"] ? fields["username"] : ""}
              onChange={(event) => handleChange(event, "username")}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Password"
            className="mb-3"
          >
            <Form.Control
              type="password"
              name="password"
              value={fields["password"] ? fields["password"] : ""}
              onChange={(event) => handleChange(event, "password")}
              required
            />
            <Form.Control.Feedback type="invalid">{errors["password"]}</Form.Control.Feedback>
          </FloatingLabel>
          <Form.Group className="mb-5">
            <Form.Check
              label="Remember me"
            />
          </Form.Group>

          <Col sm={12}>
            <Button
              color="primary"
              type="submit"
              disabled={loader}
            >
              {loader && <Spinner size="sm" className="mt-1" variant="light" />} SIGN IN
            </Button>
          </Col>
          <Col sm={12} className='text-center'>
            <a href="/forgot-password" className="mt-2 forgot">
              Forgot Password?
            </a>
            <span className="credential_router">
              Don't have an account?
              <Button
                variant="link" onClick={() => { props.closeModal(); props.showSignupModal(true) }}>
                 Sign up
              </Button>
            </span>
          </Col>

          <div className="login-bottom">
            <span className="seperator">Or</span>
            <p className="mt-3 mb-3">Login with your social media account</p>
            <div className="login_through_social_media">
              <GoogleLogin
                clientId={googleClientId}
                onSuccess={responseGoogle}
                // onFailure={responseGoogle} /*commented to remove toast after npm start*/
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="social-media-button"
                  >
                    <FcGoogle />
                  </Button>
                )}
              />

              <FacebookLogin
                appId={facebookId}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                isMobile={false}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="social-media-button"
                  >
                    <FaFacebookF className="facebook" />
                  </Button>
                )}
              />
              <TwitterLogin
                name="cib-twitter"
                loginUrl={apiUrl + "/twitter/login"}
                onFailure={responseTwitter}
                onSuccess={responseTwitter}
                className="btn twitter"
                showIcon={true}
                text={``}
                requestTokenUrl={apiUrl + "/twitter/request-token"}
              />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default Login;
