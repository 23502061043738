import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Spinner,
  Form,
  Badge, InputGroup
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import user from "../../../services/user";
import { FaTrash } from "react-icons/fa";

const LinkEmails = (props) => {
  const [userEmail] = useSelector(
    (state) => {
      return [
        state.userEmail,
      ];
    }
  );

  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({});

  const [submitted, setSubmitted] = useState(false);
  const [emails, setEmails] = useState([]);

  const [emailForm, setEmailForm] = useState(true);
  const [codeForm, setCodeForm] = useState(false);

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
  };
  useEffect(() => {
    getEmails();
  }, []);

  const getEmails = () => {
    user.getEmails().then((response) => {
      if (response.data.success) {
        setEmails(response.data.emails);
      } else if (response.data.error) {
        setErrors(response.data.message);
      }
    });
  };
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (validateEmailForm()) {
      setSubmitted(true);
      user.linkEmail({ fields: fields }).then((response) => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEmailForm(false);
          setCodeForm(true);
        } else if (response.data.error) {
          setErrors(response.data.message);
        }
        setSubmitted(false);
      });
    }
  };
  const handleCodeSubmit = (e) => {
    e.preventDefault();
    if (validateCodeForm()) {
      setSubmitted(true);
      user.confirmCode({ fields: fields }).then((response) => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEmailForm(true);
          setCodeForm(false);
          getEmails();
        } else if (response.data.error) {
          setErrors(response.data.message);
        }
        setSubmitted(false);
      });
    }
  };
  const validateEmailForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.email) {
      errors["email"] = "Pleaes enter email address!";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };
  const validateCodeForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!fields.verification_code) {
      errors["verification_code"] = "Pleaes enter verification code!";
      formIsValid = false;
    }
    setErrors(errors);
    return formIsValid;
  };
  const deleteEmail = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      user
        .deleteEmail({ id: id })
        .then((res) => {
          if (res.data.success) {
            let arr;
            arr = emails.filter((ele) => ele.id !== id);
            setEmails(arr);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error("Something went wrong !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <>
      <Card className='modified-card'>
        <Card.Header><h4 className="mb-0 p-2">Link Emails</h4></Card.Header>
        <Card.Body style={{ minHeight: 400 }}>
          {emailForm && (
            <Form onSubmit={handleEmailSubmit} method="post">
              <Row className="'d-flex align-items-center justify-content-center">
                <Col md={8}>
                  <InputGroup className="mb-3" hasValidation>
                    <Form.Control
                      name="email"
                      type="text"
                      onChange={(e) => handleChange(e, 'email')}
                      isInvalid={!!errors.email}
                      placeholder="Enter your email address"
                    />
                    <Button type="submit" variant="success" disabled={submitted}>{submitted && <Spinner animation="border" variant="light" className="me-1" size="sm" />} Save</Button>
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          )}
          {codeForm && (
            <Form onSubmit={handleCodeSubmit} method="post">
              <Form.Group row>
                <Col md={8}>
                  <Form.Control
                    type="text"
                    name="verification_code"
                    id="verification_code"
                    onChange={(e) => handleChange(e, "verification_code")}
                    invalid={errors["verification_code"] ? true : false}
                    placeholder="Enter verification code"
                  />
                  <Form.Control.Feedback invalid>
                    {errors["verification_code"]}
                  </Form.Control.Feedback>
                </Col>
                <Col md={4}>
                  <Button color="success" disabled={submitted} type="submit">
                    {submitted && <Spinner size="sm" />} Confirm
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          )}
          <div className="link_emails text-center">
            <Badge color="primary" style={{ fontSize: 13 }}>
              {(userEmail && userEmail) !== 'undefined' ? userEmail : ''}
            </Badge>
            {emails.map((email, index) => (
              <Badge color="primary" key={index} style={{ fontSize: 13 }}>
                {email.email}
                <Button
                  color="danger"
                  className="btn-sm ml-2"
                  type="button"
                  onClick={() => deleteEmail(email.id)}
                >
                  <FaTrash />
                </Button>
              </Badge>
            ))}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default LinkEmails;
