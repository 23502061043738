/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { Link } from "react-router-dom";

const QrCodeModal = (props) => {
  const apiUrl = useSelector((state) => state.apiUrl);
  const baseUrl = useSelector((state) => state.baseUrl);
  const [loader, setLoader] = useState(true);
  const [qrcodeImage, setQrCodeImage] = useState(null);
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    events
      .generateQrCode({ event_id: props.eventId })
      .then((res) => {
        if (res.data.success) {
          setQrCodeImage(res.data.file);
          setEventData(res.data.eventDetails);
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <Modal show={props.showModal}
      onHide={props.closeModal}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
    >
      <ModalHeader closeButton> <h4>QR Code </h4></ModalHeader>
      <ModalBody className="text-center">
        {loader ? (
          <Spinner />
        ) : (
          <div style={{minHeight:350}}>
            <img src={`${apiUrl}/web/qrcodes/${qrcodeImage}`} height={260}/>
            <div className="d-flex justify-content-center my-3 align-items-end">
              {eventData.apps.length > 0 &&
                eventData.apps.map((ele, index) => {
                  if(ele.appname !== null){
                    return  <img
                      key={index}
                      src={`assets/${ele.appname.icon}`}
                      alt={ele.appname.name}
                      className="img-fluid mx-2"
                      style={{ height: "30px", width: "30px" }}
                    />
                  }
                })}
            </div>
            <div>
              <Link
                to={`${baseUrl}/${eventData.url}/${eventData.id}`}
              target="_blank">{`${baseUrl}/${eventData.url}/${eventData.id}`}</Link>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default QrCodeModal;
