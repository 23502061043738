/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, ListGroup, FloatingLabel, Fade, InputGroup, Alert, Form, Spinner } from "react-bootstrap";
import common from "../../../services/common";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CreditCardForm from "./CreditCardForm";
import { toast } from "react-toastify";

let paymentsClient;
let isReadyToPayRequest;
let isReadyToPayRequestResponse;

const EdgePayPaymentForm = (props) => {
    const [loader, setLoader] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [renderCcForm, setRenderCcForm] = useState(false);
    const [totalBankCharges, setTotalBankCharges] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [edgePayConfig, setEdgePayConfig] = useState(null);
    const [errors, setErrors] = useState(false);
    const [showOtherTip, setOtherTip] = useState(false);
    const [totalTip, setCalculatedTip] = useState(0);
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const [configurationError, setConfigurationError] = useState(false);

    const bankChargePercentage = 3;
    const [fields, setFields] = useState({
        amount: props.amount ? props.amount : 0,
        tipPercent: 12,
        phone: props.phone ? props.phone : ''
    });

    useEffect(() => {
        if (edgePayConfig === null) {
            let params;
            if (props.eventDetails) {
                params = { user_id: props.eventDetails.user_id }
            }
            else {
                params = { user_id: props.userDetails.id }
            }
            common.getEdgepayConfig(params).then((res) => {
                if (res.data.success) {
                    setEdgePayConfig(res.data.record);
                    setLoader(false);
                } else if (res.data.error) {
                    setConfigurationError(true);
                    setLoader(false);
                }
            });
        }
    }, [edgePayConfig]);

    useEffect(() => {
        let totalAmount = 0;
        let tip = 0;
        let backCharges = 0;
        if (fields.tipPercent !== 'Other') {
            tip = fields.amount * parseInt(fields.tipPercent) / 100
        }
        else if (fields.tipDoller) {
            tip = parseInt(fields.tipDoller);
        }
        if (fields.amount) {
            backCharges = (parseInt(fields.amount) * (bankChargePercentage / 100));
            totalAmount = parseInt(fields.amount) + parseFloat(tip) + parseFloat(backCharges);
        }
        setCalculatedTip(parseFloat(tip).toFixed(2));
        setTotalBankCharges(backCharges.toFixed(2));
        setTotalAmount(totalAmount.toFixed(2));
    }, [fields]);

    const loadGooglePayButton = async () => {
        let scripts = document.getElementsByTagName("script");
        let scriptFound = false;
        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].getAttribute('src') == 'https://pay.google.com/gp/p/js/pay.js') {
                scriptFound = true;
            }
        }
        if (!scriptFound) {
            const script = document.createElement('script');
            script.src = "https://pay.google.com/gp/p/js/pay.js";
            script.async = true;
            script.onload = async () => {
                /* eslint-disable */
                paymentsClient = new google.payments.api.PaymentsClient({ environment: edgePayConfig.googlePay })
                /* eslint-disable */
                showGooglePayButton();
            };
            document.body.appendChild(script);
        }

    }
    const showGooglePayButton = async () => {
        isReadyToPayRequestResponse = await EdgePay.googlePay.getIsReadyToPayRequest();

        console.log(isReadyToPayRequestResponse);
        isReadyToPayRequest = isReadyToPayRequestResponse.isReadyToPayRequest;
        paymentsClient.isReadyToPay(isReadyToPayRequest)
            .then(function (response) {
                if (response.result) {
                    // ADD A GOOGLE PAY PAYMENT BUTTON
                    const button = paymentsClient.createButton({
                        onClick: () => {
                            props.closeModal();
                            //EXAMPLE HOE DEVELOPER CAN MODIFY THE paymentDataRequest OBJECT
                            let paymentDataRequest = isReadyToPayRequest;
                            paymentDataRequest.transactionInfo = {
                                "totalPriceStatus": "FINAL",
                                "totalPrice": fields.amount,
                                "currencyCode": "USD"
                            }
                            // paymentDataRequest.shippingAddress = {}

                            paymentsClient.loadPaymentData(paymentDataRequest).then(function (paymentData) {
                                EdgePay.googlePay.getToken({
                                    paymentData,
                                    onSuccess: function (response) {
                                        let payload = {
                                            amount: totalAmount,
                                            tokenId: response.successResponse.tokenID,
                                            transactionDate: new Date(),
                                            billingZip: fields.zip_code,
                                            //billingAddress: fields.billing_address,
                                            //currencyCode:common.currencySymbolsCode[props.eventDetails.currency],
                                        };
                                        if (totalTip) {
                                            payload = { ...payload, tip: totalTip };
                                        }
                                        if (eventDetails) {
                                            payload = { ...payload, event_id: eventDetails.id, user_id: eventDetails.user_id };
                                        }
                                        if (props.userDetails) {
                                            payload = { ...payload, user_id: props.userDetails.id };
                                        }
                                        if (props.booking_id) {
                                            payload = { ...payload, booking_id: props.booking_id };
                                        }
                                        if (props.invoiceId && props.invoiceId !== null) {
                                            payload = { ...payload, invoice_id: props.invoiceId };
                                        }
                                        if (props.programId && props.programId !== null) {
                                            payload = { ...payload, program_id: props.programId };
                                        }
                                        if (phone) {
                                            payload = { ...payload, phone: phone };
                                        }
                                        if (fields.email && fields.email !== "") {
                                            payload = { ...payload, email: fields.email };
                                        }
                                        if (fields.display_name && fields.display_name !== '') {
                                            payload = { ...payload, display_name: fields.display_name };
                                        }
                                        common.processEdgepayPayment(payload).then((res) => {
                                            if (res.data.success) {
                                                if (res.data.result.result === 'A') {
                                                    toast.success(res.data.message, {
                                                        position: toast.POSITION.TOP_RIGHT,
                                                    });
                                                }
                                            }
                                        }).catch((err) => {
                                            console.log(err);
                                        });
                                    },
                                    onError: function (response) {
                                        console.log("error", response);
                                    },
                                })
                            }).catch(function (err) {
                                console.error(err);
                            });
                        }
                    });
                    document.getElementById('google-pay').appendChild(button);
                    let checkGooglePayButton = setInterval(() => {
                        if (document.getElementById('google-pay').children.length === 1) {
                            loadGooglePayButton();
                        }
                        else {
                            clearInterval(checkGooglePayButton);
                            document.getElementById('google-pay-loading-text').remove();
                        }
                    }, 2000)

                }
            })
            .catch(function (err) {
                console.error(err);
            });
    }

    const loadPaymentMode = () => {
        let errors = {};
        if (parseInt(totalAmount) === 0) {
            errors['amount'] = 'Please enter the amount';
            setErrors(errors);
            return false;
        }
        if (props.capturePhone === true) {
            if (!fields.phone) {
                errors['phone'] = 'Please enter phone.';
                setErrors(errors);
                return false;
            }
            if (fields.phone && !fields.phone.match(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
                errors['phone'] = 'Please enter valid phone number.';
                setErrors(errors);
                return false;
            }
        }
        setButtonLoader(true);
        common.getReferenceId({ user_id: props.eventDetails.user_id }).then((res) => {
            if (res.data.success) {
                if (parseInt(props.selectedPaymentOption) === 23) {
                    common.appendEdgePayLibrary(res.data.result.authKey);
                    //Load Google Pay
                    loadGooglePayButton();
                }
                setButtonLoader(false);
                setRenderCcForm(true);
            }
            else {
                setButtonLoader(false);
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }).catch((err) => {
            setButtonLoader(false);
            console.log(err);
        });
    }

    const handleChange = (e, field) => {
        if (e.target.type === 'select-one') {
            if (e.target.value === 'Other') {
                setOtherTip(true);
            }
            else {
                setOtherTip(false);
            }
        }
        setFields({ ...fields, [field]: e.target.value });
    };
    return (
        <Modal
            show={props.showModal}
            onHide={props.closeModal}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton><h4>Payment Details</h4></Modal.Header>
            <Modal.Body className="p-0">
                {loader ? <Row>
                    <Col className='d-flex align-items-center justify-content-center' style={{ height: 400 }}><Spinner style={{ height: 50, width: 50 }} /></Col>
                </Row> : <Row>
                    {renderCcForm ? <Col md={12} className="my-auto text-center" style={{ minHeight: 250 }}>
                        <div className="p-4">
                            {parseInt(props.selectedPaymentOption) === 23 ? <div id="google-pay"><span id="google-pay-loading-text">Loading...</span></div> : <CreditCardForm
                                eventDetails={props.eventDetails}
                                amount={totalAmount}
                                totalTip={totalTip}
                                closeModal={props.closeModal}
                                display_name={fields.display_name ? fields.display_name : ""}
                                phone={fields.phone ? fields.phone : ""}
                            />}
                        </div>
                    </Col> : configurationError ? <Col md={12}><Alert variant="danger" className="mb-0">It seems Event Organizer has not linked his EdgePay account. Kindly contact administrator.</Alert></Col> : <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <ListGroup>
                                    {props.eventDetails && <ListGroup.Item>
                                        <div className="float-start">
                                            <h6 className="my-0"><strong>Event</strong></h6>
                                            <small className="text-muted">{props.eventDetails.title}</small>
                                        </div>
                                    </ListGroup.Item>}

                                    <ListGroup.Item>
                                        {props.editAmount ?
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Enter amount"
                                                className="mb-2"
                                            >
                                                <Form.Control
                                                    type="number"
                                                    name="amount"
                                                    placeholder="Enter amount"
                                                    onChange={(event) => handleChange(event, "amount")}
                                                    isInvalid={errors.amount ? true : false}
                                                    value={fields.amount > 0 && fields.amount}
                                                    autoComplete={false}
                                                />
                                                <Form.Control.Feedback type="invalid">Please enter the amount</Form.Control.Feedback>
                                            </FloatingLabel> : <div className="w-100 float-left mb-2">
                                                <div className="float-start">
                                                    <h6 className="my-0"><strong>Amount</strong></h6>
                                                </div>
                                                <span className="text-muted float-end"><strong>{common.currencySymbolsArr[props.eventDetails.currency]}{fields.amount}</strong></span>
                                            </div>}
                                        {props.capturePhone && <div className="mt-4">
                                            <PhoneInput
                                                country={'us'}
                                                value={fields.phone ? fields.phone : ""}
                                                onChange={(phone) => {
                                                    fields['phone'] = '+' + phone;
                                                    setFields({ ...fields });
                                                }}
                                                inputClass="w-100"
                                                enableSearch={true}
                                                countryCodeEditable={false}
                                                className="mb-2"
                                                autoComplete={false}
                                            />
                                            {errors.phone && <p className="text-danger">{errors.phone}</p>}
                                        </div>}
                                        <FloatingLabel
                                            label="Display Name"
                                            className="mb-2"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="display_name"
                                                placeholder="Enter amount"
                                                onChange={(event) => handleChange(event, "display_name")}
                                                isInvalid={!!errors.display_name}
                                                autoComplete={false}
                                            />
                                            <small><strong>Display Name: </strong> If you would like to credit a different name for your giving</small>
                                        </FloatingLabel>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="float-start">
                                            <h6 className="my-0"><strong>Bank Charges</strong></h6>
                                            <small className="text-muted">{bankChargePercentage}% of donation amount</small>
                                        </div>
                                        <span className="text-muted float-end"><strong>{props.eventDetails ? common.currencySymbolsArr[props.eventDetails.currency] : '$'}{totalBankCharges}</strong></span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="bg-warning text-dark">
                                        <Row>
                                            <Col md={12}>
                                                <h5><strong>Add a tip to support TipHub</strong></h5>
                                                <p>TipHub does not charge any platform fees and relies on your generosity to support this free service.</p>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Select onChange={(event) => handleChange(event, "tipPercent")} value={fields.tipPercent}>
                                                    <option value="12">12%</option>
                                                    <option value="15">15%</option>
                                                    <option value="7">7%</option>
                                                    <option value="Other">Other</option>
                                                </Form.Select>
                                            </Col>
                                            <Col md={6}>
                                                <Fade in={showOtherTip} tag="div">
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" name="tipDoller" onChange={(event) => handleChange(event, 'tipDoller')} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} min={0} />
                                                    </InputGroup>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="float-start">
                                            <h5 className="my-0"><strong style={{ fontSize: 30 }}>Total</strong></h5>
                                        </div>
                                        <strong className="float-end" style={{ fontSize: 30, lineHeight: 'normal' }}>{props.eventDetails ? common.currencySymbolsArr[props.eventDetails.currency] : '$'}{totalAmount}</strong>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>

                        </Row>
                    </Col>}
                </Row>}
            </Modal.Body>
            {!renderCcForm && !configurationError && !loader && <Modal.Footer><div className="w-100 d-grid">
                <Button type="button" onClick={() => loadPaymentMode()} variant="success" size="lg" disabled={buttonLoader}>
                    {buttonLoader && <Spinner variant="light" size="sm" className="me-1" />}
                    Click Here To Proceed
                </Button>
            </div></Modal.Footer>}
        </Modal>
    );
};

export default EdgePayPaymentForm;
