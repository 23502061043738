import React, { Suspense } from "react";
import "./App.scss";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import frontRoutes from "./routes/front";
import '../node_modules/aos/dist/aos.css'
import Home from "./views/Front/Home";
import Logout from "./Logout";
import FrontLayout from "../src/layouts/FrontLayout";

function App(props) {
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.userId);

  const checkAuth = (route) => {
    if (route.authentication) {
      if (token === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const signOut = (e) => {
    e.preventDefault()
    props.history.push('/login')
  }

  const checkLogin = (route) => {
    if(route.authentication){
      if(props.userId === undefined){
        return false;
      }
      else{        
        return true;
      }
    }
    else{
      return true;
    }
  }

  return (
    <BrowserRouter>
     
      <Suspense
        fallback={<div className="d-flex align-items-center justify-content-center" style={{height:600}}><div>Loading...</div></div>}
      >
        <Routes>
          <Route path="/" element={<FrontLayout />}>
            <Route index element={<Home />} />
            <Route path="/logout" element={<Logout />} />
            {frontRoutes.map((route, index) => {
              console.log(route.path)
              return <Route
              key={index}
              path={route.path}
              element={
                checkAuth(route) ? <route.component /> : <Navigate to="/home" />
              }
            />
            })}
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
