/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, ModalHeader, ModalFooter, ModalBody
  } from "react-bootstrap";
  import { connect } from "react-redux";
  import { toast } from "react-toastify";
import SaveDonorDetailsFamily from "./SaveDonorDetailsFamily";
import Signup from "../Home/Signup";

class DonorFormButton extends Component {
  constructor(props) {
    super(props);
        this.state = {
            disableDonorForm:false,
            familyDonnerModal:false,
            signupModal:false,
            fields:{},
            beneficiaries:[]
        };
    }
    registerDonor = (donor, beneficiaries) => {
      var emailArr = donor.donor_email.split('@');
      let fields = {name:`${donor.donor_first_name} ${donor.donor_last_name}`, username:emailArr[0], email:donor.donor_email};
      this.setState({fields,signupModal:true,familyDonnerModal:false,beneficiaries});
    }
  render() {
    return (
        <>
        <Button
          color="warning"
          className="btn-lg btn-block"
          type="button"
          onClick={() =>
            parseInt(
              this.props.eventData.disable_donor_form
            ) === 1
              ? this.setState({disableDonorForm:true})
              : this.setState({familyDonnerModal:true})
          }
        >
          <strong>Donor Form</strong>
        </Button>
        <p className="text-center"><strong>Note:</strong> Click here to submit your donation form.</p>
        <Modal
            isOpen={this.state.disableDonorForm}
            toggle={() => this.setState({disableDonorForm:false})}
        >
            <ModalHeader>Alert!</ModalHeader>
            <ModalBody>
                Due to technichal issue, we have disabled the donor form for the time being. We will enable it as soon as we fix the problem.
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => {
                        this.setState({disableDonorForm:false})
                    }}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
        {this.state.familyDonnerModal && (
            <SaveDonorDetailsFamily
                showModal={this.state.familyDonnerModal}
                closeModal={() => {
                    this.setState({familyDonnerModal:false});
                }}
                appDetails={this.props.eventData}
                registerDonor={this.registerDonor}

            />
        )}
        {this.state.signupModal && 
            <Signup 
                showModal={this.state.signupModal} 
                closeModal={()=>this.setState({signupModal:false})} 
                fields={this.state.fields}
                beneficiaries={this.state.beneficiaries}
            />
        }
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(DonorFormButton);


