import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Container,
  Row,
  Form,
  Col,
} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaCopy } from "react-icons/fa";

function WidgetCode() {
  const [userName, baseUrl] = useSelector((state) => {
    return [state.userName, state.baseUrl];
  });

  useEffect(() => {
    let widget = `<iframe src='${baseUrl}/api/widget?username=${userName}' width="100%" height="100%"></iframe>`;
    setWidgetCode(widget);
    setLoading(false);
  }, []);

  const [widget, setWidgetCode] = useState(null);
  const [loading, setLoading] = useState(true);

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(widget).then(
      () => {
        toast.success("Widget script copied to clipboard.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  return (
    <Card className='modified-card'>
      
      <Card.Header><h4 className="mb-0 p-2">Widget Code</h4></Card.Header>
      <Card.Body className="text-center">
        <LoadingOverlay active={loading} spinner text="Loading. Please wait...">
          <Form.Control
            as="textarea"
            id="description"
            name="description"
            value={widget !== null ? widget : ""}
            rows={3}
          />
          <Button
            variant="success"
            type="button"
            className="mt-3"
            onClick={copyToClipBoard}
          >
            <FaCopy /> Copy Code
          </Button>
        </LoadingOverlay>
      </Card.Body>
    </Card>
  );
}

export default WidgetCode;
