import { createStore } from 'redux'

let apiUrl, baseUrl;
if(document.URL.search("localhost")){
  // apiUrl = "https://tiphub.mitiztechnologies.in/api";
  // baseUrl = "https://tiphub.mitiztechnologies.in";
  apiUrl = "https://tiphub.co/api";
  baseUrl = "https://tiphub.co";
}
else{
  apiUrl = "https://tiphub.co/api";
  baseUrl = "https://tiphub.co";
}

 
let initialState = {
  sidebarShow: false,
  apiUrl: apiUrl,
  baseUrl: baseUrl,
  notifications: [],
  navigation: [],
};
if (localStorage.getItem("token")) {
  initialState = { ...initialState, token: localStorage.getItem("token") };
}
if (localStorage.getItem("userName")) {
  initialState = {
    ...initialState,
    userName: localStorage.getItem("userName")
  };
}
if (localStorage.getItem("uName")) {
  initialState = {
    ...initialState,
    uName: localStorage.getItem("uName")
  };
}
if (localStorage.getItem("userId")) {
  initialState = { ...initialState, userId: localStorage.getItem("userId") };
}
if (localStorage.getItem("userType")) {
  initialState = {
    ...initialState,
    userType: localStorage.getItem("userType")
  };
}
if (localStorage.getItem("userImage")) {
  initialState = {
    ...initialState,
    userImage: localStorage.getItem("userImage")
  };
}
if (localStorage.getItem("userEmail")) {
  initialState = {
    ...initialState,
    userEmail: localStorage.getItem("userEmail")
  };
}

if (localStorage.getItem("userDescription")) {
  initialState = {
    ...initialState,
    userDescription: localStorage.getItem("userDescription")
  };
}

if (localStorage.getItem("userImageThumb")) {
  initialState = {
    ...initialState,
    userImageThumb: localStorage.getItem("userImageThumb")
  };
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'login':
      return {...state, ...rest }
    case 'update-profile':
      return {...state, ...rest }
    case 'logout':
    localStorage.clear();
      const initialState = {
        apiUrl: apiUrl,
        baseUrl: baseUrl
      };
      return (state = initialState);
    default:
      return state
  }
}

const store = createStore(changeState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
store.subscribe(() => {
  console.log(store);
});
export default store