import React, { Component } from "react";
import { Card, Spinner, Button, Form, FloatingLabel } from "react-bootstrap";
import user from "../../../services/user";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      submitted: false,
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const params = {
      current_password: this.state.fields["current_password"]
        ? this.state.fields["current_password"]
        : "",
      new_password: this.state.fields["new_password"]
        ? this.state.fields["new_password"]
        : "",
      confirm_password: this.state.fields["confirm_password"]
        ? this.state.fields["confirm_password"]
        : "",
    };

    user.changePassword(params).then((response) => {
      this.setState({ submitted: false }, () => {
        let errors = {};
        let fields = {};
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ fields });
        } else if (response.data.error) {
          if (response.data.message.current_password) {
            errors["current_password"] =
              response.data.message.current_password[0];
          }
          if (response.data.message.password) {
            errors["new_password"] = response.data.message.password[0];
          }
          if (response.data.message.confirm_password) {
            errors["confirm_password"] =
              response.data.message.confirm_password[0];
          }
        }
        this.setState({ errors });
      });
    });
  };

  render() {
    return (
      <div className="animated fadeIn">
        <Card className="modified-card">
          
            
            <Card.Header><h4 className="mb-0 p-2">Change Password</h4></Card.Header>
            <Card.Body>
              <Form onSubmit={this.handleSubmit} className="p-5">
                <FloatingLabel
                  label="Current Password"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="current_password"
                    placeholder="current_password"
                    onChange={(event) => this.handleChange(event, "current_password")}
                    isInvalid={!!this.state.errors.current_password}
                    value={this.state.fields.current_password !== undefined ? this.state.fields.current_password : ''}
                  />
                  <Form.Control.Feedback type="invalid">{this.state.errors.current_password}</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  label="New Password"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="new_password"
                    placeholder="new_password"
                    onChange={(event) => this.handleChange(event, "new_password")}
                    isInvalid={!!this.state.errors.new_password}
                    value={this.state.fields.new_password !== undefined ? this.state.fields.new_password : ''}
                  />
                  <Form.Control.Feedback type="invalid">{this.state.errors.new_password}</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  label="Confirm Password"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="confirm_password"
                    placeholder="confirm_password"
                    onChange={(event) => this.handleChange(event, "confirm_password")}
                    isInvalid={!!this.state.errors.confirm_password}
                    value={this.state.fields.confirm_password !== undefined ? this.state.fields.confirm_password : ''}
                  />
                  <Form.Control.Feedback type="invalid">{this.state.errors.confirm_password}</Form.Control.Feedback>
                </FloatingLabel>
              
              <Button
                variant="success"
                size="lg"
                type="submit"
                className="mt-2"
                disabled={this.state.submitted}
              >
                {this.state.submitted && <Spinner className="mr-1" size="sm" />}
                Save
              </Button>
              </Form>
            </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    baseUrl: state.baseUrl,
  };
};
export default connect(mapStateToProps)(ChangePassword);
