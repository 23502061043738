/* eslint-disable react/prop-types */
import { Button, Table, Modal } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import moment from "moment";
import { connect } from "react-redux";
import { FaListAlt, FaCheckCircle } from "react-icons/fa";
import DonorDetails from "./DonorDetails";

class Donors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      loader: false,
      donors: [],
      totalCount: 0,
      disabled: false,
      showDonorDetailModal: false,
    };
  }
  listDonors = () => {
    this.setState({ loader: true });
    events
      .listDonors({
        event_id: this.props.selectedEventId,
        page: this.state.pageNo,
        verified:this.props.verified?this.props.verified:0
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ donors: res.data.donors, loader: false });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount = () => {
    this.listDonors();
  };
  sendThanksEmail = (donor_id, payment_id) => {
    events
      .sendThanksEmail({ donor_id: donor_id, payment_id: payment_id })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.listDonors();
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendReceiptEmail = (donor_id, payment_id) => {
    events
      .sendReceiptEmail({ donor_id: donor_id, payment_id: payment_id })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.listDonors();
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  showDonorDetails = (id) => {
    this.setState({ showDonorDetailModal: true, event_id: id });
  };
  render() {
    return (
      <>
        <Modal size="lg" show={this.props.showModal} onHide={() => this.props.closeModal()} 
                backdrop="static"
                keyboard={false}
                centered>
          <Modal.Header closeButton><h4>Donors</h4></Modal.Header>
          <Modal.Body style={{minHeight:300}}>
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading..."
            >
              <Table className="table table-responsive customise_table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fist Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Added On</th>
                    {this.props.showAction && <th colSpan="2">Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {this.state.donors.length > 0 ? (
                    this.state.donors.map((ele, index) => (
                      <tr key={index}>
                        <th>
                          {this.state.pageNo === 1
                            ? index + 1
                            : (this.state.pageNo - 1) * 50 + index + 1}
                          .
                        </th>
                        <td>{ele.donor_first_name}</td>
                        <td>{ele.donor_last_name}</td>
                        <td> {ele.donor_email}</td>
                        <td> {moment(ele.added_on).format("MMM Do YYYY")}</td>
                        {this.props.showAction && <td>
                            <Button type="button" className="btn-sm mr-1" color="primary" onClick={() => this.showDonorDetails(ele.id)}> {parseInt(ele.verified) === 1 ? <FaCheckCircle size="1.5em"/>:<FaListAlt size="1.5em"/>}</Button>
                            {/* {parseInt(ele.thanks_email_sent) === 0 && <Button type="button" className="btn-sm" color="success" onClick={()=>this.sendThanksEmail(ele.id, this.props.paymentId)} disabled={this.state.disabled}>Send Thanks Email</Button>}
                            {parseInt(ele.thanks_email_sent) === 1 && parseInt(ele.receipt_sent) === 0 && <Button type="button" className="btn-sm" color="warning" onClick={()=>this.sendReceiptEmail(ele.id, this.props.paymentId)}>Send receipt</Button>} */}
                        </td>}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Record not found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </LoadingOverlay>
          </Modal.Body>
        </Modal>

        {this.state.showDonorDetailModal && (
          <DonorDetails
            showModal={this.state.showDonorDetailModal}
            id={this.state.event_id}
            closeModal={() => this.setState({ showDonorDetailModal: false })}
            reloadDonors={this.listDonors}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(Donors);
