import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Spinner,
  InputGroup,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import user from "../../../services/user";

export class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      loader: false,
      email: null,
      submitted: false,
      userFound: true,
      teamMembers: [],
      teamListLoader: false,
    };
  }

  getTeamList = () => {
    this.setState({ teamListLoader: true });
    user.getTeams().then((res) => {
      if (res.data.success) {
        this.setState({ teamMembers: res.data.team, teamListLoader: false });
      }
    });
  };
  componentDidMount = () => {
    this.getTeamList();
  };

  checkUserExistence = (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    user.checkUserExistence({ email: this.state.email }).then((res) => {
      this.setState({ loader: false, userFound: res.data.found });
      if (!res.data.found) {
        let fields = this.state.fields;
        fields.email = this.state.email;
        this.setState({ fields });
      } else {
        let errors = this.state.errors;
        errors.emailChk = res.data.message.email;
        if (res.data.message == "User added successfully.") {
          this.getTeamList(); //if message is added successfully then call this
        }
        this.setState({ errors });
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  addNewMember = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ submitted: true });
      let fields = this.state.fields;
      user.addTeam({ fields }).then((res) => {
        this.setState({ submitted: false });
        if (res.data.success) {
          this.setState({ userFound: true, fields: {} });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.getTeamList();
        } else {
          if (res.data.error) {
            this.setState({ errors: res.data.message });
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }
  };

  changeEmailCheck = (e, field) => {
    e.preventDefault();
    this.setState({ [field]: e.target.value, errors: {} });
  };

  handleChange = (e, field) => {
    e.preventDefault();
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields, errors: {} });
  };

  validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!this.state.fields.name) {
      errors["name"] = "Name can not be empty.";
      formIsValid = false;
    }
    if (!this.state.fields.username) {
      errors["username"] = "Username can not be empty.";
      formIsValid = false;
    }
    if (!this.state.fields.email) {
      errors["email"] = "Email can not be empty.";
      formIsValid = false;
    }
    if (!this.state.fields.password) {
      errors["password"] = "Password can not be empty.";
      formIsValid = false;
    }
    if (!this.state.fields.confirm_password) {
      errors["confirm_password"] = "Confirm password can not be empty.";
      formIsValid = false;
    }
    if (
      this.state.fields.password &&
      this.state.fields.password !== this.state.fields.confirm_password
    ) {
      errors["confirm_password"] = "Confirm did not match with your password.";
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  };

  render() {
    return (
      <>
        <Card className="modified-card">
          <Card.Header><h4 className="mb-0 p-2">Teams</h4></Card.Header>
          <Card.Body style={{ minHeight: 400 }}>
            <Form onSubmit={this.checkUserExistence} method="post">
                <Row  className="'d-flex align-items-center justify-content-center">
                  <Col md={8}>
                    <InputGroup className="mb-3" hasValidation>
                        <Form.Control
                            name="passcode" 
                            type="text" 
                            onChange={(e) => this.changeEmailCheck(e, 'email')} 
                            isInvalid={!!this.state.errors.emailChk}
                        />
                        <Button type="submit" variant="success" disabled={this.state.loader}>{this.state.loader && <Spinner animation="border" variant="light" className="me-1" style={{width:20, height:20}}/>}Add Team Member</Button>
                        <Form.Control.Feedback type="invalid">{this.state.errors.emailChk}</Form.Control.Feedback>
                      </InputGroup>
                  </Col>
                </Row>
            </Form>
            {this.state.userFound ? (
              <LoadingOverlay
                active={this.state.teamListLoader}
                spinner
                text="Loading..."
              >
                {this.state.teamMembers.length > 0 && (
                  <>
                    <Table
                      className="table-responsive customise_table"
                      style={{ minHeight: 300 }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">User Name</th>
                          <th scope="col">Email</th>
                          {/* <th scope="col">Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.teamMembers.length > 0 &&
                          this.state.teamMembers.map((tmbr, index) => (
                            <tr key={index}>
                              <td><strong>{index + 1}</strong></td>
                              <td>{tmbr.username}</td>
                              <td>{tmbr.email}</td>
                              {/* <td></td> */}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </>
                )}
              </LoadingOverlay>
            ) : (
              <Form onSubmit={this.addNewMember} method="post">
                <Form.Group>
                  <Form.Label for="name">Full Name</Form.Label>
                  <Form.Control
                    className="input-bg"
                    type="text"
                    name="name"
                    id="name"
                    value={
                      this.state.fields["name"] ? this.state.fields["name"] : ""
                    }
                    onChange={(event) => this.handleChange(event, "name")}
                    invalid={this.state.errors["name"] ? true : false}
                  />
                  <Form.Control.Feedback>
                    {this.state.errors["name"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label for="username">Username</Form.Label>
                  <Form.Control
                    className="input-bg"
                    type="text"
                    name="username"
                    id="username"
                    value={
                      this.state.fields["username"]
                        ? this.state.fields["username"]
                        : ""
                    }
                    onChange={(event) => this.handleChange(event, "username")}
                    invalid={this.state.errors["username"] ? true : false}
                  />
                  <Form.Control.Feedback>
                    {this.state.errors["username"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label for="email">Email</Form.Label>
                  <Form.Control
                    className="input-bg"
                    type="text"
                    name="email"
                    id="email"
                    value={
                      this.state.fields["email"]
                        ? this.state.fields["email"]
                        : ""
                    }
                    onChange={(event) => this.handleChange(event, "email")}
                    invalid={this.state.errors["email"] ? true : false}
                  />
                  <Form.Control.Feedback>
                    {this.state.errors["email"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label for="password">Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    id="password"
                    value={
                      this.state.fields["password"]
                        ? this.state.fields["password"]
                        : ""
                    }
                    onChange={(event) => this.handleChange(event, "password")}
                    invalid={this.state.errors["password"] ? true : false}
                  />
                  <Form.Control.Feedback>
                    {this.state.errors["password"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label for="confirm_password">
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    value={
                      this.state.fields["confirm_password"]
                        ? this.state.fields["confirm_password"]
                        : ""
                    }
                    onChange={(event) =>
                      this.handleChange(event, "confirm_password")
                    }
                    invalid={
                      this.state.errors["confirm_password"] ? true : false
                    }
                  />
                  <Form.Control.Feedback>
                    {this.state.errors["confirm_password"]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Col md={12} className="text-right">
                    <Button
                      color="success"
                      type="submit"
                      disabled={this.state.submitted}
                    >
                      {this.state.submitted && (
                        <Spinner size="sm" color="#887d7d" className="mr-1" />
                      )}
                      Sign up
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default Teams;
