import React, { Component } from "react";
import { Link } from "react-router-dom";
import http from "../../../services/http";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Col, Row, Spinner, Button, Form } from "react-bootstrap";
import Login from "./Login";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      loader: false,
      isFormValid: false,
      showModal: false,
    };
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Please enter your username or email.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loader: true });
      this.handleForgotPassword();
    }
  };

  handleForgotPassword() {
    let reqData = {
      username: this.state.fields["username"],
    };
    var that = this;
    http
      .post("/user/forgot-password", reqData)
      .then(function (response) {
        that.setState({ loader: false });
        if (response.data.error) {
          let errors = {};
          errors = { username: response.data.message };
          that.setState({ errors });
          that.setState({ loader: false });
        } else if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          that.setState({ loader: false });
        }
      })
      .catch(function (error) {
        console.log(error);
        that.setState({ loader: false });
      });
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  toggleLoginModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };
  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Forgot Password : Tiphub.co</title>
        </Helmet>
        <section className="login-enter-section tiphub-banner pb-5">
          <div className="tiphub-banner-bg h-100"></div>
          <div id="lp-register">
            <div className="container wrapper py-4">
              <Row className="">
                <div style={{ width: "480px", margin: "auto" }}>
                  <div className="card shadow p-4 pb-5 w-100">
                    <div>
                      <div className="text-center mt-2">
                        <img src="assets/password.png" />
                      </div>
                      <h2 className="login-title text-center mt-3">
                        <b>Forgot your password?</b>
                      </h2>
                      <p className="text-center reset mb-4">
                        We’ll help you reset it and get back on track.
                      </p>
                      <form
                        className="login-form px-4"
                        method="post"
                        onSubmit={this.handleSubmit}
                      >
                        <Form.Group>
                          <Form.Label for="username">
                            Email Or Username
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            id="username"
                            value={
                              this.state.fields["username"]
                                ? this.state.fields["username"]
                                : ""
                            }
                            onChange={(event) =>
                              this.handleChange("username", event)
                            }
                            isInvalid={
                              this.state.errors["username"] ? true : false
                            }
                          />
                          {this.state.errors["username"] && (
                            <Form.Control.Feedback>
                              {this.state.errors["username"]}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <div className="mt-3 d-flex justify-content-between">
                          <Button
                            variant="success"
                            type="submit"
                            disabled={loader}
                          >
                            {loader && (
                              <Spinner
                                size="sm"
                                color="#887d7d"
                                className="mr-1"
                              />
                            )}{" "}
                            Submit
                          </Button>
                          <span className="pt-2">Or</span>
                          <span className="pt-2">
                            Return to{" "}
                            <Link to="/">
                              <Button
                                variant="link"
                                className="p-0"
                                style={{ marginTop: "-2px" }}
                              >
                                Login
                              </Button>
                            </Link>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </section>
        {this.state.showModal && (
          <Login
            showModal={this.state.showModal}
            closeModal={this.toggleLoginModal}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
