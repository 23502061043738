import React, { useState } from "react"
import {
  Row, Col, Container, Image, Modal,
  ModalHeader,
  ModalBody
} from "react-bootstrap"
import FeaturedEvents from '../Home/FeaturedEvents'
import Slider from "react-slick"
import { useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';

function PublicProfile(props) {
  const apiUrl = useSelector((state) => state.apiUrl);
  const qrCode = {
    className: "center qrSlider",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,

    speed: 500,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ],
  };
  const qRCodeSliderSettings = {
    slidesToShow: (props.onDashboard || isMobile) ? 1 : 3,
    infinite: true,
    adaptiveHeight: true,
    className: "center qrSlider",
    centerMode: true,
    infinite: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,    // For mobile view (max-width: 768px)
        settings: {
          padding: 0,
          slidesToShow: 1,  // Show only 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  }

  const [showQrCode, setShowQrCode] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState(null);


  return (
    <>
      <div className="tiphub-banner profile">
        <div className="tiphub-banner-bg public_profile_bg"></div>
        <Container>

          <Row className="mb-2">
            <Col sm={12}>
              <div className="dashboard-heading">
                <h2>My Profile</h2>
              </div>
            </Col>
          </Row>
          <div className="profile_banner">
            <Row>
              <Col sm={4}>
                <Image
                  src={
                    props.userDetails.image !== null
                      ? `${apiUrl}/web/profile_images/${props.userDetails.image}`
                      : "/assets/no-profile-image.png"
                  }

                  className="img-fluid rounded-circle square"
                />
              </Col>
              <Col sm={8}>
                <div className="profile-intro mt-4" data-aos="zoom-in">
                  <div className="profile-intro-content">
                    <h2 className="mb-4">{props.userDetails && props.userDetails.name && props.userDetails.name}</h2>
                    <div style={{ maxHeight: '60vh', overflowY: 'scroll' }}><p className="mb-5" style={{ lineBreak: 'anywhere' }} dangerouslySetInnerHTML={{ __html: props.description }}></p></div>
                    {props.userDetails && props.userDetails.sociallinks.length > 0 && <ul className="tiphub-social-links">
                      {props.userDetails.sociallinks.map((ele, index) => <li key={index}>
                        <a href={ele.url} target="_blank" rel="noopener noreferrer">
                          <img src={`/assets/${ele.plateform}.png`} height={30} />
                        </a>
                      </li>)}
                    </ul>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="tipping_apps">
        <Container>
          <h1 className="text-center mb-4" data-aos="zoom-in">
            <b>Tipping Apps ({props.services.length})</b>
          </h1>
          <div className="tipping_apps_content">
            {props.services.length > 0 && (
              <Row className="tipping_app_icon">
                {props.services.map(
                  (ele, index) => <Col key={index} className="tipping_app_box">
                    <a
                      onClick={() => props.openDonnerModal(ele)}
                      style={{ cursor: 'pointer' }}
                    >
                      {ele.appname.icon !== null ?
                        <img src={`/assets/${ele.appname.icon}`} width={50} />
                        : <img src='/assets/no-profile-image.png' width={50} />
                      }
                      <span>{ele.appname.name}</span>
                      {ele.username && <span className="payment-method-username-span">@{ele.username}</span>}
                    </a>
                  </Col>
                )}
              </Row>
            )}
          </div>
        </Container>
      </div>
      {props.qrCodes.length > 0 && <div className="tipping_apps qr_code_content pt-2">
        <Container>
          <h1 className="text-center mb-4" data-aos="zoom-in">
            <b>QR Code</b>
          </h1>
          <div className="tipping_apps_content">
            <Row>
              <Col sm={12}>
                <Slider {...qRCodeSliderSettings}>
                  {props.qrCodes.map(ele => <div key={`qrcode-key-${ele.id}`} className="qrSlider-box" onClick={() => {
                    setQrCodeImage(`${apiUrl}/web/qrcodes/${ele.image}`)
                    setShowQrCode(true)
                  }}>
                    <Image src={`${apiUrl}/web/qrcodes/${ele.image}`} fluid thumbnail className="p-2" />
                    {ele.title && <h3 className="mt-2">{ele.title}</h3>}
                  </div>)}
                </Slider>
              </Col>
            </Row>
          </div>
        </Container>
      </div>}
      <FeaturedEvents userId={props.userDetails.id} title="Recent Events" onDashboard={props.onDashboard ? true : false} />

      {showQrCode && <Modal show={showQrCode} onHide={() => setShowQrCode(false)} animation={true} className="qr_code_modal">
        <Modal.Header closeButton>
          <Modal.Title>Scan QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={qrCodeImage} class="p-2 img-fluid img-thumbnail" style={{ height: '350px' }} />
          <h3 className="mt-2">Text</h3>
        </Modal.Body>
      </Modal>}

    </>
  );
}

export default PublicProfile;
