/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Row, Col, Card
  } from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import ManageMembers from "./ManageMembers";

class EventMembers extends Component {
  constructor(props) {
    super(props);
        this.state = {
            showModal:false,
            fields: {event_id:this.props.eventDetails.id},
            errors: {},
            loader: false,
            submitted:false,
            records:[],
        };
    }
    getMembers = () => {
        this.setState({ loader: true });
        events
          .getMembers({
            event_id:this.props.eventDetails.id,details:1
          })
          .then((res) => {
            if (res.data.success) {
              this.setState({ records: res.data.members});
            } else if (res.data.error) {
              console.log(res.data.message);
            }
            this.setState({ loader: false });
          })
          .catch((err) => {
            console.log(err);
          });
    };
    componentDidMount = () =>{
        this.getMembers(1)
    }
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["member"]) {
            formIsValid = false;
            errors["member"] = "Please enter email address or username.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            let params = { fields:this.state.fields };
            events
            .addMember(params)
            .then((res) => {
                if (res.data.success) {
                    let fields = this.state.fields;
                    fields.member = '';
                    this.setState({ submitted: false, fields},()=>{
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.getMembers();
                    });
                } else if (res.data.error) {
                    let errors = [];
                    if(res.data.message.user_id){
                        errors['member'] = res.data.message.user_id;
                    }
                    else{
                        errors['member'] = res.data.message;
                    }
                    this.setState({errors, submitted:false});
                }
            })
            .catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    deleteRecord = (id) => {
        if (window.confirm('Are you sure to delete?')) {
          let params = { id: id }
          events.removeMember(params).then((response) => {
            if (response.data.success) {
              let records = this.state.records.filter((r) => parseInt(r.id) !== parseInt(id))
              this.setState({records},()=>{
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                })
              })
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            }
          }).catch((err) => {
            console.log(err)
                toast.error('Unexpected error !', {
                position: toast.POSITION.TOP_RIGHT,
            })
          })
        }
    }
  render() {
    return (
        <>
        <h4><b>Team Members</b> {this.props.userId && parseInt(this.props.userId) === parseInt(this.props.eventDetails.user_id) && <Button
          color="warning"
          className="btn-sm float-right"
          type="button"
          onClick={() => this.setState({showModal:true})}
        >
          <FaPlus className="mb-1 pr-1"/>Add Member
        </Button>}</h4>
        <hr/>
        {this.state.showModal && 
            <ManageMembers 
                eventDetails={this.props.eventDetails}
                closeModal={()=>this.setState({showModal:false})}
                showModal={this.state.showModal}
            />
        }
        <Row className="discover-event homepage-event featured-Profile">   
            {this.state.records.map((ele, index) => (
                <Col md="12" kye={index}>
                    <Card className="mb-4">
                        <Card.Body style={{minHeight:0}}>
                            <div className="profile_layout mb-2">
                                <div className="preview-details">
                                    <div className="profile">
                                        <Row>
                                            <Col md={3}>
                                                <div className="avatar avatar-lg ">
                                                <img
                                                    src={`${this.props.apiUrl}/${
                                                    ele.user.image !== null
                                                        ? `/web/profile_images/${ele.user.image}`
                                                        : `/assets/no-profile-image.png`
                                                    }`}
                                                    size="lg"
                                                />
                                                </div>
                                            </Col>
                                            <Col md={9}>
                                                <div className="profile-details">
                                                    <h4>{ele.name}</h4>
                                                    <Row>
                                                        <Col>
                                                        {ele.user.services.length > 0 &&
                                                            ele.user.services.map(
                                                            (element, i) => (
                                                                <a
                                                                href={element.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                >
                                                                <img
                                                                    src={`/assets/${element.appname.icon}`}
                                                                    style={{
                                                                        width: "35px",
                                                                        height: "35px",
                                                                        marginRight: "5px",
                                                                    }}
                                                                />
                                                                </a>
                                                            )
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                {/* <Row>
                                    <Col>
                                        {ele.user.qrcodes.map((ele, index) => (
                                        <Card key={index}>
                                            
                                            <Card.Body className="px-0">
                                                <p className="text-center"><img src={`${this.props.apiUrl}/web/qrcodes/${ele.image}`} style={{borderRadius:0, width:'auto', height:'auto'}}/></p>
                                                <p className="text-center"><Button
                                                    className="btn-blue btn-sm"
                                                    onClick={(e) =>
                                                        copyToClipBoard(e, ele.address)
                                                    }
                                                    disabled={
                                                        ele.address.length > 0
                                                            ? false
                                                            : true
                                                        }
                                                >
                                                    <FaCopy />
                                                    Copy QR Code
                                                </Button></p>
                                            </Card.Body>
                                        </Card>
                                        ))}
                                    </Col>
                                </Row> */}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(EventMembers);


