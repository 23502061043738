/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import { Tab, Tabs, Card, Container } from "react-bootstrap";
import ListPayments from "./ListPayments";
import ListDonors from "./ListDonors";
import ListBeneficiaries from "./ListBeneficiaries";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const Payments = (props) => {
  const queryString = useParams();
  const [activeTab, setTab] = useState(queryString.tabid ? queryString.tabid : "0")
  return (
      <div className="defalut-overview tiphub-banner pb-5">
        <div className="tiphub-banner-bg h-100"></div>
        <Helmet>
          <title>Payments - Tiphub</title>
        </Helmet>
        <Container>
          <Card className="modified-card">
            <Card.Body style={{minHeight:650}}>
              <Tabs
                defaultActiveKey={activeTab}                  
                className="mb-3"
                onSelect={(k) => setTab(k)}
              >
                <Tab eventKey="0" title="Payments" className="p-3">
                  <ListPayments />
                </Tab>
                <Tab eventKey="1" title="Donors">
                  <ListDonors />
                </Tab>
                <Tab eventKey="2" title="Beneficiaries">
                  <ListBeneficiaries />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Container>
      </div>
  );
};

export default Payments;