/* eslint-disable react/prop-types */
import React, {
  useState,
} from "react";
import { Collapse, Button, Form, Dropdown, Card, Row, Col, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import common from "../../../services/common";
import { useSelector } from "react-redux";
import { FaShareAlt, FaEdit, FaList } from "react-icons/fa";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import EventDetailsModal from "./EventDetailsModal";
import events from "../../../services/events";

const Supporter = (props) => {
  const [userId, apiUrl, baseUrl] = useSelector((state) => {
    return [state.userId, state.apiUrl, state.baseUrl];
  });

  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const [socialIconSize, setSocialIconSize] = useState(50);
  const [eventDetailsModal, setEventDetailsModal] = useState(false);

  const shareTransaction = (transaction) => {
    if (navigator.share) {
      navigator
        .share({
          title: transaction.event.name,
          text: `${transaction.name} donated $${transaction.amount} via ${transaction.appname.name} on Tiphub`,
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    }
  };
  let ele = props.ele;

  const showName = (name, display_name = null) => {
    if(display_name !== null && display_name !== ''){
      name = display_name;
    }
    if (props.showName == 0) {
      return "Anonymous";
    } else if (props.showName == 1) {
      return name.split(" ")[0];
    } else if (props.showName == 3) {
      let string = `${name.split(" ")[0][0]}`;
      if (name.split(" ")[1]) {
        string = string + `${name.split(" ")[1][0]}`;
      }
      return string;
    } else {
      return name;
    }
  };

  const deleteSupporter = (id) => {
    if (window.confirm("Are you sure to delete this suppoter?")) {
      events.deleteSupporter({id:id}).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.reloadSupporters();
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }).catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  }
  return (
    <>
    <Card className="mb-3 p-3 blog position-relative">
      <Row>
        <Col xs={2}>
          {ele.appname !== null && (
          <Image src={`/assets/${ele.appname.icon}`} roundedCircle fluid />
        )}
        </Col>
        <Col xs={10}>
          <div className="blog-content">
            <h6>
              <b>{showName(ele.name, ele.display_name)}</b> donated <b>{common.currencySymbolsArr[props.eventDetails.currency]}{ele.amount}</b>
            </h6>
            {ele.group !== null && <p className="py-0"><small>{ele.group.name}</small></p>}
            <p className="mb-0">{ele.added_on ? common.fromNow(ele.added_on) : "-- days ago "}</p>
          </div>
        </Col>
        
        <Dropdown className="position-absolute" style={{top:10}}>
          <Dropdown.Toggle variant="link">
            <BiDotsVerticalRounded />
          </Dropdown.Toggle>
          <Dropdown.Menu>
          {userId && parseInt(userId) === parseInt(props.eventDetails.user_id) && <>
            <Dropdown.Item href="#/action-2" onClick={() => props.passTransactionId(ele.id)}>Edit</Dropdown.Item>
            <Dropdown.Item href="#/action-3" onClick={()=>deleteSupporter(ele.id)}>Delete</Dropdown.Item>
          </>}
            <Dropdown.Item as="button" onClick={() => setEventDetailsModal(true)}>Share</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>
    </Card>
    {eventDetailsModal && (
      <EventDetailsModal
        showModal={eventDetailsModal}
        closeModal={setEventDetailsModal}
        eventDetails={props.eventDetails}
        supporter={ele}
      />
    )}
  </>
  );
};

export default Supporter;
