/* eslint-disable react/prop-types */
import {
  Button,
  Modal,
  FloatingLabel,
  Spinner,
  Form
} from 'react-bootstrap'
import { toast } from 'react-toastify'
import user from '../../../services/user'
import React, { Component, createRef } from 'react'

class ManageLinks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loader: false,
      fields: {},
      errors: {},
      submitted: false,
      name:false,
      plateforms:[
        {label:'Facebook',value:'facebook',url:'https://www.facebook.com/',padding:209},
        {label:'Twitter',value:'twitter',url:'https://www.twitter.com/',padding:188},
        {label:'Instagram',value:'instagram',url:'https://instagram.com/',padding:175},
        {label:'Clubhouse',value:'clubhouse',url:'https://www.clubhouse.com/',padding:217},
        {label:'Youtube',value:'youtube',url:'https://www.youtube.com/',padding:200},
        {label:'Spotify',value:'spotify',url:'https://www.spotify.com/',padding:191},
        {label:'Soundcloud',value:'soundcloud',url:'https://soundcloud.com/',padding:188},
        {label:'Apple Music',value:'apple-music',url:'https://music.apple.com/',padding:190},
        {label:'Twitch',value:'twitch',url:'https://www.twitch.tv/',padding:168},
        {label:'Other',value:'other',url:'',padding:10}
      ],
      url:'',
      textPaddingLeft:10,
      urlInput:createRef()
    }
  }

  handleChange = (e, field) => {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
    if (field === "plateform" && e.target.value === "other") {
      fields['url'] = '';
      this.setState({name:true,url:'',textPaddingLeft:10,fields})
    }
    else if (field === "plateform" && e.target.value !== "other") {
      let selectedPlateform = this.state.plateforms.filter(plateform=>plateform.value === e.target.value);
      fields['url'] = selectedPlateform[0].url;
      this.setState({name:false,url:selectedPlateform[0].url,textPaddingLeft:selectedPlateform[0].padding},()=>{
        this.state.urlInput.current.focus();
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.handleValidation()) {
      this.setState({ submitted: true })
      const params = {
        fields: this.state.fields,
      }
      user.addSocialLink(params).then((response) => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            this.props.toggleModal()
            this.props.updateSocialLinks(response.data.links)
          } else if (response.data.error) {
            if (response.data.message) {
              this.setState({ errors: response.data.message })
            }
          }
        })
      }).catch((err) => {
        console.log(err)
        toast.error('Unexpected error !', {
        position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }

  handleValidation = () => {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true
    if (!fields['plateform']) {
      formIsValid = false
      errors['plateform'] = 'Please choose social platform!'
    }
    if (!fields['username']) {
      formIsValid = false
      errors['username'] = 'Please enter username.'
    }
    if (fields.plateform && fields.plateform === "other" && !fields['name']) {
      if (!fields.name) {
        errors["name"] = "Please enter name.";
        formIsValid = false;
      }
    }
    this.setState({ errors: errors })
    return formIsValid
  }

  

  componentDidMount=()=>{
    let socialLinks = this.props.socialLinks;
    let plateforms = this.state.plateforms;
    socialLinks.forEach((link,index)=>{
      plateforms = plateforms.filter(p=>p.value !== link.plateform);
    })
    this.setState({plateforms});
  }
  render() {
    return (
      <Modal 
        show={this.props.showModal}
        onHide={this.props.closeModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>Manage Social Links</Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingSelect" label="Platform" className='mb-3'>
            <Form.Select onChange={(event) => this.handleChange(event, "plateform")} isInvalid={this.state.errors.plateform}>
              <option value="">Select Platform</option>
              {this.state.plateforms.map((plateform,index)=><option value={plateform.value} key={index}>{plateform.label}</option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{this.state.errors.plateform}</Form.Control.Feedback>
          </FloatingLabel>
          {this.state.name && <FloatingLabel controlId="floatingInput" label="Name" className="mb-3"
            >
            <Form.Control
              type="text"
              name="name"
              placeholder="name"
              onChange={(event) => this.handleChange(event, "name")}
              isInvalid={this.state.errors.name}
            />
            <Form.Control.Feedback type="invalid">{this.state.errors.name}</Form.Control.Feedback>
          </FloatingLabel>}
          <FloatingLabel
              controlId="floatingInput"
              label="URL"
              className="mb-3"
              style={{position:'relative'}}
            >
            <Form.Control
              type="text"
              name="username"
              placeholder="username"
              onChange={(event) => this.handleChange(event, "username")}
              isInvalid={this.state.errors.username}
              style={{paddingLeft:this.state.textPaddingLeft}}
              ref={this.state.urlInput}
            />
            <span style={{position:'absolute',left:13,top:25}}>{this.state.url}</span>
            <Form.Control.Feedback type="invalid">{this.state.errors.username}</Form.Control.Feedback>
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={this.props.toggleModal}
          >
            Cancel
          </Button>
          <Button
            variant='success'
            onClick={(e) => this.handleSubmit(e)}
            disabled={this.state.submitted}
          >
            {this.state.submitted && (
              <Spinner
                size="sm"
                className="me-1"
                variant="light"
              />
            )}
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ManageLinks
