import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Row, Col, Card, Button } from "react-bootstrap";
import events from "../../../services/events";
import common from "../../../services/common";
import { useSelector } from "react-redux";
import PaypalPaymentForm from "../Common/PaypalPaymentForm";
import EdgePayPaymentForm from "../Common/EdgePayPaymentForm";

const EventPrograms = (props) => {
  const apiUrl = useSelector((state) => state.apiUrl);
  const [programs, setPrograms] = useState([]);
  const [amount, setAmount] = useState(0);
  const [programId, setProgramId] = useState(null);
  const [paypalPaymentFormModal, setPaypalPaymentFormModal] = useState(false);
  const [edgePayPaymentFormModal, setEdgePayPaymentFormModal] = useState(false);

  const getPrograms = () => {
    events
      .getPrograms({ event_id: props.eventDetails.id })
      .then((res) => {
        if (res.data.success) {
          setPrograms(res.data.programs);
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getPrograms();
  }, []);

  return (
    <Row>
        {programs.map((program, index) => <Col key={index} className="programs">
            <Card>
                <Card.Body>
                    <img src={`${apiUrl}/web/events/${program.image}`} className="img-thumbnail"/>
                    <h3 className="pt-2">{program.name}</h3>
                    <h4>{common.currencySymbolsArr[props.eventDetails.currency]}{program.price}</h4>
                    <p>{program.description}</p>
                </Card.Body>
                <Card.Footer className="p-1 d-grid"><Button type="button" variant="primary" className="btn-block" size="lg" onClick={()=>{setAmount(program.price);setProgramId(program.id);setPaypalPaymentFormModal(true)}}>Donate</Button></Card.Footer>
            </Card>
        </Col>)}
        {paypalPaymentFormModal && (
          <PaypalPaymentForm
            showModal={paypalPaymentFormModal}
            closeModal={()=>setPaypalPaymentFormModal(false)}
            eventDetails={props.eventDetails}
            amount={amount}
            programId={programId}
            editAmount={false}
          />
        )}
        {edgePayPaymentFormModal && (
          <EdgePayPaymentForm
            showModal={edgePayPaymentFormModal}
            closeModal={()=>setEdgePayPaymentFormModal(false)}
            eventDetails={props.eventDetails}
            amount={amount}
            programId={programId}
            editAmount={false}
          />
        )}
    </Row>
  );
};

export default EventPrograms;
