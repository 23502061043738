/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, Table, Spinner
} from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import { toast } from "react-toastify";

class TicketAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            payments: [],
            errors: {},
            submitted: false,
            loader: true,
        };
    }
    getTicketAnalyticDetails() {
        events
            .getTicketAnalyticDetails({
                event_id: this.props.eventDetails.id,
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ tickets: res.data.tickets, payments: res.data.payments, loader: false })
                } else if (res.data.error) {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    componentDidMount() {
        this.getTicketAnalyticDetails();
    }

    countTotalSoldTickets = (ticket) => {
        let totalTickets = 0;
        if (ticket.bookings.length > 0) {
            ticket.bookings.forEach(booking => {
                totalTickets = totalTickets + Number(booking.total_tickets);
            })
        }
        return totalTickets;
    }
    calculateTotalAmount = (ticket) => {
        let totalAmount = 0;
        if (ticket.bookings.length > 0) {
            ticket.bookings.forEach(booking => {
                totalAmount = totalAmount + parseFloat(booking.total_price);
            })
        }
        return totalAmount.toFixed(2);
    }
    calculateGrandTotal = () => {
        let grandTotal = 0;
        if (this.state.tickets.length > 0) {
            this.state.tickets.forEach(ticket => {
                grandTotal = grandTotal + parseFloat(this.calculateTotalAmount(ticket));
            })
        }
        return grandTotal.toFixed(2);
    }
    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <Modal.Header closeButton><h4>Ticket Analytics</h4></Modal.Header>
                <Modal.Body style={{ minHeight: 250 }}>
                    {/* <Form noValidate onSubmit={handleSubmit}>
                        <InputGroup className="mb-3" size="lg" hasValidation>
                            <Form.Control
                                name="passcode" 
                                type="text" 
                                onChange={(event) => handleChange(event, 'passcode')} 
                                isInvalid={!!errors.passcode}
                            />
                            <Button type="submit" variant="success" disabled={submitted}>{submitted && <Spinner animation="border" variant="light" className="me-1" style={{width:20, height:20}}/>}Validate</Button>
                            <Form.Control.Feedback type="invalid">{errors.passcode}</Form.Control.Feedback>
                        </InputGroup>
                    </Form> */}
                    {this.state.loader ? <div className="text-center mt-5"><Spinner style={{ width: 60, height: 60 }} /></div> : <div>
                        <Table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th className="border-top-0">#</th>
                                    <th className="border-top-0">Ticket</th>
                                    <th className="border-top-0">Remaining Ticket</th>
                                    <th className="border-top-0">Sold Tickets</th>
                                    <th className="border-top-0">Ticket Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tickets.map((ticket, index) => <tr key={`bookings-${index}`}>
                                    <td>{index + 1}.</td>
                                    <td>{ticket.name}</td>
                                    <td>{Number(ticket.max_limit) - this.countTotalSoldTickets(ticket)}</td>
                                    <td>{this.countTotalSoldTickets(ticket)}</td>
                                    <td>${this.calculateTotalAmount(ticket)}</td>
                                </tr>)}
                                <tr>
                                    <td colSpan="4" className="text-end h4">Grand Total</td>
                                    <td className="h4">${this.calculateGrandTotal()}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h4>Payments Breakdown</h4>
                        <Table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th className="border-top-0">#</th>
                                    <th className="border-top-0">Payment App</th>
                                    <th className="border-top-0">Transaction Amount</th>
                                    <th className="border-top-0">Gateway Charges</th>
                                    <th className="border-top-0">Platform Charges</th>
                                    <th className="border-top-0">Net Amount</th>
                                </tr>
                                {this.state.payments.map((payment, index) => <tr key={`payments-${index}`}>
                                    <td>{index + 1}.</td>
                                    <td>{payment.service ? payment.service.name : '--'}</td>
                                    <td>${payment.total_txn_amount}</td>
                                    <td>${payment.total_gateway_charges}</td>
                                    <td>${payment.total_platform_charges}</td>
                                    <td>${payment.total_net_amount}</td>
                                </tr>)}
                            </thead>
                        </Table>
                    </div>}
                </Modal.Body>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(TicketAnalytics);