/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, Row, Col, Spinner, Form
  } from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import { ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { toast } from "react-toastify";
import VerifyBank from "./VerifyBank";
import TransferFund from "./TransferFund";
import ZelleModal from "../Common/ZelleModal";
import PaypalPaymentForm from "../Common/PaypalPaymentForm";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import EdgePayPaymentForm from "../Common/EdgePayPaymentForm";

class MembershipFormButton extends Component {
  constructor(props) {
    super(props);
        this.state = {
            showModal:false,
            fields: {event_id:this.props.eventDetails.id, form_data:[]},
            errors: {},
            loader: false,
            submitted:false,
            plans:[],
            verifyBankModal:false,
            transferFundModal:false,
            dwollaCustomerUrl:null,
            memberId:null,
            selectedApp:null,
            zelleEmailModal: false,
            zelleEmail: null,
            transferStripeFundModal:false,
            appId:null,
            paypalPaymentFormModal:false,
            edgePayPaymentFormModal:false
        };
    }
    componentDidMount = () => {
        this.getPlans();
        this.getMembershipDueForm();
    }
    getPlans = () => {
        this.setState({ loader: true });
        events
          .getPlans({
            event_id:this.props.eventDetails.id
          })
          .then((res) => {
            if (res.data.success) {
              this.setState({ plans: res.data.plans});
            } else if (res.data.error) {
              console.log(res.data.message);
            }
            this.setState({ loader: false });
          })
          .catch((err) => {
            console.log(err);
          });
    };
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["membership_level"]) {
            formIsValid = false;
            errors["membership_level"] = "Please choose membership level.";
        }
        if (this.state.selectedApp === null) {
          formIsValid = false;
          errors["selected-app"] = "Please choose payment method.";
        }
        if (!this.state.fields.first_name) {
          formIsValid = false;
          errors["first_name"] = "Please enter first name.";
        }
        if (!this.state.fields.last_name) {
          formIsValid = false;
          errors["last_name"] = "Please enter last name.";
        }
        if (!this.state.fields.email) {
          formIsValid = false;
          errors["email"] = "Please enter email.";
        }
        if (!this.state.fields.phone) {
          formIsValid = false;
          errors["phone"] = "Please enter phone.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };
    getMembershipDueForm = () =>{
        events.getMembershipDueForm({event_id:this.props.eventDetails.id}).then((res) => {
            if (res.data.success) {
                let fields = this.state.fields;
                fields['form_data'] = res.data.form.form_data;
                this.setState({fields,showForm:true});
            } else if (res.data.error) {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        });
    }
    handleSubmit = data => {
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            let fields = this.state.fields;
            fields['answer'] = data;
            let params = { fields:this.state.fields };
            events.saveMember(params).then((res) => {
              if (parseInt(this.state.selectedApp.service_id) === 64){
                if (res.data.error) {
                  this.setState({errors:res.data.message, submitted:false});
                }
                else if (res.data.exists && res.data.menberId !== null) {
                  this.setState({ showModal:false, transferFundModal:true, memberId:res.data.memberId});
                }
                else if (res.data.success) {
                  //dwollaCustomerUrl:res.data.dwollaCustomerUrl, verifyBankModal:true, memberId:res.data.memberId
                    this.setState({ submitted: false, showModal:false, dwollaCustomerUrl:res.data.dwollaCustomerUrl, memberId:res.data.memberId},()=>{
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.openDonnerModal(this.state.selectedApp);
                    });
                }
              }
              else{
                this.setState({showModal:false, submitted:false},()=>{
                  this.openDonnerModal(this.state.selectedApp);
                });
                
              }
            }).catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    handleChange(field, e) {
        let fields = this.state.fields;
        if(field === 'membership_level'){
          fields['planid'] = e.target.dataset.planid;
          fields['amount'] = e.target.dataset.amount;
          fields['membership_level'] = e.target.value;
        }
        else if(e.target.type === 'checkbox'){
            if(e.target.checked){
                fields['term_accepted'] = 1;
            }
            else{
                fields['term_accepted'] = 0;
            }
        }
        else{
          fields[field] = e.target.value;
        }
        this.setState({ fields });
    }
    openDonnerModal = (appDetails) => {
      if (parseInt(appDetails.service_id) === 9) {
        this.trackEventApp(appDetails);
      } 
      else if (parseInt(appDetails.service_id) === 64) {
        this.setState({verifyBankModal:true})
      }
      else if (parseInt(appDetails.service_id) === 65) {
        this.setState({transferStripeFundModal:true})
      }
      else if (parseInt(this.props.eventDetails.fundraising_for) === 1) {
        this.trackEventApp(appDetails);
      } else if (
        parseInt(appDetails.service_id) === 7 ||
        parseInt(appDetails.service_id) === 17 ||
        parseInt(appDetails.service_id) === 15 ||
        parseInt(appDetails.service_id) === 22 ||
        parseInt(appDetails.service_id) === 23 ||
        parseInt(appDetails.service_id) === 1 ||
        parseInt(appDetails.service_id) === 24
      ) {
        this.toggleZelleModel(appDetails);
      } 
      else if (parseInt(appDetails.service_id) === 73) {
        this.setState({paypalPaymentFormModal:true});
      } 
      else if (parseInt(appDetails.service_id) === 84) {
        this.setState({edgePayPaymentFormModal:true});
      }
      else {
        this.trackEventApp(appDetails);
      }
    };
    trackEventApp = (appDetails) => {
      if (
        parseInt(appDetails.service_id) === 7 ||
        parseInt(appDetails.service_id) === 17 ||
        parseInt(appDetails.service_id) === 15 ||
        parseInt(appDetails.service_id) === 22 ||
        parseInt(appDetails.service_id) === 23 ||
        parseInt(appDetails.service_id) === 1 ||
        parseInt(appDetails.service_id) === 24
      ) {
        this.toggleZelleModel(appDetails);
      } else {
        events
          .trackEventApp({
            event_id: appDetails.event_id,
            service_id: appDetails.service_id,
          })
          .then((res) => {
            if (res.data.success) {
            } else if (res.data.error) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(() => {
            toast.error("Unexpected Error !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        let url = appDetails.url;
        //url = appDetails.default_amount === null ? url:url+"/"+appDetails.default_amount
        let aTag = document.createElement("a");
        aTag.setAttribute("href", url);
        //aTag.setAttribute("target", "_blank");
        document.body.appendChild(aTag);
        setTimeout(function () {
          aTag.click();
          document.body.removeChild(aTag);
        }, 500);
      }
    };
    toggleZelleModel = (appDetails) => {
      let zelleEmail = null;
      if (appDetails.url !== "" && appDetails.url.search("cash.app")) {
        zelleEmail = appDetails.url.split("/")[3];
      }
      else if (appDetails.phone && appDetails.phone !== "") {
        zelleEmail = appDetails.phone;
      } else {
        zelleEmail = appDetails.email;
      }
      this.setState({appId:appDetails.service_id, zelleEmail, zelleEmailModal: true }, () => {
        events
          .trackEventApp({
            event_id: appDetails.event_id,
            service_id: appDetails.service_id,
          })
          .then((res) => {
            if (res.data.success) {
            } else if (res.data.error) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(() => {
            toast.error("Unexpected Error !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      });
    };
    
  render() {
    return (
        <>
        <div className="d-grid">
        <Button
          variant="warning"
          className="btn-lg btn-block mb-3"
          type="button"
          onClick={() => this.setState({showModal:true})}
        >
          <strong>Membership Sign up</strong>
        </Button>
        </div>
        {/* <p className="text-center"><strong>Note:</strong> Click here to submit mutual aid form.</p> */}
        
        {this.state.showModal && 
            <Modal show={this.state.showModal} size="md" onHide={() => this.setState({showModal:false})}>
                <Modal.Header closeButton><h4>Membership Sign up</h4></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} sm={12}>
                            <Form.Group className="mb-1"><Form.Label for="membership_level" className="mb-0 font-weight-bold">Membership Level <span className="label-required badge badge-danger">Required</span></Form.Label></Form.Group>
                            {this.state.plans.map(ml=><Form.Group key={`membership-level-${ml.id}`}>
                                <Form.Check type="radio" value={ml.id} data-planid={ml.paypal_plan_id} data-amount={ml.price} name="membership_level" onClick={event => this.handleChange("membership_level", event)} label={`${ml.name} (${ml.price})`} id={ml.paypal_plan_id}/>
                            </Form.Group>)}
                            {this.state.errors["membership_level"] && <p style={{color:'red'}}>{this.state.errors["membership_level"]}</p>}
                            <Form.Group className="mt-2 mb-0"><Form.Label className="mb-0 font-weight-bold">Choose Payment Method<span className="label-required badge badge-danger">Required</span></Form.Label></Form.Group>
                            {!this.props.paymentId && <Row>
                              {this.props.eventDetails.apps.map((ele, index) => (
                                  <Col md={6} sm={12} key={index} className="d-grid">
                                      <Button
                                          outline={this.state.selectedApp === null ? true : this.state.selectedApp.service_id !== ele.service_id}
                                          type="button"
                                          className="btn-block mt-3"
                                          color="info"
                                          onClick={() => {
                                              let fields = this.state.fields;
                                              fields['service_id'] = ele.service_id;
                                              this.setState({selectedApp:ele});
                                            }
                                          }
                                          disabled={this.state.submitted}
                                      >
                                          <img src={`/assets/${ele.appname.icon}`} width={32} className="me-1"/>
                                          {ele.appname.name}
                                      </Button>
                                  </Col>
                              ))}
                              {this.state.errors["selected-app"] && <Col md={12} style={{color:'red'}}>{this.state.errors["selected-app"]}</Col>}
                          </Row>}
                          <Row>
                            <Col md={12} className="mt-2">
                              <Form.Group>
                                <Form.Label >First Name <span className="label-required badge badge-danger">Required</span></Form.Label>
                                <Form.Control type="text" name="first_name" id="first_name" onChange={event => this.handleChange("first_name", event)} value={this.state.fields.first_name ? this.state.fields.first_name : ""}/>
                                {this.state.errors.first_name && <p className="text-danger">{this.state.errors.first_name}</p>}
                              </Form.Group>
                              <Form.Group>
                                <Form.Label >Last Name <span className="label-required badge badge-danger">Required</span></Form.Label>
                                <Form.Control type="text" name="last_name" id="last_name" onChange={event => this.handleChange("last_name", event)} value={this.state.fields.last_name ? this.state.fields.last_name : ""}/>
                                {this.state.errors.last_name && <p className="text-danger">{this.state.errors.last_name}</p>}
                              </Form.Group>
                              <Form.Group>
                                <Form.Label >Email <span className="label-required badge badge-danger">Required</span></Form.Label>
                                <Form.Control type="text" name="email" id="email" onChange={event => this.handleChange("email", event)} value={this.state.fields.email ? this.state.fields.email : ""}/>
                                {this.state.errors.email && <p className="text-danger">{this.state.errors.email}</p>}
                              </Form.Group>
                              <Form.Group>
                                <Form.Label >Phone <span className="label-required badge badge-danger">Required</span></Form.Label>
                                <PhoneInput
                                  country={'us'}
                                  value={this.state.fields.phone ? this.state.fields.phone : ""}
                                  onChange={(phone) => {
                                    let fields = this.state.fields;
                                    fields['phone'] = phone;
                                    this.setState({fields})
                                  }}
                                  inputClass="w-100"
                                  enableSearch={true}
                                />
                                {this.state.errors.phone && <p className="text-danger">{this.state.errors.phone}</p>}
                              </Form.Group>
                              <Form.Group check inline>
                                <Form.Label>
                                    <Form.Check type="checkbox" name="term_accepted" value="1" onChange={(event)=>this.handleChange( 'terms_accepted', event)}/> By checking this box you agree to <a href="/terms-and-conditions">Our Terms Of Services</a> and <a href="/privacy-policy">Privacy Policy</a> as well as our partner's policies.
                                </Form.Label>
                              </Form.Group>
                            </Col>
                          </Row>  
                          <ReactFormGenerator
                              data={this.state.fields.form_data}
                              onSubmit={this.handleSubmit}
                              disabled={this.state.submitted}
                              submitButton={<Button type={"submit"} color="success">{this.state.submitted && (
                                  <Spinner
                                      size="sm"
                                      color="#887d7d"
                                      className="mr-1"
                                  />
                              )} Submit</Button>}
                          />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        }
        {this.state.verifyBankModal && (
          <VerifyBank
            showModal={this.state.verifyBankModal}
            closeModal={()=>this.setState({verifyBankModal:false})}
            initiateTranser={()=>this.setState({verifyBankModal:false, transferFundModal:true})}
            dwollaCustomerUrl={this.state.dwollaCustomerUrl}
            memberId={this.state.memberId}
          />
        )}
        {this.state.transferFundModal && (
          <TransferFund
            showModal={this.state.transferFundModal}
            closeModal={()=>this.setState({transferFundModal:false})}
            memberId={this.state.memberId}
          />
        )}
        {this.state.zelleEmailModal && (
          <ZelleModal
            size="md"
            zelleEmailModal={this.state.zelleEmailModal}
            closeModal={() => {
              this.setState({ zelleEmailModal: false });
            }}
            zelleEmail={this.state.zelleEmail}
          />
        )}
        {this.state.paypalPaymentFormModal && (
            <PaypalPaymentForm
                showModal={this.state.paypalPaymentFormModal}
                closeModal={() => {
                    this.setState({ paypalPaymentFormModal: false });
                }}
                eventDetails={this.props.eventDetails}
                subscription={this.state.fields.planid}
                amount={this.state.fields.amount}
                editAmount={false}
            />
        )}
        {this.state.edgePayPaymentFormModal && (
            <EdgePayPaymentForm
                showModal={this.state.edgePayPaymentFormModal}
                closeModal={() => {
                    this.setState({ edgePayPaymentFormModal: false });
                }}
                eventDetails={this.props.eventDetails}
                subscription={this.state.fields.planid}
                amount={this.state.fields.amount}
                editAmount={false}
            />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(MembershipFormButton);


