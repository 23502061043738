/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, Col, Spinner, Row, Card, Badge, Form, FloatingLabel
} from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import 'react-form-builder2/dist/app.css';
import { toast } from "react-toastify";
import moment from "moment";
import common from "../../../services/common";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FaQrcode, FaChartBar } from "react-icons/fa";
import {Link} from 'react-router-dom'


class SalePhysicalTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            fields: {},
            tickets: [],
            errors: {},
            submitted: false,
            ticketLoader: true,
            appId: null,
            exceptThisSymbols: ["e", "E", "+", "-", "."],
            booking_id: null,
            showSaleTicketButton:false,
            vendorApps:[]
        };
    }
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (fields["tickets"] && fields["tickets"].length === 0) {
            formIsValid = false;
            errors["tickets"] = "Please choose ticket.";
        }
        if (this.props.eventDetails.apps.length > 0 && !this.checkCreditDebitPaymentOption(this.props.eventDetails.apps)) {
            if (!fields["first_name"]) {
                formIsValid = false;
                errors["first_name"] = "Please enter first name.";
            }
            if (!fields["last_name"]) {
                formIsValid = false;
                errors["last_name"] = "Please enter last name.";
            }
            if (!fields["email"]) {
                formIsValid = false;
                errors["email"] = "Please enter email.";
            }
            if (!fields["phone"]) {
                formIsValid = false;
                errors["phone"] = "Please enter phone number.";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    };
    handleSubmit = app => {
        if (this.handleValidation()) {
            //alert();
            this.setState({ submitted: true });
            let fields = this.state.fields;
            fields['service_id'] = app.service_id;
            let params = { fields: fields };/* 
            console.log(params);
            this.setState({ submitted: false});
            return false; */
            events.saveSoldTicket(params).then((res) => {
                if (res.data.success) {
                    this.setState({ submitted: false, showModal: false, booking_id: res.data.booking_id }, () => {
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
                } else if (res.data.error) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.setState({ errors: res.data.message, submitted: false });
                }
            }).catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    handleChange(e, field) {
        let fields = this.state.fields;
        if (e.target.type === 'radio') {
            fields['oneTicketPrice'] = this.props.eventDetails.tickets.filter(t => parseInt(t.id) === parseInt(e.target.value))[0].price;
        }
        else if(e.target.type === 'checkbox'){
            if(e.target.checked){
                fields['term_accepted'] = 1;
            }
            else{
                fields['term_accepted'] = 0;
            }
        }
        else{
            fields[field] = e.target.value;
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    }
    calculateTotal = () => {
        let fields = this.state.fields;
        if (fields.tickets.length > 0) {
            fields.total_price = fields.tickets.reduce(function (previousValue, currentValue) {
                if(currentValue.custom_price !== undefined && parseInt(currentValue.custom_price) > 0){
                    return previousValue + parseInt(currentValue.custom_price);
                }
                else{
                    return previousValue + currentValue.total_price;
                }
            }, 0);
        }
        else {
            fields.total_price = 0;
        }
        
        const donation_amount = fields.donation_amount !== "" ? fields.donation_amount : 0;
        /* let service_fee = fields.service_fee ? fields.service_fee : 0;
        if (service_fee) {
            service_fee = fields.total_price * parseInt(service_fee) / 100;
        } */
        let service_fee = 0;
        fields.total_price = fields.total_price + parseInt(donation_amount) + service_fee;

        this.setState({ fields });
    }
    updateTicketNumber(e, id, pticketId) {
        let action  = true;
        let fields = this.state.fields;
        if(fields.selectedTickets.length > 0 && fields.selectedTickets.includes(pticketId)){
            let indexOfSelectedTickets = fields.selectedTickets.indexOf(pticketId);
            fields.selectedTickets.splice(indexOfSelectedTickets, 1);
            action = false;
        }
        else{
            fields.selectedTickets.push(pticketId);
            action = true;
        }

        let currentTicketValue = parseInt(this.getTicketValue(id, 'total_tickets'));
        if (action) {
            currentTicketValue++;
        }
        else if (currentTicketValue > 0) {
            currentTicketValue--;
        }
        
        let target = e.target;
        if (fields.tickets && fields.tickets.length > 0) {
            const index_to_be_modified = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            if (index_to_be_modified < 0) {
                const ticket = {
                    ticket_id: id,
                    total_tickets: currentTicketValue,
                    ticket_price: target.dataset.price,
                    total_price: target.dataset.price * currentTicketValue,
                    description: target.dataset.description,
                }
                fields.tickets.push(ticket);
            }
            else {
                fields.tickets[index_to_be_modified].total_tickets = currentTicketValue;
                fields.tickets[index_to_be_modified].total_price = currentTicketValue * fields.tickets[index_to_be_modified].ticket_price;
            }
        }
        else {
            const ticket = {
                ticket_id: id,
                total_tickets: currentTicketValue,
                ticket_price: target.dataset.price,
                total_price: target.dataset.price * currentTicketValue,
                description: target.dataset.description,
            }
            fields.tickets.push(ticket);
        }
        if(currentTicketValue === 0){
            const index_to_be_removed = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            fields['tickets'].splice(index_to_be_removed, 1);
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
            console.log(fields)
        });
    }
    updateCustomPrice(e, id) {
        let currentTicketValue = parseInt(this.getTicketValue(id, 'total_tickets'));
        if (currentTicketValue === 0) {
            currentTicketValue = 1;
        }
        let target = e.target;
        let fields = this.state.fields;
        if (fields.tickets && fields.tickets.length > 0) {
            const index_to_be_modified = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            if (index_to_be_modified < 0) {
                const ticket = {
                    ticket_id: id,
                    total_tickets: currentTicketValue,
                    ticket_price: target.dataset.price,
                    total_price: target.dataset.price * currentTicketValue,
                    description: target.dataset.description,
                    custom_price: target.value 
                }
                fields.tickets.push(ticket);
            }
            else {
                fields.tickets[index_to_be_modified].total_tickets = currentTicketValue;
                fields.tickets[index_to_be_modified].total_price = currentTicketValue * fields.tickets[index_to_be_modified].ticket_price;
                fields.tickets[index_to_be_modified].custom_price = target.value;
            }
        }
        else {
            const ticket = {
                ticket_id: id,
                total_tickets: currentTicketValue,
                ticket_price: target.dataset.price,
                total_price: target.dataset.price * currentTicketValue,
                description: target.dataset.description,
                custom_price: target.value 
            }
            fields.tickets.push(ticket);
        }
        if(currentTicketValue === 0){
            const index_to_be_removed = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            fields['tickets'].splice(index_to_be_removed, 1);
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    }
    chooseTicketType = (e) => {
        let fields = this.state.fields;
        if (fields['tickets']) {
            if (e.target.checked) {
                let ticket = {
                    ticket_id: e.target.value,
                    total_tickets: 1,
                    ticket_price: e.target.dataset.price,
                    total_price: e.target.dataset.price * 1,
                    description: e.target.dataset.description,
                }
                fields['tickets'].push(ticket);
            }
            else {
                const index_to_be_removed = fields.tickets.findIndex(t => {
                    return parseInt(t.ticket_id) === parseInt(e.target.value);
                });
                fields['tickets'].splice(index_to_be_removed, 1);
            }
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    };
    getTicketValue = (id, key) => {
        let fields = this.state.fields;
        if (fields.tickets) {
            let ticket = fields.tickets.filter(t => parseInt(t.ticket_id) === parseInt(id));
            if (ticket.length > 0) {
                if (key === 'total_tickets') {
                    return ticket[0].total_tickets;
                }
                else if (key === 'total_price') {
                    return ticket[0].total_price.toFixed(2);
                }
                else if (key === 'checkbox') {
                    return true;
                }
                else if (key === 'description') {
                    return ticket[0].description;
                }
                else if (key === 'custom_price') {
                    return ticket[0].custom_price;
                }
            }
            else {
                if (key === 'total_tickets') {
                    return 0;
                }
                else if (key === 'total_price') {
                    return 0;
                }
                else if (key === 'custom_price') {
                    return '';
                }
            }
        }
    }
    toggleSaleTicketForm = () => {
        if (moment(moment(this.props.eventDetails.end_date).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))) {
            this.setState({ showPassedEventModal: true });
        }
        else {
            this.setState((prevState) => ({
                showModal: !prevState.showModal,
                fields: {
                    event_id: this.props.eventDetails.id,
                    name: this.props.eventDetails.user.name,
                    tickets: [],
                    total_price: 0,
                    donation_amount: 0,
                    service_fee: this.props.eventDetails.service_fee,
                    is_physical_ticket: 1,
                    team_id: this.props.userId,
                    selectedTickets:[]
                },
                ticketLoader: true
            }), () => {
                events
                    .getTicketsWithDetails({
                        event_id: this.props.eventDetails.id,
                        physical_ticket:1,
                        sale:1,
                        user_id:this.props.userId ? this.props.userId : null
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let tickets = res.data.tickets.filter(ticket=>ticket.allotments.length > 0);
                            this.setState({ tickets, ticketLoader: false  }, () => {
                                let fields  = this.state.fields;
                                if(res.data.tickets.length > 0 && res.data.tickets){
                                    let ticket = {
                                        ticket_id: res.data.tickets[0].id,
                                        total_tickets: 1,
                                        ticket_price: res.data.tickets[0].price,
                                        total_price: res.data.tickets[0].price * 1,
                                        description: res.data.tickets[0].description,
                                    }
                                    if(parseInt(this.props.eventDetails.allow_custom_price) === 1){
                                        ticket = {...ticket, custom_price: ''}
                                    }
                                    //fields.tickets.push(ticket);
                                    this.setState({ fields }, () => {
                                        this.calculateTotal();
                                    });
                                }
                            })
                        } else if (res.data.error) {
                            console.log(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        }

    }
    ticketsAvailable = (ticket_id) => {
        const ticket = this.state.tickets.filter(ticket => parseInt(ticket.id) === parseInt(ticket_id));
        let totalTicketsSold = ticket[0].bookings.reduce(function (previousValue, currentValue) {
            //return previousValue + parseInt(currentValue.total_tickets);
            if (currentValue.booking && parseInt(currentValue.booking.status) === 1) {
                return previousValue + parseInt(currentValue.total_tickets);
            }
            else {
                return previousValue;
            }
        }, 0);
        return parseInt(ticket[0].max_limit) - totalTicketsSold
        //return 5;
    }
    checkCreditDebitPaymentOption = (apps) => {
        let forBookingApps = apps.filter((app) => parseInt(app.for_booking) === 1);
        if (forBookingApps.length === 1 && parseInt(forBookingApps[0].service_id) === 73) {
            return true;
        }
        else {
            return false;
        }
    }
    componentDidMount(){
        events
        .checkEventVendor({
            event_id: this.props.eventDetails.id
        })
        .then((res) => {
            if (res.data.success) {
                let showSaleTicketButton = false;
                if(parseInt(res.data.totalTicketAllotments) > 0){
                    showSaleTicketButton = true;
                }
                this.setState({showSaleTicketButton, vendorApps:res.data.vendorApps})
            } else if (res.data.error) {
                console.log(res.data.message);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    render() {
        return (
            <>
                {this.props.userId && (this.state.showSaleTicketButton || parseInt(this.props.eventDetails.user_id) === parseInt(this.props.userId) && parseInt(this.props.eventDetails.category) === 59) && <Button
                    variant="warning"
                    className={this.props.marginBottom ? 'me-2 mt-1' : 'me-2 mb-1'}
                    size="sm"
                    onClick={() => this.toggleSaleTicketForm()}
                >
                    <FaQrcode className="me-2" /><strong>Sell Physical Ticket</strong>
                </Button>}
                {this.props.userId && this.state.showSaleTicketButton && parseInt(this.props.eventDetails.user_id) !== parseInt(this.props.userId) && document.URL.search('reports') < 0 && <Link className="me-2 mb-1 btn btn-dark btn-sm text-light" to={`/reports/${this.props.eventDetails.id}`}>
                    <FaChartBar
                        className="me-1 text-light"
                    />
                    Reports
                </Link>}
                {this.state.showModal &&
                    <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} size="lg">
                        <Modal.Header closeButton><p className="mb-1" style={{fontSize:25}}>Book Ticket</p></Modal.Header>
                        <Modal.Body className="pt-3">
                            <h4 className="text-center">{this.props.eventDetails.title}</h4>
                            {this.state.ticketLoader ? <div className="text-center pb-3 mt-3"><Spinner style={{width:70, height:70}}/></div> : this.state.tickets.length > 0 ? this.state.tickets.map((ticket, index) => <Row key={`ticket-index-${index}`}>
                                <Col>
                                    <Card className={(this.getTicketValue(ticket.id, 'checkbox') || false) ? "border-primary mb-3" : "mb-3"}>
                                        <Card.Body className="p-3">
                                            <Row>
                                                <Col>
                                                    <h5>{ticket.name}</h5>
                                                    <p className="mb-2">{common.currencySymbolsArr[this.props.eventDetails.currency]}{ticket.price} / person</p>
                                                </Col>
                                                <Col className="text-end">
                                                    <Badge bg="success" className="me-2">Unsold</Badge>
                                                    <Badge bg="dark" className="me-2">Selected</Badge>
                                                    <Badge bg="secondary">Sold</Badge>
                                                    <span className="total_count mb-2 ms-2">Total: {common.currencySymbolsArr[this.props.eventDetails.currency]}{this.getTicketValue(ticket.id, 'total_price')}</span>
                                                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-2">
                                                    {ticket.description}
                                                </Col>
                                            </Row>
                                            <Row style={{maxHeight:200,overflowY:'auto'}}>
                                                {ticket.allotments[0].tickets.map((pticket, index)=><Col md={2} sm={4} key={`pticket-key-${index}`} className="d-grid">{parseInt(pticket.sold) === 1 ? <Button variant="secondary" className="mb-2" disabled>{pticket.custom_ticket_id}</Button> : <Button variant={this.state.fields.selectedTickets.includes(pticket.id) ? 'dark' : 'success'} className="mb-2" onClick={(event) => this.updateTicketNumber(event, ticket.id, pticket.id)} data-price={ticket.price} data-description={ticket.description}>{pticket.custom_ticket_id}</Button>}</Col>)}
                                            </Row>
                                        </Card.Body>
                                        
                                        {(this.props.userId && this.props.eventDetails.user_id === this.props.userId) && <Card.Footer> {this.ticketsAvailable(ticket.id)} Ticket available </Card.Footer>}
                                    </Card>
                                </Col>
                            </Row>) : <Row><Col className="text-center text-danger">Tickets not available!</Col></Row>}
                            {this.state.errors["tickets"] && <p className="text-danger">{this.state.errors["tickets"]}</p>}
                            {this.props.eventDetails.apps.length > 0 && this.checkCreditDebitPaymentOption(this.props.eventDetails.apps) && <Row className="mt-3">
                                <Col md={12}><h3>Please enter the basic details</h3></Col>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="First Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="first_name"
                                            placeholder="first_name"
                                            onChange={(event) => this.handleChange(event, "first_name")}
                                            isInvalid={!!this.state.errors.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.first_name}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Last Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="last_name"
                                            placeholder="last_name"
                                            onChange={(event) => this.handleChange(event, "last_name")}
                                            isInvalid={!!this.state.errors.last_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.last_name}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="email"
                                            onChange={(event) => this.handleChange(event, "email")}
                                            isInvalid={!!this.state.errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.email}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <Form.Label htmlFor="phone">Phone</Form.Label>
                                    <PhoneInput
                                        country={'us'}
                                        value={this.state.fields.phone ? this.state.fields.phone : ""}
                                        onChange={(phone) => {
                                            let fields = this.state.fields;
                                            fields['phone'] = phone;
                                            this.setState({fields})
                                        }}
                                        inputClass="w-100"
                                        enableSearch={true}
                                    />
                                    {this.state.errors.phone && <p className="text-danger">{this.state.errors.phone}</p>}
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Your payment app username"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            name="app_username"
                                            placeholder="app_username"
                                            onChange={(event) => this.handleChange(event, "app_username")}
                                            isInvalid={!!this.state.errors.app_username}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.app_username}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Col md={12} sm={12}>
                                    <span className="font-weight-bold">Grand Total: <span style={{ fontSize: 25 }}>{common.currencySymbolsArr[this.props.eventDetails.currency]}{this.state.fields.total_price.toFixed(2)}</span>{/* {parseInt(this.props.eventDetails.service_fee) > 0 && <small> (Additional {this.props.eventDetails.service_fee}% applied.)</small>} */}</span>
                                </Col>
                                <Col md={12} sm={12}>
                                    <Form.Check type="checkbox" name="term_accepted" value="1" onChange={(event)=>this.handleChange(event, 'terms_accepted')} label={<>By checking this box you agree to <a href="/terms-and-conditions">Our Terms Of Services</a> and <a href="/privacy-policy">Privacy Policy</a> as well as our partner's policies.</>}/>
                                </Col>
                            </Row>}
                            <p className="mb-0 mt-3 text-center">Please choose below payment method to pay the amount. <b>{common.currencySymbolsArr[this.props.eventDetails.currency]}{this.state.fields.total_price.toFixed(2)}</b></p>

                            <Row>
                                {this.state.vendorApps.map((ele, index) => <Col md={12} sm={12} key={index} className="d-grid"><Button type="button" className="mt-3" variant="success" onClick={() => this.handleSubmit(ele)} disabled={this.state.submitted}>Pay <span style={{ fontSize: 25, fontWeight: 600 }} className="text-light">{common.currencySymbolsArr[this.props.eventDetails.currency]}{this.state.fields.total_price.toFixed(2)}</span> with <img src={`/assets/${ele.appname.icon}`} width={32} /> {ele.appname.name} </Button></Col>)}
                            </Row>
                        </Modal.Body>
                    </Modal>
                }
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(SalePhysicalTickets);