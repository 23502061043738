/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, Col, InputGroup, Spinner, Row, Card
} from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import 'react-form-builder2/dist/app.css';
import { toast } from "react-toastify";
import common from "../../../services/common";
import 'react-phone-input-2/lib/style.css'
import { FaUserCircle, FaSyncAlt } from "react-icons/fa"; 

class AllotPhysicalTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                event_id: this.props.eventDetails.id,
                team_id: null,
                tickets: [],
                name: this.props.eventDetails.user.name,
            },
            tickets: [],
            teams:[],
            errors: {},
            submitted: false,
            ticketLoader: true,
            teamLoader:true,
            exceptThisSymbols: ["e", "E", "+", "-", "."],
        };
    }
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (fields["team_id"] === null) {
            formIsValid = false;
            errors["team_id"] = "Please choose team member from the above list.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };
    handleSubmit = () => {
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            let fields = this.state.fields;
            let params = { fields: fields };
            events.allotPhysicalTickets(params).then((res) => {
                if (res.data.success) {
                    this.setState({ submitted: false},()=>{
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.props.closeModal();
                    });
                } else if (res.data.error) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.setState({ errors: res.data.message, submitted: false });
                }
            }).catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    handleChange(e, field) {
        let fields = this.state.fields;
        if (e.target.type === 'radio') {
            fields['oneTicketPrice'] = this.props.eventDetails.tickets.filter(t => parseInt(t.id) === parseInt(e.target.value))[0].price;
        }
        else if(e.target.type === 'checkbox'){
            if(e.target.checked){
                fields['term_accepted'] = 1;
            }
            else{
                fields['term_accepted'] = 0;
            }
        }
        else{
            fields[field] = e.target.value;
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    }
    calculateTotal = () => {
        let fields = this.state.fields;
        if (fields.tickets.length > 0) {
            fields.total_price = fields.tickets.reduce(function (previousValue, currentValue) {
                if(currentValue.custom_price !== undefined && parseInt(currentValue.custom_price) > 0){
                    return previousValue + parseInt(currentValue.custom_price);
                }
                else{
                    return previousValue + currentValue.total_price;
                }
            }, 0);
        }
        else {
            fields.total_price = 0;
        }
        
        const donation_amount = fields.donation_amount !== "" ? fields.donation_amount : 0;
        /* let service_fee = fields.service_fee ? fields.service_fee : 0;
        if (service_fee) {
            service_fee = fields.total_price * parseInt(service_fee) / 100;
        } */
        let service_fee = 0;
        fields.total_price = fields.total_price + parseInt(donation_amount) + service_fee;

        this.setState({ fields },()=>{
            console.log(this.state.fields.tickets);
        });
    }
    updateTicketNumber(e, id, action) {
        let currentTicketValue = parseInt(this.getTicketValue(id, 'total_tickets'));
        if (action) {
            currentTicketValue++;
        }
        else if (currentTicketValue > 0) {
            currentTicketValue--;
        }
        
        let target = e.target;
        let fields = this.state.fields;
        if (fields.tickets && fields.tickets.length > 0) {
            const index_to_be_modified = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            if (index_to_be_modified < 0) {
                const ticket = {
                    ticket_id: id,
                    total_tickets: currentTicketValue,
                    ticket_price: target.dataset.price,
                    total_price: target.dataset.price * currentTicketValue,
                    description: target.dataset.description,
                }
                fields.tickets.push(ticket);
            }
            else {
                fields.tickets[index_to_be_modified].total_tickets = currentTicketValue;
                fields.tickets[index_to_be_modified].total_price = currentTicketValue * fields.tickets[index_to_be_modified].ticket_price;
            }
        }
        else {
            const ticket = {
                ticket_id: id,
                total_tickets: currentTicketValue,
                ticket_price: target.dataset.price,
                total_price: target.dataset.price * currentTicketValue,
                description: target.dataset.description,
            }
            fields.tickets.push(ticket);
        }
        if(currentTicketValue === 0){
            const index_to_be_removed = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            fields['tickets'].splice(index_to_be_removed, 1);
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    }
    chooseTicketType = (e) => {
        let fields = this.state.fields;
        if (fields['tickets']) {
            if (e.target.checked) {
                let ticket = {
                    ticket_id: e.target.value,
                    total_tickets: 1,
                    ticket_price: e.target.dataset.price,
                    total_price: e.target.dataset.price * 1,
                    description: e.target.dataset.description,
                }
                fields['tickets'].push(ticket);
            }
            else {
                const index_to_be_removed = fields.tickets.findIndex(t => {
                    return parseInt(t.ticket_id) === parseInt(e.target.value);
                });
                fields['tickets'].splice(index_to_be_removed, 1);
            }
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    };
    getTicketValue = (id, key) => {
        let fields = this.state.fields;
        if (fields.tickets) {
            let ticket = fields.tickets.filter(t => parseInt(t.ticket_id) === parseInt(id));
            if (ticket.length > 0) {
                if (key === 'total_tickets') {
                    return ticket[0].total_tickets;
                }
                else if (key === 'total_price') {
                    return ticket[0].total_price.toFixed(2);
                }
                else if (key === 'checkbox') {
                    return true;
                }
                else if (key === 'description') {
                    return ticket[0].description;
                }
                else if (key === 'custom_price') {
                    return ticket[0].custom_price;
                }
            }
            else {
                if (key === 'total_tickets') {
                    return 0;
                }
                else if (key === 'total_price') {
                    return 0;
                }
                else if (key === 'custom_price') {
                    return '';
                }
            }
        }
    }
    ticketsAvailable = (ticket_id) => {
        const ticket = this.state.tickets.filter(ticket => parseInt(ticket.id) === parseInt(ticket_id));
        let totalTicketsAssigned = ticket[0].allotments.reduce(function (previousValue, currentValue) {
            return previousValue + parseInt(currentValue.total_tickets);
        }, 0);
        return parseInt(ticket[0].physical_ticket_limit) - totalTicketsAssigned
        //return 5;
    }
    checkCreditDebitPaymentOption = (apps) => {
        let forBookingApps = apps.filter((app) => parseInt(app.for_booking) === 1);
        if (forBookingApps.length === 1 && parseInt(forBookingApps[0].service_id) === 73) {
            return true;
        }
        else {
            return false;
        }
    }
    componentDidMount(){
        this.getTicketsWithDetails()
        let fields = this.state.fields;
        fields.team_id = this.props.vendor.id;
        this.setState({fields})
    }
    getTicketsWithDetails = () => {
        events
        .getTicketsWithDetails({
            event_id: this.props.eventDetails.id,
            physical_ticket:1
        })
        .then((res) => {
            if (res.data.success) {
                this.setState({ tickets: res.data.tickets, ticketLoader: false  }, () => {
                    let fields  = this.state.fields;
                    if(res.data.tickets.length > 0){
                        let ticket = {
                            ticket_id: res.data.tickets[0].id,
                            total_tickets: 10,
                        }
                        fields.tickets.push(ticket);
                        this.setState({ fields }, () => {
                            this.calculateTotal();
                        });
                    }
                })
            } else if (res.data.error) {
                console.log(res.data.message);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    selectVendor = (id) => {
        let fields = this.state.fields;
        fields.team_id = id;
        this.setState({fields})
    }
    render() {
        return (
            <Modal 
                show={this.props.showModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
                className="physical-ticket-modal" 
            >
                <Modal.Header closeButton><p className="mb-1" style={{fontSize:25}}>Physical Ticket Allotments<small style={{fontSize:15, clear:'both',display:'block'}}>{this.props.eventDetails.title}</small></p></Modal.Header>
                <Modal.Body className="pt-0">
                    {this.state.ticketLoader ? <div className="text-center pb-3"><Spinner /></div> : this.state.tickets.length === 0 ? <p className="text-center text-danger">You donot have any ticket types assigned physical ticket. <br/><Button size="sm" color="primary" onClick={()=>{
                        this.props.closeModal();
                        this.props.editEvent(this.props.eventDetails.id, 'Edit');
                    }}>Edit Event</Button></p> : <Row>
                        <Col md={12} className="mt-2 mb-2 d-flex align-items-center justify-content-center h-100">
                            <Row>
                                <Col><FaUserCircle size={100} color="grey"/></Col>
                                <Col>
                                    <h4 className="pt-3">{this.props.vendor.name}</h4>
                                    <p>{this.props.vendor.email}</p>
                                </Col>
                            </Row>
                        </Col>
                        {this.state.tickets.map((ticket, index) => <Col md={12} key={`ticket-index-${index}`}>
                            <Card className={(this.getTicketValue(ticket.id, 'checkbox') || false) ? "border-primary mb-3" : "mb-3"}>
                                <Card.Body className="p-3">
                                    <Row>
                                        <Col>
                                            <h5>{ticket.name}</h5>
                                            <p className="mb-2">{common.currencySymbolsArr[this.props.eventDetails.currency]}{ticket.price} / person</p>
                                            <p className="mb-2">{ticket.description}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} lg={4} className="pt-4">
                                            <InputGroup size="lg">
                                                <InputGroup.Text onClick={(event) => this.updateTicketNumber(event, ticket.id, false)} data-price={ticket.price} data-description={ticket.description} style={{ cursor: 'pointer' }}>-</InputGroup.Text>
                                                <span className="form-control counter">{this.getTicketValue(ticket.id, 'total_tickets')}</span>
                                                <InputGroup.Text onClick={(event) => this.updateTicketNumber(event, ticket.id, true)} data-price={ticket.price} data-description={ticket.description} style={{ cursor: 'pointer' }}>+</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} lg={8}>
                                            <ul className="list-teams mb-0">
                                                {ticket.allotments.map((allt, index) =>  <li key={`team-key-${index}`} className="selected"><FaUserCircle size={50}/><br/>{allt.team.username} - {allt.total_tickets}</li> )}
                                            </ul>
                                        </Col>
                                        <Col md={12}>{this.state.errors[ticket.id] && <p className="text-danger">{this.state.errors[ticket.id]['ticket_id']}. Please reload the see the actual status. <Button color="dark" size="sm" style={{padding:'revert'}} onClick={this.getTicketsWithDetails}><FaSyncAlt/> Reload</Button></p>}</Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="font-weight-bold"> {this.ticketsAvailable(ticket.id)} Ticket available </Card.Footer>
                            </Card>
                        </Col>)}
                    </Row>}
                    {this.state.errors["tickets"] && <p className="text-danger">{this.state.errors["tickets"]}</p>}
                    <Row>
                        <Col className="text-end">
                            <Button size="lg" variant="primary" className="me-2" onClick={()=>{
                                this.props.closeModal(false);
                                this.props.showManageVendorModal(true);
                            }}> Manage Vendor</Button>
                            <Button size="lg" variant="success" onClick={()=>this.handleSubmit()} disabled={this.state.submitted}>{this.state.submitted && <Spinner size='sm' className="mb-1"/>} Save</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(AllotPhysicalTickets);