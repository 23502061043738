import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Button,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
  FormGroup,
  Container,
} from "react-bootstrap";
import common from "../../../services/common";

class Banking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      fields: {},
      errors: {},
      submitted: false,
    };
  }

  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Please enter the name";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter email";
    }
    if (fields["email"] && !common.isValidEmail(fields["email"])) {
      formIsValid = false;
      errors["email"] = "Enter valid email address!";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Please enter the phone number";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      common.bankingEnquiry({ fields: this.state.fields }).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.props.history.push("/");
        } else if (res.data.error) {
          this.setState({
            errors: res.data.message,
            loader: false,
            submitted: false,
          });
        }
      });
    }
  };
  render() {
    const { fields, errors } = this.state;
    return (
      <>
        {this.props.userId ? (
          <Navigate to={`/${this.props.userName}`} />
        ) : (
          <>
            <Helmet>
              <title>Banking sign up - Tiphub</title>
            </Helmet>
            <div className="profile-overview tiphub-banner pb-5">
              <div className="tiphub-banner-bg h-100"></div>
              <Container className="py-4">
                <Card
                  className="mx-auto"
                  style={{
                    maxWidth: "400px",
                  }}
                >
                  <Card.Body className="shadow">
                    <h4 className="text-center">Banking sign up</h4>
                    <Form
                      method="post"
                      onSubmit={this.handleSubmit}
                      className="custom-label"
                    >
                      <FormGroup>
                        <Form.Label id="name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="name"
                          name="name"
                          value={fields.name !== undefined ? fields.name : ""}
                          invalid={errors.name ? true : false}
                          onChange={(event) => this.handleChange(event, "name")}
                        />
                        <Form.Control.Feedback invalid>
                          {errors.name}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup>
                        <Form.Label id="username">Email Address</Form.Label>
                        <Form.Control
                          type="text"
                          id="email address "
                          name="email"
                          value={fields.email !== undefined ? fields.email : ""}
                          invalid={errors.email ? true : false}
                          onChange={(event) =>
                            this.handleChange(event, "email")
                          }
                        />
                        <Form.Control.Feedback invalid>
                          {errors.email}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup>
                        <Form.Label id="phone">Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          id="phone"
                          name="phone"
                          value={fields.phone !== undefined ? fields.phone : ""}
                          invalid={errors.phone ? true : false}
                          onChange={(event) =>
                            this.handleChange(event, "phone")
                          }
                        />
                        <Form.Control.Feedback invalid>
                          {errors.phone}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <Button
                        className="mt-3"
                        variant="success"
                        type="submit"
                        disabled={this.state.submitted}
                      >
                        {this.state.loader && (
                          <Spinner
                            color="light"
                            component="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        )}
                        SUBMIT
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Container>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(Banking);
