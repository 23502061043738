import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  FormFeedback,
  Spinner,
  Form,
  Modal,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ModalBody,
  FormGroup,
  ModalFooter,
  Table,
  ModalHeader,InputGroupButtonDropdown
} from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import common from "../../../services/common";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { IoIosAdd } from "react-icons/io";
import AddEditBeneficiaryFamily from "./AddEditBeneficiaryFamily";

class SaveDonorDetailsFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donor: {},
      beneficiary: [],
      errors: {},
      submitted: false,
      imgLoader: false,
      showCurrencyMenu: false,
      openBeneficiaryModal: false,
      currencySymbol: 0,
      beneficiaryData: null,
      paymentMethods:['Zelle','PayPal','ACH','Wire Transfer','Cash Deposit','Check Deposit'],
      confirmRegistration:false,
      beneficiaries:[]
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ submitted: true });
      let donor = this.state.donor;
      donor["event_id"] = this.props.appDetails.event_id
        ? this.props.appDetails.event_id
        : this.props.appDetails.id;
      donor["service_id"] = this.props.appDetails.service_id
        ? this.props.appDetails.service_id
        : 34;
      donor["currency"] = this.state.currencySymbol;
      donor["scenario"] = "FamilyGiving";
      let params = {
        donor: this.state.donor,
        beneficiary: this.state.beneficiary,
      };
      
      events
        .saveDonorDetails(params)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            if(!this.props.userId){
              this.setState({confirmRegistration:true, beneficiaries:res.data.beneficiaries});
            }
            else{
              this.props.closeModal();
            }
            if (this.props.trackEventApp) {
              this.props.trackEventApp(this.props.appDetails);
            }
          } else if (res.data.exists || res.data.error) {
            this.setState({ errors: res.data.message });
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          this.setState({ submitted: false });
        })
        .catch((err) => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  validateForm = () => {
    const donor = this.state.donor;
    let formIsValid = true;
    const errors = {};
    this.setState({ errors });
    return formIsValid;
  };

  handleChange = (e, field) => {
    let donor = this.state.donor;
    donor[field] = e.target.value;
    this.setState({ donor });
  };

  uploadDocument = (e) => {
    const data = new FormData();
    let donor = this.state.donor;
    this.setState({ imgLoader: true });
    data.append("filepond", e.target.files[0]);
    events.paymentDocUpload(data).then((res) => {
      if (res.data.success) {
        donor["file_name"] = res.data.file;
        this.setState({ donor });
      }
      this.setState({ imgLoader: false });
    });
  };

  toggleBeneficiaryModal = () => {
    this.setState(
      { openBeneficiaryModal: !this.state.openBeneficiaryModal },
      () => {
        if (this.state.openBeneficiaryModal === false) {
          this.setState({ beneficiaryData: null });
        }
      }
    );
  };

  openEditBeneficiaryModal = (data, id) => {
    this.toggleBeneficiaryModal();
    data["id"] = id;
    this.setState({ beneficiaryData: data });
  };

  revertBeneficiaryData = (data) => {
    console.log(data);
    if (data.id !== undefined) {
      let beneficiaryArray = this.state.beneficiary;
      beneficiaryArray.splice(data.id, 1, data);
    } else {
      let beneficiaryArray = this.state.beneficiary;
      beneficiaryArray.push(data);
      this.setState({ beneficiary: beneficiaryArray });
    }
  };

  deleteBeneficiary = (data) => {
    if (window.confirm("Are you sure to delete?")) {
      let beneficiaryArray = this.state.beneficiary.filter(
        (ele) => data.beneficiary_name !== ele.beneficiary_name
      );
      console.log(beneficiaryArray);
      this.setState({ beneficiary: beneficiaryArray });
    }
  };

  render() {
    return (
      <>
        {/* <Modal size="lg" isOpen={this.props.showModal} toggle={() => this.props.closeModal()}>
          <ModalHeader toggle={() => this.props.closeModal()}>Donor Diaspora</ModalHeader>
          <Form onSubmit={this.handleSubmit}>
            <LoadingOverlay
              active={this.state.imgLoader}
              spinner
              text="Uploading document..."
            >
              <ModalBody>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Label htmlFor="amount_donated">Amount Donated:</Label>
                      <InputGroup>
                        <InputGroupButtonDropdown addonType="prepend" toggle={() => {
                            this.setState((prevState) => ({
                              showCurrencyMenu: !prevState.showCurrencyMenu,
                            }));
                          }}
                          isOpen={this.state.showCurrencyMenu}>
                          <DropdownToggle split outline color="danger"/>
                          <DropdownMenu>
                          {common.currencySymbols.map((c, index) => (
                              <DropdownItem
                                onClick={() => {
                                  this.setState({ currencySymbol: index });
                                }}
                                key={`c-${index}`}
                              >
                                {c}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                          <Button color="danger">{common.currencySymbols[this.state.currencySymbol]}</Button>
                        </InputGroupButtonDropdown>
                        <Input
                          type="number"
                          name="amount_donated"
                          id="amount_donated"
                          onChange={(event) =>
                            this.handleChange(event, "amount_donated")
                          }
                          invalid={this.state.errors.amount_donated}
                          onKeyDown={ e => ( e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                        />
                        <FormFeedback invalid>
                          {this.state.errors.amount_donated}
                        </FormFeedback>
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <Label htmlFor="payment_method">Payment Methods:</Label>
                      <Input
                        type="select"
                        onChange={(event) => this.handleChange(event, "payment_method")}
                        invalid={this.state.errors.payment_method ? true : false}
                      >
                        <option value="">- Select Payment Type - </option>
                        {this.state.paymentMethods.map((pm, indx) => (
                          <option value={indx} key={`pm${indx}`}>
                            {pm}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback invalid>{this.state.errors.payment_method}</FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col sm={6}>
                      <Label htmlFor="donor_first_name">First Name:</Label>
                      <Input
                        type="text"
                        name="donor_first_name"
                        id="donor_first_name"
                        onChange={(event) =>
                          this.handleChange(event, "donor_first_name")
                        }
                        invalid={this.state.errors.donor_first_name}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.donor_first_name}
                      </FormFeedback>
                    </Col>
                    <Col sm={6}>
                      <Label htmlFor="donor_last_name">Last Name:</Label>
                      <Input
                        type="text"
                        name="donor_last_name"
                        id="donor_last_name"
                        onChange={(event) =>
                          this.handleChange(event, "donor_last_name")
                        }
                        invalid={this.state.errors.donor_last_name}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.donor_last_name}
                      </FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col sm={6}>
                      <Label htmlFor="donor_city">City:</Label>
                      <Input
                        type="text"
                        name="donor_city"
                        id="donor_city"
                        onChange={(event) =>
                          this.handleChange(event, "donor_city")
                        }
                        invalid={this.state.errors.donor_city}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.donor_city}
                      </FormFeedback>
                    </Col>
                    <Col sm={6}>
                      <Label htmlFor="donor_state">State:</Label>
                      <Input
                        type="text"
                        name="donor_state"
                        id="donor_state"
                        onChange={(event) =>
                          this.handleChange(event, "donor_state")
                        }
                        invalid={this.state.errors.donor_state}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.donor_state}
                      </FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={6}>
                      <Label htmlFor="donor_country">Country:</Label>
                      <Input
                        type="text"
                        name="donor_country"
                        id="donor_country"
                        onChange={(event) =>
                          this.handleChange(event, "donor_country")
                        }
                        invalid={this.state.errors.donor_country}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.donor_country}
                      </FormFeedback>
                    </Col>
                    <Col sm={6}>
                      <Label htmlFor="donor_phone">Phone No:</Label>
                      <Input
                        type="text"
                        name="donor_phone"
                        id="donor_phone"
                        onChange={(event) =>
                          this.handleChange(event, "donor_phone")
                        }
                        invalid={this.state.errors.donor_phone}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.donor_phone}
                      </FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={6}>
                      <Label htmlFor="donor_email">Email:</Label>
                      <Input
                        type="text"
                        name="donor_email"
                        id="donor_email"
                        onChange={(event) =>
                          this.handleChange(event, "donor_email")
                        }
                        invalid={this.state.errors.donor_email}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.donor_email}
                      </FormFeedback>
                    </Col>
                    <Col sm={6}>
                      <Label htmlFor="file_name">Upload payment reciept:</Label>
                      <Input
                        type="file"
                        name="file_name"
                        id="file_name"
                        onChange={(e) => this.uploadDocument(e)}
                        invalid={this.state.errors.file_name}
                      />
                      <FormFeedback invalid>
                        {this.state.errors.file_name}
                      </FormFeedback>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm={6}>
                      <Label htmlFor="bank_confirmation_number">Bank Confirmation Number</Label>
                      <Input
                        type="text"
                        name="bank_confirmation_number"
                        id="bank_confirmation_number"
                        onChange={(event) => this.handleChange(event, "bank_confirmation_number")}
                        invalid={this.state.errors.bank_confirmation_number}
                      />
                    </Col>
                    <Col sm={12}>
                      <Label htmlFor="note" style={{color:'red'}}>Note: please say the amount you are giving to each beneficiary if giving to more than one person.</Label>
                      <Input
                        type="textarea"
                        name="note"
                        id="note"
                        onChange={(event) => this.handleChange(event, "note")}
                        rows={4}
                        invalid={this.state.errors.note}
                      />
                    </Col>
                    <Col sm={12}>
                      <Button
                        color="success"
                        type="button"
                        className="mt-4 float-right btn-sm"
                        onClick={() => this.toggleBeneficiaryModal()}
                      >
                        <IoIosAdd /> Add Beneficiary
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                {this.state.beneficiary.length > 0 && (
                  <LoadingOverlay
                    active={false}
                    spinner
                    text="Updating Benificery..."
                  >
                    <Table
                      className="table-responsive customise_table"
                      style={{ minHeight: 100 }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Father's Name</th>
                          <th scope="col">City</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.beneficiary.length > 0 &&
                          this.state.beneficiary.map((ele, index) => (
                            <tr key={index}>
                              <th scope="col">{index + 1}</th>
                              <td scope="col">{ele.beneficiary_name}</td>
                              <td scope="col">{ele.fathers_name}</td>
                              <td scope="col">{ele.beneficiary_city}</td>
                              <td scope="col">${ele.beneficiary_amount}</td>
                              <td scope="col">
                                <Button
                                  color="primary"
                                  className="btn-sm mr-3"
                                  type="button"
                                  onClick={() =>
                                    this.openEditBeneficiaryModal(ele, index)
                                  }
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="danger"
                                  className="btn-sm"
                                  type="button"
                                  onClick={() => this.deleteBeneficiary(ele)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </LoadingOverlay>
                )}
              </ModalBody>
              <ModalFooter className="text-right">
                <Button
                  color="danger"
                  type="button"
                  className="btn-sm"
                  onClick={() => this.props.closeModal()}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  type="submit"
                  className="btn-sm"
                  disabled={this.state.submitted}
                >
                  {this.state.submitted && <Spinner size="sm" />}
                  Submit
                </Button>
              </ModalFooter>
            </LoadingOverlay>
          </Form>
          {this.state.openBeneficiaryModal && (
            <AddEditBeneficiaryFamily
              showModal={this.state.openBeneficiaryModal}
              toggleBeneficiaryModal={this.toggleBeneficiaryModal}
              revertBeneficiaryData={this.revertBeneficiaryData}
              beneficiaryData={this.state.beneficiaryData}
            />
          )}
        </Modal>
        <Modal isOpen={this.state.confirmRegistration}>
            <ModalHeader toggle={() => this.setState({confirmRegistration:true})}>Confirm!</ModalHeader>
            <ModalBody>Do you want to register yourself with TipHub.co to access your beneficiaries for future use?</ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    onClick={() => {
                        this.setState({confirmRegistration:false},()=>this.props.closeModal())
                    }}
                >
                    No
                </Button>
                <Button
                    color="success"
                    onClick={() => {
                        this.props.registerDonor(this.state.donor, this.state.beneficiaries)
                    }}
                >
                    Yes
                </Button>
            </ModalFooter>
        </Modal> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};

export default connect(mapStateToProps)(SaveDonorDetailsFamily);
