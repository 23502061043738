import React, { Component } from "react";
import {
  Button,
  Col,
  FormGroup,
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  InputGroup,
  InputGroupAddon,
  Form,
  FormFeedback,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  InputGroupButtonDropdown,
} from "react-bootstrap";
import events from "../../../services/events";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import { FaFileAlt, FaListAlt, FaEdit } from "react-icons/fa";
import { connect } from "react-redux";
import common from "../../../services/common";
import BeneficiaryDetails from "./BeneficiaryDetails";

class DonorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      loader: true,
      modalSize: "lg",
      beneficiaryData: {},
      openBeneficiaryDetails: false,
      verificationLoader: false,
      paymentMethods: [
        "Zelle",
        "PayPal",
        "ACH",
        "Wire Transfer",
        "Cash Deposit",
        "Check Deposit",
      ],
      showEditAmountForm: false,
      amountFormSubmitted: false,
      showCurrencyMenu: false,
      currencySymbol: 0,
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    if (e.target.type === "button") {
      fields[field] = parseInt(e.target.dataset.index);
    } else {
      fields[field] = e.target.value;
    }
    this.setState({ fields });
  };
  getDonorDetails = () => {
    this.setState({ loader: true });
    events.donorDetails({ id: this.props.id }).then((res) => {
      if (res.data.success) {
        let modalSize = this.state.modalSize;
        if (parseInt(res.data.donor.event.category) === 18) {
          modalSize = "lg";
        }
        this.setState({
          fields: res.data.donor,
          loader: false,
          modalSize,
          currencySymbol: res.data.donor.currency,
        });
      } else if (res.data.error) {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.props.closeModal();
      }
    });
  };
  componentDidMount = () => {
    this.getDonorDetails();
  };

  verifyDonor = (id) => {
    this.setState({ verificationLoader: true });
    events.vefifyDonor({ id: id }).then((res) => {
      if (res.data.success) {
        this.setState({ verificationLoader: false }, () => {
          this.props.closeModal();
          if (this.props.reloadDonors) {
            this.props.reloadDonors();
          }
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  showBeneficiaryDetails = (beneficiaryData) => {
    this.setState({ beneficiaryData, openBeneficiaryDetails: true });
  };

  closeModal = () => {
    this.setState({ beneficiaryData: {}, openBeneficiaryDetails: false });
  };

  saveAmount = (e) => {
    e.preventDefault();
    if (this.validateAmount()) {
      this.setState({ amountFormSubmitted: true });
      events.updateDonorAmount({ fields: this.state.fields }).then((res) => {
        if (res.data.success) {
          this.setState(
            { showEditAmountForm: false, amountFormSubmitted: false },
            () => {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({
            showEditAmountForm: false,
            amountFormSubmitted: false,
          });
        }
      });
    }
  };
  validateAmount = () => {
    let formIsValid = true;
    const errors = {};
    if (!this.state.fields.amount_donated) {
      formIsValid = false;
      errors["amount_donated"] = "Amount can not be blank.";
    }
    this.setState({ errors });
    return formIsValid;
  };
  render() {
    return (
      <>
        <Modal
          size={this.state.modalSize}
          isOpen={this.props.showModal}
          toggle={this.props.closeModal}
        >
          <ModalHeader toggle={this.props.closeModal}>
            Donor Diaspora
          </ModalHeader>
          <ModalBody>
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading..."
            >
              {this.state.fields.event &&
              this.state.fields.event.category != 18 ? (
                <Row>
                  <Col sm={12}>
                    <FormGroup row>
                      <Form.Label
                        htmlFor="donor_first_name"
                        sm={4}
                        className="font-weight-bold"
                      >
                        First Name:
                      </Form.Label>
                      <Col sm={8} className="mt-2">
                        {this.state.fields.donor_first_name
                          ? this.state.fields.donor_first_name
                          : "--"}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Form.Label
                        htmlFor="donor_last_name"
                        sm={4}
                        className="font-weight-bold"
                      >
                        Last Name:
                      </Form.Label>
                      <Col sm={8} className="mt-2">
                        {this.state.fields.donor_last_name
                          ? this.state.fields.donor_last_name
                          : "--"}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Form.Label
                        htmlFor="donor_email"
                        sm={4}
                        className="font-weight-bold"
                      >
                        Email:
                      </Form.Label>
                      <Col sm={8} className="mt-2">
                        {this.state.fields.donor_email
                          ? this.state.fields.donor_email
                          : "--"}
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="amount_donated"
                          sm={4}
                          className="font-weight-bold"
                        >
                          Amount Donated:
                        </Form.Label>
                        {this.state.showEditAmountForm ? (
                          <Col sm={8} className="mt-2">
                            <Form onSubmit={this.saveAmount}>
                              <InputGroup>
                                <Dropdown
                                  addonType="prepend"
                                  toggle={() => {
                                    this.setState((prevState) => ({
                                      showCurrencyMenu:
                                        !prevState.showCurrencyMenu,
                                    }));
                                  }}
                                  isOpen={this.state.showCurrencyMenu}
                                >
                                  <Dropdown.Toggle
                                    split
                                    outline
                                    color="danger"
                                  />
                                  <Dropdown.Menu>
                                    {common.currencySymbols.map((c, index) => (
                                      <Dropdown.Item
                                        onClick={(event) =>
                                          this.handleChange(event, "currency")
                                        }
                                        key={`c-${index}`}
                                        data-index={index}
                                      >
                                        {c}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                  <Button color="danger">
                                    {
                                      common.currencySymbols[
                                        this.state.fields.currency
                                      ]
                                    }
                                  </Button>
                                </Dropdown>
                                <Form.Control
                                  name="amount_donated"
                                  value={this.state.fields.amount_donated}
                                  type="number"
                                  onChange={(event) =>
                                    this.handleChange(event, "amount_donated")
                                  }
                                  onKeyDown={(e) =>
                                    (e.keyCode === 69 || e.keyCode === 190) &&
                                    e.preventDefault()
                                  }
                                  invalid={
                                    this.state.errors.amount_donated
                                      ? true
                                      : false
                                  }
                                />
                                <InputGroup addonType="append">
                                  <Button
                                    color="success"
                                    type="submit"
                                    id="save-amount"
                                    name="save-amount"
                                    disabled={this.state.amountFormSubmitted}
                                  >
                                    {this.state.amountFormSubmitted
                                      ? "Saving.."
                                      : "Save"}
                                  </Button>
                                </InputGroup>
                              </InputGroup>
                              <Form.Control.Feedback type="invalid">
                                {this.state.errors.amount_donated}
                              </Form.Control.Feedback>
                            </Form>
                          </Col>
                        ) : (
                          <Col sm={8} className="mt-2">
                            {this.state.fields.amount_donated
                              ? `${
                                  common.currencySymbols[
                                    this.state.fields.currency
                                  ]
                                } ${this.state.fields.amount_donated}`
                              : "--"}{" "}
                            {this.props.editAmount === true && (
                              <Button
                                type="button"
                                id="edit-amount"
                                name="edit-amount"
                                className="btn-sm"
                                color="warning"
                                style={{ padding: `0px 3px 3px 4px` }}
                                onClick={() =>
                                  this.setState({ showEditAmountForm: true })
                                }
                              >
                                <FaEdit />
                              </Button>
                            )}
                          </Col>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="payment_method"
                          sm={5}
                          className="font-weight-bold"
                        >
                          Payment Method:
                        </Form.Label>
                        <Col sm={7} className="mt-2">
                          {this.state.fields.payment_method !== null
                            ? this.state.paymentMethods[
                                this.state.fields.payment_method
                              ]
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="donor_first_name"
                          sm={4}
                          className="font-weight-bold"
                        >
                          First Name:
                        </Form.Label>
                        <Col sm={8} className="mt-2">
                          {this.state.fields.donor_first_name
                            ? this.state.fields.donor_first_name
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="donor_last_name"
                          sm={4}
                          className="font-weight-bold"
                        >
                          Last Name:
                        </Form.Label>
                        <Col sm={8} className="mt-2">
                          {this.state.fields.donor_last_name
                            ? this.state.fields.donor_last_name
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="donor_city"
                          sm={4}
                          className="font-weight-bold"
                        >
                          City:
                        </Form.Label>
                        <Col sm={8} className="mt-2">
                          {this.state.fields.donor_city
                            ? this.state.fields.donor_city
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>

                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="donor_country"
                          sm={4}
                          className="font-weight-bold"
                        >
                          Country:
                        </Form.Label>
                        <Col sm={8} className="mt-2">
                          {this.state.fields.donor_country
                            ? this.state.fields.donor_country
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="donor_state"
                          sm={4}
                          className="font-weight-bold"
                        >
                          State:
                        </Form.Label>
                        <Col sm={8} className="mt-2">
                          {this.state.fields.donor_state
                            ? this.state.fields.donor_state
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="donor_phone"
                          sm={4}
                          className="font-weight-bold"
                        >
                          Phone No:
                        </Form.Label>
                        <Col sm={8} className="mt-2">
                          {this.state.fields.donor_phone
                            ? this.state.fields.donor_phone
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="donor_email"
                          sm={4}
                          className="font-weight-bold"
                        >
                          Email:
                        </Form.Label>
                        <Col sm={8} className="mt-2">
                          {this.state.fields.donor_email
                            ? this.state.fields.donor_email
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="bank_confirmation_number"
                          sm={6}
                          className="font-weight-bold"
                        >
                          Bank Confirmation Number:
                        </Form.Label>
                        <Col sm={6} className="mt-2">
                          {this.state.fields.bank_confirmation_number
                            ? this.state.fields.bank_confirmation_number
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      {this.state.fields.file_name && (
                        <FormGroup row>
                          <Form.Label
                            htmlFor="donor_email"
                            sm={4}
                            className="font-weight-bold"
                          >
                            Payment receipt
                          </Form.Label>
                          <Col sm={8}>
                            <a
                              href={`${this.props.apiUrl}/web/events/${this.state.fields.file_name}`}
                              target="_blank"
                            >
                              <FaFileAlt size="5em" />
                            </a>
                          </Col>
                        </FormGroup>
                      )}
                    </Col>
                    <Col sm={12}>
                      <FormGroup row>
                        <Form.Label
                          htmlFor="note"
                          sm={2}
                          className="font-weight-bold"
                        >
                          Note:
                        </Form.Label>
                        <Col sm={10} className="mt-2">
                          {this.state.fields.note
                            ? this.state.fields.note
                            : "--"}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {this.state.fields.beneficiaries &&
                      this.state.fields.beneficiaries.length > 0 && (
                        <Table className="table table-responsive customise_table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Father's Name</th>
                              <th scope="col">City</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.fields.beneficiaries &&
                              this.state.fields.beneficiaries.length > 0 &&
                              this.state.fields.beneficiaries.map(
                                (ele, index) => (
                                  <tr key={index}>
                                    <th scope="col">{index + 1}</th>
                                    <td scope="col">{ele.beneficiary_name}</td>
                                    <td scope="col">{ele.fathers_name}</td>
                                    <td scope="col">{ele.beneficiary_city}</td>
                                    <td scope="col">
                                      {
                                        common.currencySymbols[
                                          this.state.fields.currency
                                        ]
                                      }{" "}
                                      {ele.beneficiary_amount
                                        ? ele.beneficiary_amount
                                        : 0.0}
                                    </td>
                                    <td scope="col">
                                      <Button
                                        type="button"
                                        className="btn-sm mr-1"
                                        color="primary"
                                        onClick={() =>
                                          this.showBeneficiaryDetails(ele)
                                        }
                                      >
                                        <FaListAlt size="1.1em" />
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </Table>
                      )}
                  </Row>
                </>
              )}
            </LoadingOverlay>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                this.props.closeModal();
              }}
              type="button"
            >
              Cancel
            </Button>
            {parseInt(this.state.fields.verified) !== 1 && (
              <Button
                color="success"
                onClick={() => {
                  this.verifyDonor(this.state.fields.id);
                }}
                type="button"
                disabled={this.state.verificationLoader}
              >
                {this.state.verificationLoader && (
                  <Spinner className="mr-1" color="light" size="sm" />
                )}
                Verify Donor
              </Button>
            )}
          </ModalFooter>
        </Modal>
        {this.state.openBeneficiaryDetails && (
          <BeneficiaryDetails
            showModal={this.state.openBeneficiaryDetails}
            beneficiaryData={this.state.beneficiaryData}
            closeModal={this.closeModal}
            donorDetails={this.state.fields}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
  };
};
export default connect(mapStateToProps)(DonorDetails);
