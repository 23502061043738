import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, Button, Row, Col } from 'react-bootstrap'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { FaTwitter, FaInstagram } from "react-icons/fa"
import AOS from "aos"
import user from "../services/user"
import Login from "../views/Front/Home/Login";
import TwoFactorValidator from "../views/Front/Common/TwoFactorValidator"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Signup from "../views/Front/Home/Signup"
import UpdateUsername from "../views/Front/Home/UpdateUsername";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FrontLayout(props) {
  const [apiUrl] = useSelector((state) => [state.apiUrl]);
  const [token] = useSelector((state) => [state.token]);

  const [tiphubHeader, setHeader] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showTwoFactorValidatorModal, setShowTwoFactorValidatorModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showUpdateUsernameModal, setUpdateUsernameModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const dispatchLogin = () => {
    localStorage.setItem("token", userData.token);
    localStorage.setItem("userType", userData.user_type);
    localStorage.setItem("userName", userData.username);
    localStorage.setItem("uName", userData.name);
    localStorage.setItem("userId", userData.user_id);
    localStorage.setItem("userImage", userData.image);
    localStorage.setItem("userEmail", userData.email);

    let payload = {
      token: userData.token,
      userName: userData.username,
      uName: userData.name,
      userId: userData.user_id,
      userType: userData.user_type,
      userImage: userData.image,
      userEmail: userData.email,
    };
    dispatch({ type: "login", ...payload });

    navigate("/home");
  }
  useEffect(() => {
    if (userData !== null) {
      axios.post(apiUrl + "/event/check-payment-app", { send_otp: 'Y' }, {
        headers: {
          'X-Api-Key': 'Bearer ' + userData.token
        }
      }).then(function (res) {
        if (res.data.success) {
          if (res.data.is_cc_added) {
            setShowTwoFactorValidatorModal(true);
          }
          else {
            dispatchLogin();
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }, [userData]);
  const changeBackground = () => {
    if (window.scrollY >= 280) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 1200,
    })
  })

  const logout = () => {
    //setLoader(true);
    user
      .logout()
      .then((res) => {
        if (res.data.success) {

        }
      })
      .catch(() => {

      });
  };
  /* useEffect(() => {
    axios.post(apiUrl + "/common/load-edgepay-script").then(function (res) {
      if (res.data.success) {
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", res.data.url);
        scriptEle.setAttribute("type", "text/javascript");
        scriptEle.setAttribute("async", true);
        document.body.appendChild(scriptEle);
      }
    }).catch(function (error) {
      console.log(error);
    });
  }, []) */
  window.addEventListener('scroll', changeBackground);
  return (
    <div>
      <ToastContainer />
      <div className={tiphubHeader ? 'tiphubHeader sticky' : 'tiphubHeader'}>
        <Container>
          <Navbar collapseOnSelect expand="md">
            <Navbar.Brand href='/home'>
              <img src="/assets/tiphub-app.png" className="img-fluid" alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {token ? <Nav className="ms-auto me-auto">
                <Nav.Item>
                  <Link to="/">Profile</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/events">Events</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/reports">Reports</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/settings">Settings</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/payments">Payments</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/discover-events">Discover Events</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/pricing">Pricing</Link>
                </Nav.Item>
              </Nav> : <Nav className="ms-auto me-auto">
                <Nav.Item>
                  <Link to="/">Home</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/discover-events">Discover Events</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/pricing">Pricing</Link>
                </Nav.Item>
              </Nav>}
              <Nav>
                {token ? <Link className="btn btn-link" to="/logout">Logout</Link> : <Button variant="link" onClick={() => setShowLoginModal(true)}>Login</Button>}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>

      <Outlet context={[showSignupModal, setShowSignupModal]} />

      <div className="tiphub-footer">
        <Container>
          <Row className="mt-2 mb-4">
            <Col md="2" className="text-left solutions" data-aos="fade-right">
              <h5 className="mb-3"><b>Solutions</b></h5>
              <ul>
                <li>
                  <Link to="/terms-and-conditions">How It works?</Link>
                </li>
                <li>
                  <Link to="/about">About </Link>
                </li>
                <li>
                  <Link to="/discover-events">Discover Events</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
              </ul>
            </Col>
            <Col md="8" className="text-center" data-aos="zoom-in">
              <img src="/assets/tiphub-app.png" className="img-fluid mb-4" alt="Logo" />
              <p className="mb-4">Tiphub makes it as easy as possible for you to receive money as an organizer, creator or individual for the causes you care about the most.</p>
              <div>
                <ul className="tiphub-social-links">
                  <li><Link to="https://twitter.com/Tiphub_co" target="_blank"><FaTwitter /></Link></li>
                  <li><Link to="https://www.instagram.com/tiphub.co/" target="_blank"><FaInstagram /></Link></li>
                </ul>
              </div>
            </Col>
            <Col md="2" className="text-left" data-aos="fade-left">
              <h5 className="mb-3"><b>Support</b></h5>
              <ul>
                <li>
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/unsubscribe">Unsubscribe</Link>
                </li>
                <li>
                  <Link to="/policy">Acceptance Policy</Link>
                </li>
              </ul>
            </Col>
          </Row>
          <div className="footer-bottom">
            <Row>
              <Col sm="12" className="text-center mt-5 pt-5 footer-bottom-paragraph">
                <span>
                  Connect with us tiphub.co / &#169; tiphub.co All rights
                  reserved
                </span>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {showLoginModal && <Login
        showModal={showLoginModal}
        closeModal={() => setShowLoginModal(false)}
        setUserData={setUserData}
        showSignupModal={setShowSignupModal}
        showUpdateUsernameModal={setUpdateUsernameModal}
      />}
      {showTwoFactorValidatorModal && (
        <TwoFactorValidator
          showModal={showTwoFactorValidatorModal}
          closeModal={() => setShowTwoFactorValidatorModal(false)}
          dispatchLogin={dispatchLogin}
          token={userData.token}
        />
      )}
      {showSignupModal && <Signup
        showModal={showSignupModal}
        closeModal={() => setShowSignupModal(false)}
        showLoginModal={setShowLoginModal}
      />}
      {showUpdateUsernameModal && <UpdateUsername
        showModal={showUpdateUsernameModal}
        closeModal={() => setUpdateUsernameModal(false)}
      />}
    </div>

  );
}

export default FrontLayout;
