import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Form,
  FormGroup,
  Button,
  Badge,
  FloatingLabel,
} from "react-bootstrap";
import events from "../../../services/events";
import { toast } from "react-toastify";
import common from "../../../services/common";
import Pagination from "react-js-pagination";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { connect } from "react-redux";
import Carousel from "react-elastic-carousel";
import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import DoughnutChart from "./DoughnutChart";

class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        /* date_range:`${moment().subtract(29, 'days').format('MM-DD-YYYY')} to ${moment().format('MM-DD-YYYY')}` */
      },
      loading: false,
      errors: {},
      showReport: false,
      activeTab: "1",
      eventList: [],
      eventAppList: [],
      amount: 0,
      totalVisitors: 0,
      topTippers: [],
      traffic: [],
      repeatTippers: [],
      supporters: [],
      fromDevice: [],
      tippingApps: [],
      avgTippingAmount: 0,
      ranges: {},
      currentSelectedOption: "event-last_30_days",
      pageNo: 1,
      totalSupportersCount: 0,
      targetAcheived: 0,
      totalCollectedAmount: 0,
      trafficPageNo: 1,
      trafficPageDetails: {},
      initialLoader: true,
      paymentsThroughApps: [],
      totalAmountReceived: 0,
      breakPoints: [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 },
      ],
      showChart: false,
      chartData: [
        {
          name: "Page A",
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: "Page B",
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: "Page C",
          uv: 2000,
          pv: 6000,
          amt: 2290,
        },
        {
          name: "Page D",
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: "Page E",
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: "Page F",
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: "Page G",
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ],
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    if (field === "event_id" && e.target.value !== "") {
      fields[field] = e.target.value;
      let selectedEvent = this.state.eventList.filter(
        (event) => parseInt(event.id) === parseInt(e.target.value)
      );
      this.setState({ fields, amount: selectedEvent[0].target_amount }, () => {
        this.handleSubmit();
      });
    } else if (field === "service_id") {
      fields[field] = e.target.value;
      this.handleSubmit();
    } else {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  componentDidMount = () => {
    this.getOverAllReport();
    events
      .list({ published: 1 })
      .then((res) => {
        if (res.data.success) {
          this.setState({ eventList: res.data.events }, () => {
            if (res.data.events.length > 0) {
              let fields = this.state.fields;
              fields["event_id"] = res.data.events[0].id;
              this.setState(
                { fields, amount: res.data.events[0].target_amount, eventAppList:  res.data.events[0].apps},
                () => {
                  this.handleSubmit();
                }
              );
            } else {
              this.setState({ initialLoader: false });
            }
          });
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  getOverAllReport = () => {
    events
      .getOverAllReport()
      .then((res) => {
        if (res.data.success) {
          let chartData = [];
          let showChart = false;
          if (res.data.chart_records.length > 0) {
            res.data.chart_records.forEach((cr, index) => {
              chartData[index] = {
                name: cr.added_on,
                pv: parseInt(cr.total_amount),
                amt: cr.total_amount,
              };
            });
          }
          this.setState({
            totalAmountReceived:
              res.data.total_amount_received !== null
                ? res.data.total_amount_received
                : 0,
            paymentsThroughApps:
              res.data.total_amount_received !== null
                ? res.data.payments_through_app
                : [],
            chartData,
            showChart,
          });
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  getSupporters = () => {
    events
      .getSupporters({
        event_id: this.state.fields.event_id,
        pageSize: 30,
        page: this.state.pageNo,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            totalSupportersCount: res.data.supporters.pages.totalCount,
            supporters: res.data.supporters.records,
          });
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  handleSubmit = (e = null) => {
    if (e) e.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });
      events
        .getReports({ fields: this.state.fields })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              totalVisitors: res.data.totalVisitors,
              topTippers: res.data.topTippers,
              traffic: res.data.traffic.records,
              trafficPageDetails: res.data.traffic.pages,
              repeatTippers: res.data.repeatTippers,
              fromDevice: res.data.fromDevice,
              avgTippingAmount:
                res.data.avgTippingAmount !== null
                  ? parseFloat(res.data.avgTippingAmount).toFixed(2)
                  : 0,
              tippingApps: res.data.tippingApps,
              supporters: res.data.supporters.records,
              totalSupportersCount: res.data.supporters.pages.totalCount,
              initialLoader: false,
            });
            let percentage = 0;
            if (res.data.totalTransactionSum !== null) {
              this.setState({
                totalCollectedAmount: res.data.totalTransactionSum,
              });
              percentage = (
                (parseFloat(res.data.totalTransactionSum) /
                  parseFloat(this.state.amount)) *
                100
              ).toFixed(2);
            } else {
              this.setState({ totalCollectedAmount: 0 });
              percentage = 0;
            }
            this.setState({ targetAcheived: percentage }, () => {
              this.setState({
                showReport: true,
                loading: false,
                initialLoader: false,
              });
            });
          } else if (res.data.error) {
            this.setState({ loading: false }, () => {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          }
        })
        .catch(() => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false, initialLoader: false });
        });
    }
  };

  validateForm = () => {
    let formIsValid = true;
    const errors = {};
    if (!this.state.fields.event_id) {
      errors["event_id"] = "Please choose event from this list.";
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  };

  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.getSupporters();
    });
  };

  handleCallback = (startDate, endDate) => {
    let date_range =
      startDate.format("MM-DD-YYYY") + " to " + endDate.format("MM-DD-YYYY");
    let fields = this.state.fields;
    fields["date_range"] = date_range;
    this.setState({ fields }, () => {
      this.handleSubmit();
    });
  };

  chooseButton = (e) => {
    e.target.className = "mb-2 btn btn-block btn-info";
    if (this.state.currentSelectedOption !== null) {
      document.getElementById(this.state.currentSelectedOption).className =
        "mb-2 btn btn-outline-primary btn-block";
    }
    let fields = this.state.fields;
    fields["date_range"] = e.target.dataset.value;
    this.setState({ fields, currentSelectedOption: e.target.id }, () => {
      this.handleSubmit();
    });
  };

  handleTrafficPageChange = (pageNo) => {
    this.setState({ trafficPageNo: pageNo }, () => {
      this.getTraffic();
    });
  };

  getTraffic = () => {
    events
      .getTraffic({
        event_id: this.state.fields.event_id,
        pageSize: 30,
        page: this.state.trafficPageNo,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            trafficPageDetails: res.data.traffic.pages,
            traffic: res.data.traffic.records,
          });
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  render() {
    return (
      <div className="event-section">
        <Row>
          <Col sm="12">
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Form.Group as={Col} md={6}>
                  <FloatingLabel                 
                    label="Event"
                    className="mb-3"
                  >
                    <Form.Select
                      type="select"
                      name="event_id"
                      className="mb-2"
                      onChange={(e) => {
                        this.handleChange(e, "event_id");
                      }}
                      value={
                        this.state.fields["event_id"]
                          ? this.state.fields["event_id"]
                          : ""
                      }
                      invalid={this.state.errors["event_id"] ? true : false}
                    >
                      <option value="">-- Select any Event --</option>
                      {this.state.eventList.map((ele, index) => (
                        <option key={index} value={ele.id}>
                          {ele.title}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control.Feedback>
                      {this.state.errors["event_id"]}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <FloatingLabel                 
                    label="Payment App"
                    className="mb-3"
                  >
                    <Form.Select
                      type="select"
                      name="service_id"
                      className="mb-2"
                      onChange={(e) => {
                        this.handleChange(e, "service_id");
                      }}
                      value={
                        this.state.fields["service_id"]
                          ? this.state.fields["service_id"]
                          : ""
                      }
                      invalid={this.state.errors["service_id"] ? true : false}
                    >
                      <option value="">All</option>
                      {this.state.eventAppList.map((ele, index) => (
                        <option key={index} value={ele.service_id}>
                          {ele.appname.name}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
             
            </Form>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={6}>
            <Card body className="report-graph">
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col>
                      <h5 className="text-left">
                        Total Received
                        <Badge
                          className="ms-2"
                          bg={
                            this.state.totalCollectedAmount > this.state.amount
                              ? "danger"
                              : "success"
                          }
                        >
                          {(this.state.targetAcheived - 100).toFixed(2)}%
                          {this.state.totalCollectedAmount >
                          this.state.amount ? (
                            <BsArrowDown />
                          ) : (
                            <BsArrowUp />
                          )}{" "}
                        </Badge>
                      </h5>
                    </Col>
                    <Col>
                      <h2 className="text-end">
                        <b>${this.state.totalAmountReceived}</b>
                      </h2>
                    </Col>
                  </Row>

                  <ResponsiveContainer width="100%" height={175}>
                    <AreaChart
                      width={500}
                      height={200}
                      data={this.state.chartData}
                      syncId="anyId"
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="pv"
                        stroke="#82ca9d"
                        fill="#82ca9d"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Col>
                <Col sm={12} className="payment-icon-box">
                  <h6 className="payment-received">
                    Payment received from following
                  </h6>
                  <div className="payment-icon">
                    <ul>
                      {this.state.paymentsThroughApps.map((pta, index) => (
                        <li key={`pta-${index}`}>
                          {pta.appname !== null && pta.appname.icon !== null ? (
                            <img src={`/assets/${pta.appname.icon}`} />
                          ) : (
                            <img
                              src='/assets/no-profile-image.png'
                              // width={30}
                              // height={30}
                              // className="rounded-circle"
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={6}>
            <Card body className="report-graph">
              <Row>
                <Col>
                  <h5 className="text-left">
                    Goal Analyis
                    <Badge
                      className="ms-2"
                      bg={
                        this.state.totalCollectedAmount > this.state.amount
                          ? "success"
                          : "danger"
                      }
                    >
                      {(this.state.targetAcheived - 100).toFixed(2)}%
                    </Badge>  
                  </h5>
                  <div className="row d-flex justify-content-center">
                    <div className="mt-3" >
                    <DoughnutChart collectedAmt={this.state.totalCollectedAmount} amt={this.state.amount} percentage={this.state.targetAcheived} />
                    </div>
                  </div>
                </Col>
                <Col className="text-center v-line pt-2">
                  <h2>
                    <b>${this.state.totalCollectedAmount}</b>
                  </h2>
                  <h5>raised of</h5>
                  <h2 className="raised-off-price">
                    <b>${this.state.amount}</b>
                  </h2>
                  <Button
                    variant="danger"
                    type="button"
                    block
                    className="goal mt-3"
                  >
                    Achieved - {this.state.targetAcheived}%
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <div className="reports-card">
              <Row>
                <Col lg={3} md={6}>
                  <Card body inverse color="primary">
                    <Row>
                      <Col sm={6} xs={6}>
                        <Card.Title tag="h2">
                          <b>{this.state.totalVisitors}</b>
                        </Card.Title>
                        <Card.Text>Profile Visited</Card.Text>
                      </Col>
                      <Col sm={6} xs={6} className="d-flex">
                        <div className="card-img">
                          <img src="/assets/reports/reports-card-icon1.png" />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.fromDevice.length > 0 &&
                  this.state.fromDevice.map((fd, index) => (
                    <Col key={index} lg={3} md={6}>
                      <Card
                        body
                        inverse
                        color={index === 0 ? "warning" : "danger"}
                      >
                        <Row>
                          <Col sm={6} xs={6}>
                            <Card.Title tag="h2">
                              <b>{fd.totalVisitors}</b>
                            </Card.Title>
                            <Card.Text>
                              Visitors from{" "}
                              {fd.device === "Mobile"
                                ? "MOB"
                                : fd.device.toUpperCase()}
                            </Card.Text>
                          </Col>
                          <Col sm={6} xs={6} className="d-flex">
                            <div className="card-img">
                              {fd.device === "Pc" ? (
                                <img src="/assets/reports/reports-card-icon2.png" />
                              ) : (
                                <img src="/assets/reports/reports-card-icon3.png" />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
                <Col lg={3} md={6}>
                  <Card body inverse color="success">
                    <Row>
                      <Col sm={7} xs={7}>
                        <Card.Title tag="h2">
                          <b>${this.state.avgTippingAmount}</b>
                        </Card.Title>
                        <Card.Text>Average Tipping</Card.Text>
                      </Col>
                      <Col sm={5} xs={5} className="d-flex">
                        <div className="card-img">
                          <img src="/assets/reports/reports-card-icon4.png" />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {this.state.tippingApps.length > 0 && (
          <Row className="mt-4">
            <Col sm={12}>
              <h4 className="text-center mb-4">
                <b>Tipping App Clicked</b>
              </h4>
            </Col>
            <Col sm={12}>
              <div className="payment-apps-slider">
                <Carousel breakPoints={this.state.breakPoints}>
                  {this.state.tippingApps.map((tp, index) => (
                    <div className="item" key={`tpc-${index}`}>
                      <Row>
                        <Col sm={5}>
                          {tp.servicedetails !== null ? (
                            <img src={`/assets/${tp.servicedetails.icon}`} />
                          ) : (
                            <img
                              src={`/assets/no-image.jpeg`}
                              width={30}
                              height={30}
                              className="rounded-circle"
                            />
                          )}
                        </Col>
                        <Col sm={7}>
                          <h4>
                            <b>{tp.totalVisitors}</b>
                          </h4>
                          {tp.servicedetails !== null && (
                            <p>{tp.servicedetails.name}</p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col sm={12}>
            <h4 className="text-center">
              <b>Traffic Report</b>
            </h4>
          </Col>
          <Col className="mt-3">
            <Tabs
              defaultActiveKey="Top Tippers"
              id="uncontrolled-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="Top Tippers" title="Top Tippers">
                <div className="table_minimise">
                  <Table
                    className="table-responsive customise_table m-table"
                    style={{ minHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.topTippers.length > 0 ? (
                        this.state.topTippers.map((t, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{t.name}</td>
                            <td>${t.amount}</td>
                            <td>
                              {common.customeFormat(t.added_on, "MM dd, yyyy")}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={4}>
                            No record found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Tab>

              <Tab eventKey="Repeat Tippers" title="Repeat Tippers">
                <div className="table_minimise">
                  <Table
                    className="table-responsive customise_table  m-table"
                    style={{ minHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Payment Done</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.repeatTippers.length > 0 ? (
                        this.state.repeatTippers.map((rt, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{rt.name}</td>
                            <td>{rt.totalDonations} times</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={4}>
                            No record found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Tab>

              <Tab eventKey="Traffic" title="Traffic">
                <div className="table_minimise">
                  <Table
                    className="table-responsive customise_table  m-table"
                    style={{ minHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Device</th>
                        <th scope="col">IP Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.traffic.length > 0 ? (
                        this.state.traffic.map((t, index) => (
                          <tr key={index}>
                            <th scope="row">
                              {(this.state.trafficPageNo - 1) * 30 + index + 1}
                            </th>
                            <td>
                              {common.customeFormat(t.added_on, "MM dd, yyyy")}
                            </td>
                            <td>
                              {t.device === "Pc"
                                ? t.device.toUpperCase()
                                : t.device}
                            </td>
                            <td>{t.ip_address}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={4}>
                            No record found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                {this.state.trafficPageDetails.totalCount > 30 && (
                  <Row>
                    <Col md={12}>
                      <Pagination
                        activePage={this.state.trafficPageNo}
                        itemsCountPerPage={30}
                        totalItemsCount={
                          this.state.trafficPageDetails.totalCount
                        }
                        pageRangeDisplayed={5}
                        onChange={(e) => this.handleTrafficPageChange(e)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </Col>
                  </Row>
                )}
              </Tab>

              <Tab eventKey="Supports" title="Supports">
                <div className="table_minimise">
                  <Table
                    className="table-responsive customise_table  m-table"
                    style={{ minHeight: 300 }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Supporter's Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payment Source</th>
                        <th scope="col">Created on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.supporters.length > 0 ? (
                        this.state.supporters.map((suppoter, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{suppoter.name}</td>
                            <td>${suppoter.amount}</td>
                            <td>{suppoter.appname && suppoter.appname.name}</td>
                            <td>
                              {common.customeFormat(
                                suppoter.added_on,
                                "MM dd, yyyy"
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={5}>
                            No record found.
                          </td>
                        </tr>
                      )}
                      {this.state.totalSupportersCount > 30 && (
                        <tr>
                          <td colSpan={5} className="border-top-0">
                            <Pagination
                              activePage={this.state.pageNo}
                              itemsCountPerPage={30}
                              totalItemsCount={this.state.totalSupportersCount}
                              pageRangeDisplayed={5}
                              onChange={(e) => this.handlePageChange(e)}
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    baseUrl: state.baseUrl,
  };
};
export default connect(mapStateToProps)(Events);
