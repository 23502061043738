import React, { Component } from "react";
import { Spinner, Button, Carousel, Row, Col, Badge } from "react-bootstrap";
import events from "../../../services/events";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ManageGallery from "./ManageGallery";
import { FaDotCircle } from "react-icons/fa";
import Slider from "react-slick";
import { CgHello } from "react-icons/cg";

class EventGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      manageGalleryModal: false,
      loader: true,
      activeIndex: 0,
      animating: false,
      eventCategories: [],
      galleria: [],
    };
  }
  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };
  setAnimating = (animate) => {
    this.setState({ animating: animate });
  };
  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  toggleManageGallery = () => {
    this.setState((prevState) => ({
      manageGalleryModal: !prevState.manageGalleryModal,
    }));
  };
  componentDidMount() {
    this.setState({ eventData: this.props.eventData });
    events
      .getGallery({ event_id: this.props.eventDetails.id })
      .then((res) => {
        if (res.data.success) {
          let items = [];
          let counter = 0;
          if (this.props.eventDetails.image !== null) {
            items[counter] = {
              html: (
                <React.Fragment>
                  <img
                    className="d-block carousel-img"
                    src={`${this.props.apiUrl}/web/events/${this.props.eventDetails.image}`}
                  />
                </React.Fragment>
              ),
              key: counter,
            };
            counter++;
          }
          this.setState({ galleria: res.data.gallery });
          res.data.gallery.forEach((image, index) => {
            items[counter] = {
              html: (
                <React.Fragment>
                  <img
                    className="d-block carousel-img"
                    src={`${this.props.apiUrl}/web/events/${image.file_name}`}
                    alt="First slide"
                  />
                  {/* <div dangerouslySetInnerHTML={{ __html: banner.html }} /> */}
                </React.Fragment>
              ),
              key: counter,
            };
            counter++;
          });
          this.setState({ items, loader: false });
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Unexpected Error!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    events.getEventCategory().then((res) => {
      if (res.data.success) {
        this.setState({ eventCategories: res.data.categories });
      }
    });
  }

  updateSlider = (gallery) => {
    let items = [];
    let counter = 0;
    if (this.props.eventDetails.image !== null) {
      items[counter] = {
        html: (
          <React.Fragment>
            <img
              className="d-block carousel-img"
              src={`${this.props.apiUrl}/web/events/${this.props.eventDetails.image}`}
            />
          </React.Fragment>
        ),
        key: counter,
      };
      counter++;
    }
    gallery.forEach((image, index) => {
      items[counter++] = {
        html: (
          <React.Fragment>
            <img
              className="d-block carousel-img"
              src={`${this.props.apiUrl}/web/events/${image.file_name}`}
              alt="First slide"
            />
            {/* <div dangerouslySetInnerHTML={{ __html: banner.html }} /> */}
          </React.Fragment>
        ),
        key: counter,
      };
    });
    this.setState({ items, loader: false });
  };
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <Row className="mb-2">
          <Col md="8">
            {this.props.eventDetails.category !== null && (
              <Badge bg="warning" className="mb-2 p-1 me-3">
                <strong>
                  <FaDotCircle />
                </strong>{" "}
                {this.props.eventDetails.categoryDetails &&
                  this.props.eventDetails.categoryDetails.name}
              </Badge>
            )}

            {this.props.userId &&
              parseInt(this.props.userId) ===
              parseInt(this.props.eventDetails.user_id) &&
              this.props.eventDetails.recurring !== "" &&
              this.props.eventDetails.recurring !== null && (
                <Badge bg="info" className="mb-2 p-1 mr-3">
                  <strong>
                    <FaDotCircle /> Type:{" "}
                  </strong>{" "}
                  {this.props.eventDetails.recurring === "0"
                    ? "One time"
                    : "Recurring"}
                </Badge>
              )}

            {parseInt(this.props.eventDetails.fundraising_for) === 1 &&
              this.props.eventDetails.ein_number !== "" &&
              this.props.eventDetails.ein_number !== null && (
                <Badge bg="success" className="mb-2 p-1">
                  <strong>
                    <FaDotCircle /> EIN:{" "}
                  </strong>{" "}
                  {this.props.eventDetails.ein_number}{" "}
                  {/* {this.props.eventDetails.non_profit_name !== "" &&
                    `(${this.props.eventDetails.non_profit_name})`} */}
                </Badge>
              )}
          </Col>
          <Col md="4" className="text-end">
            {this.props.userId &&
              parseInt(this.props.userId) ===
              parseInt(this.props.eventDetails.user_id) && (
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm"
                  onClick={() => this.toggleManageGallery()}
                >
                  Manage Gallery
                </Button>
              )}
          </Col>
        </Row>

        {this.state.loader ? (
          <div className="text-center m-5">
            <Spinner size="lg" style={{ width: 80, height: 80 }} />
          </div>
        ) : this.state.items.length === 1 ? (
          <p className="text-center">
            {this.props.eventDetails.image !== null && (
              <img
                className="img-fluid"
                src={`${this.props.apiUrl}/web/events/${this.props.eventDetails.image}`}
              />
            )}
          </p>
        ) : (
          <Slider {...settings}>
            {this.state.galleria.map((image, i) => {
              return (
                <img
                  className="d-block carousel-img"
                  src={`${this.props.apiUrl}/web/events/${image.file_name}`}
                  alt="First slide"
                  key={`c-galary-key-${i}`}
                />
              );
            })}
          </Slider>
        )}
        {this.state.manageGalleryModal && (
          <ManageGallery
            showModal={this.state.manageGalleryModal}
            closeModal={this.toggleManageGallery}
            eventDetails={this.props.eventDetails}
            updateSlider={this.updateSlider}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
    userId: state.userId,
  };
};
export default connect(mapStateToProps)(EventGallery);
