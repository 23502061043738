import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { BsArrow90DegRight } from "react-icons/bs";
import { toast } from "react-toastify";

const Tutorial = () => {
  const copyContent = (content) => {
    navigator.clipboard.writeText(content).then(
      () => {
        toast.success("Content copied to clipboard.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  return (
    <>
      <Helmet>
        <title>Home - Tiphub</title>
      </Helmet>
      <div className="defalut-overview tiphub-banner ">
      <div className="tiphub-banner-bg h-100"></div>
        <Container>
          <Row>
            <Col sm="12">
              <h2>
                How to forward Paypal, CashApp and Venmo payment emails using
                filters in Outlook.
              </h2>
              <p>
                Set up your criteria to organize incoming mail for Paypal,
                CashApp and Venmo
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className="mb-2">
                <Card.Header>Steps to link your payment emails</Card.Header>
                <Card.Body>
                  <p>
                    &bull; First step would be to link all your emails to Tiphub
                    account so that you can synchronize the payments from all
                    your emails. This needs verification of all your emails.{" "}
                  </p>
                  <p>
                    &bull; To do this, login to your Tiphub account, go to{" "}
                    <strong>Settings</strong> &#8594;{" "}
                    <strong>Link Emails</strong> and follow the instructions to
                    verify the emails.
                  </p>
                  <p className="text-center">
                    <img
                      src="/assets/link-email.png"
                      alt=""
                      width="60%"
                      className="mb-3"
                    />
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className="mb-2">
                <Card.Header>Steps to follow:</Card.Header>
                <Card.Body>
                  <p>
                    &bull; Create an auto-forward filter: Select the{" "}
                    <strong>Settings gear</strong> &#8594; View all Outlook
                    settings{" "}
                  </p>
                  <p>
                    &bull; A popup will be shown where you need to select Mail
                    &#8594; Rules &#8594; Add New Rule{" "}
                  </p>
                  <p>
                    &bull; A new form will be opened:
                    <p className="pl-2 mb-0">
                      {" "}
                      &bull; Write some name in Rule Name field{" "}
                    </p>
                    <p className="pl-2">
                      {" "}
                      &bull; Under 'Add a condition' choose 'Sender address
                      includes'. Add all three emails (
                      <strong>
                        venmo@venmo.com, cash@square.com, service@paypal.com
                      </strong>
                      ).{" "}
                    </p>
                    <p>
                      &bull; Under 'Add an action', choose 'Forward to' and
                      write <strong>xpikdjjc@mailparser.io</strong> in the
                      textbox.{" "}
                    </p>
                  </p>
                  <p>
                    {" "}
                    &bull; Click <strong>Save</strong> button, and the filter
                    will be created.{" "}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className="mb-2">
                <Card.Header>
                  Set up a filter to auto forward in Outlook
                </Card.Header>
                <Card.Body>
                  <p>
                    <b>1.</b> Select the <b>Settings gear.</b>
                  </p>
                  <img
                    src="/assets/outlook/step_1.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>2.</b> Select <b>See All Settings.</b>
                  </p>
                  <img
                    src="/assets/outlook/step_2.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>3.</b> Go to the <b>Filters and Blocked Addresses</b>{" "}
                    tab.
                  </p>
                  <img
                    src="/assets/outlook/step_3.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>4.</b> Select <b>Add a forwarding address</b> button
                  </p>
                  <img
                    src="/assets/outlook/step_4.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>5. </b> We have two scenarios:
                  </p>
                  <ul>
                    <li
                      style={{
                        listStyle: "none",
                        borderBottom: "#b5b3b3 solid 1px",
                      }}
                      className="mb-4"
                    >
                      <p>
                        <b>a.</b> If you are creating filter for CashApp, Venmo
                        and/or Paypal, add the forwarding address : <br />{" "}
                        <b>
                          {" "}
                          cash@square.com,venmo@venmo.com,service@paypal.com{" "}
                        </b>{" "}
                        <Button
                          type="button"
                          className="btn-sm"
                          color="success"
                          onClick={() =>
                            copyContent(
                              `cash@square.com,venmo@venmo.com,service@paypal.com`
                            )
                          }
                        >
                          <FaCopy /> Copy
                        </Button>
                      </p>
                      <img
                        src="/assets/outlook/step_5.jpg"
                        alt=""
                        width="100%"
                        className="mb-3"
                      />
                    </li>
                    <li
                      style={{
                        listStyle: "none",
                        borderBottom: "#b5b3b3 solid 1px",
                      }}
                      className="mb-4"
                    >
                      <p>
                        {" "}
                        <b>b.</b> If you are creating filter for Zelle, kindly
                        follow the below steps:
                      </p>
                      <img
                        src="/assets/outlook/step_5-sub.jpg"
                        alt=""
                        width="100%"
                        className="mb-3"
                      />
                    </li>
                  </ul>
                  <p>
                    <b>6.</b> <b>Verify forwarding address</b>
                  </p>
                  <img
                    src="/assets/outlook/step_6.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Tutorial;
