/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Spinner, Modal, ModalBody, ModalFooter, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { useSelector } from "react-redux";
import { IoIosAdd } from "react-icons/io";
import { FaFileExcel, FaFileDownload } from "react-icons/fa";
import ManageTransaction from "./ManageTransaction";
import moment from "moment";
import Supporter from "./Supporter";
import SupporterLoader from "../Loaders/SupporterLoader";


const Supporters = (props) => {
  const [userId, apiUrl, userName] = useSelector((state) => {
    return [state.userId, state.apiUrl, state.userName];
  });
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [showLoadMore, setLoadMore] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [viewSshotModal, setViewSshotModel] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [supporters, setSupporters] = useState([]);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageDetails, setPageDetails] = useState([]);
  const [transactionId, setTransactionId] = useState(null);
  //const [hasMore, setHasMore] = useState(false);
  const [heightProperty, setHeightProperty] = useState(200);

  const viewSshot = (img) => {
    setScreenshot(img);
    setViewSshotModel(!viewSshotModal);
  };

  const getSupporters = (reload = false) => {
    //alert(pageNo);
    let page = pageNo;
    let eventId = props.eventDetails.id;
    if (pageNo > 1) {
      setLoadMoreLoading(true);
    }
    if (reload) {
      page = 1;
      setPageNo(1);
      return false;
    }
    events
      .getSupporters({ event_id: eventId, page: page })
      .then((res) => {
        if (res.data.success) {
          let newSupporters = res.data.supporters.records;
          if (pageNo === 1) {
            setSupporters(newSupporters);
          } else {
            setSupporters((prevState) => [...prevState, ...newSupporters]);
          }
          setPageDetails(res.data.supporters.pages);
          setLoading(false);
          if (parseInt(res.data.supporters.pages.totalCount) > 0) {
            let checkLoadMore =
              parseInt(res.data.supporters.pages.totalCount) / 30;
            if (pageNo < checkLoadMore) {
              setLoadMore(true);
              setPageNo(pageNo + 1);
            } else {
              setLoadMore(false);
            }
          }
          setLoadMoreLoading(false);
          console.log(pageNo);
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          setLoadMoreLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setLoadMoreLoading(false);
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const exportSupporters = () =>{
    setExportLoading(true);
    let eventUrl = props.eventDetails.url;
    events.exportSupporters({event_id: props.eventDetails.id}).then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data],{ type: res.data.type, encoding: 'UTF-8' })
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = userName + "-" + eventUrl + "-" + moment().format("MM-DD-YYYY") + ".xlsx";
          link.click();
          link.remove();
          setExportLoading(false);
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  useEffect(() => {
    if (pageNo === 1) {
      getSupporters();
    }
  }, [pageNo]);

  const doCheckIn = (check_in, transaction_id, event_id) => {
    let params = { id: transaction_id, event_id, event_id, check_in: check_in };
    events
      .checkIn(params)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          //fetchEventDetails();
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const checkIn = (e, transaction_id, event_id, index) => {
    if (e.target.checked) {
      if (window.confirm("Are you sure to check in this suppoter?")) {
        let supportersArr = supporters;
        supportersArr[index].check_in = 1;
        setSupporters([...supportersArr]);
        doCheckIn(e.target.checked, transaction_id, event_id);
      }
    } else {
      if (window.confirm("Are you sure to un-check in this suppoter?")) {
        let supportersArr = supporters;
        supportersArr[index].check_in = 0;
        setSupporters([...supportersArr]);
        doCheckIn(e.target.checked, transaction_id, event_id);
      }
    }
  };

  const passTransactionId = (transaction_id) => {
    setTransactionId(transaction_id);
    setOpenTransactionModal(true);
  };

  useEffect(() => {
    if (props.height > heightProperty) {
      let val = props.height;
      setHeightProperty(val);
    }
  }, [props.height]);

  return (
    <div className="descriptions">
      {loading ? (
        <SupporterLoader />
      ) : (
        <>
        <Row>
          <Col><h4>Supporters ({pageDetails.totalCount ? pageDetails.totalCount : 0})</h4></Col>
          {userId && parseInt(userId) === parseInt(props.eventDetails.user_id) &&
          <Col className="text-end">
            <Button
              size="sm"
              className="me-1"
              variant="warning"
              onClick={() => exportSupporters()}
              disabled={exportLoading}
            >
              <FaFileExcel
                style={{ width: "14px", height: "14px" }}
                className="me-1"
              />{" "}
              {exportLoading && <Spinner size="sm" className="mr-1" />}
              Export{" "}
            </Button>
            <Button
              size="sm"
              variant="success"
              onClick={() => {
                setOpenTransactionModal(true);
                setTransactionId(null);
              }}
            >
              <IoIosAdd /> Add
            </Button>
          </Col>}
        </Row>
          
          
          {supporters.map((ele, index) => (
            <Supporter
              showName={props.supporterName}
              ele={ele}
              index={index}
              passTransactionId={passTransactionId}
              viewSshot={viewSshot}
              eventDetails={props.eventDetails}
              checkIn={checkIn}
              doCheckIn={doCheckIn}
              key={`supporter-${index}`}
              reloadSupporters={getSupporters}
            />
          ))}
          {showLoadMore && (
            <p className="d-grid">
              <Button
                variant="warning"
                onClick={() => getSupporters()}
                disabled={loadMoreLoading}
              >
                {loadMoreLoading && <Spinner size="sm" className="me-1" />}
                Load more...
              </Button>
            </p>
          )}
        </>
      )}
      {openTransactionModal && (
        <ManageTransaction
          openTransactionModal={openTransactionModal}
          selectedEventId={props.eventDetails.id}
          resetEventId={() => {
            setOpenTransactionModal(false);
            getSupporters(true);
          }}
          transactionId={transactionId}
          getSupporters={getSupporters}
          fetchEventDetails={props.fetchEventDetails}
          enableAddedOn={false}
        />
      )}
      
      {viewSshotModal && (
        <Modal isOpen={viewSshotModal} size="lg">
          {/* <ModalHeader
                toggle={() => setViewSshotModel(false)}
              ></ModalHeader> */}
          <ModalBody>
            <img src={`${apiUrl}/web/events/${screenshot}`} width="100%" />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              className="btn-sm"
              onClick={() => setViewSshotModel(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default Supporters;
