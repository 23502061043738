import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Modal, Spinner, Form, Tooltip, FloatingLabel, Overlay } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import axios from "axios";
//import {useHistory} from "react-router-dom"
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import plans from "../../../services/plans";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const Signup = (props) => {
  const apiUrl = useSelector((state) => state.apiUrl);
  const passwordTarget = useRef(null);

  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState(props.fields ? props.fields : {})
  const [loader, setLoader] = useState(false);
  const [showToolTip, setToolTip] = useState(false);

  const [passRul1, setPassRule1] = useState(false);
  const [passRul2, setPassRule2] = useState(false);
  const [passRul3, setPassRule3] = useState(false);
  const [passRul4, setPassRule4] = useState(false);
  const [passRul5, setPassRule5] = useState(false);
  const [passRul6, setPassRule6] = useState(false);

  const [planArr, setPlans] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const oneLowerCaseRule = RegExp("(?=.*[a-z])");
  const oneUperCaseRule = new RegExp("(?=.*[A-Z])");
  const oneNumberRule = new RegExp("(?=.*[0-9])");
  const oneSpecialCharRule = new RegExp("(?=.*[!@#$%^&*])");

  const handleLogin = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoader(true);

      axios.post(apiUrl + "/user/signup", { fields: fields, beneficiaries: props.beneficiaries ? props.beneficiaries : [] }).then(function (response) {
        if (response.data.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userType", response.data.user_type);
          localStorage.setItem("userName", response.data.username);
          localStorage.setItem("uName", response.data.name);
          localStorage.setItem("userId", response.data.user_id);
          localStorage.setItem("userImage", response.data.image);

          let payload = {
            token: response.data.token,
            userName: response.data.username,
            uName: response.data.name,
            userId: response.data.user_id,
            userType: response.data.user_type,
            userImage: response.data.image
          };
          dispatch({ type: "login", ...payload });
          setLoader(false);
          if (props.beneficiaries) {
            navigate("/payments/tab/3");
          }
          else {
            navigate("/home");
          }
        }
        else if (response.data.error) {
          setErrors({ ...response.data.message });
          setLoader(false);
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
  const handleChange = (e, field) => {
    if (field === 'password') {
      setFields({ ...fields, [field]: e.target.value });
      if (e.target.value.length < 8) {
        setPassRule1(false);
      }
      else {
        setPassRule1(true);
      }
      if (oneLowerCaseRule.test(e.target.value)) {
        setPassRule3(true);
      }
      else {
        setPassRule3(false);
      }
      if (oneUperCaseRule.test(e.target.value)) {
        setPassRule4(true);
      }
      else {
        setPassRule4(false);
      }
      if (oneNumberRule.test(e.target.value)) {
        setPassRule5(true);
      }
      else {
        setPassRule5(false);
      }
      if (oneSpecialCharRule.test(e.target.value)) {
        setPassRule6(true);
      }
      else {
        setPassRule6(false);
      }
      if (oneLowerCaseRule.test(e.target.value) && oneUperCaseRule.test(e.target.value) && oneNumberRule.test(e.target.value) && oneSpecialCharRule.test(e.target.value)) {
        setPassRule2(true);
      }
      else {
        setPassRule2(false);
      }
    }
    else if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setFields({ ...fields, [field]: 1 })
      }
      else {
        setFields({ ...fields, [field]: 0 })
      }
    }
    else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  const validateForm = () => {
    let formIsValid = true
    const errors = {}
    if (!fields.name) {
      errors['name'] = 'Name can not be empty.'
      formIsValid = false
    }
    if (!fields.username) {
      errors['username'] = 'Username can not be empty.'
      formIsValid = false
    }
    if (!fields.email) {
      errors['email'] = 'Email can not be empty.'
      formIsValid = false
    }
    if (!fields.password) {
      errors['password'] = 'Password can not be empty.'
      formIsValid = false
    }
    if (!fields.plan) {
      errors['plan'] = 'Please select plan.'
      formIsValid = false
    }
    if (fields.password && (!oneLowerCaseRule.test(fields.password) || !oneUperCaseRule.test(fields.password) || !oneNumberRule.test(fields.password) || !oneSpecialCharRule.test(fields.password))) {
      errors['password'] = 'Password must be strong.'
      formIsValid = false
      //setToolTip(!showToolTip);
    }
    if (!fields.confirm_password) {
      errors['confirm_password'] = 'Confirm password can not be empty.'
      formIsValid = false
    }
    if (fields.password && fields.password !== fields.confirm_password) {
      errors['confirm_password'] = 'Confirm did not match with your password.'
      formIsValid = false
    }
    if (!fields.terms_accepted) {
      errors['terms_accepted'] = 'Please accept our terms.'
      formIsValid = false
    }
    if (fields.terms_accepted && fields.terms_accepted === 0) {
      errors['terms_accepted'] = 'Please accept our terms.'
      formIsValid = false
    }
    setErrors(errors)
    return formIsValid
  }
  const toggleTooltip = () => {
    setToolTip(!showToolTip);
  }
  useEffect(() => {
    plans
      .getPlans()
      .then((res) => {
        if (res.data.success) {
          setPlans(res.data.plans);
          if (props.plan) {
            setFields({ ...fields, ['plan']: props.plan })
          }

        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [])
  return (
    <Modal
      show={props.showModal}
      onHide={props.closeModal}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="frontend_modal"
    >
      <div className="modal-background">
        <Row>
          <Col md={6}>
            <div className="modal-align">
              <div className="modal-align-content">
                <h3 className="mb-3">WELCOME BACK</h3>
                <p className="mb-5">To keep connected with us please login with your personal info</p>
                <Button
                  variant="link" onClick={()=>{props.closeModal();props.showLoginModal(true)}}>
                  Sign in <BsArrowRight />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Modal.Body className='pt-4 px-5 pb-4'></Modal.Body>
          <Form 
            className="shadow signUp"
            noValidate
            method="post"
            onSubmit={handleLogin}
          >

            <Modal.Header closeButton>
              <h4>SIGN UP</h4>
            </Modal.Header>

            <FloatingLabel
              controlId="floatingInput"
              label="Full Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="name"
                placeholder="name"
                onChange={(event) => handleChange(event, "name")}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Username"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="username"
                placeholder="username"
                onChange={(event) => handleChange(event, "username")}
                isInvalid={errors.username}
              />
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Email"
              className="mb-3"
            >
              <Form.Control
                type="email"
                name="email"
                placeholder="email"
                onChange={(event) => handleChange(event, "email")}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Password"
              className="mb-3"
            >
              <Form.Control
                type="password"
                name="password"
                placeholder="password"
                onChange={(event) => handleChange(event, "password")}
                ref={passwordTarget}
                onFocus={toggleTooltip}
                onBlur={toggleTooltip}
                isInvalid={errors.password}
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              <Overlay target={passwordTarget.current} show={showToolTip} placement="top">
                <Tooltip className='mt-3'>
                  <p className='text-light text-start mb-1'>{passRul1 ? <FaCheckCircle className='text-success' /> : <FaTimesCircle className='text-danger' />} At least 8 characters in length.</p>
                  <p className='text-light text-start mb-1'>{passRul2 ? <FaCheckCircle className='text-success' /> : <FaTimesCircle className='text-danger' />} Should match the following rule.</p>
                  <p className='text-light text-start ml-3 mb-1'>{passRul3 ? <FaCheckCircle className='text-success' /> : <FaTimesCircle className='text-danger' />} One lower case letters (a-z)</p>
                  <p className='text-light text-start ml-3 mb-1'>{passRul4 ? <FaCheckCircle className='text-success' /> : <FaTimesCircle className='text-danger' />} One uper case letters (A-Z)</p>
                  <p className='text-light text-start ml-3 mb-1'>{passRul5 ? <FaCheckCircle className='text-success' /> : <FaTimesCircle className='text-danger' />} Numbers (i.e 0-9)</p>
                  <p className='text-light text-start ml-3 mb-1'>{passRul6 ? <FaCheckCircle className='text-success' /> : <FaTimesCircle className='text-danger' />} Special characters (e.g !@#$%^&*)</p>
                </Tooltip>
              </Overlay>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Confirm Password"
              className="mb-3"
            >
              <Form.Control
                type="password"
                name="confirm_password"
                placeholder="confirm_password"
                onChange={(event) => handleChange(event, "confirm_password")}
                isInvalid={errors.confirm_password}
              />
              <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel controlId="floatingSelect" label="Plan">
              <Form.Select onChange={(event) => handleChange(event, "plan")} isInvalid={errors.plan}>
                <option value="">Choose Plan</option>
                {planArr.length > 0 && planArr.map((plan, index) => (
                  <option
                    value={plan.id}
                    key={`plan-${index}`}
                  >
                    {plan.plan}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.plan}</Form.Control.Feedback>
            </FloatingLabel>
            <Form.Group className="mt-3" controlId="formBasicCheckbox">
              <Form.Check.Input type="checkbox" name="term_accepted" value="1" onChange={(event) => handleChange(event, 'terms_accepted')} />
              <Form.Check.Label className='d-inline ms-1'>By checking this box you agree to <a href="/terms-and-conditions">Our Terms Of Services</a> and <a href="/privacy-policy">Privacy Policy</a> as well as our partner <a href="https://www.dwolla.com/legal/tos/" target="_blank">Dwolla's Terms Of Services</a> and <a href="https://www.dwolla.com/legal/privacy/" target="_blank">Privacy Policy</a></Form.Check.Label>
            </Form.Group>
            <Row className='mt-2'>
              <Col md={12} className="d-grid gap-2">
                <Button
                  color="primary"
                  type="submit"
                  disabled={loader}
                  size="lg"
                >
                  {loader && (
                    <Spinner
                      size="sm"
                      className="me-1"
                      variant="light"
                    />
                  )}
                  Sign up
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className='text-center mt-2'>
                <span className="credential_router">
                  Already have an account?
                  <Button
                    variant="link" onClick={()=>{props.closeModal();props.showLoginModal(true)}}>
                    Sign in
                  </Button>
                </span>
              </Col>
            </Row>
          </Form>
      </div>
    </Modal>
  );
}


export default Signup;
