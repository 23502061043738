import React, {useEffect} from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import user from "../../services/user";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


const ForceLogin = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token} = useParams();
    useEffect(()=>{
        user.getUserByAccessToken({token:token}).then((result) => {
            if (result.data.success) {
                localStorage.setItem("token", result.data.token);
                localStorage.setItem("userType", result.data.userType);
                localStorage.setItem("userName", result.data.userName);
                localStorage.setItem("uName", result.data.uName);
                localStorage.setItem("userId", result.data.userId);
                localStorage.setItem("userImage", result.data.userImage);
                localStorage.setItem("userEmail", result.data.userEmail);

                let payload = {
                    token: result.data.token,
                    userName: result.data.userName,
                    uName: result.data.uName,
                    userId: result.data.userId,
                    userType: result.data.userType,
                    userImage: result.data.userImage,
                    userEmail: result.data.userEmail,
                };
                dispatch({ type: "login", ...payload });
                navigate("/home");
            } else if (result.data.error) {
                toast.error(result.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/home");
            }
        }).catch(() => {
            toast.error("Unexpected Error !", {
                position: toast.POSITION.TOP_RIGHT,
            });
            navigate("/home");
        });
    },[])
    return <div className="animated fadeIn">
        <Row>
            <Col xl={12}>
                <div className="justify-content-center row mt-5">
                    <Spinner style={{width:60, height: 60}}/>
                </div>
            </Col>
        </Row>
    </div>;
}

export default ForceLogin;