import React, { Component } from "react";
import {
  Col,
  Row,
  Form,  
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "react-bootstrap";
import common from "../../../services/common";

class BeneficiaryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal isOpen={this.props.showModal} toggle={this.props.closeModal}>
        <ModalHeader toggle={this.props.closeModal}>
          Beneficiary (Sponsored Family)
        </ModalHeader>
        <ModalBody>
          {this.props.beneficiaryData && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label
                    htmlFor="beneficiary_name"
                    sm={4}
                    className="font-weight-bold"
                  >
                    Name:
                  </Form.Label>
                  <Col sm={8} className="mt-2">
                    {this.props.beneficiaryData.beneficiary_name
                      ? this.props.beneficiaryData.beneficiary_name
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="fathers_name" sm={5} className="font-weight-bold">
                    Father's Name:
                  </Form.Label>
                  <Col sm={7} className="mt-2">
                    {this.props.beneficiaryData.fathers_name
                      ? this.props.beneficiaryData.fathers_name
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    htmlFor="grand_fathers_name"
                    sm={6}
                    className="font-weight-bold"
                  >
                    Grandfather's Name:
                  </Form.Label>
                  <Col sm={6} className="mt-2">
                    {this.props.beneficiaryData.grand_fathers_name
                      ? this.props.beneficiaryData.grand_fathers_name
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    htmlFor="beneficiary_city"
                    sm={4}
                    className="font-weight-bold"
                  >
                    City:
                  </Form.Label>
                  <Col sm={8} className="mt-2">
                    {this.props.beneficiaryData.beneficiary_city
                      ? this.props.beneficiaryData.beneficiary_city
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    htmlFor="beneficiary_wereda"
                    sm={4}
                    className="font-weight-bold"
                  >
                    Wereda:
                  </Form.Label>
                  <Col sm={8} className="mt-2">
                    {this.props.beneficiaryData.beneficiary_wereda
                      ? this.props.beneficiaryData.beneficiary_wereda
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    htmlFor="beneficiary_kelebe"
                    sm={4}
                    className="font-weight-bold"
                  >
                    Kelebe:
                  </Form.Label>
                  <Col sm={8} className="mt-2">
                    {this.props.beneficiaryData.beneficiary_kelebe
                      ? this.props.beneficiaryData.beneficiary_kelebe
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    htmlFor="beneficiary_tabla"
                    sm={4}
                    className="font-weight-bold"
                  >
                    Tabla:
                  </Form.Label>
                  <Col sm={8} className="mt-2">
                    {this.props.beneficiaryData.beneficiary_tabla
                      ? this.props.beneficiaryData.beneficiary_tabla
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    htmlFor="beneficiary_phone"
                    sm={4}
                    className="font-weight-bold"
                  >
                    Phone:
                  </Form.Label>
                  <Col sm={8} className="mt-2">
                    {this.props.beneficiaryData.beneficiary_phone
                      ? this.props.beneficiaryData.beneficiary_phone
                      : "--"}
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label
                    htmlFor="beneficiary_amount"
                    sm={4}
                    className="font-weight-bold"
                  >
                    Amount:
                  </Form.Label>
                  <Col sm={8} className="mt-2">
                  {common.currencySymbols[this.props.donorDetails.currency]}{this.props.beneficiaryData.beneficiary_amount
                      ? this.props.beneficiaryData.beneficiary_amount
                      : "0.00"}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.props.closeModal} type="button">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default BeneficiaryDetails;
