import React from "react";
import Event from "../views/Front/Event"
import Home from "../views/Front/Home";
import Banking from "../views/Front/Common/Banking";
import DiscoverEvent from "../views/Front/Event/DiscoverEvent";
import Blogs from "../views/Front/Blogs/Index";
import BlogDetails from "../views/Front/Blogs/BlogDetails";
import Pricing from "../views/Front/Home/Pricing";
import ForceLogin from "../views/Front/ForceLogin";
import MyAccount from "../views/Front/Profile";
import EventDetails from "../views/Front/Event/EventDetails";
import EventReports from "../views/Front/Event/EventReports";
import Settings from "../views/Front/Settings";
import Payments from "../views/Front/Payments";
import Reports from "../views/Front/Reports";
import PrivacyPolicy from "../views/Front/PrivacyPolicy";
import About from "../views/Front/About";
import TermsAndConditions from "../views/Front/TermsAndConditions";
import Unsubscribe from "../views/Front/Unsubscribe";
import Policy from "../views/Front/Policy";
import ForgotPassword from "../views/Front/Home/ForgotPassword";
import MutualAidRequest from "../views/Front/Event/MutualAidRequest";
import ResetPassword from "../views/Front/Home/ResetPassword";
import TutorialGmail from "../views/Front/Tutorial/gmail";
import TutorialOutlook from "../views/Front/Tutorial/outlook";
import TicketDetails from "../views/Front/Event/TicketDetails";

const frontRoutes = [
  { path: "/home", name: "Home", component: Home, authentication: false },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
    authentication: false,
  },
  {
    path: "/force-login/:token",
    name: "Force Login",
    component: ForceLogin,
    authentication: false,
  },
  {
    path: "/discover-events",
    name: "DiscoverEvent",
    component: DiscoverEvent,
    authentication: false,
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    authentication: false,
  },
  {
    path: "/blog/:url/:id",
    exact: true,
    name: "Blog Details",
    component: BlogDetails,
    authentication: false,
  },
  {
    path: "/banking",
    name: "Banking",
    component: Banking,
    authentication: false,
  },
  {
    path: "/events",
    exact: true,
    name: "Event",
    component: Event,
    authentication: true,
  },
  /* {
    path: "/events/success",
    exact: true,
    name: "Event",
    component: Event,
    authentication: true,
  }, */
  {
    path: "/reset-password/:token",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
    authentication: false,
  },
  {
    path: "/payments",
    exact: true,
    name: "Payments",
    component: Payments,
    authentication: true,
  },
  {
    path: "/payments/tab/:tabid",
    exact: true,
    name: "Payments",
    component: Payments,
    authentication: true,
  },
  {
    path: "/tutorial/gmail",
    exact: true,
    name: "Gmail Settings",
    component: TutorialGmail,
    authentication: false,
  },
  {
    path: "/tutorial/outlook",
    exact: true,
    name: "Outlook Settings",
    component: TutorialOutlook,
    authentication: false,
  },
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    component: Settings,
    authentication: true,
  },
  /* {
    path: "/settings/stripe-connect",
    exact: true,
    name: "Stripe Connect",
    component: Settings,
    authentication: true,
  }, */
  {
    path: "/settings/paypal/success",
    exact: true,
    name: "PayPal",
    component: Settings,
    authentication: true,
  },
  {
    path: "/reports",
    exact: true,
    name: "Reports",
    component: Reports,
    authentication: true,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    name: "Terms And Conditions",
    component: TermsAndConditions,
    authentication: false,
  },
  {
    path: "/about",
    exact: true,
    name: "About Us",
    component: About,
    authentication: false,
  },
  {
    path: "/policy",
    exact: true,
    name: "Tiphub Policy",
    component: Policy,
    authentication: false,
  },

  {
    path: "/ticket/:id",
    name: "Ticket",
    exact: true,
    component: TicketDetails,
    authentication: false,
  },
  {
    path: "/privacy-policy",
    exact: true,
    name: "Reports",
    component: PrivacyPolicy,
    authentication: false,
  },
  {
    path: "/unsubscribe",
    exact: true,
    name: "Unsubscribe",
    component: Unsubscribe,
    authentication: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "Forgot Password",
    component: ForgotPassword,
    authentication: false,
  },
  {
    path: "/events/mutual-aid-request/:id",
    exact: true,
    name: "Mutual Aid Request",
    component: MutualAidRequest,
    authentication: false,
  },
  /* {
    path: "/event-:id",
    exact: true,
    name: "EventDetails",
    component: EventDetails,
    authentication: false,
  }, */
  {
    path: "/reports/:id",
    exact: true,
    name: "EventReports",
    component: EventReports,
    authentication: true,
  },
  {
    path: "/:url/:id",
    exact: true,
    name: "EventDetails",
    component: EventDetails,
    authentication: false,
  },
  /* {
    path: "/:url/:id/success",
    exact: true,
    name: "EventDetails",
    component: EventDetails,
    authentication: false,
  }, */
  // {
  //   path: "/:url/:id/:preview",
  //   exact: true,
  //   name: "EventDetails",
  //   component: EventDetails,
  //   authentication: false,
  // },
  {
    path: "/:username",
    exact: true,
    name: "Profile",
    component: MyAccount,
    authentication: false,
  },
  /* {
    path: "/:username/invoice/:invoice",
    exact: true,
    name: "Profile",
    component: MyAccount,
    authentication: false,
  }, */

  // {
  //   path: "/reset-password/:token",
  //   exact: true,
  //   name: "ChangePassword",
  //   component: ChangePassword,
  //   leftMenu: commonMenu
  // },
];

export default frontRoutes;
