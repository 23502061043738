/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Button, Modal, Spinner, Badge } from "react-bootstrap";

import { toast } from "react-toastify";
import events from "../../../services/events";
import { useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import TippingApp from "./TippingApp";
import AddEditEvent from "./AddEditEvent";
import { FiCopy, FiEdit3 } from "react-icons/fi";
import { FaChartBar } from "react-icons/fa";
import { FaTrashAlt, FaShare } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";

import ManageQrCode from "./ManageQrCode";
import Supporters from "./Supporters";
import moment from "moment";

import EventUpdate from "./EventUpdate";
import EventGallery from "./EventGallery";
import NotFound from "../Common/NotFound";
import ListTippingApps from "./ListTippingApps";
import MutualAidButton from "./MutualAidButton";
import ToggleDonorForm from "./ToggleDonorForm";
import DonorFormButton from "./DonorFormButton";
import MutualAidFormButton from "./MutualAidFormButton";
import MembershipFormButton from "./MembershipFormButton";
import TargetedAmount from "./TargetedAmount";
import EventMembers from "./EventMembers";
import SaleTickets from "./SaleTickets";
import EventGroup from "./EventGroup";
import EventDetailsLoader from "../Loaders/EventDetailsLoader";
import PasscodeValidator from "../Common/PasscodeValidator";
import EventPrograms from "./EventPrograms";
import SalePhysicalTickets from "./SalePhysicalTickets";
import ManageVendors from "./ManageVendors";
import AllotPhysicalTickets from "./AllotPhysicalTickets";
import PrintTickets from "./PrintTickets";
import CreditCardForm from "../Common/CreditCardForm";

const EventDetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [eventData, setEventData] = useState({});
  const userId = useSelector((state) => state.userId);
  const apiUrl = useSelector((state) => state.apiUrl);
  const navigate = useNavigate();
  const queryString = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [openTippingModal, setOpenTippingModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const [appListUpdateStatus, setAppListUpdateStatus] = useState(false);
  const [supportersHeight, setSupportersHeight] = useState(300)
  const [passCodeValidator, setPasscodeValidator] = useState(false);
  const [showManageVendorModal, setShowMangeVendor] = useState(false);
  const [allotPhsicalTicketModal, setAllotPhysicalTicketModal] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [printTicketsModal, setPrintTicketsModal] = useState(false);
  const [allotments, setAllotments] = useState([]);
  const [creditCardFormModal, setCreditCardFormModal] = useState(false);

  const heightCallBack = (high) => {
    setHeight(height + high);
  };

  const leftContainer = useRef();
  const tippingAppContainer = useRef();
  const qrCodeContainer = useRef();
  const targetedAmountContainer = useRef();
  const myContainer = useRef();

  useEffect(() => {
    if (myContainer.current) {
      if (myContainer.current.clientHeight) {
        //setHeight(myContainer.current.clientHeight);
      }
    }
    setTimeout(() => {
      let qrCodeHeight = qrCodeContainer.current ? qrCodeContainer.current.clientHeight : 0;
      let tippingAppHeight = tippingAppContainer.current ? tippingAppContainer.current.clientHeight : 0;
      let targetedAmountHeight = targetedAmountContainer.current ? targetedAmountContainer.current.clientHeight : 0;
      let leftContainerHeight = leftContainer.current ? leftContainer.current.clientHeight : 0;
      let supportersHeight = leftContainerHeight - (tippingAppHeight + qrCodeHeight + targetedAmountHeight);
      setSupportersHeight(supportersHeight - 300);
    }, 3000)


  }, []);

  const [showMangeQrCodeModal, setShowManageQrCodeModal] = useState(false);

  const [targetAcheived, setTargetAcheived] = useState(0);

  const fetchEventDetails = () => {
    let eventId = queryString.id;
    /* if(location.pathname.search('event-') === 1){
      let paramArr = location.pathname.split("-"); 
      eventId = paramArr[1];
    }
    else{
      eventId = props.match.params.id;
    } */

    let params = { id: eventId };
    if (queryString.preview) {
      params = { ...params, preview: 1 };
    }
    events
      .getOne(params, userId)
      .then((res) => {
        if (res.data.success) {
          /* if(location.pathname.search('event-') === 1){
            navigate(`${res.data.event.url}/${res.data.event.id}/`);
          } */
          let eventURL = document.URL.split('/');
          if (eventURL[3] !== res.data.event.url) {
            navigate(`/${res.data.event.url}/${res.data.event.id}`);
          }
          setEventData(res.data.event);
          if (parseInt(res.data.event.event_type) === 1) {
            if (userId) {
              if (parseInt(userId) !== parseInt(res.data.event.user_id)) {
                setPasscodeValidator(true);
              }
            }
            else {
              setPasscodeValidator(true);
            }
          }
          if (res.data.event.transactions.length > 0) {
            let percentage = (
              (parseFloat(res.data.event.transactions[0].totalAmount) /
                parseFloat(res.data.event.target_amount)) *
              100
            ).toFixed(2);
            setTargetAcheived(percentage);
          }
          setLoading(false);
        } else if (res.data.error) {
          setNotFound(true);
          /* toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          }); */
          setLoading(false);
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEventDetails();
    if (document.URL.includes('success')) {
      toast.success("Payment app has been added successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, []);

  const shareProfile = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: eventData.title,
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    }
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href).then(
      () => {
        toast.success("Event url copied to clipboard.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const eventDeleteHandler = () => {
    setDeleteLoading(true);
    events
      .deleteOne({ id: props.match.params.id })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          console.log(res);
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/event");
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setDeleteLoading(false);
      })
      .catch(() => {
        toast.error("Something went wrong !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const deleteQrCode = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id };
      events
        .deleteQrcode(params)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchEventDetails();
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <div className="event-details">
      <Container>
        {loading ? (
          <EventDetailsLoader />
        ) : notFound ? (
          <NotFound />
        ) : (
          <>
            <Row>
              <Col md={4}>
                <div className="dashboard-heading">
                  <h2>
                    Event{" "}
                    {eventData &&
                      eventData.end_date !== null &&
                      moment(
                        moment(eventData.end_date).format("YYYY-MM-DD")
                      ).isBefore(moment().format("YYYY-MM-DD")) ? (
                      <Badge
                        bg="danger"
                        style={{ fontSize: 15 }}
                      >
                        Closed
                      </Badge>
                    ) : (
                      <Badge
                        bg="success"
                        style={{ fontSize: 15 }}
                      >
                        Live
                      </Badge>
                    )}
                  </h2>
                </div>
              </Col>
              {userId &&
                parseInt(userId) === parseInt(eventData.user_id) &&
                parseInt(eventData.category) === 18 && (
                  <ToggleDonorForm
                    eventData={eventData}
                    fetchEventDetails={fetchEventDetails}
                  />
                )}
              <Col
                md={parseInt(eventData.category) === 18 ? 4 : 8}
                sm={12}
                className="text-end mb-2"
              >
                {userId && <SalePhysicalTickets eventDetails={eventData} />}
                {userId && parseInt(userId) === parseInt(eventData.user_id) && (
                  <>
                    {parseInt(eventData.category) === 59 && <Button
                      className="me-2 mb-1"
                      size="sm"
                      onClick={() => setShowMangeVendor(true)}
                      style={{ backgroundColor: 'blueviolet', color: 'white' }}
                    >
                      Manage Ticket Allotments
                    </Button>}
                    {(parseInt(eventData.category) === 57 || parseInt(eventData.category) === 59 || parseInt(eventData.category) === 70 || parseInt(eventData.category) === 75) && <>
                      <Link className="me-2 mb-1 btn btn-dark btn-sm text-light" to={`/reports/${eventData.id}`}>
                        <FaChartBar
                          className="me-1"
                        />
                        Reports
                      </Link>
                    </>}
                    <Button
                      className="me-2 mb-1"
                      size="sm"
                      variant="success"
                      onClick={() => setOpenEditModal(true)}
                    >
                      <FiEdit3
                        className="me-2"
                        style={{ width: "auto", height: "auto" }}
                      />
                      Edit
                    </Button>
                    <Button
                      className="me-2 mb-1"
                      size="sm"
                      variant="danger"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      <FaTrashAlt
                        className="me-2"
                        style={{ width: "auto", height: "auto" }}
                      />
                      Delete
                    </Button>
                    {parseInt(eventData.category) === 56 && (
                      <MutualAidButton eventData={eventData} />
                    )}
                  </>
                )}
                <Button
                  varient="primary"
                  onClick={shareProfile}
                  className="mobile_view btn-sm mb-1"
                >
                  <FaShare />
                  Share Event
                </Button>
                <Button
                  varient="primary"
                  onClick={() => setOpenModal(true)}
                  className="destop_view btn-sm mb-1"
                >
                  <FaShare className="pe-1" />
                  Share Event
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <h3>
                  <b>{eventData.title}</b>
                </h3>
                <p>
                  {eventData.user !== null && <Link
                    to={`/${eventData.user.username}`}
                  >
                    Created by :{" "}
                    {eventData.user &&
                      eventData.user.name}
                  </Link>} | {eventData.start_date &&
                    eventData.start_date !==
                    null && (
                      <>
                        {"From "}
                        {moment(
                          eventData.start_date
                        ).format(
                          "MMM DD, YYYY"
                        )}{" "}
                      </>
                    )}
                  {eventData.end_date &&
                    eventData.end_date !== null && (
                      <>
                        {" to "}
                        {moment(
                          eventData.end_date
                        ).format("MMM DD, YYYY")}
                      </>
                    )}
                </p>
                {parseInt(eventData.show_target) === 1 && <div className="mobile_view w-100">
                  <TargetedAmount
                    eventData={eventData}
                    targetAcheived={targetAcheived}
                  />
                </div>}
                {eventData.image !== null && (
                  <EventGallery eventDetails={eventData} />
                )}
                <hr className="mt-5" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: eventData.description,
                  }}
                />
                <EventUpdate
                  eventId={queryString.id}
                  eventDetails={eventData}
                  heightCallBack={heightCallBack}
                />
              </Col>
              <Col md={4}>
                {/* <Button variant="warning" onClick={()=>setCreditCardFormModal(true)}>Make Payment</Button> */}
                <div className="event-details-aside">
                  <div className="right_align_Individual" ref={leftContainer}>
                    {parseInt(eventData.category) === 18 && (
                      <DonorFormButton eventData={eventData} />
                    )}
                    {parseInt(eventData.category) === 56 && (
                      <MutualAidFormButton
                        eventData={eventData}
                      />
                    )}
                    {parseInt(eventData.category) === 57 && (
                      <MembershipFormButton
                        eventDetails={eventData}
                      />
                    )}
                    {(parseInt(eventData.category) === 59 || parseInt(eventData.category) === 71) && (
                      <SaleTickets
                        eventDetails={eventData}
                      />
                    )}
                    {parseInt(eventData.category) === 58 && (
                      <EventMembers eventDetails={eventData} />
                    )}
                    {parseInt(eventData.show_target) === 1 && <div ref={targetedAmountContainer} className="destop_view">
                      <TargetedAmount
                        eventData={eventData}
                        targetAcheived={targetAcheived}
                      />
                    </div>}

                    {parseInt(eventData.category) === 5 && parseInt(eventData.sub_category) === 70 && (
                      <EventGroup
                        eventDetails={eventData}
                        setOpenTippingModal={
                          setOpenTippingModal
                        }
                        appListUpdateStatus={
                          appListUpdateStatus
                        }
                        setAppListUpdateStatus={
                          setAppListUpdateStatus
                        }
                      />
                    )}
                    {parseInt(eventData.show_tipping_app) === 1 && parseInt(eventData.sub_category) !== 70 &&
                      eventData.apps &&
                      eventData.apps.length > 0 && (
                        <div className="descriptions mb-4" ref={tippingAppContainer} id="tippling-app-container">
                          <ListTippingApps
                            eventDetails={eventData}
                            setOpenTippingModal={
                              setOpenTippingModal
                            }
                            appListUpdateStatus={
                              appListUpdateStatus
                            }
                            setAppListUpdateStatus={
                              setAppListUpdateStatus
                            }
                          />
                        </div>
                      )}
                    {parseInt(eventData.category) !== 58 && parseInt(eventData.category) !== 59 &&
                      eventData.qrcodes.length > 0 && (
                        <div className="descriptions mb-4" ref={qrCodeContainer}>
                          {parseInt(eventData.category) !==
                            18 && (
                              <h4>
                                QR Codes
                                {userId &&
                                  parseInt(userId) ===
                                  parseInt(eventData.user_id) && (
                                    <span className="float-right">
                                      <Button
                                        size="sm"
                                        color="success"
                                        onClick={() =>
                                          setShowManageQrCodeModal(
                                            true
                                          )
                                        }
                                      >
                                        <IoIosAdd />
                                        Upload
                                      </Button>
                                    </span>
                                  )}
                              </h4>
                            )}
                          <ul className="tipping-apps">
                            {eventData.qrcodes.length > 0 &&
                              eventData.qrcodes.map(
                                (ele, index) => (
                                  <li
                                    key={index}
                                    className="text-center"
                                  >
                                    <img
                                      src={`${apiUrl}/web/qrcodes/${ele.file_name}`}
                                      className="img-thumbnail"
                                    />
                                    {userId &&
                                      parseInt(userId) ===
                                      parseInt(
                                        eventData.user_id
                                      ) && (
                                        <>
                                          <br />
                                          <Button
                                            color="danger"
                                            className="btn-sm mt-2"
                                            onClick={() =>
                                              deleteQrCode(ele.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </>
                                      )}
                                  </li>
                                )
                              )}
                          </ul>
                        </div>
                      )}
                    {parseInt(eventData.show_supporters) === 1 &&
                      <Supporters
                        supporterName={eventData.show_name}
                        height={supportersHeight}
                        eventDetails={eventData}
                        fetchEventDetails={fetchEventDetails}
                      />
                    }
                    {parseInt(eventData.category) === 75 &&
                      <EventPrograms
                        eventDetails={eventData}
                      />
                    }
                  </div>
                </div>
              </Col>
              <Modal show={openModal} onHide={() => setOpenModal(false)}>
                <Modal.Header>Share Event</Modal.Header>
                <Modal.Body>
                  {/* <Input
                  component="textarea"
                  defaultValue={window.location.href}
                /> */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setOpenModal(false)}>
                    Close
                  </Button>
                  <Button onClick={() => copyUrl()}>
                    <FiCopy /> Copy
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={openDeleteModal}
                onHide={() => setOpenDeleteModal(false)}
              >
                <Modal.Header closeButton>
                  Are you sure you want to delete this event ?
                </Modal.Header>
                <Modal.Body>
                  Once you delete an event, you will loose the event details.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setOpenDeleteModal(false);
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => eventDeleteHandler()}
                  // disabled={deleteLoading}
                  >
                    {deleteLoading && (
                      <Spinner
                        variant="light"
                        size="sm"
                        style={{ marginRight: 3 }}
                      />
                    )}
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
              {openEditModal && (
                <AddEditEvent
                  EventId={queryString.id}
                  showModal={openEditModal}
                  fetchEventDetails={fetchEventDetails}
                  closeModal={setOpenEditModal}
                />
              )}
              {openTippingModal && (
                <TippingApp
                  openTippingModal={openTippingModal}
                  selectedEventId={queryString.id}
                  resetEventId={() => {
                    setOpenTippingModal(false);
                    fetchEventDetails();
                  }}
                  setAppListUpdateStatus={setAppListUpdateStatus}
                />
              )}
            </Row>
          </>
        )}
        {showMangeQrCodeModal && (
          <ManageQrCode
            showModal={showMangeQrCodeModal}
            closeModal={setShowManageQrCodeModal}
            eventId={eventData.id}
            setEventData={setEventData}
            eventData={eventData}
          />
        )}
        {passCodeValidator && (
          <PasscodeValidator
            showModal={passCodeValidator}
            closeModal={setPasscodeValidator}
            eventId={eventData.id}
          />
        )}
        {showManageVendorModal && (
          <ManageVendors
            showModal={showManageVendorModal}
            closeModal={setShowMangeVendor}
            eventDetails={eventData}
            showAllotPhysicalTicketModal={setAllotPhysicalTicketModal}
            choseVendor={setVendor}
            openPrintTicketsModal={(allotments) => {
              setShowMangeVendor(false)
              let allotmentsArr = [];
              if (allotments.length > 0) {
                allotments.forEach((allotment, index) => {
                  allotmentsArr[index] = allotment.id;
                });
              }
              setAllotments(allotmentsArr);
              setPrintTicketsModal(true)
            }}
          />
        )}
        {allotPhsicalTicketModal && (
          <AllotPhysicalTickets
            showModal={allotPhsicalTicketModal}
            closeModal={setAllotPhysicalTicketModal}
            eventDetails={eventData}
            editEvent={setOpenEditModal}
            showManageVendorModal={setShowMangeVendor}
            vendor={vendor}

          />
        )}
        {printTicketsModal && (
          <PrintTickets
            showModal={printTicketsModal}
            closeModal={setPrintTicketsModal}
            allotments={allotments}
          />
        )}
        {creditCardFormModal && (
          <CreditCardForm
            showModal={creditCardFormModal}
            closeModal={setCreditCardFormModal}
            eventDetails={eventData}
          />
        )}
      </Container>
    </div>
  );
};

export default EventDetails;
