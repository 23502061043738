import React, { Component } from "react";
import events from "../../../services/events";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button, Row, Col, Spinner
} from "react-bootstrap";
import { toast } from "react-toastify";
import { ReactFormBuilder, ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';


class DesignMembershipDueForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { event_id: this.props.eventId, form_data: this.props.formData},
            tools: [
                { key: 'TextInput' },
                { key: 'NumberInput' },
                { key: 'Dropdown' },
                { key: 'TextArea' },
                { key: 'RadioButtons' },
                { key: 'Checkboxes' },
                /* { 
                    key: "DatePicker",
                    canDefaultToday: true,
                    canReadOnly: true,
                    dateFormat: "MM/dd/yyyy",
                    timeFormat: "hh:mm aa",
                    showTimeSelect: false,
                    showTimeSelectOnly: false,
                    name: "Birthdate",
                    icon: "far fa-calendar-alt",
                    label: "Placeholder Label",
                } */
            ],
            showPreview: false,
            submitted: false,
            showForm: false
        };
    }

    saveData = () => {
        if (this.state.fields.form_data.length === 0) {
            toast.error('Form can not be blank.', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else if(!this.props.eventId){
            this.props.updateFormData(this.state.fields.form_data);
        }
        else {
            this.setState({ submitted: true });
            events.saveMembershipDueForm({ fields: this.state.fields }).then((res) => {
                if (res.data.success) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.props.closeModal();
                } else if (res.data.error) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                this.setState({ submitted: false });
            });
        }
    };
    collectData = (data) => {
        let fields = this.state.fields;
        fields['form_data'] = data.task_data;
        this.setState({ fields });
    };
    componentDidMount = () => {
        events.getMembershipDueForm({ event_id: this.props.eventId }).then((res) => {
            if (res.data.success) {
                let fields = this.state.fields;
                fields['form_data'] = res.data.form.form_data;
                this.setState({ fields });
            } else if (res.data.error) {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            this.setState({ showForm: true });
        });
    }
    resetForm = () => {
        let fields = this.state.fields;
        fields['form_data'] = this.props.formData;
        this.setState({ fields, showForm: false }, () => {
            this.setState({showForm:true})
        });
    }
    render() {

        return (
            <>
                <Modal size="lg" isOpen={this.props.showModal}>
                    <ModalHeader toggle={() => this.props.closeModal()} tag="div" className="d-block">
                        <Row>
                            <Col md={8}><h5>Design Form</h5></Col>
                            <Col md={4}><Button color="primary" onClick={() => this.setState({ showPreview: true })}>Preview Form</Button></Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                {this.state.showForm ? <ReactFormBuilder
                                    toolbarItems={this.state.tools}
                                    onPost={this.collectData}
                                    data={this.state.fields.form_data}
                                /> : <p className="text-center p-5"><Spinner size="lg" /></p>}
                            </Col>
                            <Col md={12} className="mt-2">
                                <Button
                                    color="success"
                                    disabled={this.state.submitted}
                                    onClick={this.saveData}
                                    size="lg"
                                    className="mr-2"
                                >
                                    {this.state.submitted && (
                                        <Spinner
                                            size="sm"
                                            color="#887d7d"
                                            className="mr-1 mb-1"
                                        />
                                    )}
                                    Submit
                                </Button>
                                <Button
                                    color="danger"
                                    disabled={this.state.submitted}
                                    onClick={this.resetForm}
                                    size="lg"
                                    type="button"
                                > 
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                {this.state.showPreview && <Modal size="md" isOpen={this.props.showModal}>
                    <ModalHeader toggle={() => this.setState({ showPreview: false })}>Preview</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <ReactFormGenerator
                                    data={this.state.fields.form_data}
                                    hide_actions={true}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>}
            </>
        );
    }
}

export default DesignMembershipDueForm;
