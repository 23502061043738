import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Spinner, Button } from "react-bootstrap";
import events from "../../../services/events";
import moment from "moment";
import { useSelector } from "react-redux";

const EventUpdate = (props) => {
  const apiUrl = useSelector((state) => state.apiUrl);
  const userId = useSelector((state) => state.userId);
  const [eventList, setEventList] = useState([]);
  const [loader, setLoader] = useState(true);
  // const [height,setHeight] = useState(0);
  const updateHeight = useRef();

  const EventUpdateList = () => {
    events
      .eventList({ event_id: props.eventId })
      .then((res) => {
        if (res.data.success) {
          setLoader(false);

          setEventList(res.data.updates);
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    EventUpdateList();
  }, []);

  useEffect(() => {
    if (updateHeight.current) {
      if (updateHeight.current.clientHeight) {
        props.heightCallBack(updateHeight.current.clientHeight);
      }
    }
  });

  const deleteUpdate = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      events
        .deleteUpdate({ id: id })
        .then((res) => {
          if (res.data.success) {
            let arr = eventList;
            arr = eventList.filter((ele) => ele.id !== id);
            setEventList(arr);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error("Something went wrong !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <div ref={updateHeight}>
      {loader ? (
        <Spinner />
      ) : (
        eventList.map((singlevent, index) => (<>
        <hr />
          <Card style={{ backgroundColor: "#fff0eead" }} key={index}>
            <Card.Body style={{ minHeight: "auto" }}>
              <p className="mt-2">
                <strong>Updates on:</strong>{" "}
                {moment(singlevent.added_on).format("MMM DD, YYYY h:mm a")}{" "}
                {userId &&
                  parseInt(userId) === parseInt(props.eventDetails.user_id) && (
                    <Button
                      type="button"
                      className="float-right btn-sm"
                      color="danger"
                      onClick={() => deleteUpdate(singlevent.id)}
                    >
                      Delete
                    </Button>
                  )}
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: singlevent.description,
                }}
              />
              {singlevent.image !== null && (
                <p>
                  <img
                    src={`${apiUrl}/web/events/${singlevent.image}`}
                    className="img-fluid"
                  />
                </p>
              )}
            </Card.Body>
          </Card>
        </>
        ))
      )}
    </div>
  );
};

export default EventUpdate;
