/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Col, Form
  } from "react-bootstrap";
  import { connect } from "react-redux";
  import events from "../../../services/events";
  import { toast } from "react-toastify";


class ToggleDonorForm extends Component {
  constructor(props) {
    super(props);
        this.state = {
            checked:parseInt(this.props.eventData.disable_donor_form) === 1 ? true: false
        };
    }
    toggleDonorForm = e => {
        this.setState({checked:e.target.checked},()=>{
            let params = {id: this.props.eventData.id,checked:this.state.checked};
            events.toggleDonorForm(params).then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.props.fetchEventDetails();
                } else {
                  toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }
              }).catch((err) => {
                toast.error('Unexpected error !', {
                    position: toast.POSITION.TOP_RIGHT,
                })
              })
        })
    }
  render() {
    return (
        <Col md={2} sm={12} className="text-right mb-2">
            <Form.Group>
                <Form.Check type="checkbox" id="disable_donor_form" label="Disable donor form" inline checked={this.state.checked} onChange={(event)=>this.toggleDonorForm(event)}/>
            </Form.Group>
        </Col>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ToggleDonorForm);


