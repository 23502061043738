/* eslint-disable react/prop-types */
import { Button, Modal, Form, Col, Row, FloatingLabel } from "react-bootstrap";
import React, { Component } from "react";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { connect } from "react-redux";


class GroupDonorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                event_id: this.props.eventDetails.id,
            },
            groups: [],
            errors: {},
            submitted: false,
            selectedApp:!this.props.paymentId ? this.props.selectedApp : null
        };
    }
    getGroupList = () => {
        events
            .getGroupList({ event_id: this.props.eventDetails.id })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ groups: res.data.groups });
                }
            });
    };
    componentDidMount = () => {
        this.getGroupList();
        if(this.props.paymentId){
            events.getGroupPaymentDetails({ id: this.props.paymentId }).then((res) => {
                if (res.data.success) {
                    this.setState({ fields: res.data.payment });
                }
            });
        }
    };
    handleChange = (e, field) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    };
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["group_id"]) {
            formIsValid = false;
            errors["group_id"] = "Please choose group.";
        }
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "Please enter first name.";
        }
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "Please enter last name.";
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter email.";
        }
        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = "Please enter phone number.";
        }
        if (!fields["amount"]) {
            formIsValid = false;
            errors["amount"] = "Please enter any amount.";
        }
        if (!fields["city"]) {
            formIsValid = false;
            errors["city"] = "Please enter your city.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };
    handleSubmit = () => {
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            let fields = this.state.fields;
            if(this.state.selectedApp !== null){
                fields["service_id"] = this.state.selectedApp.service_id;
            }
            let params = { fields: fields };
            events.saveGroupPayment(params).then((res) => {
                if (res.data.success) {
                    this.setState({ submitted: false, showModal: false }, () => {
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.props.closeModal();
                        if(!this.props.paymentId){
                            this.props.openDonnerModal(this.state.selectedApp);
                            this.props.setAmount(fields.amount);
                        }
                    });
                } else if (res.data.error) {
                    this.setState({ errors: res.data.message, submitted: false });
                }
            })
            .catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    render() {
        return (
            <Modal 
                show={this.props.showModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                size="md"
            >
                <Modal.Header closeButton><h4 className="mb-0">Donor Details</h4></Modal.Header>
                
                <Modal.Body>
                    <FloatingLabel label="Group Name" className="mb-3">
                        <Form.Select onChange={(event) => this.handleChange(event, "group_id")} isInvalid={this.state.errors.group_id}>
                            <option value="">Choose a group</option>
                            {this.state.groups.map((group, index) => (
                                <option value={group.id} key={`group-${index}`}>
                                    {group.name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{this.state.errors.group_id}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="First Name"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="first_name"
                            onChange={(event) => this.handleChange(event, "first_name")}
                            isInvalid={this.state.errors.first_name}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.first_name}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Middle Name"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            name="middle_name"
                            placeholder="middle_name"
                            onChange={(event) => this.handleChange(event, "middle_name")}
                            isInvalid={this.state.errors.middle_name}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.middle_name}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Last Name"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            name="last_name"
                            placeholder="last_name"
                            onChange={(event) => this.handleChange(event, "last_name")}
                            isInvalid={this.state.errors.last_name}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.last_name}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="City"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            name="city"
                            placeholder="city"
                            onChange={(event) => this.handleChange(event, "city")}
                            isInvalid={this.state.errors.city}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.city}</Form.Control.Feedback>
                    </FloatingLabel>
                    
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            name="email"
                            placeholder="email"
                            onChange={(event) => this.handleChange(event, "email")}
                            isInvalid={this.state.errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.email}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Phone"
                        className="mb-3"
                        >
                        <Form.Control
                            type="text"
                            name="phone"
                            placeholder="phone"
                            onChange={(event) => this.handleChange(event, "phone")}
                            isInvalid={this.state.errors.phone}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.phone}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Amount"
                        className="mb-3"
                        >
                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="amount"
                            onChange={(event) => this.handleChange(event, "amount")}
                            isInvalid={this.state.errors.amount}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.amount}</Form.Control.Feedback>
                    </FloatingLabel>
                    
                    
                    {!this.props.paymentId && <Row>
                        {this.props.eventDetails.apps.map((ele, index) => (
                            <Col md={6} sm={12} key={index} className="d-grid mb-3">
                                <Button
                                    variant={(this.state.selectedApp !== null && this.state.selectedApp.id === ele.id) ? 'warning' : 'outline-warning'}
                                    type="button"
                                    onClick={() => this.setState({selectedApp:ele})}
                                    disabled={this.state.submitted}
                                >
                                    <img src={`/assets/${ele.appname.icon}`} width={32} className="mr-1"/>
                                    {ele.appname.name}
                                </Button>
                            </Col>
                        ))}
                    </Row>}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="mr-3"
                        variant="danger"
                        size="lg"
                        onClick={() => this.props.closeModal()}
                        disabled={this.state.submitted}
                    >Close</Button>
                    <Button
                        type="button"
                        variant="success"
                        size="lg"
                        onClick={() => this.handleSubmit()}
                        disabled={this.state.submitted}
                    >Submit</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(GroupDonorForm);
