/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, Form, Table, Spinner, Col, InputGroup
  } from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import { toast } from "react-toastify";
import moment from "moment";

class ManageMembers extends Component {
  constructor(props) {
    super(props);
        this.state = {
            showModal:false,
            fields: {event_id:this.props.eventDetails.id},
            errors: {},
            loader: false,
            submitted:false,
            records:[],
        };
    }
    getMembers = () => {
        this.setState({ loader: true });
        events
          .getMembers({
            event_id:this.props.eventDetails.id
          })
          .then((res) => {
            if (res.data.success) {
              this.setState({ records: res.data.members});
            } else if (res.data.error) {
              console.log(res.data.message);
            }
            this.setState({ loader: false });
          })
          .catch((err) => {
            console.log(err);
          });
    };
    componentDidMount = () =>{
        this.getMembers(1)
    }
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["member"]) {
            formIsValid = false;
            errors["member"] = "Please enter email address or username.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            let params = { fields:this.state.fields };
            events
            .addMember(params)
            .then((res) => {
                if (res.data.success) {
                    let fields = this.state.fields;
                    fields.member = '';
                    this.setState({ submitted: false, fields},()=>{
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.getMembers();
                    });
                } else if (res.data.error) {
                    let errors = [];
                    if(res.data.message.user_id){
                        errors['member'] = res.data.message.user_id;
                    }
                    else{
                        errors['member'] = res.data.message;
                    }
                    this.setState({errors, submitted:false});
                }
            })
            .catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    
    handleChange(e, field) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    deleteRecord = (id) => {
        if (window.confirm('Are you sure to delete?')) {
          let params = { id: id }
          events.removeMember(params).then((response) => {
            if (response.data.success) {
              let records = this.state.records.filter((r) => parseInt(r.id) !== parseInt(id))
              this.setState({records},()=>{
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                })
              })
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            }
          }).catch((err) => {
            console.log(err)
                toast.error('Unexpected error !', {
                position: toast.POSITION.TOP_RIGHT,
            })
          })
        }
    }
  render() {
    return (
        <Modal 
            show={this.state.showModal}
            onHide={this.props.closeModal}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
                <Modal.Header closeButton>Manage Members</Modal.Header>
                <Modal.Body>
                    <Form
                        method="post"
                        className="px-2"
                        onSubmit={this.handleSubmit}
                    >
                        <InputGroup className="mb-3" size="lg" hasValidation>
                            <Form.Control
                                name="member" 
                                type="text" 
                                onChange={(event) => this.handleChange(event, 'member')} 
                                isInvalid={!!this.state.errors.member}
                            />
                            <Button type="submit" variant="success" disabled={this.state.submitted}>{this.state.submitted && <Spinner animation="border" variant="light" className="me-1" style={{width:20, height:20}}/>}Save</Button>
                            <Form.Control.Feedback type="invalid">{this.state.errors.member}</Form.Control.Feedback>
                        </InputGroup>
                        
                        <Table className="table table-responsive customise_table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th>Added On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.records.length > 0 ? (
                                    this.state.records.map((ele, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}. </td>
                                            <td> {ele.user.email}</td>
                                            <td> {moment(ele.added_on).format("MMM Do YYYY")}</td>
                                            <td>
                                                <Button type="button" className="btn-sm mr-1" color="danger" onClick={() => this.deleteRecord(ele.id)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="11" className="text-center" style={{ height: 200 }}>
                                            Record not found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Form>
                </Modal.Body>
            </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ManageMembers);


