import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Unsubscribe = () => {
  return (
    <div className="dashboard-banner">
      <div className="tiphub-banner-bg"></div>
      <Container className="pt-5" style={{minHeight:500}}>
        <Row>
          <Col sm="12">
            <h1>Unsubscribe</h1>
            <hr className="mt-0" />
            <p>
              To Unsubscribe/Delete your account, go to{" "}
              <strong>Settings</strong> page after login and choose Delete
              Account button. This will delete your account permanently.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Unsubscribe;
