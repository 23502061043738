/* eslint-disable react/prop-types */
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import events from "../../../services/events";
import { connect } from "react-redux";
import { IoIosAdd } from "react-icons/io";
import AddEditPermission from "./AddEditPermission";
import { toast } from "react-toastify";
import common from "../../../services/common";
import AllotPhysicalTickets from "./AllotPhysicalTickets";

class TeamAndPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      loader: false,
      team: [],
      eventId: null,
      totalCount: 0,
      errors: {},
      disabled: false,
      showModal: false,
      eventDetails: {},
      allotTicketModal: false,
      teamId: null,
    };
  }

  listTeamMember = () => {
    this.setState({ loader: true });
    events
      .getOne({ id: this.props.selectedEventId }, this.props.userId)
      .then((res) => {
        if (res.data.success) {
          this.setState({ eventDetails: res.data.event }, () => {
            events
              .getTeams({ event_id: this.props.selectedEventId })
              .then((res) => {
                if (res.data.success) {
                  this.setState({ team: res.data.team });
                } else if (res.data.error) {
                  console.log(res.data.message);
                }
                this.setState({ loader: false });
              })
              .catch((err) => {
                console.log(err);
              });
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.listTeamMember();
  };

  addPermissionModal = () => {
    this.setState({ showModal: !this.state.showModal });
    if (!this.state.showModal) {
      this.setState({ eventId: null });
      // this.listTeamMember(); //commented to stop API call while opening modal
    }
  };

  editHandler = (user) => {
    this.addPermissionModal();
    this.setState({ eventId: user.id });
  };

  toggleAllotTicketModal = () => {

  }

  deleteRecord = (id) => {
    if (window.confirm('Are you sure to delete?')) {
      let params = { id: id }
      events.deleteTeam(params).then((response) => {
        if (response.data.success) {
          let records = this.state.team.filter((r) => parseInt(r.id) !== parseInt(id))
          this.setState({ records }, () => {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        console.log(err)
        toast.error('Unexpected error !', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }
  render() {
    return (
      <>
        <Modal size="lg" show={this.props.showModal} onHide={() => this.props.closeModal()}>
          <Modal.Header closeButton><h4>Teams and Permissions</h4></Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col sm="9">
                <h5>Team</h5>
              </Col>
              <Col sm="3">
                <Button
                  color="primary"
                  className="btn-sm mr-0 ml-auto d-block"
                  onClick={this.addPermissionModal}
                >
                  <IoIosAdd />
                  Assign Permission
                </Button>
              </Col>
            </Row>
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading..."
            >
              <Table className="table table-responsive customise_table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.team.length > 0 ? (
                    this.state.team.map((ele, index) => (
                      <tr key={index}>
                        <th>
                          {this.state.pageNo === 1
                            ? index + 1
                            : (this.state.pageNo - 1) * 50 + index + 1}
                          .
                        </th>
                        <td>{ele.team.username}</td>
                        <td>{common.eventRoleArr.filter(r => parseInt(r.value) === parseInt(ele.role))[0].label}</td>
                        <td>
                          <Button
                            variant="success"
                            className="btn-sm mx-1"
                            onClick={() => this.editHandler(ele)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            className="btn-sm mx-1"
                            onClick={() => this.deleteRecord(ele.id)}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        Record not found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </LoadingOverlay>
          </Modal.Body>
        </Modal>
        {this.state.showModal && (
          <AddEditPermission
            showModal={this.state.showModal}
            addPermissionModal={this.addPermissionModal}
            eventId={this.state.eventId}
            selectedEventId={this.props.selectedEventId}
            fetchTeamMemberList={this.listTeamMember}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(TeamAndPermissions);
