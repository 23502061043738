/* eslint-disable react/prop-types */
import {
  Row,
  Col,
  Button,
  Spinner,
  Form,Offcanvas
} from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import user from "../../../services/user";
import common from "../../../services/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { connect } from "react-redux";
import classnames from "classnames";
import ManageMembershipPlans from "./ManageMembershipPlans";
import { FaQuestionCircle } from "react-icons/fa";
import AsyncSelect from "react-select/async";
import ManageTickets from "./ManageTickets";
import NonProfitQuesion from "./NonProfitQuesion";
import ManageGroups from "./ManageGroups";
import ManagePrograms from "./ManagePrograms";
import * as Icons from "react-icons/fa";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class AddEditEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        fundraising_for: "0",
        existing_gofundme_campaign: "1",
        work_for_non_profit: "0",
        require_email_collection: "1",
        recurring: "0",
        show_name: "1",
        published: 1,
        start_date: new Date(moment()),
        end_date: new Date(moment().add(7, 'day')),
        event_type: 0,
        show_target: 1,
        currency: 0,
        show_address:0,
        show_supporters:1,
        show_tipping_app:1
      },
      errors: {},
      step: 1,
      submitted: false,
      defaultAmountArray: [
        { label: "$1", value: "1", checked: false },
        { label: "$5", value: "5", checked: true },
        { label: "$10", value: "10", checked: false },
        { label: "$20", value: "20", checked: false },
        { label: "Other", value: "other", checked: false },
      ],
      checkinArray: [
        { label: "In Person", value: "1", checked: false },
        { label: "Online", value: "2", checked: false },
        { label: "Both", value: "3", checked: false },
        { label: "No Check In", value: "4", checked: false },
      ],
      recurringArray: [
        { label: "Yes", value: "1" },
        { label: "No", value: "2" },
      ],
      description: null,
      overLayLoading: false,
      showOtherFeild: false,
      currentCheckedIndex: 1,
      loading: false,
      categories: [],
      currentStep: 0,
      subCategories: [],
      subSubCategories: [],
      locations: [],
      timeStamp: Date.now(),
      showReligiousCategory: false,
      subCategoryQuesion:'',
      subSubCategoryQuesion:'',
    };
  }

  setCurrentStep = (step) => {
    if (this.state.currentStep < this.state.step) {
      this.setState({ currentStep: step - 1 });
    }
  };

  eventFormValidation = () => {
    let formIsValid = true;
    const errors = {};
    if (this.state.step === 1 && (this.props.selectedEventId || this.props.EventId)){
      formIsValid = false;
      this.setState({step:2})
    }
    else if (this.state.step === 2) {
      if (
        this.state.fields.existing_gofundme_campaign === "0" &&
        !this.state.fields.campaign_url
      ) {
        formIsValid = false;
        errors["campaign_url"] = "Please enter existing gofundme campaign url.";
      }
      if (this.state.fields.fundraising_for === "1") {
        if (!this.state.fields.non_profit_name) {
          formIsValid = false;
          errors["non_profit_name"] = "Please enter non profit name.";
        }
        if (!this.state.fields.city) {
          formIsValid = false;
          errors["city"] = "Please enter city.";
        }
        if (!this.state.fields.state) {
          formIsValid = false;
          errors["state"] = "Please enter state.";
        }
        if (!this.state.fields.ein_number) {
          formIsValid = false;
          errors["ein_number"] = "Please enter EIN number.";
        }
      }
      if (this.state.fields.category && (parseInt(this.state.fields.category) === 59 || parseInt(this.state.fields.category) === 71) && parseInt(this.state.fields.show_address) === 0) {
        if (!this.state.fields.location) {
          formIsValid = false;
          errors["location"] = "Please enter venue details.";
        }
        if (!this.state.fields.venue_name) {
          formIsValid = false;
          errors["venue_name"] = "Please enter venue name.";
        }
        if (!this.props.selectedEventId && !this.props.EventId && !this.state.fields.tickets) {
          formIsValid = false;
          errors["tickets"] = "Please create ticket.";
        }
      }
      if (formIsValid) {
        formIsValid = false;
        this.setState({ errors: errors, step: 3 });
        this.setCurrentStep(3);
      } else {
        formIsValid = false;
        this.setState({ errors, timeStamp: Date.now() });
      }
      console.log(errors);
    }
    else if (this.state.step === 3) {
      if (!this.state.fields.title) {
        errors["title"] = "Event Title can not be empty";
        formIsValid = false;
      }
      if (!this.state.fields.target_amount) {
        errors["target_amount"] = "Event Target Amount can not be empty";
        formIsValid = false;
      } else if (this.state.fields.target_amount <= 0) {
        errors["target_amount"] = "Event Target Amount can not be zero or negative!";
        formIsValid = false;
      }
      if (this.state.description === null) {
        errors["description"] = "Event Description can not be empty!";
        formIsValid = false;
      }
      if (parseInt(this.state.fields.event_type) === 1 && !this.state.fields.passcode) {
        errors["passcode"] = "Passcode can not be empty!";
        formIsValid = false;
      }
      this.setState({ errors });
    }
    return formIsValid;
  };

  navigateStep = (e) => {
    let innerHTML = e.target.innerHTML;
    this.setState((prevState) => {
      if (innerHTML === "Next") {
        return { step: prevState.step + 1 };
      } else {
        return { step: prevState.step - 1 };
      }
    });
  };

  handleSubmit = (e = null) => {
    let fields = this.state.fields;
    if (e !== null) {
      e.preventDefault();
      fields["published"] = 1;
    }
    if (this.eventFormValidation()) {
      this.setState({ submitted: true });
      fields["description"] = this.state.description;
      if (fields["start_date"]) {
        fields["start_date_custom_format"] = moment(fields["start_date"]).format(
          "YYYY-MM-DD h:mm:ss a"
        );
      }
      if (fields["end_date"]) {
        fields["end_date_custom_format"] = moment(fields["end_date"]).format(
          "YYYY-MM-DD h:mm:ss a"
        );
      }

      let params = { fields: fields };
      events
        .add(params)
        .then((res) => {
          if (res.data.success) {
            this.props.closeModal();
            if (this.props.loadEvents) {
              this.props.loadEvents();
            }
            if (this.props.fetchEventDetails) {
              this.props.fetchEventDetails();
            }
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (res.data.error) {
            this.setState({ errors: res.data.message });
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          this.setState({ submitted: false });
        })
        .catch((err) => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    if (e.target.type === 'checkbox' && field === 'show_target') {
      if (e.target.checked) {
        fields['show_target'] = 1;
      }
      else {
        fields['show_target'] = 0;
      }
    }
    if (e.target.type === 'checkbox' && field === 'allow_custom_price') {
      if (e.target.checked) {
        fields['allow_custom_price'] = 1;
      }
      else {
        fields['allow_custom_price'] = 0;
      }
    }
    this.setState({ fields, timeStamp: Date.now() });
  };

  uploadFile = (e) => {
    this.setState({ overLayLoading: true });
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    events
      .uploadEventImage(formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        if (res.data.success) {
          let fields = this.state.fields;
          fields["image"] = res.data.file;
          this.setState({ fields });
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({ overLayLoading: false });
      })
      .catch((err) => {
        this.setState({ overLayLoading: false });
        toast.error("Unexpected Error!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  fetchEventDetails = (id) => {
    this.setState({ overLayLoading: true });
    events
      .getToEdit({ id })
      .then((res) => {
        if (res.data.success) {
          let subCategoryQuesion = '';
          let subSubCategoryQuesion = '';
          let subCategories = [];
          let eventDetails = Object.assign({}, res.data.event);
          if (eventDetails.start_date != null) {
            var t = eventDetails.start_date.split(/[- :]/);
            eventDetails.start_date = new Date(
              t[0],
              t[1] - 1,
              t[2],
              t[3],
              t[4],
              t[5]
            );
            eventDetails["start_date_custom_format"] = moment(
              new Date(eventDetails.start_date)
            ).format("YYYY-MM-DD h:mm:ss a");
          }
          if (eventDetails.end_date != null) {
            var t = eventDetails.end_date.split(/[- :]/);
            eventDetails.end_date = new Date(
              t[0],
              t[1] - 1,
              t[2],
              t[3],
              t[4],
              t[5]
            );
            eventDetails["end_date_custom_format"] = moment(
              new Date(eventDetails.end_date)
            ).format("YYYY-MM-DD h:mm:ss a");
            eventDetails["location"] = { label: eventDetails.location, value: eventDetails.location };
          }
          if (parseInt(eventDetails.category) === 5) {
            let category = this.state.categories.filter(c => parseInt(c.id) === parseInt(eventDetails.category));
            subCategories = category[0].subcategory; 
            subCategoryQuesion = `What kind of ${category[0].name}?`;
          }
          if (eventDetails.sub_sub_category !== null) {
            let category = this.state.subSubCategories.filter(c => parseInt(c.id) === parseInt(eventDetails.sub_sub_category));
            subSubCategoryQuesion = `What kind of ${category[0].name}?`;
          }
          this.setState({
            fields: eventDetails,
            description: eventDetails.description,
            subCategories,
            subCategoryQuesion,
            subSubCategoryQuesion
          });
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({ overLayLoading: false });
      })
      .catch((err) => {
        this.setState({ overLayLoading: false });
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.setState({overLayLoading:true})
    let categoryArr = [];
    let fields = this.state.fields;
    events.getEventCategory().then((res) => {
      if (res.data.success) {
        let categories = res.data.categories;
        user.getMembership().then((res) => {
          if (res.data.success) {
            if (res.data.membership !== null && parseInt(res.data.membership.plan_id) === 1) {
              categoryArr = categories.filter(category => parseInt(category.id) === 72);
            }
            else {
              categoryArr = categories;
            }
          }
          this.setState({ categories: categoryArr, fields, overLayLoading:false }, () => {
            if (this.props.selectedEventId) { // selectedEventId is used from list event page. 
              setTimeout(()=>{
                this.fetchEventDetails(this.props.selectedEventId);
              }, 500)
            } else if (this.props.EventId) { // selectedEventId is used from list event details page. 
              
              setTimeout(()=>{
                this.fetchEventDetails(this.props.EventId);
              }, 500)
            }
          });
        });
        events.getSubCategory().then((res) => {
          if (res.data.success) {
            this.setState({ subSubCategories: res.data.categories });
          }
        });
      }
    });
  };

  handleDescription = (description) => {
    this.setState({ description });
  };

  handleStartDate = (date) => {
    let fields = this.state.fields;
    fields["start_date"] = date;
    fields["start_date_custom_format"] = moment(date).format(
      "YYYY-MM-DD h:mm:ss a"
    );
    this.setState({ fields });
  };

  handleEndDate = (date) => {
    let fields = this.state.fields;
    fields["end_date"] = date;
    fields["end_date_custom_format"] = moment(date).format(
      "YYYY-MM-DD h:mm:ss a"
    );
    this.setState({ fields });
  };

  navigateTab = (e, t) => {
    if (e.target.parentElement.className.includes("hoverPointer")) {
      this.setState({ step: t });
    }
  };

  saveDraft = () => {
    let fields = this.state.fields;
    fields["published"] = 0;
    this.setState({ fields }, () => {
      this.handleSubmit();
    });
  };

  renderButtons = (step) => {
    return (
      <React.Fragment>
        {step > 1 && (
          <Button
            size="lg"
            varient="secondary"
            onClick={this.navigateStep}
            className="me-1"
            style={{ width: "auto" }}
          >
            Back
          </Button>
        )}
        {step === 3 && (
          <Button
            size="lg"
            varient="warning"
            onClick={this.saveDraft}
            className="me-1 border-remover"
            style={{ width: "auto", backgroundColor:'#ffc108' }}
          >
            Save As Draft
          </Button>
        )}

        <Button
          size='lg'
          type="submit"
          varient="success"
          disabled={this.state.submitted}
          style={{ backgroundColor: "limegreen", width: "auto" }}
          className="border-remover"
        >
          {this.state.submitted && (
            <Spinner className="mr-1" color="light" size="sm" />
          )}
          {step === 3 ? "Publish" : "Next"}
        </Button>
      </React.Fragment>
    );
  };

  updatePlans = (plans) => {
    let fields = this.state.fields;
    fields['plans'] = plans;
    this.setState({ fields })
    console.log(this.state.fields);
  }
  updateTickets = (tickets) => {
    let fields = this.state.fields;
    fields['tickets'] = tickets;
    this.setState({ fields })
  }
  updatePrograms = (programs) => {
    let fields = this.state.fields;
    fields['programs'] = programs;
    this.setState({ fields })
    console.log(this.state.fields);
  }
  updateFormData = (formData) => {
    let fields = this.state.fields;
    fields['form_data'] = formData;
    this.setState({ fields })
  }
  updateTeams = (teams) => {
    let fields = this.state.fields;
    fields['groups'] = teams;
    this.setState({ fields })
    console.log(this.state.fields);
  }
  promiseLocationOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue !== "") {
        let that = this;
        setTimeout(() => {
          events.getLocations({ location: inputValue }).then(function (response) {
            if (response.data.locations.resourceSets) {
              let locations = [];
              response.data.locations.resourceSets[0].resources[0].value.forEach((loc, index) => {
                locations[index] = {
                  label: loc.address.formattedAddress,
                  value: loc.address.formattedAddress
                };
              });
              that.setState({ locations }, () => {
                resolve(that.filterLocation(inputValue));
              }
              );
            } else if (response.data.error) {

            }
          }).catch(function (error) {
            console.log(error);
          });
        }, 1000);
      } else {
        resolve(this.filterLocation(inputValue));
      }
    });
  };
  handleLocationChange = (location) => {
    let fields = this.state.fields;
    fields['location'] = location;
    this.setState({ fields });
  };
  filterLocation = (inputValue) => {
    return this.state.locations.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  setCategory = (category) => {
    let fields = this.state.fields;
    let step = this.state.step;
    fields['category'] = category.id;
    fields['sub_category'] = null;
    if (parseInt(category.id) === 9) {
      fields['fundraising_for'] = "1";
    }
    else {
      fields['fundraising_for'] = "0";
    }
    if(parseInt(category.id) === 75 || parseInt(category.id) === 57){
      step = 2;
    }
    this.setState({fields, subCategories:category.subcategory, subCategoryQuesion:`What kind of ${category.name}?`, subSubCategoryQuesion:`What kind of ${category.name}?`, step})
  }
  setSubCategory = (category) => {
    let fields = this.state.fields;
    fields['sub_category'] = category.id;
    this.setState({fields, subSubCategoryQuesion:`What kind of ${category.name}?`}, ()=>{
      let section = document.getElementById('sub_sub_category_container');
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    })
  }
  setSubSubCategory = (category) => {
    let fields = this.state.fields;
    fields['sub_sub_category'] = category.id;
    this.setState({fields, step: 2})
  }
  render() {
    return (
      <>
        <Offcanvas show={this.props.showModal} onHide={this.props.closeModal} placement="end" backdrop="static" className="event-custom-modal">
          <Offcanvas.Header closeButton><h4>{this.props.selectedEventId || this.props.EventId ? "Edit " : "Add "} Event</h4></Offcanvas.Header>
          <Offcanvas.Body>
            <LoadingOverlay
              active={this.state.overLayLoading}
              spinner
              text="Loading. Please wait..."
              classNamePrefix="mitiz_"
            >
              {this.state.step === 1 && (
                <Form onSubmit={this.handleSubmit} method="post" style={{minHeight:550}}>
                  <Row className="ticket_module">
                    <Col md={12}>
                      <h5>
                        <b><FaQuestionCircle /></b> What are you fundraising for?
                      </h5>
                    </Col>
                    {this.state.categories.map((category, index) => <Col md={3} className="fundraising-event-selection mb-4" onClick={() => this.setCategory(category)} key={`category-${index}`}>
                        <Button
                          varient="outline-primary"
                          className={classnames({
                            selected: parseInt(this.state.fields.category) === parseInt(category.id),
                          })}
                          value={category.id}
                          type="button"
                        >
                          <DynamicIcon name={category.icon} />
                          <h6 style={{ marginBottom: '0px' }}>{category.name}</h6>
                        </Button>
                    </Col>)}
                  </Row>
                  {this.state.subCategories.length > 0 && <Row className="ticket_module">
                    <Col md={12}><h5><b><FaQuestionCircle /></b> {this.state.subCategoryQuesion}</h5></Col>
                    {this.state.subCategories.map((category, index) => <Col md={6} className="fundraising-event-selection mb-4" onClick={() => this.setSubCategory(category)} key={`sub_category-${index}`}>
                        <Button
                          varient="outline-primary"
                          className={classnames({
                            selected: parseInt(this.state.fields.sub_category) === parseInt(category.id),
                          })}
                          value={category.id}
                          type="button"
                        >
                          <DynamicIcon name={category.icon} />
                          <h5>{category.name}</h5>
                          <h6 style={{ marginBottom: '0px' }}>{category.description}</h6>
                        </Button>
                    </Col>)}
                  </Row>}
                  {(parseInt(this.state.fields.category) === 59 || this.state.fields.sub_category) && <Row className="ticket_module">
                    <Col md={12} id="sub_sub_category_container"><h5><b><FaQuestionCircle /></b> {this.state.subSubCategoryQuesion}</h5></Col>
                    {this.state.subSubCategories.map((category, index) => <Col md={4} className="fundraising-event-selection mb-4" onClick={() => this.setSubSubCategory(category)} key={`sub_sub_category-${index}`}>
                        <Button
                          varient="outline-primary"
                          className={classnames({
                            selected: parseInt(this.state.fields.sub_sub_category) === parseInt(category.id),
                          })}
                          value={category.id}
                          type="button"
                        >
                          <DynamicIcon name={category.icon} />
                          <h6>{category.name}</h6>
                        </Button>
                    </Col>)}
                  </Row>}
                  {(this.props.selectedEventId || this.props.EventId) && <Row>
                    <Col className="text-end mt-3" md={12}>
                      {this.renderButtons(1)}
                    </Col>
                  </Row>}
                </Form>
              )}
              {this.state.step === 2 && 
                <Form onSubmit={this.handleSubmit} method="post">
                    {parseInt(this.state.fields.sub_category) === 9 && <NonProfitQuesion
                        fields={this.state.fields}
                        errors={this.state.errors}
                        handleChange={this.handleChange}
                        timeStamp={this.state.timeStamp}
                    />}
                    {parseInt(this.state.fields.category) === 59 && <Row className="smaller-button mb-3">
                      <Col md={12}>
                        <h5><b><FaQuestionCircle /></b> Is this event virtual?</h5>
                      </Col>
                      <Col className="col-sm-3">
                        <Button
                          varient="outline-secondary"
                          className={parseInt(this.state.fields.show_address) === 0 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value={0}
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "show_address"
                            )
                          }
                        >
                          <Form.Check type="checkbox" className="styled-checkbox" id={'show_address_0'} checked={parseInt(this.state.fields.show_address) === 0} label="No" name={'show_address'} onClick={(event) => this.handleChange(event,"show_address")} value={0}/>
                        </Button>
                      </Col>
                      <Col className="col-sm-3">
                        <Button
                          varient="outline-secondary"
                          className={parseInt(this.state.fields.show_address) === 1 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value={1}
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "show_address"
                            )
                          }
                        >
                          <Form.Check type="checkbox" className="styled-checkbox" id={'show_address_1'} checked={parseInt(this.state.fields.show_address) === 1} label="Yes" name={'show_address'} onClick={(event) => this.handleChange(event,"show_address")} value={1} />
                        </Button>
                      </Col>
                    </Row>}
                    {parseInt(this.state.fields.category) === 59 && parseInt(this.state.fields.show_address) === 0 && <Row className="smaller-button mb-3">
                      <Col md={6}>
                            <h5><b><FaQuestionCircle /></b> Where is the venue name?</h5>
                            <Form.Control
                              type="text"
                              id="venue_name"
                              name="venue_name"
                              placeholder="Event Venue"
                              value={
                                this.state.fields.venue_name !== undefined
                                  ? this.state.fields.venue_name
                                  : ""
                              }
                              onChange={(event) => this.handleChange(event, "venue_name")}
                            />
                            {this.state.errors.venue_name && <small className="text-danger">{this.state.errors.venue_name}</small>}
                      </Col>
                      <Col md={6}>
                          <h5><b><FaQuestionCircle /></b> Where is the venue address?</h5>
                          <AsyncSelect
                            cacheOptions
                            name="location"
                            value={
                              this.state.fields["location"] &&
                              this.state.fields["location"]
                            }
                            defaultOptions={this.state.locations}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            loadOptions={this.promiseLocationOptions}
                            onChange={this.handleLocationChange}
                            isClearable={true}
                            placeholder="Enter address"
                          />
                          {this.state.errors.location && <small className="text-danger">{this.state.errors.location}</small>}
                      </Col>
                    </Row>}
                    {parseInt(this.state.fields.sub_category) === 9 && <Row className="smaller-button mb-3">
                      <Col md={12}>
                        <h5>
                          <b><FaQuestionCircle /></b> Do you work for this non-profit?
                        </h5>
                      </Col>
                      <Col md={3} sm={6}>
                        <Button
                          color="outline-primary"
                          className={classnames({
                            selected:
                              parseInt(
                                this.state.fields.work_for_non_profit
                              ) === 1,
                          })}
                          value="1"
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "work_for_non_profit"
                            )
                          }
                        >
                          Yes
                        </Button>
                      </Col>
                      <Col md={3} sm={6}>
                        <Button
                          color="outline-primary"
                          className={classnames({
                            selected:
                              parseInt(
                                this.state.fields.work_for_non_profit
                              ) === 0,
                          })}
                          value="0"
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "work_for_non_profit"
                            )
                          }
                        >
                          No
                        </Button>
                      </Col>
                    </Row>}
                    {parseInt(this.state.fields.category) === 9 && parseInt(this.state.fields.work_for_non_profit) === 1 && <Row className="mb-3">
                      <Col md={12}>
                        <h5>
                          <b><FaQuestionCircle /></b> Would you like to require email collection for each donation?
                        </h5>
                      </Col>
                      <Col md={3} sm={6}>
                        <Button
                          varient="outline-primary"
                          className={classnames({
                            selected:
                              parseInt(
                                this.state.fields.require_email_collection
                              ) === 1,
                          })}
                          value="1"
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "require_email_collection"
                            )
                          }
                        >
                          Yes
                        </Button>
                      </Col>
                      <Col md={3} sm={6}>
                        <Button
                          varient="outlineprimary"
                          className={classnames({
                            selected:
                              parseInt(
                                this.state.fields.require_email_collection
                              ) === 0,
                          })}
                          value="0"
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "require_email_collection"
                            )
                          }
                        >
                          No
                        </Button>
                      </Col>
                    </Row>}
                    {parseInt(this.state.fields.category) === 5 && <Row className="smaller-button mb-3">
                      <Col md={12}>
                        <h5><b><FaQuestionCircle /></b> Does your event have an existing gofundme campaign?</h5>
                      </Col>
                      <Col className="col-sm-3">
                        <Button
                          varient="outline-secondary"
                          className={parseInt(this.state.fields.existing_gofundme_campaign) === 0 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value={0}
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "existing_gofundme_campaign"
                            )
                          }
                        >
                         <Form.Check type="checkbox" id={'existing_gofundme_campaign_0'} checked={parseInt(this.state.fields.existing_gofundme_campaign) === 0} name={'existing_gofundme_campaign'} onClick={(event) => this.handleChange(event,"existing_gofundme_campaign")} value={0} label="Yes"/>
                        </Button>
                      </Col>
                      <Col className="col-sm-3">
                        <Button
                          varient="outline-secondary"
                          className={parseInt(this.state.fields.existing_gofundme_campaign) === 1 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value={1}
                          onClick={(event) =>
                            this.handleChange(
                              event,
                              "existing_gofundme_campaign"
                            )
                          }
                        >
                        <Form.Check type="checkbox" className="styled-checkbox" id={'existing_gofundme_campaign_1'} checked={parseInt(this.state.fields.existing_gofundme_campaign) === 1} name={'existing_gofundme_campaign'} onClick={(event) => this.handleChange(event,"existing_gofundme_campaign")} value={1} label="No"/>
                        </Button>
                      </Col>
                    </Row>}
                    {parseInt(this.state.fields.category) === 5 && parseInt(this.state.fields.existing_gofundme_campaign) === 0 && (
                      <Row className="mb-3">
                        <Col sm="12">
                          <Form.Control
                            as="textarea"
                            id="campaign_url"
                            name="campaign_url"
                            rows={3}
                            placeholder="Add Campaign Url..."
                            isInvalid={!!this.state.errors.campaign_url}
                            onChange={(event) =>
                              this.handleChange(event, "campaign_url")
                            }
                            defaultValue={
                              this.state.fields.campaign_url !== undefined
                                ? this.state.fields.campaign_url
                                : ""
                            }
                          />
                          {this.state.errors.campaign_url && <Form.Control.Feedback type="invalid">{this.state.errors.campaign_url}</Form.Control.Feedback>}
                          <p className="text-primary mb-0"> *Note: Please add one url per line </p>
                        </Col>
                      </Row>
                    )}
                    {parseInt(this.state.fields.category) === 5 && <Row className="smaller-button mb-3">
                    <Col md={12}>
                      <h5>
                        <b><FaQuestionCircle /></b> Event type
                      </h5>
                    </Col>
                    <Col className="col-sm-4">
                      <Button
                        varient="outline-secondary"
                        className={parseInt(this.state.fields.recurring) === 0 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                        value="0"
                        onClick={(event) =>
                          this.handleChange(event, "recurring")
                        }
                      >
                        <Form.Check type="checkbox" className="styled-checkbox" id={'recurring_0'} checked={parseInt(this.state.fields.recurring) === 0} name={'recurring'} onClick={(event) => this.handleChange(event,"recurring")} value={0} label="One time event"/>
                      </Button>
                    </Col>
                    <Col className="col-sm-4">
                      <Button
                        varient="outline-secondary"
                        className={parseInt(this.state.fields.recurring) === 1 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                        value="1"
                        onClick={(event) =>
                          this.handleChange(event, "recurring")
                        }
                      >
                      <Form.Check type="checkbox" className="styled-checkbox" id={'recurring_1'} checked={parseInt(this.state.fields.recurring) === 1} name={'recurring'} onClick={(event) => this.handleChange(event,"recurring")} value={1}  label="Recurring"/>
                      </Button>
                    </Col>
                    <Col className="col-sm-4">
                      <Button
                        varient="outline-secondary"
                        className={parseInt(this.state.fields.recurring) === 2 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                        value={2}
                        onClick={(event) =>
                          this.handleChange(event, "recurring")
                        }
                      >
                      <Form.Check type="checkbox" className="styled-checkbox" id={'recurring_2'} checked={parseInt(this.state.fields.recurring) === 2} name={'recurring'} onClick={(event) => this.handleChange(event,"recurring")} value={2}  label="Ongoing"/>
                      </Button>
                    </Col>
                  </Row>}
                  {(parseInt(this.state.fields.category) === 5 || parseInt(this.state.fields.category) === 59) && <Row className="smaller-button">
                      <Col md={12}>
                        <h5>
                          <b><FaQuestionCircle /></b> How would you like public supporters names to show up?
                        </h5>
                      </Col>
                      <Col sm="12" md="3" className="mb-3">
                        <Button
                          variant="outline-secondary"
                          className={parseInt(this.state.fields.show_name) === 0 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value={0}
                          onClick={(event) =>
                            this.handleChange(event, "show_name")
                          }
                        >
                          <Form.Check type="checkbox" id={'show_name_0'} checked={parseInt(this.state.fields.show_name) === 0} name={'show_name'} onClick={(event) => this.handleChange(event,"show_name")} value={0} label="Anonymous" />
                        </Button>
                      </Col>
                      <Col sm="12" md="3" className="mb-3">
                        <Button
                          variant="outline-secondary"
                          className={parseInt(this.state.fields.show_name) === 1 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value="1"
                          onClick={(event) =>
                            this.handleChange(event, "show_name")
                          }
                        >
                          <Form.Check type="checkbox" id={'show_name_1'} checked={parseInt(this.state.fields.show_name) === 1} name={'show_name'} onClick={(event) => this.handleChange(event,"show_name")} value={1}  label="First Name"/>
                        </Button>
                      </Col>
                      <Col sm="12" md="3" className="mb-3">
                        <Button
                          varient="outline-secondary"
                          className={parseInt(this.state.fields.show_name) === 2 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value="2"
                          onClick={(event) =>
                            this.handleChange(event, "show_name")
                          }
                        >
                          <Form.Check type="checkbox" id={'show_name_2'} checked={parseInt(this.state.fields.show_name) === 2} name={'show_name'} onClick={(event) => this.handleChange(event,"show_name")} value={2}  label="Full Name"/>
                        </Button>
                      </Col>
                      <Col sm="12" md="3" className="mb-3">
                        <Button
                          varient="outline-primary"
                          className={parseInt(this.state.fields.show_name) === 3 ? 'selected d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'}
                          value="3"
                          onClick={(event) =>
                            this.handleChange(event, "show_name")
                          }
                        >
                          <Form.Check type="checkbox" id={'show_name_3'} checked={parseInt(this.state.fields.show_name) === 3} name={'show_name'} onClick={(event) => this.handleChange(event,"show_name")} value={3}  label="Initials"/>
                        </Button>
                      </Col>
                  </Row>}
                  <Row className="mb-4">
                      <Col md={12}>
                          <h5>Currency </h5>
                          <div>
                              {common.currencySymbols.map((currency, index) => <Form.Check type="radio" id={currency} label={`${currency}(${common.currencySymbolsArr[index]})`} inline name="currency" value={index} onChange={(event) => this.handleChange(event, "currency")} checked={parseInt(this.state.fields.currency) === index} key={`curr-${index}`}/>)}
                          </div>
                      </Col>
                  </Row>
                  {parseInt(this.state.fields.category) === 59 && <Row>
                    <Col>
                      <ManageTickets
                        eventId={this.props.selectedEventId ? this.props.selectedEventId : this.props.EventId}
                        updateTickets={this.updateTickets}
                        updateFormData={this.updateFormData}
                        formData={this.state.fields.form_data ? this.state.fields.form_data : []}
                        handleChange={this.handleChange}
                        service_fee={this.state.fields.service_fee}
                        currency={this.state.fields.currency}
                        allowCustomPrice={this.state.fields.allow_custom_price}
                        physicalTicket={this.state.fields.physical_ticket}
                      />
                      {this.state.errors.tickets && <p className="text-danger">{this.state.errors.tickets}</p>}
                    </Col>
                  </Row>}
                  {parseInt(this.state.fields.category) === 75 && <Row>
                    <Col>
                      <ManagePrograms
                        eventId={this.props.selectedEventId ? this.props.selectedEventId : this.props.EventId}
                        updatePrograms={this.updatePrograms}
                        updateFormData={this.updateFormData}
                        formData={this.state.fields.form_data ? this.state.fields.form_data : []}
                        handleChange={this.handleChange}
                        currency={this.state.fields.currency}
                      />
                      {this.state.errors.programs && <p className="text-danger">{this.state.errors.programs}</p>}
                    </Col>
                  </Row>}
                  {parseInt(this.state.fields.sub_category) === 70 && <Row>
                    <Col>
                      <ManageGroups
                        eventId={this.props.selectedEventId ? this.props.selectedEventId : this.props.EventId}
                        updateTeams={this.updateTeams}
                        updateFormData={this.updateFormData}
                        formData={this.state.fields.form_data ? this.state.fields.form_data : []}
                      />
                      {this.state.errors.teams && <small className="text-danger">{this.state.errors.teams}</small>}
                    </Col>
                  </Row>}
                  {parseInt(this.state.fields.category) === 57 && <Row>
                      <Col>
                        <ManageMembershipPlans
                          eventId={this.props.selectedEventId ? this.props.selectedEventId : this.props.EventId}
                          updatePlans={this.updatePlans}
                          updateFormData={this.updateFormData}
                          formData={this.state.fields.form_data ? this.state.fields.form_data : []}
                        />
                      </Col>
                  </Row>}
                  <Row>
                    <Col className="text-end mt-3" md={12}>
                      {this.renderButtons(2)}
                    </Col>
                  </Row>
                </Form>
              }
              {this.state.step === 3 && (
                <Form onSubmit={this.handleSubmit} method="post">
                  <Row>
                    <Form.Group as={Col} md={12} className="mb-2">
                      <Form.Label id="title">Event Title</Form.Label>
                        <Form.Control
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Event Title"
                          value={
                            this.state.fields.title !== undefined
                              ? this.state.fields.title
                              : ""
                          }
                          onChange={(event) => this.handleChange(event, "title")}
                        />
                        {this.state.errors.title && <small className="text-danger">{this.state.errors.title}</small>}
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-2">
                      <Form.Label id="target_amount">Targeted amount </Form.Label> <div className="float-end mt-1" style={{fontSize:14}}><Form.Check type="checkbox" name="show_target" onChange={(event) => this.handleChange(event, "show_target")} checked={parseInt(this.state.fields.show_target) === 1} label="Display on event details page"/></div> 
                      <Form.Control
                        type="number"
                        id="target_amount"
                        name="target_amount"
                        placeholder="Targeted amount($)"
                        value={
                          this.state.fields.target_amount !== undefined
                            ? this.state.fields.target_amount
                            : ""
                        }
                        invalid={this.state.errors.target_amount ? true : false}
                        onChange={(event) =>
                          this.handleChange(event, "target_amount")
                        }
                        onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                      />
                      {this.state.errors.target_amount && <Form.Control.Feedback tpe="invalid">{this.state.errors.target_amount}</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-2">
                      <Form.Label htmlFor="file">Event Image</Form.Label>
                      <Form.Control
                        type="file"
                        id="file"
                        name="file"
                        onChange={(event) => this.uploadFile(event)}
                        style={{ border: "1px solid #e4e7ea" }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-2">
                      <Form.Label for="start_date">Start Date</Form.Label>
                      <DatePicker
                        className="form-control"
                        selected={
                          this.state.fields["start_date"]
                            ? this.state.fields["start_date"]
                            : null
                        }
                        onChange={this.handleStartDate}
                        dateFormat="MM-dd-yyyy h:mm aa"
                        showTimeSelect
                      />
                    </Form.Group>
                    <Form.Group as={Col}  md="6" className="mb-2">
                      <Form.Label htmlFor="end_date">End Date</Form.Label>
                      <DatePicker
                        className="form-control"
                        selected={
                          this.state.fields["end_date"]
                            ? this.state.fields["end_date"]
                            : null
                        }
                        onChange={this.handleEndDate}
                        dateFormat="MM-dd-yyyy h:mm aa"
                        showTimeSelect
                        disabled={parseInt(this.state.fields.recurring) === 2}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-2">
                      <Form.Label id="description">Event Description</Form.Label>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={this.state.description}
                            onChange={ ( event, editor ) => {
                              this.handleDescription(editor.getData())
                            } }
                        />
                        {this.state.errors.description && (
                          <span style={{ color: "red" }}>
                            {this.state.errors.description}
                          </span>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md={4} sm="12" className="mb-2">
                      <Form.Label>Event Type</Form.Label>
                        <div>
                          <Form.Check type="radio" id="public" label="Public" inline name="event_type" value="0" onChange={(event) => this.handleChange(event, "event_type")} checked={parseInt(this.state.fields.event_type) === 0} />
                          <Form.Check type="radio" id="private" label="Private" inline name="event_type" value="1" onChange={(event) => this.handleChange(event, "event_type")} checked={parseInt(this.state.fields.event_type) === 1} />
                        </div>
                        {(this.state.fields.event_type && parseInt(this.state.fields.event_type) === 1) ? <div>
                          <Form.Control type="text" id="passcode" inline name="passcode" className="form-control mt-2" onChange={(event) => this.handleChange(event, "passcode")} style={{ width: 300 }} placeholder="Enter passcode" /> 
                          <small>Please make sure you copied the passcode as it will not be visible again.</small>
                        </div> : <div></div>}
                        
                    </Form.Group>
                    <Form.Group as={Col} md={4} sm="12" className="mb-2">
                        <Form.Label>Show Supporters</Form.Label>
                        <div>
                          <Form.Check type="radio" id="yes" label="Yes" inline name="show_supporters" value="1" onChange={(event) => this.handleChange(event, "show_supporters")} checked={parseInt(this.state.fields.show_supporters) === 1} />
                          <Form.Check type="radio" id="no" label="No" inline name="show_supporters" value="0" onChange={(event) => this.handleChange(event, "show_supporters")} checked={parseInt(this.state.fields.show_supporters) === 0} />
                        </div>
                    </Form.Group>
                    <Form.Group as={Col} md={4} sm="12" className="mb-2">
                        <Form.Label>Show Tipping App</Form.Label>
                        <div>
                          <Form.Check inline  type="radio" id="show_tipping_app_0" label="Yes" name="show_tipping_app" value="1" onChange={(event) => this.handleChange(event, "show_tipping_app")} checked={parseInt(this.state.fields.show_tipping_app) === 1} />
                          <Form.Check type="radio" id="show_tipping_app_1" label="No" inline name="show_tipping_app" value="0" onChange={(event) => this.handleChange(event, "show_tipping_app")} checked={parseInt(this.state.fields.show_tipping_app) === 0} />
                        </div>
                    </Form.Group>
                    <Form.Group as={Col} md={6} sm="12" className="mb-2">
                      <Form.Label htmlFor="button_text">Buy Button Text</Form.Label>
                        <Form.Control
                            type="text"
                            id="button_text"
                            name="button_text"
                            value={
                              this.state.fields.button_text !== undefined
                                ? this.state.fields.button_text
                                : ""
                            }
                            onChange={(event) => this.handleChange(event, "button_text")}
                          />
                      </Form.Group>
                      <Form.Group as={Col} md={12} sm="12" className="text-end">
                        {this.renderButtons(3)}
                      </Form.Group>
                  </Row>
                </Form>
              )}
            </LoadingOverlay>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(AddEditEvent);

export function DynamicIcon(props) {
  const IconComponent = Icons[props.name];
  return <IconComponent size={45}/>; 
}
