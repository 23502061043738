import React, {useState, useEffect} from "react";
import { Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom"
import plans from "../../../services/plans";
import { toast } from "react-toastify";
import Signup from "./Signup";

const Pricing = () => {
  const [planArr, setPlans] = useState([]);
  const [signupModal, setSignupModal] = useState(false);
  const [plan, setPlan] = useState(null);

  useEffect(()=>{
    plans
      .getPlans()
      .then((res) => {
        if (res.data.success) {
          setPlans(res.data.plans);
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  },[])
    return (
      <>
        <Helmet>
          <title>Home - Pricing</title>
        </Helmet>
        <div className="discover-event tiphub-banner pb-5">
        <div className="tiphub-banner-bg h-100"></div>
          <Container>
            <div className="discover-event-filter">
              <h2 className="text-center mb-4">
                <strong>Pricing</strong>
              </h2>
              <p className="text-center mb-5">
                Sign up in less than 30 seconds. Try out our 7 day risk free
                trail, upgrade at anytime, no questions, no hassle
              </p>
            </div>
            <div className="pricing-wrapper">
              {planArr.map((plan, index)=><div className="pricing-box">
                <h4 className="mb-3">{plan.plan}</h4>
                <h1 className="mb-4">
                  {parseInt(plan.monthly_price) === 0 ? <span>Free</span> : <><span>$</span>{plan.monthly_price}<span>/mo</span></>}
                </h1>
                <div dangerouslySetInnerHTML={{__html:plan.description}}></div>
                <Button color="outline-dark" size="md" type="button" onClick={()=>{setPlan(plan.id);setSignupModal(true)}}>
                  SIGN UP TODAY
                </Button>
              </div>)}
            </div>
          </Container>
        </div>
        {signupModal && (
          <Signup   
            showModal={signupModal} 
            closeModal={setSignupModal} 
            plan={plan}
          />
        )}
      </>
    );
}

export default Pricing;
