import React, { useEffect, useState } from "react";
import { Card, Alert } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import user from "../../../services/user";
import moment from "moment";

const Membership = (props) => {
  const [membership, setMembership] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    user
      .getMembership()
      .then((res) => {
        if (res.data.success) {
          setMembership(res.data.membership);
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Card className="modified-card">
      <Card.Header><h4 className="mb-0 p-2">Membership</h4></Card.Header>
      <Card.Body style={{ minHeight: 400 }}>
        <LoadingOverlay active={loading} spinner text="Loading. Please wait...">
          {membership !== null ? (
            <Alert variant="success" style={{ fontSize: 18 }} className="text-center">
              Your current plan is <strong>{membership.plan.plan}</strong>,{" "}
              {moment(
                moment(membership.plan_ends_on).format("YYYY-MM-DD")
              ).isBefore(moment().format("YYYY-MM-DD"))
                ? "expires"
                : "expired"}{" "}
              on{" "}
              <strong>
                {moment(membership.plan_ends_on).format("D MMM, YYYY")}
              </strong>
              .
            </Alert>
          ) : (
            <p className="text-center">
              You donot have any active subscription
            </p>
          )}
        </LoadingOverlay>
      </Card.Body>
    </Card>
  );
};

export default Membership;
