/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import {
    Badge, Button, Modal, Table, Spinner, InputGroup, Row, Col, Form, FloatingLabel
} from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import user from "../../../services/user";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class ManageVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
        vendors: [],
        errors: {},
        fields:{event_id: this.props.eventDetails.id},
        submitted: false,
        loader: false,
        addEditVendorModal:false, 
        allotmentsModal:this.props.showModal,
        selectedRecords:[],
        showEmailModal:false,
        showSmsModal:false
    };
}
getVendors(){
    events
    .getVendors({
        event_id: this.props.eventDetails.id,
    })
    .then((res) => {
        if (res.data.success) {
            this.setState({ vendors: res.data.vendors, loader: false  })
        } else if (res.data.error) {
            console.log(res.data.message);
        }
    })
    .catch((err) => {
        console.log(err);
    });
}
componentDidMount(){
    this.getVendors();
}
countTotalSoldTickets = (allotments) => {
    let totalTickets = 0;
    if(allotments.length > 0){
        allotments.forEach(allotment=>{
            if(allotment.ticket.bookings.length > 0){
                allotment.ticket.bookings.forEach(booking=>{
                    totalTickets = totalTickets + parseInt(booking.total_tickets);
                })
            }
        })
    }
    return totalTickets;
}
countTotalAllotedTickets = (allotments) => {
    let totalTickets = 0;
    if(allotments.length > 0){
        allotments.forEach(allotment=>{
            totalTickets = totalTickets + parseInt(allotment.total_tickets);
        })
    }
    return totalTickets;
}
checkUserExistence = (e) => {
    e.preventDefault();
    if(this.state.fields.inputemail !== ''){
        this.setState({ loader: true });
        user.checkUserExistence({ email: this.state.fields.inputemail }).then((res) => {
            this.setState({ loader: false });
            if (!res.data.found) {
              this.setState({ allotmentsModal:false, addEditVendorModal: true});
            } else {
              if(res.data.message == "User added successfully."){
                this.getVendors();
              }
            }
        });
    }
};
validateForm = () => {
let formIsValid = true;
const errors = {};
if (!this.state.fields.name) {
    errors["name"] = "Name can not be empty.";
    formIsValid = false;
}
if (!this.state.fields.username) {
    errors["username"] = "Username can not be empty.";
    formIsValid = false;
}
if (!this.state.fields.email) {
    errors["email"] = "Email can not be empty.";
    formIsValid = false;
}
if (!this.state.fields.phone) {
    errors["phone"] = "Phone can not be empty.";
    formIsValid = false;
    }
if (!this.state.fields.password) {
    errors["password"] = "Password can not be empty.";
    formIsValid = false;
}
if (!this.state.fields.confirm_password) {
    errors["confirm_password"] = "Confirm password can not be empty.";
    formIsValid = false;
}
if (
    this.state.fields.password &&
    this.state.fields.password !== this.state.fields.confirm_password
) {
    errors["confirm_password"] = "Confirm did not match with your password.";
    formIsValid = false;
}
this.setState({ errors });
return formIsValid;
};
addNewMember = (e) => {
e.preventDefault();
  if (this.validateForm()) {
      this.setState({ submitted: true });
      let fields = this.state.fields;
      user.addTeam({ fields }).then((res) => {
        this.setState({ submitted: false });
        if (res.data.success) {
            this.setState({ userFound: true, fields: {}, addEditVendorModal:false, allotmentsModal: true }, ()=>{
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              this.getVendors();
            });
        } else {
            if (res.data.error) {
            this.setState({ errors: res.data.message });
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        }
      });
  }
};
handleChange = (e, field) => {
    let errors = this.state.errors;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    errors[field] = '';
    this.setState({ fields, errors });
};
selectAll = (e) => {
    let checkboxes = document.querySelectorAll('input[name=members]');
    checkboxes.forEach(c=>{
      c.checked = e.target.checked;
    });
    let selRecords = [];
    if(e.target.checked){
      this.state.records.forEach((d,index)=>{
        selRecords[index] = d.id;
      });
    }
    this.setState({selectedRecords:selRecords});
  }
  selectRecord = (e) => {
    let selRecords = [...this.state.selectedRecords];
    if (e.target.checked) {
      selRecords.push(e.target.value);
      selRecords = [...this.state.selectedRecords, e.target.value]
    } else {
      selRecords.splice(selRecords.indexOf(e.target.value), 1);
    }
    this.setState({selectedRecords: selRecords});
  };
    render() {
        return (
            <>
              <Modal 
                    show={this.state.allotmentsModal}
                    onHide={() => this.props.closeModal(false)}
                    dialogClassName="modal-90w"
                    fullscreen={true}
                >
                
                    <Modal.Header closeButton><p className="mb-1" style={{fontSize:25}}>Physical Ticket Allotments<small style={{fontSize:15, clear:'both',display:'block'}}>{this.props.eventDetails.title}</small></p></Modal.Header>
                    <Modal.Body className="pt-3">
                        <Row>
                            <Col className="d-flex align-items-center justify-content-center h-100">
                                <Form onSubmit={this.checkUserExistence}>
                                    <InputGroup className="mb-3" size="lg" hasValidation>
                                      <Form.Control
                                          name="inputemail" 
                                          type="text" 
                                          onChange={(event) => this.handleChange(event, 'inputemail')} 
                                          isInvalid={!!this.state.errors.inputemail}
                                      />
                                      <Button type="submit" variant="success" disabled={this.state.submitted}>{this.state.loader && <Spinner variant="light" className="me-1" style={{width:20, height:20}}/>}Add New Vendor</Button>
                                      <Form.Control.Feedback type="invalid">{this.state.errors.passcode}</Form.Control.Feedback>
                                  </InputGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Table className="table table-responsive">
                        <thead>
                            <tr>
                                <th className="border-top-0"><input type="checkbox" onChange={this.selectAll} value={0}/></th>
                                <th className="border-top-0">#</th>
                                <th className="border-top-0">Name</th>
                                <th className="border-top-0">Email</th>
                                <th className="border-top-0">Phone</th>
                                <th className="border-top-0">Total Tickets Alloted</th>
                                <th className="border-top-0" colSpan={3}>Total Tickets Sold</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.vendors.map((ele, index) => (
                                <tr key={`bookings-${index}`}>
                                    <td className="border-bottom-0"><input type="checkbox" onChange={this.selectRecord} value={ele.email} name="members"/></td>
                                    <td className="border-bottom-0">{index + 1}.</td>
                                    <td className="border-bottom-0">{ele.name}</td>
                                    <td className="border-bottom-0">{ele.email}</td>
                                    <td className="border-bottom-0">{ele.phone}</td>
                                    <td className="border-bottom-0">{this.countTotalAllotedTickets(ele.allotments)}</td>
                                    <td className="border-bottom-0">{this.countTotalSoldTickets(ele.allotments)}</td>
                                    <td className="border-bottom-0"><Button variant="primary" size="sm" onClick={()=>{
                                        this.props.choseVendor(ele);
                                        this.props.closeModal(false);
                                        this.props.showAllotPhysicalTicketModal(true);
                                    }}>Assign</Button></td>
                                    <td className="border-bottom-0"><Button variant="warning" size="sm" onClick={()=>this.props.openPrintTicketsModal(ele.allotments)}>Print Tickets</Button></td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={8} className="border-top-0">
                                    <Button type="button" variant="success" disabled={this.state.selectedRecords.length === 0} className="me-2" onClick={()=>this.setState({showEmailModal:true})}><Badge bg="primary" style={{width:'auto',overflow:'auto'}}>{this.state.selectedRecords.length}</Badge> Send Email</Button> 
                                    <Button type="button" className="me-2" variant="warning" disabled={this.state.selectedRecords.length === 0} onClick={()=>this.setState({showSmsModal:true})}><Badge bg="primary" style={{width:'auto',overflow:'auto'}}>{this.state.selectedRecords.length}</Badge> Send SMS</Button>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
                <Modal 
                    show={this.state.addEditVendorModal}
                    onHide={this.props.closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="md"
                >
                    <Modal.Header><p className="mb-1" style={{fontSize:25}}>Add New Vendor<small style={{fontSize:15, clear:'both',display:'block'}}>{this.props.eventDetails.title}</small></p></Modal.Header>
                    <Form method="post" onSubmit={this.addNewMember}>
                        <Modal.Body className="pt-0">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Full Name"
                              className="mb-3"
                              >
                              <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="name"
                                  onChange={(event) => this.handleChange(event, "name")}
                                  isInvalid={this.state.errors.name}
                              />
                              <Form.Control.Feedback type="invalid">{this.state.errors.name}</Form.Control.Feedback>
                            </FloatingLabel>

                              <FloatingLabel
                                  controlId="floatingInput"
                                  label="Username"
                                  className="mb-3"
                                  >
                                  <Form.Control
                                      type="text"
                                      name="username"
                                      placeholder="username"
                                      onChange={(event) => this.handleChange(event, "username")}
                                      isInvalid={this.state.errors.username}
                                  />
                                  <Form.Control.Feedback type="invalid">{this.state.errors.username}</Form.Control.Feedback>
                              </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                    className="mb-3"
                                    >
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder="email"
                                        onChange={(event) => this.handleChange(event, "email")}
                                        isInvalid={this.state.errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{this.state.errors.email}</Form.Control.Feedback>
                                </FloatingLabel>
                                <PhoneInput
                                      country={'us'}
                                      value={this.state.fields.phone ? this.state.fields.phone : ""}
                                      onChange={(phone) => {
                                          let fields = this.state.fields;
                                          fields['phone'] = phone;
                                          this.setState({fields})
                                      }}
                                      inputClass="w-100"
                                      enableSearch={true}
                                  />  
                                  <small>{this.state.errors["phone"]}</small> 
                                  <FloatingLabel
                                    controlId="floatingInput"
                                    label="Password"
                                    className="mb-3"
                                    >
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder="password"
                                        onChange={(event) => this.handleChange(event, "password")}
                                        isInvalid={this.state.errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">{this.state.errors.email}</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Confirm Password"
                                    className="mb-3"
                                    >
                                    <Form.Control
                                        type="password"
                                        name="confirm_password"
                                        placeholder="confirm_password"
                                        onChange={(event) => this.handleChange(event, "confirm_password")}
                                        isInvalid={this.state.errors.confirm_password}
                                    />
                                    <Form.Control.Feedback type="invalid">{this.state.errors.email}</Form.Control.Feedback>
                                </FloatingLabel>  
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="danger" type="button" className="mr-2" onClick={()=>this.setState({addEditVendorModal:false, allotmentsModal: true})}>Cancel</Button>
                            <Button color="success" type="submit">{this.state.submitted && <Spinner size="sm" className="mr-1"/>}Submit</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                {this.state.showEmailModal && <SendEmailModal 
                    showModal = {this.state.showEmailModal} 
                    closeModal={()=>this.setState({showEmailModal:false})}
                    eventId={this.props.eventDetails.id}
                    selectedRecords={this.state.selectedRecords}
                />}
                {this.state.showSmsModal && <SendSmsModal 
                    showModal = {this.state.showSmsModal} 
                    closeModal={()=>this.setState({showSmsModal:false})}
                    eventId={this.props.eventDetails.id}
                    selectedRecords={this.state.selectedRecords}
                />}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(ManageVendors);

export function SendEmailModal(props) {
    const [submitted, setSubmitted] = useState(false)
    const [fields, setFields] = useState({emails:props.selectedRecords})
    const [errors, setErrors] = useState({})
    const [email_content, setEmailContent] = useState('')
  
    const handleChange = (e) => {
      setFields({...fields, [e.target.name]: e.target.value})
    }
  
    const handleSubmit = (e) => {
      const errors = {};
      e.preventDefault();
      if(!fields.email_subject){
        errors['email_subject'] = 'Subject can not be blank.';
      }
      if(email_content === ''){
        errors['email_content'] = 'Email content can not be blank.';
      }
      else{
        setSubmitted(true);
        events.sendEmailPaymentReminder({...fields, email_content})
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.closeModal();
          } else if (res.data.error) {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
      setErrors(errors);
    }
    return (
        <Modal 
            show={props.showModal}
            onHide={props.closeModal}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
        <Modal.Header closeButton>Send Email</Modal.Header>
          <Form onSubmit={handleSubmit} method="post">
            <Modal.Body>
              <FloatingLabel
                  controlId="floatingInput"
                  label="Subject"
                  className="mb-3"
                  >
                  <Form.Control
                      type="text"
                      name="email_subject"
                      placeholder="email_subject"
                      onChange={(event) => handleChange(event, "email_subject")}
                      isInvalid={errors.email_subject}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email_subject}</Form.Control.Feedback>
              </FloatingLabel>  
              <Form.Label>Email Content</Form.Label>
              <CKEditor
                  editor={ ClassicEditor }
                  data={email_content}
                  onChange={ ( event, editor ) => {
                      handleChange(editor.getData())
                  } }
              />
              {errors.email_content && (
                <span style={{ color: "red" }}>
                  {errors.email_content}
                </span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={()=>props.closeModal()}>Cancel</Button>
              <Button
                variant="success"
                type="submit"
                disabled={submitted}
              >
                {submitted && (
                  <Spinner
                    variant="light"
                    size="sm"
                  />
                )}
                Send
              </Button>
            </Modal.Footer>
          </Form>
      </Modal>
    );
  }
  
  export function SendSmsModal(props) {
    const [submitted, setSubmitted] = useState(false)
    const [fields, setFields] = useState({eventId: props.eventId, selectedRecords:props.selectedRecords})
    const [errors, setErrors] = useState({})
  
    const handleChange = (e) => {
      setFields({...fields, [e.target.name]: e.target.value})
    }
  
    const handleSubmit = (e) => {
      const errors = {};
      e.preventDefault();
      if(!fields.sms_content){
        errors['sms_content'] = 'Sms content can not be blank.';
      }
      else{
        setSubmitted(true);
        events.sendPaymentReminder(fields).then((res) => {
            if (res.data.success) {
              setSubmitted(false);
              toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
              });
              props.closeModal();  
            } else if (res.data.error) {
              toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
              });
              setSubmitted(false);
            }
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setSubmitted(false);
        });
      }
      setErrors(errors);
    }
    return (
      <Modal 
            show={props.showModal}
            onHide={props.closeModal}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
        <Modal.Header closeButton>Send SMS</Modal.Header>
          <Form onSubmit={handleSubmit} method="post">
            <Modal.Body>
              <Form.Group>
                <Form.Controll
                  type="textarea"
                  name="sms_content"
                  id="sms_content"
                  value={
                    fields["sms_content"] ? fields["sms_content"] : ""
                  }
                  onChange={(event) => handleChange(event, "sms_content")}
                  invalid={errors["sms_content"] ? true : false}
                  rows={5}
                  maxLength={160}
                />
                {errors["sms_content"] && (
                  <Form.Controll.Feedback type="invalid">{errors["sms_content"]}</Form.Controll.Feedback>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button color="danger" onClick={()=>props.closeModal()}>Cancel</Button>
              <Button
                variant="success"
                type="submit"
                disabled={submitted}
              >
                {submitted && (
                  <Spinner
                    variant="light"
                    size="sm"
                  />
                )}
                Send
              </Button>
            </Modal.Footer>
          </Form>
      </Modal>
    );
  }