/* eslint-disable react/prop-types */
import { Button, Modal, FloatingLabel, Form, Spinner } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import common from "../../../services/common";
import { connect } from "react-redux";


class AddEditTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            records: [],
            showModal: false,
            fields: { event_id: this.props.eventId, visibility: 0},
            errors: {},
            submitted: false,
        };
    }
    handleChange = (e, field) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    };
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Please enter name of the ticket.";
        }
        if (!fields["price"]) {
            formIsValid = false;
            errors["price"] = "Please enter price.";
        }
        if (!fields["max_limit"]) {
            formIsValid = false;
            errors["max_limit"] = "Please enter limit of tickets to be sold.";
        }
        if (fields["max_limit"] && fields["physical_ticket_limit"] && fields["physical_ticket_limit"] !== "" && fields["max_limit"] !== "" && parseInt(fields["max_limit"]) < parseInt(fields["physical_ticket_limit"])) {
            formIsValid = false;
            errors["physical_ticket_limit"] = "Physical ticket limit must be lesser or equal to max limit.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ submitted: true });
            let params = { fields: this.state.fields };
            if (this.props.eventId) {
                events
                    .saveTicket(params)
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({ submitted: false }, () => {
                                this.props.listRecords();
                                this.props.closeModal();
                                toast.success(res.data.message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                        } else if (res.data.error) {
                            this.setState({ errors: res.data.message, submitted: false });
                        }
                    })
                    .catch(() => {
                        toast.error("Unexpected Error !", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }
            else {
                this.props.updateRecords(this.state.fields);
            }
        }
    };
    componentDidMount = () => {
        if (this.props.ticketId) {
            this.getTicketDetails();
        }
    }

    getTicketDetails = () => {
        this.setState({ loader: true });
        events
            .getTicketDetails({
                id: this.props.ticketId
            })
            .then((res) => {
                if (res.data.success) {
                    this.setState({ fields: res.data.ticket, loader: false });
                } else if (res.data.error) {
                    console.log(res.data.message);
                }
                this.setState({ loader: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    render() {
        return (
            <Modal 
                show={this.props.showModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"
            >
                <Modal.Header closeButton>Add Ticket</Modal.Header>
                <Modal.Body>
                    <LoadingOverlay
                        active={this.state.loader}
                        spinner
                        text="Loading..."
                    >
                        <div className="mb-2">
                            <Form.Check
                                inline
                                label="Public"
                                name="visibility"
                                type="radio"
                                id="public"
                                value={0}
                                onChange={(event) => this.handleChange(event, "visibility")}
                                checked={parseInt(this.state.fields["visibility"]) === 0}
                            />
                            <Form.Check
                                inline
                                label="Private"
                                name="visibility"
                                type="radio"
                                id="private"
                                value={1}
                                checked={parseInt(this.state.fields["visibility"]) === 1}
                                onChange={(event) => this.handleChange(event, "visibility")}
                            />
                        </div>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            className="mb-3"
                            >
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="name"
                                onChange={(event) => this.handleChange(event, "name")}
                                isInvalid={this.state.errors.name}
                                value={
                                    this.state.fields["name"]
                                        ? this.state.fields["name"]
                                        : ""
                                }
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.name}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Price"
                            className="mb-3"
                            >
                            <Form.Control
                                type="number"
                                name="price"
                                placeholder="price"
                                onChange={(event) => this.handleChange(event, "price")}
                                isInvalid={this.state.errors.price}
                                value={
                                    this.state.fields["price"]
                                        ? this.state.fields["price"]
                                        : ""
                                }
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.price}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Max Limit"
                            className="mb-3"
                            >
                            <Form.Control
                                type="number"
                                name="max_limit"
                                placeholder="max_limit"
                                onChange={(event) => this.handleChange(event, "max_limit")}
                                isInvalid={this.state.errors.max_limit}
                                value={
                                    this.state.fields["max_limit"]
                                        ? this.state.fields["max_limit"]
                                        : ""
                                }
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.max_limit}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Physical Ticket Limit"
                            className="mb-3"
                            >
                            <Form.Control
                                type="number"
                                name="physical_ticket_limit"
                                placeholder="physical_ticket_limit"
                                onChange={(event) => this.handleChange(event, "physical_ticket_limit")}
                                isInvalid={this.state.errors.physical_ticket_limit}
                                value={
                                    this.state.fields["physical_ticket_limit"]
                                        ? this.state.fields["physical_ticket_limit"]
                                        : ""
                                }
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.physical_ticket_limit}</Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Description"
                            className="mb-3"
                            >
                            <Form.Control
                                type="textarea"
                                name="description"
                                placeholder="description"
                                onChange={(event) => this.handleChange(event, "description")}
                                isInvalid={this.state.errors.description}
                                value={
                                    this.state.fields["description"]
                                        ? this.state.fields["description"]
                                        : ""
                                }
                            />
                            <Form.Control.Feedback type="invalid">{this.state.errors.description}</Form.Control.Feedback>
                        </FloatingLabel>
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        type="button"
                        disabled={this.state.submitted}
                        onClick={this.handleSubmit}
                    >
                        {this.state.submitted && (
                            <Spinner
                                size="sm"
                                variant="lingt"
                                className="mr-1"
                            />
                        )}
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(AddEditTicket);
