import React, { useState } from "react";
import { Link } from "react-router-dom";
import user from "../../../services/user";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  Col,
  Row,
  Spinner, Button, Form
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

const ResetPassword = (props) => {
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const { token } = useParams();
  const navigate = useNavigate();


  const handleChange = (field, e) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true)
    const params = {
      new_password: fields.new_password ? fields.new_password : "",
      confirm_password: fields.confirm_password ? fields.confirm_password : "",
      token: token ? token : ""
    };
    user.resetPassword(params).then((response) => {
      if (response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/home");
      } else if (response.data.error) {
        setSubmitted(false)
        setFields({})
        if (typeof response.data.message === "object") {
          setErrors(response.data.message);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Reset Password : Tiphub.co</title>
      </Helmet>
      <section className="login-enter-section tiphub-banner pb-5">
        <div className="tiphub-banner-bg h-100"></div>
        <div id="lp-register">
          <div className="container wrapper">
            <Row>
              <Col sm={5} className="mx-auto custom-label">
                <div className="card log-reg-area shadow py-4">
                  <div className="log-reg">
                    <h4 className="login-title text-center">
                      Reset your password
                    </h4>
                    <form
                      className="px-5 login-form"
                      method="post"
                      onSubmit={handleSubmit}
                    >
                      <Form.Group>
                        <Form.Label for="new_password">
                          New Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="new_password"
                          id="new_password"
                          value={
                            fields["new_password"]
                              ? fields["new_password"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange("new_password", event)
                          }
                          isInvalid={errors["new_password"]}
                        />
                        {errors["new_password"] && (
                          <Form.Control.Feedback type="invalid">
                            {errors["new_password"]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label for="confirm_password">Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirm_password"
                          id="confirm_password"
                          value={
                            fields["confirm_password"]
                              ? fields["confirm_password"]
                              : ""
                          }
                          onChange={(event) =>
                            handleChange("confirm_password", event)
                          }
                          isInvalid={errors["confirm_password"]}
                        />
                        {errors["confirm_password"] && (
                          <Form.Control.Feedback type="invalid">
                            {errors["confirm_password"]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <div className="mt-3 d-flex justify-content-between">
                        <Button
                          variant="success"
                          type="submit"
                          disabled={submitted}
                        >
                          {submitted && (
                            <Spinner
                              size="sm"
                              color="#887d7d"
                              className="mr-1"
                            />)} Submit
                        </Button>
                        <span className="pt-2">Or</span>
                        <span className="pt-2">
                          Return to{" "}
                          <Link to="/">
                            <Button
                              variant="link"
                              className="p-0"
                              style={{ marginTop: "-2px" }}
                            >
                              Login
                            </Button>
                          </Link>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ResetPassword;
