import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Tab,
  Tabs,
  Card,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import "bootstrap-daterangepicker/daterangepicker.css";
import Events from "./Events";
import Profile from "./Profile";
import { BiUser, BiCalendarCheck } from "react-icons/bi";

const Reports = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Fragment>
      <Helmet>
        <title>Reports - Tiphub</title>
      </Helmet>
      <div className="reports-overview tiphub-banner pb-5">
        <div className="tiphub-banner-bg h-100"></div>
        <div className="container">
          <div className="reports">
            <Row>
              <Col sm="12">
                <h2 className="text-center mb-4">
                  <b>Reports Overview</b>
                </h2>
              </Col>
            </Row>
            <div className="reports-nav">
              <Row>
                <Col sm="12">
                  <Card>
                    <Card.Body>
                      <Tabs
                        defaultActiveKey="profile"
                        id="report-tabs"
                        className="mb-3"
                        justify
                      >
                        <Tab
                          eventKey="profile"
                          title={
                            <span>
                              <BiUser /> Profile
                            </span>
                          }
                        >
                          <Profile />
                        </Tab>
                        <Tab
                          eventKey="events"
                          title={
                            <span>
                              <BiCalendarCheck /> Events
                            </span>
                          }
                        >
                          <Events />
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Reports;
