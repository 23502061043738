/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {Form, FloatingLabel, Button, Spinner, Modal} from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import common from "../../../services/common";

class DwollaPaymentForm extends Component {
  constructor(props) {
    super(props);
        this.state = {
            fields: {},
            errors: {},
            loader: false,
            submitted:false,
        };
    }
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "Please enter your first name.";
        }
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "Please enter your last name.";
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }
        /* if (!fields["amount"]) {
            formIsValid = false;
            errors["amount"] = "Please choose amount.";
        } */
        this.setState({ errors: errors });
        return formIsValid;
    };

    handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
        this.setState({ submitted: true });
        let params = { fields:this.state.fields };
        common
        .addDwollaCustomer(params)
        .then((res) => {
            if (res.data.success) {
            this.setState({ submitted: false },()=>{
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                if(this.props.closeModal){
                    this.props.initiateVefification(res.data.dwollaCustomerUrl);
                }
            })
            } else if (res.data.error) {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        })
        .catch(() => {
            toast.error("Unexpected Error !", {
            position: toast.POSITION.TOP_RIGHT,
            });
        });
    }
    };
    handleChange(e, field) {
        let errors = this.state.errors;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        errors[field] = '';
        this.setState({ fields, errors });
    }
  render() {
    return (
        <Modal show={this.props.showModal} size="md" onHide={this.props.closeModal} backdrop="static">
            <Form
                method="post"
                onSubmit={this.handleSubmit}
            >
                <Modal.Header closeButton><h5>Fill up your details to make the payment</h5></Modal.Header>
                <Modal.Body>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="First Name"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="first_name"
                            onChange={(event) => this.handleChange(event, "first_name")}
                            isInvalid={this.state.errors.first_name}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.first_name}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Last Name"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            onChange={(event) => this.handleChange(event, "last_name")}
                            isInvalid={this.state.errors.last_name}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.last_name}</Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email Address"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            name="email"
                            placeholder="email"
                            onChange={(event) => this.handleChange(event, "email")}
                            isInvalid={this.state.errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{this.state.errors.email}</Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        type="button"
                        disabled={this.state.submitted}
                        onClick={()=>this.props.closeModal()}
                        size="lg"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        type="submit"
                        disabled={this.state.submitted}
                        size="lg"
                    >
                        {this.state.submitted && (
                            <Spinner
                                size="sm"
                                variant="light"
                                className="me-1"
                            />
                        )}
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(DwollaPaymentForm);


