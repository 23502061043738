/* eslint-disable react/prop-types */
import { Button, Table, Row, Col,  Modal, ModalHeader, ModalBody } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import moment from "moment";
import { connect } from "react-redux";
import { FaTrashAlt, FaFileExcel } from "react-icons/fa";
import Pagination from "react-js-pagination";

class ListMutualRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      loader: false,
      mutualRequests: [],
      pageDetails: {},
    };
  }
  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.listMutualRequests();
    });
  };
  listMutualRequests = () => {
    this.setState({ loader: true });
    events
      .listMutualRequests({
        event_id: this.props.eventData.id,
        page: this.state.pageNo,
        fields:this.state.fields
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ mutualRequests: res.data.mutual_requests, loader: false, pageDetails:res.data.pages });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount = () => {
    this.listMutualRequests();
  };
  deleteRequest = (id) => {
    if (window.confirm('Are you sure to delete?')) {
      let params = { id: id }
      events.deleteMutualAidRequest(params).then((response) => {
        if (response.data.success) {
          let mutualRequests = this.state.mutualRequests.filter((donor) => parseInt(donor.id) !== parseInt(id))
          this.setState({mutualRequests},()=>{
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        toast.error('Unexpected error !', {
        position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }
  exportMutualRequests = () =>{
    this.setState({loader:true});
    events.exportMutualRequests({event_id: this.props.eventData.id}).then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data],{ type: res.data.type, encoding: 'UTF-8' })
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = this.props.userName + "-" + this.props.eventData.url + "-" + moment().format("MM-DD-YYYY") + ".xlsx";
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({loader:false});
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  sendMutualEmail = (id) =>{
    events.sendMutualEmail({id: id}).then((res) => {
      if (res.data.success) {
        this.props.closeModal();
      } else if (res.data.error) {
        console.log(res.data.message);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  render() {
    return (
        <Modal size="lg" isOpen={this.props.showModal}>
            <ModalHeader
                toggle={() => {
                    this.props.closeModal(false);
                }}
            >
                <h3>Mutual Aid Requests</h3>
            </ModalHeader>
            <ModalBody>
                <LoadingOverlay
                    active={this.state.loader}
                    spinner
                    text="Loading..."
                >
                  <Row>
                    <Col md={12} className="text-right">
                        <Button type="button" color="warning" onClick={()=>this.exportMutualRequests()}><FaFileExcel size="1em" className="mb-1 mr-1"/>Export</Button>
                    </Col>
                  </Row>
                    <Table className="table table-responsive customise_table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Amount Requested</th>
                                <th>Payment Method</th>
                                <th>Payment Username</th>
                                <th>Reason for Request of Funds</th>
                                <th>Submission Date</th>
                                <th colSpan="2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.mutualRequests.length > 0 ? (
                                this.state.mutualRequests.map((ele, index) => (
                                    <tr key={index}>
                                        <td>{this.state.pageNo === 1
                                            ? index + 1
                                            : (this.state.pageNo - 1) * 50 + index + 1}
                                            . </td>
                                        <td> {ele.first_name}</td>
                                        <td> {ele.last_name}</td>
                                        <td> {ele.email}</td>
                                        <td> ${ele.amount}</td>
                                        <td> {ele.appname !== null?ele.appname.name:''}</td>
                                        <td> {ele.service_identifier}</td>
                                        <td> {ele.reason}</td>
                                        <td> {moment(ele.added_on).format("MMM Do YYYY")}</td>
                                        <td>
                                            <Button type="button" className="btn-sm mr-1" color="success" onClick={()=>this.sendMutualEmail(ele.id)}>Send Email</Button>
                                        </td>
                                        <td>
                                            <Button type="button" className="btn-sm mr-1" color="danger" onClick={() => this.deleteRequest(ele.id)}><FaTrashAlt/></Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="11" className="text-center" style={{ height: 300 }}>
                                        Record not found.
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan="11">
                                    <Pagination
                                        activePage={this.state.pageNo}
                                        itemsCountPerPage={30}
                                        totalItemsCount={
                                            this.state.pageDetails.totalCount ? parseInt(this.state.pageDetails.totalCount) : 0
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={(e) => this.handlePageChange(e)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </LoadingOverlay>
            </ModalBody>
        </Modal>
      
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ListMutualRequests);
