import React, { useEffect, useState } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import events from "../../../services/events";
import { BsArrowRight } from "react-icons/bs";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const PayPal = (props) => {
  const [isPaypalLinked, setIsPayPalLinked] = useState(false);
  const [paypalLoader, setPaypalLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [redirectStatus, setRedirectStatus] = useState(false);
  const baseUrl = useSelector((state) => state.baseUrl);

  useEffect(() => {
    events
      .isPaypalLinked()
      .then((res) => {
        if (res.data.success) {
          setIsPayPalLinked(true);
        } else if (res.data.error) {
          setIsPayPalLinked(false);
        }
        setPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getPayPalToken = () => {
    setPaypalLoader(true);
    events
      .getPaypalToken({ redirect_url: baseUrl })
      .then((res) => {
        if (res.data.success) {
          setPaypalLoader(false);
          setIsPayPalLinked(true);
          setRedirectStatus(true);
          window.location = res.data.linking_data.links[1].href;
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Card className="modified-card">
      <Card.Header><h4 className="mb-0 p-2">PayPal</h4></Card.Header>
      
      <Card.Body style={{ minHeight: 400 }}>
        {redirectStatus && (
          <h4 className="text-center pt-5">
            You are being redirected. <br />
            Please wait..
          </h4>
        )}
        {pageLoader && (
          <div className="text-center pt-5">
            <Spinner style={{ width: 50, height: 50 }} />
          </div>
        )}
        {!isPaypalLinked && !pageLoader && (
          <div>
            <h5 style={{ lineHeight: 1.5 }}>
              After clicking on continue button you will be redirected to Paypal
              to get registered as a Merchant to accept donation. Please{" "}
              <strong>DO NOT </strong>forget to return back after successful
              registration.
            </h5>
            <p className="text-right">
              <Button
                variant="success"
                type="button"
                size="lg"
                className=""
                onClick={() => getPayPalToken()}
                disabled={paypalLoader}
              >
                {paypalLoader && <Spinner size="sm" className="mr-1 mb-1" />}
                Continue <BsArrowRight />
              </Button>
            </p>
          </div>
        )}
        {isPaypalLinked && (
          <h4 className="text-center pt-5 text-success">
            Account already linked.
          </h4>
        )}
      </Card.Body>
    </Card>
  );
};

export default PayPal;
