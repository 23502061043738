/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Form,
  Button,
  Spinner,
} from 'react-bootstrap'
import { FiEdit3 } from "react-icons/fi";
import user from '../../../services/user'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function Description(props) {
  const [showDescription, setShowDescription] = useState(false)
  const [error, setError] = useState(null)
  const [descriptionSubmitted, setDescriptionSubmitted] = useState(false)
  const [newDescription, setNewDescription] = useState(null)

  const toggleDescription = () => {
    setShowDescription(!showDescription)
  }

  const handleChange = (description) => {
    setNewDescription(description)
    props.updateDescription(description);
  };

  const validDescription = () => {
    setError(null)
    let formIsValid = true
    if (newDescription === '' || newDescription === null || newDescription === undefined) {
      formIsValid = false
      setError('Description can not be empty!')
    }
    return formIsValid
  }

  const submitDescription = (e) => {
    e.preventDefault()
    if (validDescription()) {
      setDescriptionSubmitted(true)
      user
        .updateDescription({ fields: { description: newDescription } })
        .then((res) => {
          console.log(res)
          setDescriptionSubmitted(false)
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            setShowDescription(false)
            props.updateDescription(res.data.description)
          }
        })
        .catch((error) => {
          setError(error)
          setShowDescription(false)
        })
    }
  }

  useEffect(()=>{
    setNewDescription(props.description)
  })
  return (
    <div>
      {showDescription ? (
        <div>
          <Form onSubmit={submitDescription}>
            <div className="mb-3">
              <CKEditor
                  editor={ ClassicEditor }
                  data={props.description}
                  onChange={ ( event, editor ) => {
                      handleChange(editor.getData())
                  } }
              />
              {error && (
                <span style={{ color: "red" }}>
                  {error}
                </span>
              )}
            </div>
            <Button
              disabled={descriptionSubmitted}
              type="submit"
              variant='success'
            >
              {descriptionSubmitted && (
                <Spinner
                  className='me-1'
                  size='sm'
                  variant="light"
                />
              )}
              Save
            </Button>
          </Form>
        </div>
      ) : (
        <div>
          <p style={{ lineBreak: 'anywhere' }} dangerouslySetInnerHTML={{__html: props.description}} />
          <Button onClick={() => toggleDescription()} variant="success" size='sm'>
            <FiEdit3/> Edit Description
          </Button>
        </div>
      )}
    </div>
  )
}

export default Description
