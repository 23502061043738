/* eslint-disable react/prop-types */
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";


const ZelleModal = (props) => {
  const isEmailAddress = (str) => {
    let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return str.match(pattern);
  };
  const isUsername = (str) => {
    if(str.search('$')){
      return true;
    }
    else{
      return false;
    }
  };
  const copyZelleEmail = (content) => {
    navigator.clipboard.writeText(content).then(
      () => {
        toast.success("Content copied to clipboard successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  return (
    <Modal 
      show={props.zelleEmailModal}
      onHide={props.closeModal}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <h4>Copy
        {isEmailAddress(props.zelleEmail) ? " email address" : isUsername(props.zelleEmail) ? " username" : " phone number"} to make the payment</h4>
      </Modal.Header>
      <Modal.Body>
        {parseInt(props.appId) === 17 ? <Row>
          <Col>
            <p>
              <h3>Canadians!</h3>Copy this email address by selecting the “copy email” button below<br/>Use this email address to eTrasfer with your full name through your bank account.
            </p>
          </Col>
          
        </Row> : <Row>
        <Col>
            <p>
              &bull; Copy the {isEmailAddress(props.zelleEmail) ? " email address" : isUsername(props.zelleEmail) ? " username" : " phone number"} using the green button. 
            </p>
            <p>
              &bull; Open your payment app (zelle, paypal, apple pay). 
            </p>
            <p>
              &bull; Paste the {isEmailAddress(props.zelleEmail) ? " email address" : isUsername(props.zelleEmail) ? " username" : " phone number"} number in the app.   
            </p>
            <p>
              &bull; Enter the payment amount.  
            </p>
            <p>
              &bull; Click submit.  
            </p>
          </Col>
        </Row>}
        <Row>
          <Col className="text-center">
            <div style={{fontSize:25,fontWeight:600}}>{props.zelleEmail !== null && props.zelleEmail}</div>
            <Button
              variant="outline-success"
              className="mt-3"
              size="lg"
              onClick={() => copyZelleEmail(props.zelleEmail)}
            >
              Copy {isEmailAddress(props.zelleEmail) ? " Email" : isUsername(props.zelleEmail) ? " Username" : " Phone Number"}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ZelleModal;
