import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Aboutus = () => {
  return (
    <div className="dashboard-banner">
      <div className="tiphub-banner-bg"></div>
      <Container className="pt-5">
          <Row>
            <Col sm="12">
              <h1>About Us</h1>
              <hr className="mt-0" />
              <p>Welcome to Tiphub!</p>
              <p>We are a fundraising platform with a mission to unlock radical generosity and help people and organizations raise funds for the causes they care about. At Tiphub, we believe that everyone should have the opportunity to make a positive impact in the world, and we are committed to providing a simple and effective way for people to do just that. <br/><br/>Our platform is designed to make it easy for anyone to create a fundraising campaign and share it with their network. Whether you are an individual looking to raise money for a personal cause or an organization seeking support for a project, Tiphub has the tools and resources you need to succeed.<br/><br/>With Tiphub, you can create a personalized fundraising page, set a goal, and share your campaign with your friends, family, and community. We offer a variety of options for accepting donations, including credit card and PayPal, and we provide support and resources to help you reach your fundraising goals.<br/><br/> We are proud to have helped thousands of people and organizations raise millions of dollars for causes they are passionate about. Our slogan, "radical generosity unlocked," reflects our commitment to empowering individuals and organizations to make a difference in the world through the power of fundraising.<br/><br/>Thank you for considering Tiphub for your fundraising needs. We are excited to support you in your efforts to make a difference and unlock radical generosity in your community.</p>
            </Col>
          </Row>
        </Container>
      </div>
  );
};

export default Aboutus;
