/* eslint-disable react/prop-types */
import {
  Button,
  Spinner,
  Form,
  Modal,
} from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import moment from "moment";
import { connect } from "react-redux";
import classnames from "classnames";

class SaveDonnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      submitted: false,
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ submitted: true });
      let fields = this.state.fields;
      fields["event_id"] = this.props.appDetails.event_id;
      fields["service_id"] = this.props.appDetails.service_id;
      fields["scenario"] = "Donor";
      let params = { fields: fields };
      events
        .saveDonorDetails(params)
        .then((res) => {
          if (res.data.success || res.data.exists) {
            this.props.closeModal();
            this.props.trackEventApp(this.props.appDetails);
          } else if (res.data.error) {
            this.setState({ errors: res.data.message });
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          this.setState({ submitted: false });
        })
        .catch((err) => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  validateForm = () => {
    const fields = this.state.fields;
    let formIsValid = true;
    const errors = {};
    if (!fields["donor_first_name"]) {
      formIsValid = false;
      errors["donor_first_name"] = "Please enter your first name.";
    }
    if (!fields["donor_last_name"]) {
      formIsValid = false;
      errors["donor_last_name"] = "Please enter your last name.";
    }
    if (!fields["donor_email"]) {
      formIsValid = false;
      errors["donor_email"] = "Please enter your email.";
    }
    this.setState({ errors });
    return formIsValid;
  };
  render() {
    return (
      <Modal size="sm" show={this.props.showModal} onHide={() => this.props.closeModal()}>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>Please enter your details to contact you</Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label htmlFor="donor_first_name">First name</Form.Label>
              <Form.Control
                type="text"
                name="donor_first_name"
                id="donor_first_name"
                onChange={(event) =>
                  this.handleChange(event, "donor_first_name")
                }
                value={
                  this.state.fields.donor_first_name !== undefined
                    ? this.state.fields.donor_first_name
                    : ""
                }
                isInvalid={!!this.state.errors.donor_first_name}
              />
              <Form.Control.Feedback invalid>{this.state.errors.donor_first_name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="donor_last_name">Last name</Form.Label>
              <Form.Control
                type="text"
                name="donor_last_name"
                id="donor_last_name"
                onChange={(event) =>
                  this.handleChange(event, "donor_last_name")
                }
                value={
                  this.state.fields.donor_last_name !== undefined
                    ? this.state.fields.donor_last_name
                    : ""
                }
                invalid={this.state.errors.donor_last_name ? true : false}
              />
              <Form.Control.Feedback invalid>{this.state.errors.donor_last_name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="donor_email">Email</Form.Label>
              <Form.Control
                type="text"
                name="donor_email"
                id="donor_email"
                onChange={(event) => this.handleChange(event, "donor_email")}
                value={
                  this.state.fields.donor_email !== undefined
                    ? this.state.fields.donor_email
                    : ""
                }
                isInvalid={!!this.state.errors.donor_email}
              />
              <Form.Control.Feedback invalid>{this.state.errors.donor_email}</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="text-end">
            <Button
              variant="danger"
              type="button"
              onClick={() => this.props.closeModal()}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              type="submit"
              disabled={this.state.submitted}
            >
              {this.state.submitted && <Spinner size="sm" />} Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(SaveDonnerDetails);
