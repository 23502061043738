import React from "react";
import { Col, Row, Container } from "react-bootstrap";

const NotFound = (props) => {
    return <div className="d-flex justify-content-center">
    <div className="float-left"><img src="/assets/under-review.png"/></div>
    <div className="float-left align-self-center" >
        <Container className="text-center">
            <h3>Thank you for submitting your event on tiphub!</h3>
            <h5>Please give our team 24 hours to review and approve.</h5>
        </Container>
    </div>
</div>;
}

export default NotFound;