// import React, { lazy } from 'react'

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { BsGraphUp } from "react-icons/bs";

import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Spinner,
  Dropdown,
  Modal,
  Container,
  Badge,
  Form,
  ProgressBar,
} from "react-bootstrap";
import events from "../../../services/events";
import { Helmet } from "react-helmet";
import common from "../../../services/common";
import TippingApp from "./TippingApp";
import ManageTransaction from "./ManageTransaction";
import AddEditEvent from "./AddEditEvent";
import { IoIosAdd, IoMdCopy } from "react-icons/io";
import { FiEdit3, FiTrash, FiRss, FiBarChart } from "react-icons/fi";
import {
  FaDollarSign,
  FaQrcode,
  FaFileAlt,
  FaCaretDown,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
/* import MobilePreview from "./MobilePreview"; */
import QrCodeModal from "./QrCodeModal";
import Report from "./Report";
import PostUpdateModal from "./PostUpdateModal";
import Donors from "./Donors";
import TeamAndPermissions from "./TeamAndPermissions";
import TableLoader from "../Loaders/TableLoader";
/* import ManageQrCode from "./ManageQrCode" */
import { loadScript } from "@paypal/paypal-js";
import { useSelector } from "react-redux";

const Event = () => {
  const [addEditEventModal, setAddEditEventModal] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openTippingModal, setOpenTippingModal] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openDonorsModal, setOpenDonorsModal] = useState(false);
  const [openTeamPermissionModal, setOpenTeamPermissionModal] = useState(false);
  const [val, setVal] = useState(null);
  const [showQrCodeModal, setQrCodeModal] = useState(false);
  const [eventIdForQrCode, setEventId] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [published, setPublished] = useState(1);
  const [categories, setCategories] = useState([]);
  const [fields, setFields] = useState([]);
  const [showPostUpdateModal, setPostUpdateModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const baseUrl = useSelector((state) => state.baseUrl);
  const userId = useSelector((state) => state.userId);

  useEffect(() => {
    loadEvents();
    /* loadScript({ "client-id": "AVJNh81SEjwDJGRK8quVWQ-F1-0FPOfeXRAfmHh9xDAnO2ErrB4NxV9_m_wyjS9nLpKuCnbN8BbLEo-b" })
    .then((paypal) => {
        paypal
            .Buttons()
            .render("#paypal-form-container")
            .catch((error) => {
                console.error("failed to render the PayPal Buttons", error);
            });
    })
    .catch((error) => {
        console.error("failed to load the PayPal JS SDK script", error);
    }); */
    if (document.URL.includes('success')) {
      toast.success("Payment app has been added successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    events.getEventCategory().then((res) => {
      if (res.data.success) {
        setCategories(res.data.categories);
      }
    });

  }, [published]);

  useEffect(() => {
    loadEvents();
  }, [fields]);

  const loadEvents = () => {
    setListLoading(true);
    events
      .list({ published: published, pageSize: 50, fields: JSON.stringify(fields) })
      .then((res) => {
        if (res.data.success) {
          setEventList(res.data.events);
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setListLoading(false);
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const selectEventOperation = (id, type) => {
    setSelectedEventId(id);
    if (type === "Edit") {
      setAddEditEventModal(true);
    } else if (type === "Delete") {
      setOpenDeleteModal(true);
    } else if (type === "Tipping") {
      setOpenTippingModal(true);
    } else if (type === "Transaction") {
      let eventDetails = eventList.filter(eventDetail => parseInt(eventDetail.id) === parseInt(id));
      setEventDetails(eventDetails[0]);
      setOpenTransactionModal(true);
    } else if (type === "Donors") {
      setOpenDonorsModal(true);
    } else if (type === "TeamPermission") {
      setOpenTeamPermissionModal(true);
    }
  };

  const eventDeleteHandler = () => {
    setDeleteLoading(true);
    events
      .deleteOne({ id: selectedEventId })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          let arr = eventList;
          arr = eventList.filter((ele) => ele.id !== selectedEventId);
          setEventList(arr);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDeleteLoading(false);
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setDeleteLoading(false);
      })
      .then(() => {
        setOpenDeleteModal(false);
        setSelectedEventId(null);
      })
      .catch((err) => {
        toast.error("Something went wrong !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const toggleQrCodeModal = (eventId = "") => {
    setQrCodeModal(!showQrCodeModal);
    if (showQrCodeModal === false) {
      setEventId(eventId);
    }
  };

  const togglePostUpdateModal = (eventId = "") => {
    setPostUpdateModal(!showPostUpdateModal);
    if (eventId !== "") {
      setEventId(eventId);
    }
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value })
  }

  const copyEventUrl = (url, id) => {
    let eventNewUrl = '';
    let eventUrlArr = url.split('-');
    eventUrlArr.forEach((eua) => {
      eventNewUrl = eventNewUrl + eua.substr(0, 1);
    })
    eventNewUrl = baseUrl + '/' + eventNewUrl + '/' + id;
    navigator.clipboard.writeText(eventNewUrl).then(
      () => {
        toast.success("Event url copied to clipboard.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  }
  return (
    <>
      <Helmet>
        <title>My Events - Tiphub</title>
      </Helmet>
      <div className="profile-overview event-overview tiphub-banner pb-5">
        <div className="tiphub-banner-bg h-100"></div>
        <Container className="pt-4 pt-md-0">
          <Row>
            <Col sm="12">
              <div className="title mb-3">
                <h4>
                  Action
                  <span className="float-end">
                    {published === 0 ? (
                      <Button
                        variant="success"
                        to="/events/drafts"
                        onClick={() => {
                          setPublished(1);
                        }}
                      >
                        Pubilshed Events
                      </Button>
                    ) : (
                      <Button
                        variant="warning"
                        to="/events/drafts"
                        className="ms-2"
                        onClick={() => {
                          setPublished(0);
                        }}
                      >
                        <FaFileAlt className="pb-1" />
                        Drafts
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      onClick={() => setAddEditEventModal(true)}
                      className="ms-2"
                    >
                      <IoIosAdd />
                      Add Event
                    </Button>
                  </span>
                </h4>
              </div>
              <Row className="bg-white-glass m-0 mb-3">
                <Col md={6} className="p-0">
                  <iframe
                    width="100%"
                    height="250"
                    src="https://www.youtube.com/embed/vdDTwRFquUE"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Col>
                <Col md={6}>
                  <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="event_mail_logo mt-3">
                      <ul>
                        <li>
                          <a href="/tutorial/gmail" target="_blank">
                            <img src="/assets/mail.png" alt="gmail" />
                          </a>
                        </li>
                        <li>
                          <a href="/tutorial/gmail" target="_blank">
                            <img src="/assets/yahoo.png" alt="yahoo" />
                          </a>
                        </li>
                        <li>
                          <a href="/tutorial/outlook" target="_blank">
                            <img src="/assets/outlook.png" alt="hotmail" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <p className="text-center mt-4 fs-5">
                      Link your email to track all your Venmo, CashApp and
                      PayPal payments instantly
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Card className="modified-card">
                    <Card.Header>
                      <Row>
                        <Col sm={8}>
                          <div className="d-flex h-100 align-items-center">
                            <h4 className="m-0">Events List</h4>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="h-100 d-flex justify-content-center align-items-center">
                            <Form.Label
                              className="m-0 d-none d-md-block"
                              sm={6}
                            >
                              Filter By Category
                            </Form.Label>
                            <Form.Select
                              name="category"
                              id="category"
                              style={{ maxWidth: "250px" }}
                              className="ms-2"
                              onChange={(event) =>
                                handleChange(event, "category")
                              }
                            >
                              <option value="">All Category</option>
                              {categories.map((category, index) => (
                                <option
                                  value={category.id}
                                  key={`category-${index}`}
                                >
                                  {category.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body className="p-4" style={{ minHeight: 400 }}>
                      {listLoading ? (
                        <TableLoader />
                      ) : eventList.length > 0 ? (
                        <Table
                          className="table-responsive customise_table"
                          style={{ minHeight: 300 }}
                        >
                          <thead>
                            <tr>
                              <th scope="col" className="border-top-0 text-center">
                                #
                              </th>
                              <th scope="col" className="border-top-0">
                                Event title
                              </th>
                              {/* <th scope="col">Description</th> */}
                              <th scope="col" className="border-top-0">
                                Target amount
                              </th>
                              <th scope="col" className="border-top-0">
                                Start Date
                              </th>
                              <th scope="col" className="border-top-0">
                                End Date
                              </th>
                              <th scope="col" className="border-top-0">
                                Created on
                              </th>
                              <th scope="col" className="border-top-0">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {eventList.length > 0 &&
                              eventList.map((ele, index) => (
                                <tr key={index}>
                                  <th scope="row" className="text-center">{index + 1}</th>
                                  <td>
                                    <Link
                                      to={`/${ele.url}/${ele.id}${published === 0 ? "/true" : ""
                                        }`}
                                      target="_blank"
                                    >
                                      <div className="descripation-table">
                                        {ele.title}{" "}
                                        <sup>
                                          {parseInt(ele.approved) === 1 ? (
                                            <Badge bg="success">
                                              Approved
                                            </Badge>
                                          ) : (
                                            <Badge bg="danger">
                                              Pending Approval
                                            </Badge>
                                          )}
                                        </sup>
                                      </div>
                                    </Link>
                                    <small
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        copyEventUrl(ele.url, ele.id)
                                      }
                                    >
                                      <IoMdCopy />
                                      Copy Event URL
                                    </small>
                                  </td>
                                  <td>
                                    <div className="progressbar_bar">
                                      <ProgressBar
                                        variant="success"
                                        now={parseInt(
                                          common.getTragetPercentageNew(
                                            ele.target_amount,
                                            ele.transactions
                                          )
                                        )}
                                      />
                                      <span>
                                        {common.getTragetPercentageNew(
                                          ele.target_amount,
                                          ele.transactions
                                        )}
                                        %
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    {ele.start_date !== null
                                      ? common.customeFormat(
                                        ele.start_date,
                                        "MM dd, yyyy"
                                      )
                                      : "--"}
                                  </td>
                                  <td>
                                    {ele.end_date !== null
                                      ? common.customeFormat(
                                        ele.end_date,
                                        "MM dd, yyyy"
                                      )
                                      : "--"}
                                  </td>
                                  <td>
                                    {common.customeFormat(
                                      ele.added_on,
                                      "MM dd, yyyy"
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      className="me-1"
                                      onClick={() => toggleQrCodeModal(ele.id)}
                                    >
                                      <FaQrcode className="mr-1" /> Get QR Code
                                    </Button>
                                    {parseInt(ele.user.id) === parseInt(userId) &&
                                      <Dropdown
                                        className="ms-2"
                                        style={{ display: "inline-block" }}
                                        align="end"
                                      >
                                        <Dropdown.Toggle
                                          variant="success"
                                          className="btn-sm"
                                        >
                                          Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className="event-dropdown"
                                          left
                                        >
                                          <Dropdown.Item
                                            onClick={() => {
                                              selectEventOperation(
                                                ele.id,
                                                "Edit"
                                              );
                                            }}
                                          >
                                            <FiEdit3 className="mb-1 me-1" />
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              selectEventOperation(
                                                ele.id,
                                                "Delete"
                                              )
                                            }
                                          >
                                            <FiTrash className="mb-1 mr-1" />
                                            Delete
                                          </Dropdown.Item>
                                          {(parseInt(ele.category) === 57 ||
                                            parseInt(ele.category) === 59 ||
                                            parseInt(ele.category) === 70 ||
                                            parseInt(ele.category) === 75) && (
                                              <Dropdown.Item
                                                tag="a"
                                                href={`/reports/${ele.id}`}
                                              >
                                                <FiBarChart className="mb-1 me-1" />{" "}
                                                Report
                                              </Dropdown.Item>
                                            )}
                                          {/* <Dropdown.Item onClick={() => toggleEventQrCodeModal(ele.id)}>
                                          <FaQrcode className="mr-1 mb-1"/>
                                          Manage QR Code
                                        </Dropdown.Item> */}
                                          <Dropdown.Item
                                            onClick={() =>
                                              selectEventOperation(
                                                ele.id,
                                                "Tipping"
                                              )
                                            }
                                          >
                                            <FaDollarSign className="mb-1 me-1" />
                                            Tipping App
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              selectEventOperation(
                                                ele.id,
                                                "Transaction"
                                              )
                                            }
                                          >
                                            <BsGraphUp className="mb-1 me-1" />
                                            Transactions
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              togglePostUpdateModal(ele.id)
                                            }
                                          >
                                            <FiRss className="mb-1 me-1" /> Post
                                            Updates
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              selectEventOperation(
                                                ele.id,
                                                "Donors"
                                              )
                                            }
                                          >
                                            <FaUserAlt className="mb-1 me-1" />
                                            Donors
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              selectEventOperation(
                                                ele.id,
                                                "TeamPermission"
                                              )
                                            }
                                          >
                                            <FaUsers className="mb-1 mr-1" />
                                            Team & Permissions
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <h4 className="text-center mt-5">No records found</h4>
                      )}
                    </Card.Body>
                  </Card>
                  <div id="pay-pal-form"></div>
                </Col>
                <Modal show={openDeleteModal}>
                  <Modal.Header>
                    Are you sure you want to delete this event ?
                  </Modal.Header>
                  <Modal.Body>
                    Once you delete an event, you will loose the event details.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setOpenDeleteModal(false);
                        setSelectedEventId(null);
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => eventDeleteHandler()}
                      disabled={deleteLoading}
                    >
                      {deleteLoading && (
                        <Spinner
                          variant="light"
                          component="div"
                          size="sm"
                          aria-hidden="true"
                          style={{ marginRight: 3 }}
                        />
                      )}
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
                {openTippingModal && (
                  <TippingApp
                    openTippingModal={openTippingModal}
                    selectedEventId={selectedEventId}
                    resetEventId={() => {
                      setOpenTippingModal(false);
                      setSelectedEventId(null);
                    }}
                  />
                )}
                {openTransactionModal && (
                  <ManageTransaction
                    openTransactionModal={openTransactionModal}
                    selectedEventId={selectedEventId}
                    resetEventId={() => {
                      setOpenTransactionModal(false);
                      setSelectedEventId(null);
                    }}
                    reLoadEvents={loadEvents}
                    transactionId={null}
                    eventDetails={eventDetails}
                  />
                )}
              </Row>
            </Col>
            {showQrCodeModal && (
              <QrCodeModal
                showModal={showQrCodeModal}
                closeModal={setQrCodeModal}
                eventId={eventIdForQrCode}
              />
            )}
            {showPostUpdateModal && (
              <PostUpdateModal
                showModal={showPostUpdateModal}
                closeModal={setPostUpdateModal}
                eventId={eventIdForQrCode}
              />
            )}
            {showReportModal && (
              <Modal size="lg" isOpen={showReportModal}>
                <Modal.Header toggle={() => setShowReportModal()}>
                  Report
                </Modal.Header>
                <Modal.Body>
                  <Report eventId={eventIdForQrCode} />
                </Modal.Body>
              </Modal>
            )}
            {addEditEventModal && (
              <AddEditEvent
                showModal={addEditEventModal}
                closeModal={setAddEditEventModal}
                selectedEventId={selectedEventId}
                loadEvents={loadEvents}
                resetEventId={setSelectedEventId}
              />
            )}
            {openDonorsModal && (
              <Donors
                showModal={openDonorsModal}
                closeModal={setOpenDonorsModal}
                selectedEventId={selectedEventId}
                showAction={true}
              />
            )}
            {openTeamPermissionModal && (
              <TeamAndPermissions
                showModal={openTeamPermissionModal}
                closeModal={setOpenTeamPermissionModal}
                selectedEventId={selectedEventId}
              />
            )}

          </Row>
          {/*  <div id="paypal-form-container"></div> */}
        </Container>
      </div>
    </>
  );
};

export default Event;
