import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
} from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { BsArrow90DegRight } from "react-icons/bs";
import { toast } from "react-toastify";

const TutorialGmail = () => {
  const copyContent = (content) => {
    navigator.clipboard.writeText(content).then(
      () => {
        toast.success("Content copied to clipboard.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  return (
    <>
      <Helmet>
        <title>Home - Tiphub</title>
      </Helmet>
      <div className="defalut-overview tiphub-banner ">
      <div className="tiphub-banner-bg h-100"></div>
        <Container>
          <Row>
            <Col sm="12">
              <h2>
                How to forward Paypal, CashApp and Venmo payment emails using
                filters in gmail.
              </h2>
              <p>
                Set up your criteria to organize incoming mail for Paypal,
                CashApp and Venmo
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className="mb-2">
                <Card.Header>Steps to link your payment emails</Card.Header>
                <Card.Body>
                  <p>
                    &bull; First step would be to link all your emails to Tiphub
                    account so that you can synchronize the payments from all
                    your emails. This needs verification of all your emails.{" "}
                  </p>
                  <p>
                    &bull; To do this, login to your Tiphub account, go to{" "}
                    <strong>Settings</strong> &#8594;{" "}
                    <strong>Link Emails</strong> and follow the instructions to
                    verify the emails.
                  </p>
                  <p className="text-center">
                    <img
                      src="/assets/link-email.png"
                      alt=""
                      width="60%"
                      className="mb-3"
                    />
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className="mb-2">
                <Card.Header>Steps to follow:</Card.Header>
                <Card.Body>
                  <p>
                    &bull; Create an auto-forward filter: Select the{" "}
                    <b>
                      Settings gear &#8594; See All Settings &#8594; Forwarding
                      and POP/IMAP &#8594; Add a forwarding address.
                    </b>
                  </p>
                  <p>
                    &bull; Next, enter the email address(
                    <strong>xpikdjjc@mailparser.io</strong>) and click Next
                    button.
                  </p>
                  <p>
                    &bull; You should receive a confirmation dialog. Click{" "}
                    <strong>Procced</strong> button.{" "}
                  </p>
                  <p>
                    &bull; An OTP will be sent to your email address, Go to your
                    inbox and copy the OTP.
                  </p>
                  <p>
                    &bull; Again go to{" "}
                    <b>
                      Settings gear &#8594; See All Settings &#8594; Forwarding
                      and POP/IMAP &#8594; Verify xpikdjjc@mailparser.io
                    </b>
                  </p>
                  <p>
                    &bull; Paste the copied OTP and click{" "}
                    <strong>Vefify</strong> button.
                  </p>
                  <p>
                    &bull; Click <strong>Filters and Blocked Addresses</strong>{" "}
                    tab &#8594; Create a new filter{" "}
                  </p>
                  <p>
                    &bull; Enter{" "}
                    <strong>{`{cash@square.com,venmo@venmo.com,service@paypal.com}`}</strong>{" "}
                    in <strong>From</strong> field &#8594; click{" "}
                    <strong>Create filter</strong> button &#8594; Choose{" "}
                    <strong>Forward it to</strong> with{" "}
                    <strong>xpikdjjc@mailparser.io</strong> &#8594; Click{" "}
                    <strong>Create filter</strong> button.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className="mb-2">
                <Card.Header>
                  Set up a filter to auto forward in gmail
                </Card.Header>
                <Card.Body>
                  <p>
                    <b>1.</b> Select the <b>Settings gear.</b>
                  </p>
                  <img
                    src="/assets/step/step_1.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>2.</b> Select <b>See All Settings.</b>
                  </p>
                  <img
                    src="/assets/step/step_2.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>3.</b> Go to the <b>Filters and Blocked Addresses</b>{" "}
                    tab.
                  </p>
                  <img
                    src="/assets/step/step_3.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>4.</b> Select <b>Add a forwarding address</b> button
                  </p>
                  <img
                    src="/assets/step/step_4.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>5.</b>{" "}
                    <b>
                      Add forwarding address{" "}
                      <strong>xpikdjjc@mailparser.io</strong>
                    </b>{" "}
                    <Button
                      type="button"
                      className="btn-sm"
                      color="success"
                      onClick={() => copyContent("xpikdjjc@mailparser.io")}
                    >
                      <FaCopy /> Copy
                    </Button>
                  </p>
                  <img
                    src="/assets/step/step_5.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>6.</b> <b>Verify forwarding address</b>
                  </p>
                  <img
                    src="/assets/step/step_6.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>7.</b> Select <b>Create a new filter.</b>
                  </p>
                  <img
                    src="/assets/step/step_7.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>8.</b> We have two scenarios:
                  </p>
                  <ul>
                    <li
                      style={{
                        listStyle: "none",
                        borderBottom: "#b5b3b3 solid 1px",
                      }}
                      className="mb-4"
                    >
                      <p>
                        {" "}
                        <b>-</b> If you are creating filter for CashApp, Venmo
                        and/or Paypal, do the following: <br />
                        Enter all the emails in 'From' field of the filter
                        popup. The emails should be in format{" "}
                        <b>{`{cash@square.com,venmo@venmo.com,service@paypal.com}`}</b>{" "}
                        <Button
                          type="button"
                          className="btn-sm"
                          color="success"
                          onClick={() =>
                            copyContent(
                              `{cash@square.com,venmo@venmo.com,service@paypal.com}`
                            )
                          }
                        >
                          <FaCopy /> Copy
                        </Button>
                      </p>
                      <img
                        src="/assets/step/step_8.jpg"
                        alt=""
                        width="100%"
                        className="mb-3"
                      />
                    </li>
                    <li
                      style={{
                        listStyle: "none",
                        borderBottom: "#b5b3b3 solid 1px",
                      }}
                      className="mb-4"
                    >
                      <p>
                        {" "}
                        <b>-</b> If you are creating filter for Zelle, kindly
                        follow the below steps:
                      </p>
                      <img
                        src="/assets/step/step_8-Sub.jpg"
                        alt=""
                        width="100%"
                        className="mb-3"
                      />
                    </li>
                  </ul>
                  <p>
                    <b>9.</b> Select the <b>Forward it to</b> check box and
                    choose the address to which you want these messages
                    delivered from the drop-down list, then
                  </p>
                  <img
                    src="/assets/step/step_9.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                  <p>
                    <b>10.</b> Select <b>Create filter</b>. Email matching the
                    criteria you set will be forwarded to this address.
                  </p>
                  <img
                    src="/assets/step/step_10.jpg"
                    alt=""
                    width="100%"
                    className="mb-3"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TutorialGmail;
