import http from "./http";

const service = {
  add: data => http.post("/service/add", data),
  getOne: param => http.get("/service/get-one", { params: param }),
  list: param => http.get("/service/list", { params: param }),
  delete: data => http.post("/service/delete", data),
  getPaymentServiceNames: param => http.get("/service/get-payment-service-names", { params: param }),
  updateSequence: data => http.post("/service/update-sequence", data),
  checkDwollaSetting: data => http.post("/service/check-dwolla-setting", data),
};

export default service;
