import React, { useEffect, useState } from "react";
import { Button, Tooltip, Badge, Overlay } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaInfoCircle, FaListAlt, FaCheckCircle } from "react-icons/fa";
import common from "../../../services/common";
import events from "../../../services/events";
import { Link } from "react-router-dom";
import DonorDetails from "../Event/DonorDetails";
import { toast } from "react-toastify";
import ManageTransaction from "../Event/ManageTransaction";
import LinkMember from "./LinkMember";
import { BiBorderRadius } from "react-icons/bi";

const Payment = (props) => {
  const [userName, baseUrl, userId] = useSelector((state) => {
    return [state.userName, state.baseUrl, state.userId];
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [showDonorDetailModal, setShowDonorDetailModal] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [showLinkMemberModal, setLinkMemberModal] = useState(false);

  /* const passTransactionId = (transaction_id) => {
    setTransactionId(transaction_id);
    setOpenTransactionModal(true);
  }; */

  const sendPaymentConfirmationEmail = (payment_id) => {
    if (window.confirm("Are you sure you want to confirm this payment?")) {
      events
        .sendPaymentConfirmationEmail({ id: payment_id })
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.success.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (props.reloadPayments) {
              props.reloadPayments();
            }
          } else if (res.data.error) {
            toast.error(res.data.error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <>
      <tr key={props.payment.id}>
        <td style={{borderBottomWidth:0}}>
          <input
            type="checkbox"
            onChange={props.handleCheckBox}
            value={props.payment.id}
            checked={props.selectedPayments.indexOf(props.payment.id) >= 0}
          />
        </td>
        <td style={{borderBottomWidth:0}}>{(props.pageNo - 1) * 50 + props.index + 1}.</td>
        <td style={{borderBottomWidth:0}}>{props.payment.name !== null ? props.payment.name : "--"}</td>
        <td style={{borderBottomWidth:0}}>{props.payment.payment_identifier}</td>
        <td style={{borderBottomWidth:0}}>${props.payment.txn_amount}</td>
        <td style={{borderBottomWidth:0}}>
          {props.payment.service !== null ? props.payment.service.name : "--"}
        </td>
        <td style={{borderBottomWidth:0}}>
          {props.payment.received_at !== null
            ? common.customeFormat(props.payment.received_at, "MM dd, yyyy")
            : "--"}
        </td>
        <td style={{borderBottomWidth:0}}>
          {props.payment.event !== null && parseInt(props.payment.status) === 1 ? (
            <Link
              to={`/${
                props.payment.event !== null
                  ? `${props.payment.event.url}/${props.payment.event.id}`
                  : "#"
              }`}
            >
              <div
                style={{
                  width:200,
                  textOverflow:'ellipsis',
                  overflow:'hidden',
                  backgroundColor:
                    props.payment.event !== null
                      ? props.payment.event.color_code
                      : "#cccccc",
                  color:'white',
                  padding:3,
                  borderRadius:5,
                  fontSize:13
                }}
                
              >
                <FaInfoCircle /> {props.payment.event.title}
              </div>
            </Link>
          ) : (
            "--"
          )}
        </td>
        
      </tr>
      <tr>
        <td colSpan={10} className="border-top-0 text-center">
        {props.payment.event_id === null ? (
            <Button
              variant="primary"
              size="sm"
              className="mr-1"
              onClick={() => props.toggleLinkEventModal(props.payment.id)}
            >
              Link Event
            </Button>
          ) : (
            <>
              <Button
                variant="info"
                size="sm"
                className="me-1"
                onClick={() => {setTransactionId(props.payment.transaction_id);setOpenTransactionModal(true)}}
              >
                Edit
              </Button>
              {props.payment.donor_id !== null && (
                <Button
                  type="button"
                  className="btn-sm me-1"
                  variant="primary"
                  onClick={() => setShowDonorDetailModal(true)}
                >
                  <FaListAlt size="1.5em" />
                </Button>
              )}
              <Button
                variant="warning"
                size="sm"
                onClick={() => props.unLinkPayment(props.payment.id)}
                className="me-1"
              >
                Un-link
              </Button>
              {(parseInt(props.payment.event.fundraising_for) === 1 ||
                parseInt(props.payment.event.category) === 18) &&
                parseInt(props.payment.confirmation_email) === 0 && (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() =>
                      sendPaymentConfirmationEmail(props.payment.id)
                    }
                    className="me-1"
                  >
                    Send Confirmation Email
                  </Button>
                )}
                {parseInt(props.payment.event.category) === 57 && props.payment.event_id !== null && 
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => {
                        setTransaction(props.payment.transaction)
                        setLinkMemberModal(true)
                      }
                    }
                    className="me-1"
                  >
                    {(props.payment.transaction !== null && props.payment.transaction.member) ? <><FaCheckCircle/> Linked To {props.payment.transaction.member.first_name} {props.payment.transaction.member.last_name}</> : 'Link Member'}
                  </Button>
                }
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => props.deletePayment(props.payment.id)}
                >
                  Delete
                </Button>
            </>
          )}
        </td>
      </tr>
      {showDonorDetailModal && (
        <DonorDetails
          showModal={showDonorDetailModal}
          id={props.payment.donor_id}
          closeModal={() => setShowDonorDetailModal(false)}
          editAmount={false}
        />
      )}
      {openTransactionModal && (
        <ManageTransaction
          openTransactionModal={openTransactionModal}
          resetEventId={() => {
            setOpenTransactionModal(false);
            props.reloadPayments();
          }}
          transactionId={transactionId}
          selectedEventId={props.payment.event_id}
          enableAddedOn={true}
        />
      )}
      {showLinkMemberModal && (
        <LinkMember
          showModal={showLinkMemberModal}
          eventId={props.payment.event_id}
          transaction={transaction}
          closeModal={() => setLinkMemberModal(false)}
          reloadPayments={props.reloadPayments}
        />
      )}
    </>
  );
};

export default Payment;
