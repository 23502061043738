/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form, FloatingLabel, InputGroup, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaCcVisa, FaCcMastercard, FaCcDiscover, FaCcAmex } from "react-icons/fa";
import common from "../../../services/common";

const CreditCardForm = (props) => {
  const [fields, setFields] = useState({
    expired_on: 'MM/YY',
    email: props.email ? props.email : "",
    phone: props.phone ? props.phone : "",
    name: props.display_name ? props.display_name : ''
  })
  const [loader, setLoader] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [authKey, setAuthKey] = useState(null);

  useEffect(() => {
    initializeEdgePay();
  }, []);
  const handleChange = (e, field) => {
    let inputValue = e.target.value;
    if (field === 'card_no') {
      inputValue = common.prepareCcNumber(inputValue.replace(/\D/g, ''))
    }
    else if (field === 'cvv') {
      inputValue = inputValue.replace(/\D/g, '');
    }
    else if (field === 'expired_on') {
      inputValue = common.prepareCcExpiry(inputValue)

    }
    else if (field === 'amount') {
      inputValue = inputValue.replace(/[^0-9.]/g, '');
    }
    setFields({ ...fields, [field]: inputValue });
  };
  useEffect(() => {
    if (handleValidation()) {
      setIsFormValid(false);
    }
    else {
      setIsFormValid(true);
    }
  }, [fields])
  const initializeEdgePay = () => {
    common.getReferenceId({ user_id: props.eventDetails.user_id }).then((res) => {
      if (res.data.success) {
        common.appendEdgePayLibrary(res.data.result.authKey);
      }
      else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setLoader(true);
      initializeEdgePay();
      /* eslint-disable */
      const btnPivotSubmit = document.getElementById('btnPivotSubmit');
      EdgePay.getToken({
        formFieldIdSelector: {
          submitButton: "btnPivotSubmit", //id of button that will trigger the credit card tokenization 
          cardNumber: "inputCreditCard", //id of input field that will contain the credit card number 
          cardExpirationDate: "inputDate", //id of input field that will contain the expiration date in MMYY format
          cvv2: "inputSecurityCode" // if of input field that will contain the cvv
        },
        onSuccess: function (success) {
          let payload = {
            amount: props.amount,
            tokenId: success.body.tokenID,
            transactionDate: new Date(),
            billingZip: fields.zip_code,
            //billingAddress: fields.billing_address,
            //currencyCode:common.currencySymbolsCode[props.eventDetails.currency],
          };
          if (props.totalTip) {
            payload = { ...payload, tip: props.totalTip };
          }
          if (props.eventDetails) {
            payload = { ...payload, event_id: props.eventDetails.id, user_id: props.eventDetails.user_id };
          }
          if (props.userDetails) {
            payload = { ...payload, user_id: props.userDetails.id };
          }
          if (props.booking_id) {
            payload = { ...payload, booking_id: props.booking_id };
          }
          if (props.invoiceId && props.invoiceId !== null) {
            payload = { ...payload, invoice_id: props.invoiceId };
          }
          if (props.programId && props.programId !== null) {
            payload = { ...payload, program_id: props.programId };
          }
          if (fields.phone) {
            payload = { ...payload, phone: fields.phone };
          }
          if (fields.email && fields.email !== "") {
            payload = { ...payload, email: fields.email };
          }
          if (fields.name && fields.name !== '') {
            payload = { ...payload, display_name: fields.name };
          }
          common.processEdgepayPayment(payload).then((res) => {
            if (res.data.success) {
              if (res.data.result.result === 'A') {
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setLoader(false);
                props.closeModal();
              }
            }
            else {
              setLoader(false);
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }).catch((err) => {
            setLoader(false);
            console.log(err);
          });
        },
        onError: function (error) {
          console.log(error);
        }
      });
      /* eslint-enable */
      btnPivotSubmit.click();
    }
  }
  const handleValidation = () => {
    //alert(common.validateEmail(fields["email"]));
    let formIsValid = true;
    if (!fields["name"] || fields["name"] === '') {
      formIsValid = false;
    }
    if (!fields["card_no"] || fields["card_no"] === '') {
      formIsValid = false;
    }
    if (!fields["expired_on"] || fields["expired_on"] === '') {
      formIsValid = false;
    }
    if (!fields["cvv"] || fields["cvv"] === '') {
      formIsValid = false;
    }
    if (!fields["email"] || fields["email"] === '' || common.validateEmail(fields["email"]) === null) {
      formIsValid = false;
    }
    /* if (!fields["billing_address"] || fields["billing_address"] === '') {
      formIsValid = false;
    } */
    if (!fields["zip_code"] || fields["zip_code"] === '') {
      formIsValid = false;
    }
    return formIsValid;
  };
  return (
    <div>
      <p className="text-center">
        <FaCcVisa className="me-2" size={45} />
        <FaCcMastercard className="me-2" size={45} />
        <FaCcAmex className="me-2" size={45} />
        <FaCcDiscover className="me-2" size={45} />
      </p>
      <Form method="post" onSubmit={(event) => event.preventDefault()}>
        <Row>
          <Form.Label><span className="display-6">{common.currencySymbolsArr[props.eventDetails.currency]}{props.amount}</span></Form.Label>
          <Form.Group as={Col} md="12">
            <FloatingLabel
              label="Name on card"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="name"
                placeholder="name"
                onChange={event => handleChange(event, "name")}
                value={fields.name ? fields.name : ''}
                autoComplete={false}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              label="Email"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="email"
                placeholder="email"
                onChange={event => handleChange(event, "email")}
                autoComplete={false}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              label="Phone"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="phone"
                placeholder="phone"
                onChange={event => handleChange(event, "phone")}
                value={fields.phone ? fields.phone : ''}
                autoComplete={false}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="12">
            <FloatingLabel
              label="Card number"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="card_no"
                placeholder="card"
                onChange={event => handleChange(event, "card_no")}
                pattern="[0-9]*"
                inputMode="numeric"
                value={fields.card_no}
                autoComplete={false}
              />
              <input type="hidden" id="inputCreditCard" value={fields.card_no ? fields.card_no.replace(/\s/g, '') : ''} />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              label="Expired On"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="expired_on"
                id="inputDate"
                placeholder="MM/YY"
                onChange={event => handleChange(event, "expired_on")}
                value={fields.expired_on}
                onFocus={(event) => {
                  if (event.target.value === 'MM/YY') {
                    event.target.value = "";
                  }
                }}
                onBlur={(event) => {
                  if (event.target.value === '') {
                    event.target.value = "MM/YY";
                  }
                }}
                autoComplete={false}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              label="CVV"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="cvv"
                id="inputSecurityCode"
                placeholder="0000"
                onChange={event => handleChange(event, "cvv")}
                value={fields.cvv}
                autoComplete={false}
              />
            </FloatingLabel>
          </Form.Group>
          {/* <Form.Group as={Col} md="12">
                <FloatingLabel
                  label="Billing Address"
                  className="mb-3"
                  >
                  <Form.Control
                      type="text"
                      name="billing_address"
                      placeholder="billing_address"
                      onChange={event => handleChange(event, "billing_address")}
                  />
              </FloatingLabel>
            </Form.Group> */}
          <Form.Group as={Col} md="12">
            <FloatingLabel
              label="Zip Code"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="zip_code"
                placeholder="zip_code"
                onChange={event => handleChange(event, "zip_code")}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="12" className="d-grid">
            <Button variant="success" size="lg" type="button" disabled={isFormValid || loader} onClick={handleSubmit}>
              {loader && <Spinner variant="light" size="sm" />} Pay {common.currencySymbolsArr[props.eventDetails.currency]}{props.amount}</Button>
            <Button type="submit" id="btnPivotSubmit" style={{ display: 'none' }}>Submit</Button>
          </Form.Group>
        </Row>
      </Form>
    </div >
  );
};

export default CreditCardForm;
