import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Form,
  FloatingLabel,
  Spinner,
  Table,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import events from "../../../services/events";
import moment from "moment";
import { toast } from "react-toastify";
import { FaCheckCircle, FaFileExcel } from "react-icons/fa";
import GroupDonorForm from "./GroupDonorForm";
import Pagination from "react-js-pagination";

export class GroupPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      showModal: false,
      pageNo: 1,
      groupPayments: [],
      pageDetails: {},
      loader: false,
      paymentId: null
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  getGroupPaymentList = (e) => {
    if (e) e.preventDefault();
    this.setState({ loader: true });
    events
      .getGroupPaymentList({
        event_id: this.props.eventData.id,
        page: this.state.pageNo,
        fields: this.state.fields,
        export: false,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            groupPayments: res.data.groupPayments,
            loader: false,
            pageDetails: res.data.pages,
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteRecord = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id, event_id: this.props.eventData.id };
      events
        .deleteGroupPayment(params)
        .then((response) => {
          if (response.data.success) {
            let records = this.state.groupPayments.filter(
              (r) => parseInt(r.id) !== parseInt(id)
            );
            this.setState({ groupPayments: records }, () => {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  componentDidMount = () => {
    this.getGroupPaymentList();
  };
  followUpGroupDonor = (id) => {
    this.setState({ loader: true });
    events
      .followUpGroupDonor({
        payment_id: id,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ loader: false }, () => {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  confirmGroupDonor = (id) => {
    this.setState({ loader: true });
    events
      .confirmGroupDonor({
        payment_id: id,
      })
      .then((res) => {
        if (res.data.success) {
          this.getGroupPaymentList();
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.getGroupPaymentList();
    });
  };
  render() {
    return (
      <div className="mt-3">
        <Form onSubmit={this.getGroupPaymentList}>
          <Row>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="First Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder="first_name"
                  onChange={(event) => this.handleChange(event, "first_name")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Last Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="last_name"
                  onChange={(event) => this.handleChange(event, "last_name")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="email"
                  onChange={(event) => this.handleChange(event, "email")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Phone"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="phone"
                  onChange={(event) => this.handleChange(event, "phone")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><Form.Check type="checkbox" name="status" onChange={(e) => {this.handleCheckboxChange(e, "status");}}/></InputGroup.Text>
                <Form.Label>Confirmed</Form.Label>
              </InputGroup>
            </Form.Group>  
            <Form.Group as={Col} md={4} className="d-grid">
              <Button
                  type="submit"
                  variant="success"
                  disabled={this.state.submitted}
                >
                  {this.state.submitted && <Spinner size="sm" />} Search
                </Button>
            </Form.Group>    
          </Row>
        </Form>
        <Row>
          <Col md={12}>
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading. Please wait..."
            >
              <Table className="table-responsive">
                <thead>
                  <tr>
                    <th className="border-top-0">#</th>
                    <th className="border-top-0">Group</th>
                    <th className="border-top-0">First Name</th>
                    <th className="border-top-0">Last Name</th>
                    <th className="border-top-0">Email</th>
                    <th className="border-top-0">Phone</th>
                    <th className="border-top-0">Price</th>
                    <th className="border-top-0">Added On</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.groupPayments.length > 0 ? (
                    this.state.groupPayments.map((ele, index) => (
                      <>
                        <tr key={`groupPayments-${index}`}>
                          <td className="border-bottom-0">
                            {this.state.pageNo === 1
                              ? index + 1
                              : (this.state.pageNo - 1) * 50 + index + 1}
                            .
                          </td>
                          <td className="border-bottom-0">{ele.group.name}</td>
                          <td className="border-bottom-0">{ele.first_name}</td>
                          <td className="border-bottom-0">{ele.last_name}</td>
                          <td className="border-bottom-0">{ele.email}</td>
                          <td className="border-bottom-0">{ele.phone}</td>
                          <td className="border-bottom-0">${ele.amount}</td>
                          <td className="border-bottom-0">
                            {moment(ele.added_on).format("MMM Do, YYYY")}
                          </td>
                        </tr>
                        <tr key={`groupPayments-actions-tr-${index}`}>
                          <td className="border-top-0 pt-0 text-center" colSpan={8}>
                            {parseInt(ele.status) === 1 ? (
                              <Button
                                type="button"
                                className="btn-sm m-1"
                                variant="success"
                                onClick={() =>
                                  this.confirmGroupDonor(ele.id)
                                }
                              >
                                <FaCheckCircle /> Confirmed
                              </Button>
                            ) : <>
                              <Button
                                type="button"
                                className="btn-sm m-1"
                                color="primary"
                                onClick={() =>
                                  this.followUpGroupDonor(ele.id)
                                }
                              >
                                Send Follow Up
                              </Button>
                              <Button
                                type="button"
                                className="btn-sm m-1"
                                color="success"
                                onClick={() =>
                                  this.confirmGroupDonor(ele.id)
                                }
                              >
                                Confirm
                              </Button>
                            </>
                            }

                            <Button
                              type="button"
                              className="btn-sm m-1"
                              variant="primary"
                              onClick={() => this.setState({ showModal: true, paymentId: ele.id })}
                            >
                              Edit
                            </Button>
                            <Button
                              type="button"
                              className="btn-sm m-1"
                              variant="danger"
                              onClick={() => this.deleteRecord(ele.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="11"
                        className="text-center"
                        style={{ height: 300 }}
                      >
                        Record not found.
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="11">
                      <Pagination
                          activePage={this.state.pageNo}
                          itemsCountPerPage={50}
                          totalItemsCount={
                              this.state.pageDetails.totalCount ? parseInt(this.state.pageDetails.totalCount) : 0
                          }
                          pageRangeDisplayed={5}
                          onChange={(e) => this.handlePageChange(e)}
                          innerClass="pagination float-left"
                          itemClass="page-item"
                          linkClass="page-link"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </LoadingOverlay>
          </Col>
        </Row>
        {this.state.showModal && (
          <GroupDonorForm
            eventDetails={this.props.eventData}
            paymentId={this.state.paymentId}
            showModal={this.state.showModal}
            closeModal={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  }
}

export default GroupPayments;
